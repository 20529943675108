import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { decodeRouteParam } from 'src/app/globals';
import { GeneralStatistics, Widget } from 'src/app/interfaces';
import { widgets } from './../widgets-helper';

@Component({
  selector: 'app-single-widget',
  templateUrl: './single-widget.component.html',
  styleUrls: ['./single-widget.component.scss'],
})
export class SingleWidgetComponent implements OnInit {
  townshipId: string;
  widgetId: string;
  allWidgets = widgets;
  widget: Observable<Widget>;
  generalStatisticsObs: Observable<GeneralStatistics>;

  constructor(public db: AngularFirestore, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.townshipId = decodeRouteParam(
      this.route.snapshot.paramMap.get('townshipId')
    );
    this.widgetId = decodeRouteParam(
      this.route.snapshot.paramMap.get('widgetId')
    );
    console.log('townshipId', this.townshipId);
    console.log('widgetId', this.widgetId);
    const widgetCollection = this.db.doc<Widget>(
      `township/${this.townshipId}/widgets/${this.widgetId}`
    );
    this.widget = widgetCollection.valueChanges({ idField: 'id' });
    this.widget.subscribe((widget) => {
      console.log('widget', widget);
    });
    this.generalStatisticsObs = this.db
      .doc<GeneralStatistics>(`township/${this.townshipId}/statistics/general`)
      .valueChanges();
    this.generalStatisticsObs.subscribe((statistics) => {
      console.log('generalStatistics', statistics);
    });
  }
}
