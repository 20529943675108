import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupLink } from 'src/app/interfaces';

export interface DialogData {
  groupLink: GroupLink;
}

@Component({
  selector: 'app-delete-group-link',
  templateUrl: './delete-group-link.component.html',
  styleUrls: ['./delete-group-link.component.scss'],
})
export class DeleteGroupLinkComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
}
