// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widgets-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

@media only screen and (max-width: 649px) and (min-width: 0px) {
  .widget-wrapper {
    width: 100% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/statistics/statistics.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;AADF;;AAIA;EACE;IACE,sBAAA;EADF;AACF","sourcesContent":[".page {\n}\n.widgets-wrapper {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width: 100%;\n}\n\n@media only screen and (max-width: 649px) and (min-width: 0px) {\n  .widget-wrapper {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
