import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { setDoc, doc } from 'firebase/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Font } from 'src/app/interfaces';

export interface DialogData {
  tag: Font;
}

@Component({
  selector: 'app-edit-font',
  templateUrl: './edit-font.component.html',
  styleUrls: ['./edit-font.component.scss'],
})
export class EditFontComponent implements OnInit {
  saving = false;
  newFont = false;
  fontId: string;
  fontForm: UntypedFormGroup;
  fontFile: File;
  townshipId = localStorage.getItem('township');
  townShipRef = '/township/' + this.townshipId;

  constructor(
    public db: AngularFirestore,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditFontComponent>
  ) {}

  ngOnInit(): void {
    this.fontForm = this.fb.group({
      name: [, Validators.required],
    });
    if (this.data.tag) {
      this.fontId = this.data.tag.id;
      this.fontForm.patchValue(this.data.tag);
    } else {
      this.fontId = this.db.createId();
      this.newFont = true;
    }
  }

  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const saveObj = { ...this.fontForm.value } as Font;
    saveObj.storageRefPath = `${this.townshipId}/fonts/${this.fontFile.name}`;
    console.log('saveObj', saveObj);
    // Save values to tb :)
    const uploadTask = await this.storage.upload(
      saveObj.storageRefPath,
      this.fontFile
    );
    console.log('downloadUrl', await uploadTask.ref.getDownloadURL());
    await setDoc(
      doc(this.db.firestore, `${this.townShipRef}/fonts/${this.fontId}`),
      { ...saveObj },
      { merge: true }
    );
    this.dialogRef.close();
  }

  openFileInput(id) {
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }
  uploadedFile(event, id) {
    const file = event.target.files[0] as File;
    if (!file) {
      return;
    }
    console.log('id', id);
    console.log('file', file);
    console.log('file.size', file.size);
    if (id === 'font') {
      if (file.name) {
        this.fontFile = file;
        this.fontForm.controls.name.setValue(file.name);
      } else {
        console.error('file has no name?');
      }
    }
  }

  getError(name) {
    const field = this.fontForm.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      return '';
    }
  }
}
