import { Component, OnInit, Inject } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  file: File;
  ratio: string;
  resizeWidth: number;
  resizeHeight: number;
  staticWidth: number;
  staticHeight: number;
  disableResizeSquares: boolean;
  containWithinAspectRatio: boolean;
  cropperMaxWidth: number;
  cropperMaxHeight: number;
  other?: any;
}

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit {
  ratio: string = this.data.ratio;
  resizeWidth: number = this.data.resizeWidth;
  resizeHeight: number = this.data.resizeHeight;
  staticWidth: number = this.data.staticWidth;
  staticHeight: number = this.data.staticHeight;
  disableResizeSquares: boolean = this.data.disableResizeSquares;
  containWithinAspectRatio: boolean = this.data.containWithinAspectRatio;
  cropperMaxWidth: number = this.data.cropperMaxWidth;
  cropperMaxHeight: number = this.data.cropperMaxHeight;
  imageFile: any;
  croppedImage: any = '';

  constructor(
    private dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    // if (this.data.other.upscale && this.data.other.upscale === true) {
    // } else {
    this.imageFile = this.data.file;
    // }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  save() {
    let image = this.croppedImage;

    fetch(image)
      .then((res) => res.blob())
      .then((blob) => {
        image = new File([blob], `${this.data.file.name}`, {
          type: 'image/png',
        });
        this.dialogRef.close({
          result: 'success',
          image: image,
          other: this.data.other ? this.data.other : null,
        });
      });
  }
}
