import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  VoucherGroup,
  Voucher,
  Township,
  Theme,
  Organisation,
} from '../interfaces';
import { VoucherGroupEditComponent } from './voucher-group-edit/voucher-group-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VoucherGroupDetailComponent } from './voucher-group-detail/voucher-group-detail.component';
import * as XLSX from 'xlsx';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActionListComponent } from './dialogs/action-list/action-list.component';
import { ChangeHiddenStatusComponent } from './dialogs/change-hidden-status/change-hidden-status.component';
import { FinishVouchergroupComponent } from './dialogs/finish-vouchergroup/finish-vouchergroup.component';
import {
  increment,
  setDoc,
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  query,
  DocumentData,
  QuerySnapshot,
  Timestamp,
  onSnapshot,
} from 'firebase/firestore';
import { ThemedVoucherGroupEditComponent } from './themed-voucher-group-edit/themed-voucher-group-edit.component';
import { AmbitionLevels, BurdenOfProofStatus } from '../enums';
import exportFromJSON, { ExportType } from 'export-from-json';
import { VouchersExportTypeComponent } from './dialogs/vouchers-export-type/vouchers-export-type.component';
import { iframeUrl, shortenNumber } from '../globals';
import moment from 'moment';

@Component({
  selector: 'app-voucher-management',
  templateUrl: './voucher-management.component.html',
  styleUrls: ['./voucher-management.component.scss'],
})
export class VoucherManagementComponent implements OnInit {
  env = environment;
  townshipId: string = localStorage.getItem('township');
  statisticsLastUpdatedDate: string;
  showHidden: boolean = false;
  budgetMode: boolean = JSON.parse(localStorage.getItem('budgetMode')) ?? false;
  loaded: boolean = false;
  now = new Date();
  refreshCounter: number = 0;
  voucherGroups: VoucherGroup[] = [];
  chartScheme = {
    domain: environment.chartScheme,
    // '#62C590' - Beschikbaar
    // '#D7F0BC' - Gereserveerd
    // '#066238' - Geactiveerd
    // '#F5DC6A' - Gedeeltelijk geclaimd
    // '#FC9550' - Geclaimd
    // '#1A697F' - Betaald
    // '#E1DFD6' - Niet Beschikbaar
  };

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    const township = (
      await getDoc(doc(this.db.firestore, `township/${this.townshipId}`))
    ).data() as Township;

    this.statisticsLastUpdatedDate = township.statisticsLastUpdated!
      ? moment(township.statisticsLastUpdated.voucherGroups.toDate()).format(
          '[om] HH:mm [op] DD-MM-YYYY'
        )
      : 'N.V.T';

    const voucherGroupQuery = query(
      collection(
        this.db.firestore,
        `/township/${this.townshipId}/voucherGroups`
      )
    );
    const voucherGroupDocs = await getDocs(voucherGroupQuery);

    voucherGroupDocs.forEach((voucherGroup) => {
      const voucherGroupData = this.createStatisticsChart({
        ...voucherGroup.data(),
        id: voucherGroup.id,
      } as VoucherGroup);
      this.voucherGroups.push(voucherGroupData);
    });

    const unsubscribeVoucherGroups = onSnapshot(
      voucherGroupQuery,
      (querySnapshot) => {
        const voucherGroups = [];
        querySnapshot.docs.forEach((doc) => {
          const voucherGroup = this.createStatisticsChart({
            ...doc.data(),
            id: doc.id,
          } as VoucherGroup);
          voucherGroups.push(voucherGroup);
        });
        this.voucherGroups = voucherGroups;
        this.voucherGroups.sort((a, b) => a.name.localeCompare(b.name));
      }
    );
    this.loaded = true;
  }

  createStatisticsChart(voucherGroup: VoucherGroup) {
    voucherGroup.totalVouchers = voucherGroup.totalVouchers ?? 0;
    voucherGroup.paidVouchers = voucherGroup.paidVouchers ?? 0;
    voucherGroup.toBeActivated = voucherGroup.toBeActivated ?? 0;
    voucherGroup.activatedVouchers = voucherGroup.activatedVouchers ?? 0;
    voucherGroup.reservedVouchers = voucherGroup.reservedVouchers ?? 0;
    voucherGroup.partlyClaimedVouchers =
      voucherGroup.partlyClaimedVouchers ?? 0;
    voucherGroup.claimedVouchers = voucherGroup.claimedVouchers ?? 0;
    voucherGroup.budgetActivatedVouchers =
      voucherGroup.budgetActivatedVouchers ?? 0;
    voucherGroup.budgetClaimedVouchers =
      voucherGroup.budgetClaimedVouchers ?? 0;
    voucherGroup.budgetPaidVouchers = voucherGroup.budgetPaidVouchers ?? 0;

    let totalVouchers = 0;
    totalVouchers = totalVouchers + voucherGroup.paidVouchers;
    voucherGroup.claimedVouchers = voucherGroup.claimedVouchers - totalVouchers;
    totalVouchers = totalVouchers + voucherGroup.claimedVouchers;
    voucherGroup.partlyClaimedVouchers =
      voucherGroup.partlyClaimedVouchers - totalVouchers;
    totalVouchers = totalVouchers + voucherGroup.partlyClaimedVouchers;
    voucherGroup.activatedVouchers =
      voucherGroup.activatedVouchers - totalVouchers;
    totalVouchers = totalVouchers + voucherGroup.activatedVouchers;
    voucherGroup.reservedVouchers =
      voucherGroup.reservedVouchers + voucherGroup.toBeActivated;
    let availableVouchers =
      voucherGroup.totalVouchers -
      voucherGroup.activatedVouchers -
      voucherGroup.reservedVouchers -
      voucherGroup.partlyClaimedVouchers -
      voucherGroup.claimedVouchers -
      voucherGroup.paidVouchers;

    let notAvailableTotalVouchers = voucherGroup.totalVouchers;
    if (voucherGroup.totalVouchers > voucherGroup.maxVouchers) {
      voucherGroup.totalVouchers = voucherGroup.maxVouchers;
      let newTotalVouchers = voucherGroup.maxVouchers;
      if (voucherGroup.paidVouchers > newTotalVouchers) {
        voucherGroup.paidVouchers = newTotalVouchers;
        newTotalVouchers = 0;
      } else {
        newTotalVouchers = newTotalVouchers - voucherGroup.paidVouchers;
      }
      if (voucherGroup.claimedVouchers > newTotalVouchers) {
        voucherGroup.claimedVouchers = newTotalVouchers;
        newTotalVouchers = 0;
      } else {
        newTotalVouchers = newTotalVouchers - voucherGroup.claimedVouchers;
      }
      if (voucherGroup.partlyClaimedVouchers > newTotalVouchers) {
        voucherGroup.partlyClaimedVouchers = newTotalVouchers;
        newTotalVouchers = 0;
      } else {
        newTotalVouchers =
          newTotalVouchers - voucherGroup.partlyClaimedVouchers;
      }
      if (voucherGroup.reservedVouchers > newTotalVouchers) {
        voucherGroup.reservedVouchers = newTotalVouchers;
        newTotalVouchers = 0;
      } else {
        newTotalVouchers -= voucherGroup.reservedVouchers;
      }
      if (voucherGroup.activatedVouchers > newTotalVouchers) {
        voucherGroup.activatedVouchers = newTotalVouchers;
        newTotalVouchers = 0;
      } else {
        newTotalVouchers -= voucherGroup.activatedVouchers;
      }
      availableVouchers = newTotalVouchers;
    }
    voucherGroup.statisticsChart = [
      {
        name: 'Beschikbaar',
        value: availableVouchers,
      },
      {
        name: 'Gereserveerd',
        value: voucherGroup.reservedVouchers,
      },
      {
        name: 'Geactiveerd',
        value: voucherGroup.activatedVouchers,
      },
      {
        name: 'Gedeeltelijk geclaimd',
        value: voucherGroup.partlyClaimedVouchers,
      },
      {
        name: 'Geclaimd',
        value: voucherGroup.claimedVouchers,
      },
      {
        name: 'Betaald',
        value: voucherGroup.paidVouchers,
      },
    ];
    if (notAvailableTotalVouchers < voucherGroup.maxVouchers) {
      voucherGroup.statisticsChart.push({
        name: 'Niet beschikbaar',
        value: voucherGroup.maxVouchers - notAvailableTotalVouchers,
      });
    }

    voucherGroup.budgetTotalAmount =
      (voucherGroup.totalVouchers > voucherGroup.maxVouchers
        ? voucherGroup.maxVouchers
        : voucherGroup.totalVouchers) * voucherGroup.couponValue;

    let budgetActivatedVouchers = voucherGroup.budgetActivatedVouchers ?? 0;
    let budgetClaimedVouchers = voucherGroup.budgetClaimedVouchers ?? 0;
    let budgetPaidVouchers = voucherGroup.budgetPaidVouchers ?? 0;

    budgetActivatedVouchers = budgetActivatedVouchers - budgetClaimedVouchers;
    budgetClaimedVouchers = budgetClaimedVouchers - budgetPaidVouchers;
    // if (voucherGroup.cashback) {
    //   budgetClaimedVouchers = voucherGroup.budgetClaimedVouchers ?? 0;
    // }

    voucherGroup.budgetAmountInUse =
      budgetActivatedVouchers + budgetClaimedVouchers + budgetPaidVouchers;

    voucherGroup.budgetChart = [
      {
        name: 'empty1',
        value: 0,
      },
      {
        name: 'empty2',
        value: 0,
      },
      {
        name: 'In omloop',
        value: budgetActivatedVouchers,
      },
      {
        name: 'empty3',
        value: 0,
      },
      {
        name: 'Geclaimd',
        value: budgetClaimedVouchers,
      },
      {
        name: 'Uitbetaald',
        value: budgetPaidVouchers,
      },
      {
        name: 'Nog niet geactiveerd',
        value:
          voucherGroup.budgetTotalAmount -
          budgetActivatedVouchers -
          budgetClaimedVouchers -
          budgetPaidVouchers,
      },
    ];

    return voucherGroup;
  }

  shortenNumber(number) {
    return shortenNumber(number);
  }

  setBudgetMode() {
    this.budgetMode = !this.budgetMode;
    localStorage.setItem('budgetMode', this.budgetMode.toString());
  }

  async openVoucherGroupDetails(voucherGroup: VoucherGroup) {
    let theme: Theme;
    if (voucherGroup?.themeRef) {
      const themeDoc = await getDoc(voucherGroup.themeRef);
      theme = themeDoc.data() as Theme;
      theme.id = themeDoc.id;
      theme.ref = themeDoc.ref;
    }
    this.dialog.open(VoucherGroupDetailComponent, {
      width: '500px',
      data: { townshipId: this.townshipId, voucherGroup, theme },
      autoFocus: false,
    });
  }

  finishVoucherGroup(voucherGroup?) {
    if (voucherGroup && voucherGroup.id === 'expired') {
      return;
    }
    const dialogRef = this.dialog.open(FinishVouchergroupComponent, {
      width: '500px',
      data: { voucherGroup },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.http
          .post(`${environment.functionsUrl}/httpFinishVoucherGroup`, {
            townshipId: localStorage.getItem('township'),
            voucherGroupId: voucherGroup.id,
          })
          .subscribe((res) => {
            this.snackBar.open('Campagne is afgerond.', 'X', {
              duration: 5000,
            });
          });
        this.snackBar.open('Campagne wordt afgerond, even geduld a.u.b.', 'X', {
          duration: 5000,
        });
      }
    });
  }

  async openEditVoucherGroup(voucherGroup?: VoucherGroup, card?: boolean) {
    if (
      (voucherGroup && voucherGroup.id === 'expired') ||
      (this.env.prefix === 'toegangs' && card)
    ) {
      return;
    }
    let component: any = VoucherGroupEditComponent;
    let panelClass = [];
    if (this.env.abbreviation === 'tb') {
      component = ThemedVoucherGroupEditComponent;
      panelClass.push('fullscreen-dialog');
    }
    let theme: Theme;
    if (voucherGroup?.themeRef) {
      const themeDoc = await getDoc(voucherGroup.themeRef);
      if (themeDoc.data()) {
        theme = themeDoc.data() as Theme;
        theme.id = themeDoc.id;
        theme.ref = themeDoc.ref;
      } else {
        delete voucherGroup.themeRef;
      }
    }
    this.dialog.open(component, {
      width: '700px',
      data: {
        voucherGroup,
        theme,
        townshipId: this.townshipId,
        newGroup: voucherGroup ? false : true,
      },
      autoFocus: false,
      disableClose: true,
      panelClass,
    });
  }

  openDuplicateVoucherGroup(voucherGroup: VoucherGroup) {
    this.dialog.open(VoucherGroupEditComponent, {
      width: '700px',
      data: {
        voucherGroup: { ...voucherGroup, name: '' },
        townshipId: this.townshipId,
        newGroup: true,
      },
      disableClose: true,
    });
  }

  async copyIframeUrl(voucherGroup, language?) {
    const encodeTownshipId = this.townshipId;
    const encodeVoucherGroupId = voucherGroup.id;
    // const encodeTownshipId = encodeRouteParam(localStorage.getItem('township'));
    // const encodeVoucherGroupId = encodeRouteParam(voucherGroup.id);

    navigator.clipboard.writeText(
      `${await iframeUrl(this.townshipId, this.db.firestore, language)}/${
        voucherGroup.type === 'sell' ? 'buy' : 'activate'
      }/${encodeTownshipId}/${encodeVoucherGroupId}`
    );
    this.snackBar.open('Link gekopieerd.', 'X', {
      duration: 5000,
    });
  }

  async recalcAllStats() {
    this.snackBar.open(
      'Statistieken worden herberekend, even geduld a.u.b.',
      'X',
      {
        duration: 5000,
      }
    );
    for await (const voucherGroup of this.voucherGroups) {
      this.recalcStats(voucherGroup, false);
    }
    const rightNow = moment().toDate();
    this.statisticsLastUpdatedDate = moment(rightNow).format(
      '[om] HH:mm [op] DD-MM-YYYY'
    );

    await setDoc(
      doc(this.db.firestore, `township/${this.townshipId}`),
      {
        statisticsLastUpdated: { voucherGroups: Timestamp.fromDate(rightNow) },
      },
      { merge: true }
    );
    this.snackBar.open('Statistieken herberekend', 'X', {
      duration: 5000,
    });
  }

  recalcStats(voucherGroup, single) {
    if (single) {
      this.snackBar.open(
        'Statistieken worden herberekend, even geduld a.u.b.',
        'X',
        {
          duration: 5000,
        }
      );
    }

    this.http
      .post(`${environment.functionsUrl}/httpRecalcStatistics`, {
        type: 'voucherGroup',
        townshipId: localStorage.getItem('township'),
        voucherGroupId: voucherGroup.id,
        cashback: voucherGroup.cashback,
      })
      .subscribe((res) => {
        if (single) {
          this.snackBar.open('Statistieken herberekend', 'X', {
            duration: 5000,
          });
        }
      });
  }

  async exportVoucherGroup(voucherGroup: VoucherGroup) {
    const dialog = this.dialog.open(VouchersExportTypeComponent, {
      width: '400px',
    });
    dialog
      .afterClosed()
      .subscribe(async (result?: { type: 'xlsx' | 'json' | 'csv' }) => {
        if (!result?.type) {
          return;
        }
        const voucherGroupVouchers = await getDocs(
          query(
            collection(
              this.db.firestore,
              `township/${this.townshipId}/vouchers/`
            ),
            where('voucherGroupId', '==', voucherGroup.id)
          )
        );
        if (voucherGroupVouchers.docs.length == 0) {
          // Nothing to export
          return this.snackBar.open(
            'Er zijn geen bonnen om te exporteren.',
            'X',
            {
              duration: 5000,
            }
          );
        }
        const fileName = `${this.env.name}.${voucherGroup.name}`;
        switch (result.type) {
          case 'xlsx':
            this.exportVouchersToExcel(
              fileName,
              voucherGroup,
              voucherGroupVouchers
            );
            break;
          case 'json':
            this.exportVouchersToOthers(
              fileName,
              voucherGroupVouchers,
              exportFromJSON.types.json
            );
            break;
          case 'csv':
            this.exportVouchersToOthers(
              fileName,
              voucherGroupVouchers,
              exportFromJSON.types.csv
            );
            break;
        }
      });
  }

  exportVouchersToOthers(
    fileName: string,
    voucherDocs: QuerySnapshot<DocumentData>,
    exportType: ExportType
  ) {
    const vouchersArray = [];
    voucherDocs.docs.forEach((voucherDoc) => {
      const voucher = voucherDoc.data() as Voucher;
      Object.keys(voucher).forEach((key) => {
        const value = voucher[key];
        if (value instanceof Timestamp) {
          // Transforms Timestamp into Date
          voucher[key] = value.toDate();
        }
        if (this.isObject(value)) {
          Object.keys(value).forEach((deepKey) => {
            const deepValue = voucher[key][deepKey];
            if (deepValue instanceof Timestamp) {
              // Transforms Timestamp into Date up to 2 layers deep
              voucher[key][deepKey] = deepValue.toDate();
            }
          });
        }
      });
      vouchersArray.push(voucher);
    });
    exportFromJSON({
      data: vouchersArray,
      fileName: fileName,
      exportType: exportType,
    });
  }

  isObject(obj) {
    return obj === Object(obj);
  }

  async exportVouchersToExcel(
    fileName: string,
    voucherGroup: VoucherGroup,
    voucherDocs: QuerySnapshot<DocumentData>
  ) {
    const organisations = await getDocs(
      collection(
        this.db.firestore,
        `/township/${this.townshipId}/organisations/`
      )
    );
    const vouchersArray = [];
    voucherDocs.docs.forEach((voucherDoc) => {
      const voucher = voucherDoc.data() as Voucher;
      let organisationName = '';
      let iban = '';
      if (voucher.voucherGroupId) {
        const organisationDoc = organisations.docs.find(
          (organisations) => organisations.id === voucher.claimOrganisationId
        );
        if (organisationDoc) {
          const data = organisationDoc.data() as Organisation;
          if (data.sepaSettings) {
            organisationName = data.sepaSettings.name
              ? data.sepaSettings.name
              : '';
            iban = data.sepaSettings.iban ? data.sepaSettings.iban : '';
          }
        }
      }
      const exportVoucherObj = {};
      exportVoucherObj['Vouchernummer'] = voucher.number ? voucher.number : '';
      exportVoucherObj['Huidige waarde'] =
        voucher.value !== null ? Number(voucher.value) : '';
      exportVoucherObj['Originele waarde'] = voucher.originalValue
        ? voucher.originalValue
        : '';
      exportVoucherObj['Verzilveringswaarde'] = voucher.claimAmount
        ? voucher.claimAmount
        : '';
      exportVoucherObj['Opwaardeerwaarde'] = voucher.topUpAmount
        ? voucher.topUpAmount
        : '';
      exportVoucherObj['Retourwaarde'] = voucher.refundAmount
        ? voucher.refundAmount
        : '';
      if (voucherGroup.id === 'expired' || voucherGroup.id === 'partial') {
        exportVoucherObj['Orginele campagne ID'] =
          voucher.originalVoucherGroupId ? voucher.originalVoucherGroupId : '';
      }
      exportVoucherObj['Te betalen waarde'] = voucher.amountToPayOrg
        ? Number(voucher.amountToPayOrg)
        : '';
      exportVoucherObj['Waarde kassabon'] = voucher.receiptValue
        ? Number(voucher.receiptValue)
        : '';
      exportVoucherObj['Kassabon/factuur url'] = voucher.imageUrl
        ? voucher.imageUrl
        : '';
      exportVoucherObj['Naam'] = voucher.name ? voucher.name : '';
      exportVoucherObj['Telefoonnummer'] = voucher.phone ? voucher.phone : '';
      exportVoucherObj['E-mail'] = voucher.email ? voucher.email : '';
      exportVoucherObj['Postcode'] = voucher.postal ? voucher.postal : '';
      exportVoucherObj['Straat'] = voucher.street
        ? voucher.street.toString()
        : '';
      exportVoucherObj['Huisnummer'] = voucher.houseNumber
        ? voucher.houseNumber
        : '';
      exportVoucherObj['Toevoeging'] = voucher.houseNumberAddition
        ? voucher.houseNumberAddition
        : '';
      exportVoucherObj['Woonplaats'] = voucher.city ? voucher.city : '';
      exportVoucherObj['Gemeente'] = voucher.municipality
        ? voucher.municipality
        : '';
      exportVoucherObj['Kenmerk'] = voucher.type ? voucher.type.toString() : '';
      exportVoucherObj['Uitgegeven'] = voucher.distributed ? 'WAAR' : '';
      exportVoucherObj['Activatiedatum'] = voucher.activateDate
        ? moment(voucher.activateDate.toDate())
            .tz('Europe/Amsterdam')
            .format('DD-MM-YYYY')
        : '';
      exportVoucherObj['Activatie tijdstip'] = voucher.activateDate
        ? moment(voucher.activateDate.toDate())
            .tz('Europe/Amsterdam')
            .format('HH:mm')
        : '';
      exportVoucherObj['Activatiebedrijf'] = voucher.activateOrganisation
        ? voucher.activateOrganisation
        : '';
      exportVoucherObj['Claimdatum'] = voucher.claimDate
        ? moment(voucher.claimDate.toDate())
            .tz('Europe/Amsterdam')
            .format('DD-MM-YYYY')
        : '';
      exportVoucherObj['Claimbedrijf'] = voucher.claimOrganisation
        ? voucher.claimOrganisation
        : '';
      exportVoucherObj['Betaaldatum'] = voucher.paidDate
        ? moment(voucher.paidDate.toDate())
            .tz('Europe/Amsterdam')
            .format('DD-MM-YYYY')
        : '';
      exportVoucherObj['Betalingskenmerk'] = voucher.paymentId
        ? voucher.paymentId
        : '';
      exportVoucherObj['Referentienummer betalingsverplichting'] =
        voucher.paymentReference ? voucher.paymentReference : '';
      exportVoucherObj['Referentienummer ondernemer'] =
        voucher.organisationReference ? voucher.organisationReference : '';
      exportVoucherObj['IBAN'] = iban ? iban : '';
      exportVoucherObj['Naam bankrekeninghouder'] = organisationName
        ? organisationName
        : '';
      exportVoucherObj['Vervaldatum'] = voucher.validUntilDate
        ? moment(voucher.validUntilDate.toDate())
            .tz('Europe/Amsterdam')
            .format('DD-MM-YYYY')
        : '';
      exportVoucherObj['Herinnering verstuurd'] = voucher.reminderSend
        ? 'WAAR'
        : '';
      exportVoucherObj['Herinnering verstuurd op'] = voucher.reminderDate
        ? voucher.reminderDate
        : '';
      exportVoucherObj['Bewijslastformulier ingevuld '] =
        voucher?.burdenOfProofForm ? 'WAAR' : '';
      exportVoucherObj['Bewijslastformulier opmerking'] = voucher
        .burdenOfProofForm?.completeRemarks
        ? voucher.burdenOfProofForm.completeRemarks
        : '';
      switch (voucher.burdenOfProofForm?.status) {
        case 'Accepted':
          exportVoucherObj['Status van bewijslastformulier'] =
            BurdenOfProofStatus.Accepted;
          break;
        case 'Submitted':
          exportVoucherObj['Status van bewijslastformulier'] =
            BurdenOfProofStatus.Submitted;
          break;
        case 'Concept':
          exportVoucherObj['Status van bewijslastformulier'] =
            BurdenOfProofStatus.Concept;
          break;
        case 'Rejected':
          exportVoucherObj['Status van bewijslastformulier'] =
            BurdenOfProofStatus.Rejected;
          break;
        default:
          exportVoucherObj['Status van bewijslastformulier'] = '';
      }
      exportVoucherObj['Reden voor afkeuring'] = voucher.burdenOfProofForm
        ?.reasonOfRejection
        ? voucher.burdenOfProofForm.reasonOfRejection
        : '';
      exportVoucherObj['Opmerkingen'] = voucher.notes ? voucher.notes : '';
      exportVoucherObj['Campagne'] = voucherGroup.name ? voucherGroup.name : '';
      exportVoucherObj['Kostenplaats'] = voucher.costCenter
        ? voucher.costCenter
        : '';
      switch (voucher.ambitionLevel) {
        case 'bronze':
          exportVoucherObj['Ambitieniveau'] = AmbitionLevels.bronze;
          break;
        case 'silver':
          exportVoucherObj['Ambitieniveau'] = AmbitionLevels.silver;
          break;
        case 'gold':
          exportVoucherObj['Ambitieniveau'] = AmbitionLevels.gold;
          break;
        case 'platinum':
          exportVoucherObj['Ambitieniveau'] = AmbitionLevels.platinum;
          break;
        default:
          exportVoucherObj['Ambitieniveau'] = '';
      }
      exportVoucherObj['Uitgiftegroep'] = voucher.groupLink
        ? voucher.groupLink
        : '';
      exportVoucherObj['Blacklisted'] = voucher.isBlacklisted ? 'WAAR' : '';
      vouchersArray.push(exportVoucherObj);
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vouchersArray); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Vouchers');

    // /* save to file */
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  openActionList(voucherGroup) {
    this.dialog.open(ActionListComponent, {
      width: '375px',
      data: { voucherGroup: voucherGroup },
    });
  }

  async changeHiddenStatus(voucherGroup, status) {
    const dialogRef = this.dialog.open(ChangeHiddenStatusComponent, {
      width: '375px',
      data: { voucherGroup, type: status },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await setDoc(
          doc(
            this.db.firestore,
            `township/${this.townshipId}/voucherGroups/${voucherGroup.id}`
          ),
          { hidden: status === 'hide' ? true : false },
          { merge: true }
        );
        await setDoc(
          doc(this.db.firestore, `township/${this.townshipId}`),
          { hiddenVoucherGroups: increment(status === 'hide' ? 1 : -1) },
          { merge: true }
        );
        this.snackBar.open(
          status === 'hide'
            ? 'De campagne is verborgen'
            : 'De campagne is weer zichtbaar',
          '',
          {
            duration: 5000,
          }
        );
      }
    });
  }
}
