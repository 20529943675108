// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .widget-line-chart {
  display: block;
  box-sizing: content-box;
  padding: 16px;
}
::ng-deep .widget-line-chart mat-card-header {
  padding: 0;
}
::ng-deep .widget-line-chart mat-card-header .mat-mdc-card-header-text mat-card-title {
  display: flex;
  align-items: center;
}
::ng-deep .widget-line-chart mat-card-content {
  padding: 0;
}
::ng-deep .widget-line-chart mat-card-content .flex-box {
  flex-direction: column;
}
::ng-deep .widget-line-chart mat-card-content .flex-box ::ng-deep .ngx-charts {
  float: none !important;
}

.picker {
  display: flex;
  flex-direction: row;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/statistics/widgets/vouchers-line-chart/vouchers-line-chart.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,uBAAA;EACA,aAAA;AACF;AAAE;EACE,UAAA;AAEJ;AAAM;EACE,aAAA;EACA,mBAAA;AAER;AAEE;EACE,UAAA;AAAJ;AACI;EACE,sBAAA;AACN;AAAM;EACE,sBAAA;AAER;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAAF","sourcesContent":["::ng-deep .widget-line-chart {\n  display: block;\n  box-sizing: content-box;\n  padding: 16px;\n  mat-card-header {\n    padding: 0;\n    .mat-mdc-card-header-text {\n      mat-card-title {\n        display: flex;\n        align-items: center;\n      }\n    }\n  }\n  mat-card-content {\n    padding: 0;\n    .flex-box {\n      flex-direction: column;\n      ::ng-deep .ngx-charts {\n        float: none !important;\n      }\n    }\n  }\n}\n.picker {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
