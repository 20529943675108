// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-form-field {
  width: 100%;
}

::ng-deep .manage-user-dialog-title {
  padding: 0;
}

::ng-deep .manage-user-dialog-content {
  padding: 16px 0 0 0 !important;
}

::ng-deep .manage-user-dialog-actions {
  padding: 0;
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/users/dialogs/manage-user/manage-user.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,UAAA;EACA,aAAA;EACA,yBAAA;AACF","sourcesContent":["mat-form-field {\n  width: 100%;\n}\n\n::ng-deep .manage-user-dialog-title {\n  padding: 0;\n}\n\n::ng-deep .manage-user-dialog-content {\n  padding: 16px 0 0 0 !important;\n}\n\n::ng-deep .manage-user-dialog-actions {\n  padding: 0;\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
