import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteDomainsComponent } from './dialog/confirm-delete-domains/confirm-delete-domains.component';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { StaffelFile, Township, User } from '../interfaces';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { EditThemesDialogComponent } from '../themes/dialogs/edit-themes-dialog/edit-themes-dialog.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ChangePasswordComponent } from './dialog/change-password/change-password.component';
import { EditTownshipFileComponent } from './dialog/edit-township-file/edit-township-file.component';
import { DeleteTownshipFileComponent } from './dialog/delete-township-file/delete-township-file.component';
import { CreateInvoiceComponent } from './dialog/create-invoice/create-invoice.component';
import { CalculateMonthlyCostComponent } from './dialog/calculate-monthly-cost/calculate-monthly-cost.component';
import { AddUserToTownshipComponent } from './dialog/add-user-to-township/add-user-to-township.component';
import { EditStaffelFileComponent } from './dialog/edit-staffel-file/edit-staffel-file.component';
import { MassMailComponent } from './dialog/mass-mail/mass-mail.component';
@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent implements OnInit {
  settingsForm: UntypedFormGroup;
  env = environment;
  saving = false;
  isSuperUser: boolean = false;
  userDoc: AngularFirestoreDocument<User>;
  domainEmptyOnLoad: boolean;
  domainFileUploaded: File;
  domainWorksheet: any;
  deleteDomains = false;
  townshipId = localStorage.getItem('township');
  township: Township;
  themes: any[] = [];
  commissionFiles: any = [];
  staffelFiles: any = [];

  constructor(
    private fb: UntypedFormBuilder,
    private db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private http: HttpClient,
    private storage: AngularFireStorage
  ) {}

  async ngOnInit(): Promise<any> {
    this.settingsForm = this.fb.group({
      usesSendGrid: [],
    });

    this.township = (
      await getDoc(doc(this.db.firestore, `township/${this.townshipId}`))
    ).data() as Township;

    if (this.township.voucherPrefix === 'duurzaamwonen') {
      this.settingsForm.controls.usesSendGrid.disable();
    }

    this.domainEmptyOnLoad = await (
      await getDocs(collection(this.db.firestore, '/blacklistedDomains'))
    ).empty;

    this.settingsForm.patchValue(this.township);

    this.afAuth.user.subscribe(async (user) => {
      this.userDoc = this.db.doc<User>('users/' + user.uid);
      const userData = (await lastValueFrom(this.userDoc.get())).data();
      if (userData.rights === 'admin') {
        this.isSuperUser = true;
      } else {
        this.router.navigate(['/dashboard-township']);
      }
    });

    onSnapshot(
      query(
        collection(
          this.db.firestore,
          `globalSettings/townships/commissionSettings`
        ),
        orderBy('townshipName')
      ),
      { includeMetadataChanges: true },
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const docData = { ...change.doc.data(), id: change.doc.id };
          if (change.type === 'added') {
            this.commissionFiles.push(docData);
          }
          if (change.type === 'modified') {
            const index = this.commissionFiles.findIndex(
              (commissionFile) => commissionFile.id === docData.id
            );
            this.commissionFiles[index] = docData;
          }
          if (change.type === 'removed') {
            this.commissionFiles = this.commissionFiles.filter(
              (commissionFile) => commissionFile.id !== docData.id
            );
          }
          this.sortCommissionFiles();
        });
      }
    );
    onSnapshot(
      query(
        collection(this.db.firestore, `globalSettings/staffelFiles/staffels`),
        orderBy('name')
      ),
      { includeMetadataChanges: true },
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const docData = { ...change.doc.data(), id: change.doc.id };
          if (change.type === 'added') {
            this.staffelFiles.push(docData);
          }
          if (change.type === 'modified') {
            this.staffelFiles[
              this.staffelFiles.findIndex(
                (staffelFile) => staffelFile.id === docData.id
              )
            ] = docData;
          }
          if (change.type === 'removed') {
            this.staffelFiles = this.staffelFiles.filter(
              (staffelFile) => staffelFile.id !== docData.id
            );
          }
          this.sortStaffelFiles();
        });
      }
    );

    const q = query(
      collection(this.db.firestore, `themes`),
      where('env', '==', this.env.name)
    );
    const themesCol = await getDocs(q);
    themesCol.forEach((theme) => {
      let themeData = theme.data();
      themeData = { ...themeData, id: theme.id };
      this.themes.push(themeData);
    });
  }

  async save() {
    this.saving = true;
    try {
      const saveObj = this.settingsForm.value;
      await setDoc(
        doc(this.db.firestore, `/township/${this.townshipId}`),
        { ...saveObj },
        { merge: true }
      );
      this.openSnackBar('Algemene instellingen zijn opgeslagen');
      this.saving = false;
    } catch (e) {
      console.error(e);
      this.openSnackBar('Er is iets misgegaan bij het opslaan');
      this.saving = false;
    }

    const batches = [];
    let batchIndex = 0;
    let operationCounter = 0;
    if (this.domainWorksheet) {
      const addressExcpetion = [];
      const spreadsheet = {};
      Object.keys(this.domainWorksheet).forEach((key) => {
        try {
          if (
            key !== '!ref' &&
            key !== '!margins' &&
            key !== '!autofilter' &&
            key !== '!merges'
          ) {
            const rowId = key.match(/\d+/g).toString();
            const colId = key.match(/[a-zA-Z]+/g).toString();
            if (!spreadsheet[rowId]) {
              spreadsheet[rowId] = {};
            }
            spreadsheet[rowId][colId] = this.domainWorksheet[key].w;
          }
        } catch (error) {
          console.log('key with error:', key);
          console.error(error);
        }
      });
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'domein':
            columnNames[key] = 'domain';
            break;
        }
      });
      delete spreadsheet[1];
      batches[batchIndex] = this.db.firestore.batch();
      Object.keys(spreadsheet).forEach((key) => {
        const rowObj = {} as any;
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          if (colName && spreadsheet[key][colKey].length !== 0) {
            rowObj[colName] = spreadsheet[key][colKey];
          }
        });

        if (operationCounter == 500) {
          batchIndex++;
          operationCounter = 0;
          batches[batchIndex] = this.db.firestore.batch();
        }

        batches[batchIndex].set(
          this.db.doc(`blacklistedDomains/${rowObj.domain}`).ref,
          rowObj,
          { merge: true }
        );
        operationCounter++;
      });
    }

    batches.forEach(async (batch) => {
      await batch.commit();
    });
    if (this.deleteDomains) {
      const requestUrl = `${environment.functionsUrl}/httpDeleteDomains`;
      let res: Observable<any>;
      const postData = {};
      res = this.http.post(requestUrl, postData);
      res.subscribe(async (result) => {
        if (result.message === 'succeed') {
          this.router.navigateByUrl(this.env.defaultRoute.path);
        } else {
          this.snackBar.open('Oops er iets verkeerds gegaan', 'X', {
            duration: 4000,
          });
        }
      });
    } else {
      this.router.navigateByUrl(this.env.defaultRoute.path);
    }
  }

  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }

  uploadedFile(event, type) {
    const file = event.target.files[0] as File;
    this.domainFileUploaded = file;
    this.readExcel(type);
  }

  readExcel(type) {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      const storeData = readFile.result as any;
      const data = new Uint8Array(storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.domainWorksheet = workbook.Sheets[firstSheetName];
    };
    readFile.readAsArrayBuffer(this.domainFileUploaded);
  }

  confirmDeleteDomains(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDomainsComponent, {
      width: '475px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteDomains = true;
        this.snackBar.open(
          'Druk op opslaan om de adressen definitief te verwijderen',
          'X',
          {
            duration: 5000,
          }
        );
      } else {
        this.deleteDomains = false;
      }
    });
  }

  async exportBlacklist() {
    const exportArray = [];
    const blacklistedDomains = await getDocs(
      collection(this.db.firestore, 'blacklistedDomains')
    );
    blacklistedDomains.forEach((domain) => {
      const exportObj = {};
      exportObj['Domein'] = domain.id;
      exportArray.push(exportObj);
    });
    if (exportArray.length === 0) {
      exportArray.push({
        Domein: '',
      });
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Geblokkeerde email domeinen');

    // /* save to file */
    XLSX.writeFile(wb, 'Geblokkeerde email domeinen' + '.xlsx');
  }

  openSnackBar(txt: string) {
    this.snackBar.open(txt, 'X', {
      duration: 5000,
    });
  }

  openCreateInvoiceDialog(townshipFileId: string) {
    this.dialog.open(CreateInvoiceComponent, {
      width: '400px',
      data: {
        townshipFileId: townshipFileId,
      },
      autoFocus: false,
    });
  }

  openCalculateMonthlyCostDialog(commissionFile: any) {
    this.dialog.open(CalculateMonthlyCostComponent, {
      width: '400px',
      data: {
        commissionFile: commissionFile,
        townshipId: commissionFile.id,
      },
      autoFocus: false,
    });
  }

  openEditTownshipFileDialog(townshipFileId: string | null) {
    this.dialog.open(EditTownshipFileComponent, {
      width: '700px',
      data: {
        currentTownshipId: this.townshipId,
        townshipFileId: townshipFileId,
        townshipFileName: this.township.name,
      },
      autoFocus: false,
    });
  }

  openEditStaffelFileDialog(staffelFile: StaffelFile) {
    const townshipsAlreadyInStaffels = [];
    this.staffelFiles.forEach((staffelFileDoc) => {
      if (staffelFileDoc.townships) {
        staffelFileDoc.townships.forEach((staffelFileTownship) => {
          townshipsAlreadyInStaffels.push(staffelFileTownship);
        });
      }
    });

    this.dialog.open(EditStaffelFileComponent, {
      width: '700px',
      data: {
        staffelFile: staffelFile,
        townshipsAlreadyInStaffels: townshipsAlreadyInStaffels,
      },
      autoFocus: false,
    });
  }

  openDeleteTownshipFileDialog(townshipFileId: string, townshipName: string) {
    const dialogRef = this.dialog.open(DeleteTownshipFileComponent, {
      width: '375px',
      data: {
        townshipName: townshipName,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await deleteDoc(
          doc(
            this.db.firestore,
            `globalSettings/townships/commissionSettings/${townshipFileId}`
          )
        );
        this.snackBar.open(
          `Exploitantbestand voor ${townshipName} is verwijderd`,
          'X',
          {
            duration: 5000,
          }
        );
      }
    });
  }

  openEditThemesDialog(theme) {
    const dialog = this.dialog.open(EditThemesDialogComponent, {
      width: '700px',
      data: {
        id: theme.id,
        theme,
      },
      autoFocus: false,
    });

    dialog.afterClosed().subscribe(async (result) => {
      const q = query(
        collection(this.db.firestore, `themes`),
        where('env', '==', this.env.name)
      );
      this.themes = [];
      const themesCol = await getDocs(q);
      themesCol.forEach((theme) => {
        let themeData = theme.data();
        themeData = { ...themeData, id: theme.id };
        this.themes.push(themeData);
      });

      if (localStorage.getItem('tempAmount')) {
        const max = parseInt(localStorage.getItem('tempAmount')) + 1;
        for (let i = 1; i !== max; ++i) {
          this.storage.ref(localStorage.getItem(`tempFile${i}`)).delete();
          localStorage.removeItem(`tempFile${i}`);
        }
        localStorage.removeItem('tempAmount');
      }
    });
  }

  openSendMassMailDialog() {
    const dialog = this.dialog.open(MassMailComponent, {
      width: '700px',
      autoFocus: false,
    });
  }

  openChangePasswordDialog() {
    const dialog = this.dialog.open(ChangePasswordComponent, {
      width: '375px',
      autoFocus: false,
    });
  }

  openAddUserToTownshipDialog() {
    const dialog = this.dialog.open(AddUserToTownshipComponent, {
      width: '375px',
      autoFocus: false,
    });
  }

  openEditThemeDialog() {
    const dialog = this.dialog.open(EditThemesDialogComponent, {
      width: '700px',
      data: {
        theme: {
          basicInfo: {
            inputs: {
              name: {},
              description: {},
              slogan: {},
              type: {},
              couponValue: {},
              suffix: {},
              validUntilDate: {},
              validUntilTime: {},
              validUntilTimeValue: {},
              validUntilTimeType: {},
              updateOnlyActivatedVouchers: {},
              terms: {},
              termsUrl: {},
            },
          },
          extraInfo: {
            inputs: {
              labels: {},
              maxVouchers: {},
              groupLink: {},
              groupLinkTxt: {},
              expiredNote: {},
              claimedNote: {},
              groupContactEmail: {},
              addressType: {},
              activateFromType: {},
              voucherReminderTime: {},
              voucherReminderMail: {},
              amountVoucherReminder: {},
              requestCouponDays: {},
              startDate: {},
              endDate: {},
              postal: {},
              houseNumber: {},
              houseNumberAddition: {},
            },
          },
          extraDocument: {
            inputs: {
              groupPhoneNumber: {},
              arrangementEmail: {},
            },
          },
          guestOptions: {
            inputs: {
              guestsHaveToPay: {},
              voucherPrice: {},
              guestPaymentUrl: {},
              maxVouchers: {},
            },
          },
          backgroundColor: {
            inputs: {
              backgroundColor: {},
            },
          },
          themeImage: {
            inputs: {
              themeImage: {},
            },
          },
          logo: {
            inputs: {},
          },
          fonts: {
            inputs: {
              defaultTextFont: {},
              titleFont: {},
            },
          },
          titleColor: {
            inputs: {
              titleColor: {},
            },
          },
          textColor: {
            inputs: {
              defaultTextColor: {},
            },
          },
          requestOptions: {
            inputs: {
              verifyPhone: {},
              hideVoucherRemainingInfo: {},
              requestName: {},
              requestPhone: {},
              requestIdentification: {},
              uniquePhoneNumbersOnly: {},
              uniqueMailsOnly: {},
              hideHeaderImage: {},
              noAdressChecks: {},
              dontRequestAddress: {},
              multiplePeople: {},
            },
          },
          voucherOptions: {
            inputs: {
              sendSmsCoupon: {},
              claimInstantly: {},
              forceSpentFullAmount: {},
              dontSyncWithApi: {},
              multipleUses: {},
              scannable: {},
              nameOnVoucher: {},
              priceOnTicket: {},
            },
          },
        },
      },
      autoFocus: false,
    });
    dialog.afterClosed().subscribe(async (result) => {
      const q = query(
        collection(this.db.firestore, `themes`),
        where('env', '==', this.env.name)
      );
      this.themes = [];
      const themesCol = await getDocs(q);
      themesCol.forEach((theme) => {
        let themeData = theme.data();
        themeData = { ...themeData, id: theme.id };
        this.themes.push(themeData);
      });

      if (localStorage.getItem('tempAmount')) {
        const max = parseInt(localStorage.getItem('tempAmount')) + 1;
        for (let i = 1; i !== max; ++i) {
          this.storage.ref(localStorage.getItem(`tempFile${i}`)).delete();
          localStorage.removeItem(`tempFile${i}`);
        }
        localStorage.removeItem('tempAmount');
      }
    });
  }

  sortCommissionFiles() {
    this.commissionFiles.sort((file1, file2) => {
      const a = file1.townshipName.toLowerCase();
      const b = file2.townshipName.toLowerCase();
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }

  sortStaffelFiles() {
    this.staffelFiles.sort((file1, file2) => {
      const a = file1.name.toLowerCase();
      const b = file2.name.toLowerCase();
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }
}
