import { Component, OnInit, Inject } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Voucher, Organisation } from 'src/app/interfaces';
import {
  deleteField,
  DocumentReference,
  DocumentData,
  setDoc,
} from 'firebase/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ReceiptComponent } from '../../../voucher-management/dialogs/receipt/receipt.component';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { doc, getDoc } from 'firebase/firestore';

export interface DialogData {
  voucherNumber: string;
}

@Component({
  selector: 'app-check-voucher',
  templateUrl: './check-voucher.component.html',
  styleUrls: ['./check-voucher.component.scss'],
})
export class CheckVoucherComponent implements OnInit {
  numberForm: UntypedFormGroup;
  voucherForm: UntypedFormGroup;
  voucherNumber: string;
  voucher: Voucher;
  voucherDoc: DocumentReference<DocumentData>;
  saving: boolean;

  voucherValue: string;
  amountToPay: string;

  organisationCollection: AngularFirestoreCollection<Organisation>;
  organisations: Observable<Organisation[]>;
  organisationsArray: Array<Organisation>;

  receiptFile: any;
  receiptUrl: string;
  townshipId = localStorage.getItem('township') as string;
  deleteReceipt: boolean;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CheckVoucherComponent>,
    private snackBar: MatSnackBar,
    private storage: AngularFireStorage
  ) {}

  ngOnInit() {
    this.voucherForm = this.fb.group({
      receiptValue: [, Validators.required],
    });
    this.voucherForm
      .get('receiptValue')
      .valueChanges.subscribe((receiptValue) => {
        console.log('receiptValue', receiptValue);
        this.amountToPay = (
          receiptValue - this.voucher.value > 0
            ? receiptValue - this.voucher.value
            : 0
        )
          .toFixed(2)
          .replace('.', ',');
        console.log('amountToPay', this.amountToPay);
      });
    if (this.data.voucherNumber) {
      this.getVoucher(this.data.voucherNumber);
    }

    this.organisationCollection = this.db.collection<Organisation>(
      'township/' + localStorage.getItem('township') + '/organisations/',
      (ref) => ref.orderBy('name', 'asc')
    );
    this.organisations = this.organisationCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Organisation;
          const id = a.payload.doc['id'];
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.organisations.subscribe((organisations) => {
      // console.log('products', products);
      this.organisationsArray = organisations;
    });
  }

  async getVoucher(voucherNumber) {
    if (this.saving) {
      return;
    }
    this.saving = true;
    this.voucherDoc = doc(
      this.db.firestore,
      `township/${localStorage.getItem('township')}/vouchers/${voucherNumber}`
    );
    this.voucher = (await (await getDoc(this.voucherDoc)).data()) as Voucher;
    if (this.voucher) {
      this.voucherValue = this.voucher.value.toFixed(2).replace('.', ',');
      console.log('this.voucher', this.voucher);
      const patchObj = this.voucher as any;
      if (this.voucher.activateDate) {
        patchObj.activateDate = this.voucher.activateDate.toDate();
      }
      if (this.voucher.claimDate) {
        patchObj.claimDate = this.voucher.claimDate.toDate();
      }
      if (this.voucher.paidDate) {
        patchObj.paidDate = this.voucher.paidDate.toDate();
      }
      if (this.voucher.imageUrl) {
        this.receiptUrl = this.voucher.imageUrl;
      }
      this.voucherNumber = this.voucher.number;
      this.voucherForm.patchValue(this.voucher);
    }
    this.saving = false;
  }

  addEditReceipt() {
    const dialogRef = this.dialog.open(ReceiptComponent, {
      width: 'auto',
      height: 'auto',
      // data:
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed', result);
      if (result) {
        this.receiptFile = result;
      }
    });
  }

  setDelete() {
    this.deleteReceipt = true;
    this.snackBar.open(
      'De afbeelding wordt verwijderd wanneer de bon wordt opgeslagen',
      'X',
      {
        duration: 5000,
      }
    );
  }
  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const saveObj = { ...this.voucherForm.value };
    saveObj.amountToPayOrg =
      saveObj.receiptValue > this.voucher.value
        ? this.voucher.value
        : saveObj.receiptValue;
    // Make convert empty strings to 'null' for our queries.
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = null;
      } else if (
        typeof saveObj[key] === 'string' &&
        saveObj[key].length === 0
      ) {
        saveObj[key] = null;
      }
    });
    console.log('saveObj', saveObj);
    // Save values to tb :)
    if (this.receiptFile) {
      const file = this.receiptFile;
      const filePath = `${this.townshipId}/receipts/${this.voucherNumber}`;
      const uploadTask = await this.storage.upload(filePath, file);
      saveObj.imageUrl = await uploadTask.ref.getDownloadURL();
      this.receiptFile = null;
    }
    if (this.deleteReceipt) {
      const fileUrl = `${this.townshipId}/receipts/${this.voucherNumber}`;
      const file = await this.storage.ref(fileUrl).delete();
      file.subscribe(() => {
        console.log('file deleted');
      });
      saveObj.imageUrl = deleteField();
    }
    await setDoc(this.voucherDoc, saveObj, { merge: true });
    this.dialogRef.close();
  }
}
