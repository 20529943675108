import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-delete-domains',
  templateUrl: './confirm-delete-domains.component.html',
  styleUrls: ['./confirm-delete-domains.component.scss']
})
export class ConfirmDeleteDomainsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
