import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { doc, getDoc, setDoc, addDoc, collection } from 'firebase/firestore';
import { db } from 'src/app/app.component';
import { AppointmentTypeKind } from 'src/app/enums';

@Component({
  selector: 'app-manage-appointment-types',
  templateUrl: './manage-appointment-types.component.html',
  styleUrls: ['./manage-appointment-types.component.scss'],
})
export class ManageAppointmentTypesComponent implements OnInit {
  townshipId = localStorage.getItem('township') as string;
  appointmentTypeId: string;
  appointmentType: any;
  appointmentForm: UntypedFormGroup = this.fb.group({
    type: ['', [Validators.required]],
    name: ['', [Validators.required]],
    color: [, [Validators.required]],
    description: ['', [Validators.required]],
    pipedriveId: [null],
    durationHours: ['', [Validators.required]],
    durationMinutes: [
      '',
      [Validators.required, Validators.max(59), Validators.min(0)],
    ],
    externalCoach: [false],
    multipleCoaches: [false],
    recurring: [false],
    // recurringDuration: [],
  });
  apointmentTypeKind = Object.keys(AppointmentTypeKind).map((type) => ({
    key: type,
    value: AppointmentTypeKind[type],
  }));
  colors = [
    '#FFADAD',
    '#FFD6A5',
    '#FDFFB6',
    '#CAFFBF',
    '#9BF6FF',
    '#A0C4FF',
    '#BDB2FF',
    '#FFC6FF',
  ];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.appointmentTypeId = this.route.snapshot.paramMap.get('id');
    if (this.appointmentTypeId !== 'new') {
      this.appointmentType = (
        await getDoc(
          doc(
            db,
            `township/${this.townshipId}/appointmentTypes/${this.appointmentTypeId}`
          )
        )
      ).data();
      this.appointmentForm.patchValue(this.appointmentType);
    }
  }

  async save() {
    if (this.appointmentForm.invalid) {
      return this.appointmentForm.markAllAsTouched();
    }
    const form = this.appointmentForm.value;
    const data = {
      type: form.type,
      name: form.name,
      color: form.color,
      description: form.description,
      pipedriveId: form.pipedriveId,
      durationHours: form.durationHours,
      durationMinutes: form.durationMinutes,
      externalCoach: form.externalCoach,
      multipleCoaches: form.type === 'job' ? true : form.multipleCoaches,
      recurring: form.recurring,
      // recurringDuration: this.appointmentForm.value.recurringDuration,
    };
    if (this.appointmentTypeId === 'new') {
      await addDoc(
        collection(db, `township/${this.townshipId}/appointmentTypes`),
        data
      );
    } else {
      await setDoc(
        doc(
          db,
          `township/${this.townshipId}/appointmentTypes/${this.appointmentTypeId}`
        ),
        data,
        { merge: true }
      );
    }
    this.router.navigate(['appointment-types']);
  }

  cancel() {
    this.router.navigate(['appointment-types']);
  }

  setColor(color: string) {
    console.log(color);

    this.appointmentForm.controls.color.setValue(color);
  }
}
