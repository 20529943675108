// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  margin-bottom: 0;
}

.mat-mdc-dialog-content {
  padding: 10px 0 0 0;
}

.mat-mdc-dialog-actions {
  padding: 10px 0 0 0;
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/statistics/statistics-management/widget-delete/widget-delete.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,mBAAA;AAEF;;AAAA;EACE,mBAAA;EACA,aAAA;EACA,yBAAA;AAGF","sourcesContent":[".title {\n  margin-bottom: 0;\n}\n.mat-mdc-dialog-content {\n  padding: 10px 0 0 0;\n}\n.mat-mdc-dialog-actions {\n  padding: 10px 0 0 0;\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
