import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-continue',
  templateUrl: './continue.component.html',
  styleUrls: ['./continue.component.scss'],
})
export class ContinueComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
