import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Appointment, PlanningUser } from 'src/app/interfaces';

export interface DialogData {
  appointment: Appointment;
  planningUsers: PlanningUser[];
}

@Component({
  selector: 'app-team-appointment-details',
  templateUrl: './team-appointment-details.component.html',
  styleUrls: ['./team-appointment-details.component.scss'],
})
export class TeamAppointmentDetailsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<TeamAppointmentDetailsComponent>
  ) {}

  createReport() {
    // const dialogRef = this.dialog.open(ManageReportComponent, {
    //   data: {
    //     appointment: this.data.appointment,
    //     planningUser: this.data.planningUser,
    //   },
    //   disableClose: true,
    //   panelClass: ['fullscreen-dialog'],
    // });
    // dialogRef.afterClosed().subscribe(async (changesMade) => {
    //   console.log('changesMade', changesMade);
    //   if (changesMade) {
    //     this.dialogRef.close();
    //   }
    // });
  }
}
