import { Component, OnInit } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Township, ExternalVoucherGroup, ExternalVoucher } from '../interfaces';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ManageExternalVoucherGroupComponent } from './dialogs/manage-external-voucher-group/manage-external-voucher-group.component';
import { getDocs, collection, query, where } from 'firebase/firestore';

@Component({
  selector: 'app-external-voucher-management',
  templateUrl: './external-voucher-management.component.html',
  styleUrls: ['./external-voucher-management.component.scss'],
})
export class ExternalVoucherManagementComponent implements OnInit {
  env = environment;
  externalVoucherGroupsRef: AngularFirestoreCollection<ExternalVoucherGroup>;
  externalVoucherGroups: Observable<ExternalVoucherGroup[]>;
  townshipDoc: AngularFirestoreDocument<Township>;
  $township: Observable<Township>;
  township: Township;
  loaded = false;

  public chartScheme = {
    domain: ['#62C590', '#cff584', '#F5DC69', '#FC9F5B', '#1A697F'],
  };

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.externalVoucherGroupsRef = this.db.collection(
      '/externalVouchers',
      (ref) => ref.orderBy('name')
    );
    this.externalVoucherGroups = this.externalVoucherGroupsRef
      .snapshotChanges()
      .pipe(
        debounceTime(1000),
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as ExternalVoucherGroup;
            data.id = a.payload.doc['id'];
            data.totalVouchers = data.totalVouchers ? data.totalVouchers : 0;
            data.distributedVouchers = data.distributedVouchers
              ? data.distributedVouchers
              : 0;
            const reservedVouchers = data.distributedVouchers;
            const availableVouchers =
              data.totalVouchers - data.distributedVouchers;
            data.statisticsChart = [
              {
                name: 'Beschikbaar',
                value: availableVouchers,
              },
              {
                name: 'Uitgegeven',
                value: reservedVouchers,
              },
            ];
            return { ...data };
          })
        )
      );
    this.externalVoucherGroups.subscribe((externalVoucherGroups) => {
      console.log('externalVoucherGroups', externalVoucherGroups);
      this.loaded = true;
    });
  }

  openEditVoucherGroup(voucherGroup?) {
    this.dialog.open(ManageExternalVoucherGroupComponent, {
      width: '500px',
      data: { voucherGroup, township: this.township },
    });
  }

  async recalcStats(voucherGroup) {
    const requestUrl = `${environment.functionsUrl}/httpRecalcStatistics`;
    // console.log('requestUrl', requestUrl);
    this.http
      .post(requestUrl, {
        type: 'externalVoucherGroup',
        voucherGroupId: voucherGroup.id,
      })
      .subscribe((res) => {
        console.log(res);
      });
    this.snackBar.open(
      'Statistieken worden herberekend, even geduld a.u.b.',
      'X',
      {
        duration: 5000,
      }
    );
  }

  async exportVoucherGroup(voucherGroup, onlyExportAvailable) {
    console.log('voucherGroup', voucherGroup);

    let voucherRef = query(
      collection(
        this.db.firestore,
        `/externalVouchers/${voucherGroup.id}/vouchers/`
      )
    );
    if (onlyExportAvailable) {
      voucherRef = query(
        collection(
          this.db.firestore,
          `/externalVouchers/${voucherGroup.id}/vouchers/`
        ),
        where('distributed', '==', null)
      );
    }
    const vouchersArray = [];
    const vouchersObservable = await getDocs(voucherRef);
    vouchersObservable.forEach((voucherDoc) => {
      // console.log('vouchers', vouchers);
      const voucher = voucherDoc.data() as ExternalVoucher;
      const exportVoucherObj = {};
      exportVoucherObj['Vouchernummer'] = voucher.number ? voucher.number : '';
      // exportVoucherObj['PIN'] = voucher.pin ? voucher.pin : '';
      exportVoucherObj['Uitgegeven'] = voucher.distributed ? 'WAAR' : '';
      exportVoucherObj['Gemeente/Organisatie naam'] = voucher.townshipName
        ? voucher.townshipName
        : '';
      exportVoucherObj['Gemeente/Organisatie id'] = voucher.townshipId
        ? voucher.townshipId
        : '';
      exportVoucherObj['Campagne naam'] = voucher.voucherGroupName
        ? voucher.voucherGroupName
        : '';
      exportVoucherObj['Campagne id'] = voucher.voucherGroupId
        ? voucher.voucherGroupId
        : '';
      vouchersArray.push(exportVoucherObj);
    });
    if (vouchersArray.length > 0) {
      console.log('vouchers', vouchersArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vouchersArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Vouchers');

      // /* save to file */
      XLSX.writeFile(wb, this.env.name + '.' + voucherGroup.name + '.xlsx');
    } else {
      // Nothing to export
      this.snackBar.open('Er zijn geen bonnen om te exporteren.', 'X', {
        duration: 5000,
      });
    }
  }
}
