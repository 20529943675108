import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Widget } from 'src/app/interfaces';
import { checkAndUpdateStats } from '../../bigquery-helper';
import { calculateWidgetHeight } from '../../widgets-helper';
import { Observable } from 'rxjs';

@Component({
  selector: 'widget-total-claimed-value',
  templateUrl: './widget-total-claimed-value.component.html',
  styleUrls: ['./widget-total-claimed-value.component.scss'],
})
export class WidgetTotalClaimedValueComponent implements OnInit {
  @Input() widget: Widget;
  townshipId = localStorage.getItem('township');
  statistics: Observable<any>;
  compareDate: Date;
  valueNow = 0;
  valueThen = 0;
  amountToShow: any = 0;
  percentageIncrease = 0;
  widgetHeight: any;

  constructor(public db: AngularFirestore) {}

  async ngOnInit() {
    checkAndUpdateStats(this.widget, 'bigqueryTotalAmountToPayOrgs', {
      townshipId: this.townshipId,
      widgetId: this.widget.id,
    });
    this.statistics = this.db
      .doc<any>(
        `township/${localStorage.getItem('township')}/widgets/${this.widget.id}`
      )
      .valueChanges();
    this.statistics.subscribe((stats) => {
      this.compareDate = stats.settings.fromDate.toDate();
      this.valueNow = stats.stats?.now ? stats.stats.now.toFixed(2) : 0;
      this.valueThen = stats.stats?.then ? stats.stats.then.toFixed(2) : 0;
      this.amountToShow = Number(this.valueNow).toLocaleString('nl-NL', {
        style: 'currency',
        currency: 'EUR',
      });
      let percentage =
        this.valueThen && this.valueNow
          ? (this.valueThen / this.valueNow) * 100
          : 100;
      // console.log('percentage', percentage);
      percentage =
        percentage > 99 && percentage < 100
          ? Math.floor(percentage)
          : Math.round(percentage);
      // console.log('percentage after rounding', percentage);
      this.percentageIncrease = 100 - percentage;
      this.widgetHeight = calculateWidgetHeight(stats.height, 34);
    });
  }
}
