// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-items {
  width: 85%;
  margin: 0 auto 0 auto;
}
.list-items .list-item-wrapper {
  border-bottom: 1px solid black;
}
.list-items .list-item-wrapper .list-item-col-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.list-items .list-item-wrapper .list-item-col-wrapper .list-item-col {
  flex: 1;
  align-content: center;
  min-height: 50px;
}
.list-items .list-item-wrapper .list-item-col-wrapper .list-item-col .type-color {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: 1px solid #bfc8cc;
}
.list-items .list-item-wrapper .list-item-col-wrapper .list-item-col:first-of-type {
  display: flex;
  align-items: center;
  gap: 10px;
}
.list-items .list-item-wrapper .list-item-col-wrapper .list-item-buttons {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 659px) and (min-width: 0px) {
  .list-items .list-item-wrapper .list-item-col-wrapper .unimportant {
    display: none;
  }
}
.list-items .headers {
  border-bottom: 0;
  font-weight: bold;
  margin: 0 0 20px 0;
}
@media only screen and (max-width: 659px) and (min-width: 0px) {
  .list-items .headers .unimportant {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/appointment-types/appointment-types.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,qBAAA;AACF;AAAE;EACE,8BAAA;AAEJ;AAAI;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AAEN;AADM;EACE,OAAA;EACA,qBAAA;EACA,gBAAA;AAGR;AAFQ;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAAA;AAIV;AADM;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAGR;AADM;EACE,aAAA;EACA,yBAAA;AAGR;AADM;EACE;IACE,aAAA;EAGR;AACF;AAEE;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AACI;EACE;IACE,aAAA;EACN;AACF","sourcesContent":[".list-items {\n  width: 85%;\n  margin: 0 auto 0 auto;\n  .list-item-wrapper {\n    border-bottom: 1px solid black;\n\n    .list-item-col-wrapper {\n      display: flex;\n      width: 100%;\n      height: 100%;\n      overflow: hidden;\n      .list-item-col {\n        flex: 1;\n        align-content: center;\n        min-height: 50px;\n        .type-color {\n          min-width: 20px;\n          min-height: 20px;\n          border-radius: 50%;\n          border: 1px solid #bfc8cc;\n        }\n      }\n      .list-item-col:first-of-type {\n        display: flex;\n        align-items: center;\n        gap: 10px;\n      }\n      .list-item-buttons {\n        display: flex;\n        justify-content: flex-end;\n      }\n      @media only screen and (max-width: 659px) and (min-width: 0px) {\n        .unimportant {\n          display: none;\n        }\n      }\n    }\n  }\n\n  .headers {\n    border-bottom: 0;\n    font-weight: bold;\n    margin: 0 0 20px 0;\n    @media only screen and (max-width: 659px) and (min-width: 0px) {\n      .unimportant {\n        display: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
