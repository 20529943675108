import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  where,
  query,
} from 'firebase/firestore';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, lastValueFrom } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { EditCharacteristicsDialogComponent } from './dialog/edit-characteristics-dialog/edit-characteristics-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Address, Organisation } from '../interfaces';
import { ConfirmDeleteDialogComponent } from './dialog/confirm-delete-dialog/confirm-delete-dialog.component';
import { getFunctionsLocationUrl } from '../globals';
import { HttpClient } from '@angular/common/http';
import { getAuth } from 'firebase/auth';

@Component({
  selector: 'app-characteristics',
  templateUrl: './characteristics.component.html',
  styleUrls: ['./characteristics.component.scss'],
})
export class CharacteristicsComponent implements OnInit {
  townshipId = localStorage.getItem('township');
  organisationId = localStorage.getItem('organisationId');
  addressForm: UntypedFormGroup;
  validAddressForm = false;
  addressTypes: string[] = [];
  addressTypesChecked = [];
  addresses = [];
  addOrSearched: boolean = false;
  formChanged: boolean;
  addressFileUploaded: File;
  addressWorksheet: any;
  organisation?: Organisation;
  orgHasRights = false;
  env = environment;
  saving = false;
  isOrganisation = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public db: AngularFirestore,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {}

  async ngOnInit(): Promise<void> {
    console.log('this env', this.env);
    console.log('organisationId =>', this.organisationId);
    this.addressForm = this.formBuilder.group({
      postal: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[1-9][0-9]{3}[ ]{0,1}(?!SA|SD|SS)[A-Z]{2}$/i),
        ],
      ],
      houseNumber: ['', [Validators.pattern('[0-9]*')]],
      addition: [''],
    });

    this.addressForm.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        if (
          this.addressForm.value.postal &&
          this.addressForm.value.houseNumber
        ) {
          this.validAddressForm = true;
        }
      });

    if (this.env.env == 'org' && this.organisationId) {
      this.isOrganisation = true;
      const orgRef = doc(
        this.db.firestore,
        `township/${this.townshipId}/organisations/${this.organisationId}`
      );
      this.organisation = (await getDoc(orgRef)).data() as Organisation;
      console.log('org', this.organisation);
      if (this.organisation && this.organisation.addressTypesRights) {
        this.organisation.addressTypesRights.forEach((type) => {
          this.addressTypes.push(type.name.toString().toLowerCase());
          this.addressForm.addControl(type.name, new UntypedFormControl());
        });
      }
    } else {
      const querySnapshot = await getDocs(
        collection(this.db.firestore, `township/${this.townshipId}/addresTypes`)
      );
      querySnapshot.forEach((doc) => {
        this.addressTypes.push(doc.id);
        this.addressForm.addControl(doc.id, new UntypedFormControl());
      });
    }

    console.log('addressTypes', this.addressTypes);
    // const addressesSnapshot = await getDocs(
    //   collection(this.db.firestore, `township/${this.townshipId}/addresses`)
    // );
    // addressesSnapshot.forEach((doc) => {
    //   this.addresses.push(doc.data());
    // });
  }

  async addClicked() {
    if (this.saving) {
      return;
    }
    this.addOrSearched = true;
    if (!this.addressForm.value.postal && !this.addressForm.value.houseNumber) {
      this.addressForm.markAllAsTouched();
    }
    if (!this.addressForm.value.houseNumber) {
      this.validAddressForm = false;
      this.addressForm.controls['houseNumber'].addValidators([
        Validators.required,
      ]);
      this.addressForm.markAllAsTouched();
      this.addressForm.controls['houseNumber'].removeValidators([
        Validators.required,
      ]);
    }
    if (this.validAddressForm) {
      let id;
      const postal = this.addressForm.value.postal
        .replace(' ', '')
        .toUpperCase();
      const additionValue =
        this.addressForm.value.addition === '-'
          ? ''
          : this.addressForm.value.addition.toLowerCase();
      if (additionValue) {
        id = postal + this.addressForm.value.houseNumber + additionValue;
      } else {
        id = postal + this.addressForm.value.houseNumber;
      }
      console.log(id);
      const addressRef = this.db.firestore
        .collection(`township/${this.townshipId}/addresses/`)
        .doc(id);
      const addressDoc = await addressRef.get();
      if (addressDoc.exists) {
        const addressData = addressDoc.data();
        const addressTypes = addressData.type;
        this.addressTypesChecked.forEach((type) => {
          if (addressTypes.indexOf(type) == -1) {
            addressTypes.push(type);
          }
        });
        try {
          this.saving = true;
          await addressRef.update({
            type: addressTypes,
          });
          const oldAddress = this.addresses.find((obj) => {
            return (
              obj.postal === postal &&
              obj.houseNumber === this.addressForm.value.houseNumber
            );
          });
          const index = this.addresses.indexOf(oldAddress, 0);
          this.addresses.splice(index, 1);
          this.addresses.push({
            houseNumber: this.addressForm.value.houseNumber,
            postal: postal,
            houseNumberAddition: additionValue,
            type: addressTypes,
          });
          this.sortAddresses();
          this.saving = false;
          this.snackBar.open(
            'De kenmerken van dit adres zijn bijgewerkt',
            'X',
            {
              duration: 5000,
            }
          );
        } catch (error) {
          console.log(error);
          this.snackBar.open('Er is iets misgegaan', 'X', {
            duration: 5000,
          });
        }
      } else {
        try {
          this.saving = true;
          await setDoc(
            doc(
              this.db.firestore,
              `township/${this.townshipId}/addresses/${id}`
            ),
            {
              houseNumber: this.addressForm.value.houseNumber,
              postal: postal,
              houseNumberAddition: additionValue,
              type: this.addressTypesChecked,
            },
            { merge: true }
          );
          const oldAddress = this.addresses.find((obj) => {
            return (
              obj.postal === postal &&
              obj.houseNumber === this.addressForm.value.houseNumber
            );
          });
          const index = this.addresses.indexOf(oldAddress, 0);
          this.addresses.splice(index, 1);
          this.addresses.push({
            houseNumber: this.addressForm.value.houseNumber,
            postal: postal,
            houseNumberAddition: additionValue,
            type: this.addressTypesChecked,
          });
          this.sortAddresses();
          this.saving = false;
          this.snackBar.open('Het adres is toegevoegd', 'X', {
            duration: 5000,
          });
        } catch (error) {
          console.log(error);
          this.snackBar.open('Er is iets misgegaan', 'X', {
            duration: 5000,
          });
        }
      }
    }
  }

  async searchClicked() {
    this.addOrSearched = false;
    this.addresses = [];
    const postal = this.addressForm.value.postal
      ? this.addressForm.value.postal.replace(' ', '').toUpperCase()
      : null;
    if (this.addressForm.value.postal && !this.addressForm.value.houseNumber) {
      const addressesRef = collection(
        this.db.firestore,
        `township/${this.townshipId}/addresses`
      );
      const postalQuery = query(addressesRef, where('postal', '==', postal));
      const querySnapshot = await getDocs(postalQuery);
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          this.addresses.push(doc.data());
        });
        this.sortAddresses();
      } else {
        this.snackBar.open(
          'Deze postcode is niet aanwezig in het systeem.',
          'X',
          {
            duration: 5000,
          }
        );
      }
    }
    if (
      this.addressForm.value.postal &&
      this.addressForm.value.houseNumber &&
      !this.addressForm.value.addition
    ) {
      const addressesRef = collection(
        this.db.firestore,
        `township/${this.townshipId}/addresses`
      );
      const postalHouseNumberQuery = query(
        addressesRef,
        where('houseNumber', '==', this.addressForm.value.houseNumber),
        where('postal', '==', postal)
      );
      const querySnapshot = await getDocs(postalHouseNumberQuery);
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          this.addresses.push(doc.data());
        });
        this.sortAddresses();
      } else {
        this.snackBar.open(
          'Deze postcode is niet aanwezig in het systeem.',
          'X',
          {
            duration: 5000,
          }
        );
      }
    }
    if (
      this.addressForm.value.postal &&
      this.addressForm.value.houseNumber &&
      this.addressForm.value.addition
    ) {
      const additionValue = this.addressForm.value.addition.toLowerCase();
      const id = postal + this.addressForm.value.houseNumber + additionValue;
      const docRef = doc(
        this.db.firestore,
        `township/${this.townshipId}/addresses/${id}`
      );
      const addressesSnapshot = await getDoc(docRef);
      if (addressesSnapshot.exists()) {
        this.addresses.push(addressesSnapshot.data());
      } else {
        this.snackBar.open('Dit adres is niet aanwezig in het systeem.', 'X', {
          duration: 5000,
        });
      }
    }
  }

  addressTypeChecked(event: MatCheckboxChange, value: string) {
    if (event.checked) {
      this.addressTypesChecked.push(value);
    } else if (!event.checked) {
      const index = this.addressTypesChecked.indexOf(value, 0);
      this.addressTypesChecked.splice(index, 1);
    }
  }

  openDialog(address) {
    if (this.saving) {
      return;
    }
    console.log('types', address.types);
    const dialogRef = this.dialog.open(EditCharacteristicsDialogComponent, {
      width: '800px',
      data: {
        postal: address.postal,
        houseNumber: address.houseNumber,
        houseNumberAddition: address.houseNumberAddition
          ? address.houseNumberAddition
          : '-',
        types: address.type,
        addressTypes: this.addressTypes,
        townshipId: this.townshipId,
        pin: address.pin,
        phonenumber: address.phonenumber,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const oldAddress = this.addresses.find((obj) => {
          return (
            obj.postal === result.postal &&
            obj.houseNumber === result.houseNumber
          );
        });
        const index = this.addresses.indexOf(oldAddress, 0);
        this.addresses.splice(index, 1);
        this.addresses.push(result);
        this.sortAddresses();
      }
    });
  }

  openFileInput(htmlId) {
    if (this.saving) {
      return;
    }
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }
  uploadedFile(event) {
    this.formChanged = true;
    const file = event.target.files[0] as File;
    this.addressFileUploaded = event.target.files[0];
    this.readExcel();
  }
  readExcel() {
    const readFile = new FileReader();
    readFile.onload = () => {
      const storeData = readFile.result as any;
      const data = new Uint8Array(storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.addressWorksheet = workbook.Sheets[firstSheetName];
      console.log('this.addressWorksheet', this.addressWorksheet);
      this.saveExcel();
    };
    readFile.readAsArrayBuffer(this.addressFileUploaded);
  }
  async saveExcel() {
    this.saving = true;
    const promises: any[] = [];
    let addressesSpreadsheetError;
    if (this.addressWorksheet) {
      const spreadsheet = {};
      Object.keys(this.addressWorksheet).forEach((key) => {
        try {
          if (
            key !== '!ref' &&
            key !== '!margins' &&
            key !== '!autofilter' &&
            key !== '!merges'
          ) {
            const rowId = key.match(/\d+/g).toString();
            const colId = key.match(/[a-zA-Z]+/g).toString();
            if (!spreadsheet[rowId]) {
              spreadsheet[rowId] = {};
            }
            spreadsheet[rowId][colId] =
              this.addressWorksheet[key].w ?? this.addressWorksheet[key].v;
          }
        } catch (error) {
          console.log('key with error:', key);
          console.error(error);
        }
      });
      // console.log('spreadsheet', spreadsheet);
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        // console.log('key', key);
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'postcode':
            columnNames[key] = 'postal';
            break;
          case 'huisnummer':
            columnNames[key] = 'houseNumber';
            break;
          case 'toevoeging':
            columnNames[key] = 'houseNumberAddition';
            break;
          case 'kenmerk':
            columnNames[key] = 'type';
            break;
          case 'straatnaam':
            columnNames[key] = 'street';
            break;
          case 'woonplaats':
            columnNames[key] = 'place';
            break;
          case 'woz waarde':
            columnNames[key] = 'wozValue';
            break;
          case 'bouwjaar':
            columnNames[key] = 'buildYear';
            break;
          case 'energielabel':
            columnNames[key] = 'energyLabel';
            break;
          case 'oppervlakte':
            columnNames[key] = 'surfaceM2';
            break;
          case 'pincode':
            columnNames[key] = 'pin';
            break;
        }
        // console.log('columnNames', columnNames);
      });
      delete spreadsheet[1];
      // console.log('columnNames', columnNames);
      const addressCompareArray = [];
      this.addressTypes.forEach((type) => {
        addressCompareArray.push(type.toString().toLowerCase());
      });
      Object.keys(spreadsheet).forEach(async (key) => {
        const rowObj = {} as any;
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          // console.log('spreadsheet[key][colKey]', spreadsheet[key][colKey]);
          // console.log('colName', colName);
          if (colName && spreadsheet[key][colKey].length !== 0) {
            rowObj[colName] = spreadsheet[key][colKey];
            // set rowObj with value from spreadsheet for ALL fields
            if (colName === 'postal') {
              rowObj[colName] = spreadsheet[key][colKey]
                .toUpperCase()
                .replace(/ /g, '');
            }
            if (colName === 'houseNumberAddition') {
              rowObj[colName] = spreadsheet[key][colKey]
                .toLowerCase()
                .replace(/ /g, '');
            }
            if (colName === 'pin') {
              rowObj[colName] = spreadsheet[key][colKey]
                .toLowerCase()
                .replace('-', '');
            }
            if (colName === 'surfaceM2') {
              rowObj[colName] = this.prepareNumberForDb(
                spreadsheet[key][colKey]
              );
            }
            if (colName === 'wozValue') {
              rowObj[colName] = this.prepareNumberForDb(
                spreadsheet[key][colKey]
              );
            }
          }
        });
        // console.log('rowObj', rowObj.type);
        let newtypes = [];
        if (rowObj.type && rowObj.type.includes(',')) {
          newtypes = rowObj.type.split(',').map((item: string) => item.trim());
        } else if (rowObj.type && !rowObj.type.includes(',')) {
          // cannot use else because of empty excel field
          newtypes = [rowObj.type.toString()];
        }
        if (newtypes && newtypes.length > 0) {
          newtypes.forEach(async (type, index) => {
            const foundAtIndex = addressCompareArray.indexOf(
              type.toLowerCase()
            );
            if (foundAtIndex !== -1) {
              newtypes[index] = this.addressTypes[foundAtIndex];
            } else {
              const addresTypeRef = doc(
                this.db.firestore,
                `township/${this.townshipId}/addresTypes/${type}`
              );
              promises.push(
                setDoc(addresTypeRef, { type: type }, { merge: true })
              );
              this.addressTypes.push(type);
              addressCompareArray.push(type.toLowerCase());
            }
          });
          //sort array and remove duplicate types
          newtypes.sort();
          const fixedNewtypes = [newtypes[0]];
          for (var i = 1; i < newtypes.length; i++) {
            if (newtypes[i] != newtypes[i - 1]) fixedNewtypes.push(newtypes[i]);
          }
          newtypes = fixedNewtypes;
          console.log(newtypes);
        }

        if (newtypes.length > 0) {
          rowObj.type = newtypes;
        } else {
          rowObj.type = [];
        }
        // Only upload address if it has both of the required values
        if (rowObj.postal && rowObj.houseNumber) {
          // Check if the organisation has rights to use the addressTypes in the row
          if (this.env.env === 'org') {
            if (
              rowObj.type &&
              Array.isArray(rowObj.type) &&
              rowObj.type.length > 0
            ) {
              console.log('rowObj.type', rowObj.type);
              for (let type of rowObj.type) {
                if (this.addressTypes.includes(type)) {
                  this.orgHasRights = true;
                  break;
                } else {
                  this.orgHasRights = false;
                }
              }
            } else {
              this.orgHasRights = true;
            }
          } else {
            this.orgHasRights = true;
          }
          // Only upload addresses with addressTypes that an organisation has the rights to
          if (this.orgHasRights) {
            const addressString =
              rowObj.postal +
              rowObj.houseNumber +
              (rowObj.houseNumberAddition ? rowObj.houseNumberAddition : '');
            // console.log('addressString', addressString);
            const ref = doc(
              this.db.firestore,
              `township/${this.townshipId}/addresses/${addressString}`
            );
            let newAddressTypes = [];
            const addressSnapshot = await (await getDoc(ref)).data();
            if (rowObj.type) {
              if (this.env.env === 'org') {
                rowObj.type.forEach((type) => {
                  if (this.addressTypes.includes(type)) {
                    newAddressTypes.push(type);
                  }
                });
              } else if (this.env.env === 'township') {
                rowObj.type.forEach((type) => {
                  newAddressTypes.push(type);
                });
              }
              if (addressSnapshot && addressSnapshot.type) {
                addressSnapshot.type.forEach((type) => {
                  if (!newAddressTypes.includes(type.toString())) {
                    newAddressTypes.push(type);
                  }
                });
              }
            } else {
              newAddressTypes.concat(addressSnapshot.type);
            }
            rowObj.type = newAddressTypes;
            console.log('rowObj', rowObj);
            promises.push(setDoc(ref, rowObj, { merge: true }));
          }
        }
      });
      await Promise.all(promises);
      this.saving = false;
      this.snackBar.open('De adressen zijn succesvol geïmporteerd', 'X', {
        duration: 5000,
      });
    }
  }

  async exportAddresses() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const exportArray = [];

    const addressesRef = this.db.firestore.collection(
      `township/${this.townshipId}/addresses/`
    );
    const addressesDocs = await addressesRef.get();
    addressesDocs.forEach((addressDoc) => {
      // addresses.forEach((addressDoc) => {
      const exportObj = {};
      const address = addressDoc.data() as Address;
      console.log('address', address);
      if (address.houseNumber.includes(' ')) {
        return;
      }
      exportObj['Postcode'] = address.postal;
      exportObj['Huisnummer'] = address.houseNumber;
      exportObj['Toevoeging'] = address.houseNumberAddition;
      exportObj['Kenmerk'] = address.type ? address.type.join(', ') : '';
      exportObj['Straatnaam'] = address.street ? address.street : '';
      exportObj['Woonplaats'] = address.place ? address.place : '';
      exportObj['WOZ waarde'] = address.wozValue ? address.wozValue : '';
      exportObj['Bouwjaar'] = address.buildYear ? address.buildYear : '';
      exportObj['Energielabel'] = address.energyLabel
        ? address.energyLabel
        : '';
      exportObj['Oppervlakte'] = address.surfaceM2 ? address.surfaceM2 : '';
      exportObj['Pincode'] = address.pin ? address.pin : '';
      exportArray.push(exportObj);
      // });
    });
    if (exportArray.length === 0) {
      exportArray.push({
        Postcode: '',
        Huisnummer: '',
        Toevoeging: '',
        Type: '',
        Straatnaam: '',
        Woonplaats: '',
      });
    }
    console.log('exportArray', exportArray);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
    const columns = [6, 9];
    const rows = exportArray.length;
    columns.forEach((C) => {
      for (let R = 1; R <= rows; R = R + 1) {
        let cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
        cell.z = '#,##00.00';
      }
    });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Addressenlijst');

    // /* save to file */
    XLSX.writeFile(wb, this.env.name + '.' + 'Addressenlijst' + '.xlsx');
    this.saving = false;
  }

  deleteAddresses(): void {
    if (this.saving) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '375px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('result', result);
      if (result) {
        try {
          this.saving = true;
          const res = (await lastValueFrom(
            this.http.post(
              `${getFunctionsLocationUrl('europe-west1')}/httpDeleteAddresses`,
              {
                townshipId: this.townshipId,
              }
            )
          )) as any;
          if (res.message != 'succeed') {
            console.log(res.message);
            this.snackBar.open('Er is iets misgegaan', 'X', {
              duration: 5000,
            });
          } else {
            this.snackBar.open(
              'Alle adressen zijn definitief verwijderd',
              'X',
              {
                duration: 5000,
              }
            );
          }
          this.saving = false;
        } catch (error) {
          console.log('ERROR:', error);
          this.snackBar.open('Er is iets misgegaan', 'X', {
            duration: 5000,
          });
        }
      }
    });
  }

  async exportAddressesTemplate() {
    const exportArray = [];

    exportArray.push({
      Postcode: '',
      Huisnummer: '',
      Toevoeging: '',
      Kenmerk: '',
      Straatnaam: '',
      Woonplaats: '',
      'WOZ Waarde': '',
      Bouwjaar: '',
      Energielabel: '',
      Oppervlakte: '',
      Pincode: '',
    });

    console.log('exportArray', exportArray);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Addressenlijst');

    // /* save to file */
    XLSX.writeFile(wb, this.env.name + '.' + 'Addressenlijst' + '.xlsx');
  }

  getError(name: string, form: UntypedFormGroup) {
    const field = form.get(name);
    let error = '';
    if (field?.touched || !field?.pristine) {
      if (field?.hasError('required')) {
        this.validAddressForm = false;
        return (error = 'Dit veld moet ingevuld zijn.');
      }
      if (field?.hasError('minlength')) {
        this.validAddressForm = false;
        return (error = 'Vul een geldige postcode in.');
      }
      if (field?.hasError('maxlength')) {
        this.validAddressForm = false;
        return (error = 'Vul een geldige postcode in.');
      }
      if (field?.hasError('pattern')) {
        if (name === 'houseNumber') {
          this.validAddressForm = false;
          return (error = 'Vul een geldig huisnummer in');
        }
        if (name === 'postal') {
          this.validAddressForm = false;
          return (error = 'Vul een geldige postcode in.');
        }
      }
    }
    return error;
  }

  sortAddresses() {
    this.addresses.sort((address1, address2) => {
      const a = parseInt(address1.houseNumber);
      const b = parseInt(address2.houseNumber);
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }

  prepareNumberForDb(content: any) {
    const REGEX = new RegExp(
      `([0-9]{1,3})*([,.]{1}[0-9]{3})*([,.]{1}[0-9]{1,2})?`
    );
    content = content.toString();
    content = content.replace(REGEX, (match, p1, p2, p3) => {
      let lastIndex = content.lastIndexOf('.');
      if (p3 && p3 !== undefined) {
        if (p3.indexOf(',') !== -1) {
          lastIndex = content.lastIndexOf(',');
          content =
            content.substring(0, lastIndex) +
            '.' +
            content.substring(lastIndex + 1);
        }
      }
      let i = lastIndex - 1;
      while (i >= 0) {
        if (content[i] === ',' || content[i] === '.') {
          content = content.substring(0, i) + content.substring(i + 1);
        }
        i = i - 1;
      }
      return content;
    });
    return Number(content);
  }
}
