import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../../../app.component';
import { Township, Voucher, VoucherGroup } from 'src/app/interfaces';

@Component({
  selector: 'app-mass-mail',
  templateUrl: './mass-mail.component.html',
  styleUrls: ['./mass-mail.component.scss'],
})
export class MassMailComponent implements OnInit {
  mailForm: UntypedFormGroup;
  voucherGroupForm: UntypedFormGroup;
  loading: boolean = true;
  sending: boolean = false;
  townships: Township[] = [];
  voucherGroups: VoucherGroup[] = [];
  voucherGroupFormFields: [
    {
      id: string;
      name: string;
    }?
  ] = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MassMailComponent>,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit(): Promise<void> {
    this.mailForm = this.fb.group({
      township: [localStorage.getItem('township'), [Validators.required]],
      sendDate: [new Date(), [Validators.required]],
    });
    this.voucherGroupForm = this.fb.group({});
    await this.getDBDataAndPrep();
    this.loading = false;
  }

  async getDBDataAndPrep() {
    (await getDocs(query(collection(db, `township`), orderBy('name')))).forEach(
      async (township) => {
        const townshipData = {
          ...township.data(),
          id: township.id,
        } as Township;
        this.townships.push(townshipData);
      }
    );
    await this.getVoucherGroups();
    this.mailForm.controls.township.valueChanges.subscribe(async () => {
      this.voucherGroups = [];
      this.voucherGroupFormFields = [];
      this.voucherGroupForm = this.fb.group({});
      await this.getVoucherGroups();
    });
  }

  async getVoucherGroups() {
    (
      await getDocs(
        query(
          collection(
            db,
            `township/${this.mailForm.controls.township.value}/voucherGroups`
          ),
          orderBy('name')
        )
      )
    ).forEach(async (voucherGroup) => {
      const voucherGroupData = {
        ...voucherGroup.data(),
        id: voucherGroup.id,
      } as VoucherGroup;

      this.voucherGroups.push(voucherGroupData);
      this.voucherGroupFormFields.push({
        id: voucherGroupData.id,
        name: voucherGroupData.name,
      });
      this.voucherGroupForm.addControl(
        `${voucherGroupData.id}`,
        new UntypedFormControl(false)
      );

      this.voucherGroupFormFields.sort(this.orderVoucherGroups);
    });
  }

  orderVoucherGroups(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  async send() {
    this.sending = true;
    if (this.errorCheck()) {
      this.sending = false;
      return;
    }
    const voucherGroupIdsToQuery = [];
    this.voucherGroupFormFields.forEach((formFieldInfo) => {
      if (this.voucherGroupForm.controls[formFieldInfo.id].value) {
        voucherGroupIdsToQuery.push(formFieldInfo.id);
      }
    });

    (
      await getDocs(
        query(
          collection(
            db,
            `township/${this.mailForm.controls.township.value}/vouchers`
          ),
          where('voucherGroupId', 'in', voucherGroupIdsToQuery),
          where('activateDate', '!=', null)
        )
      )
    ).forEach(async (voucher) => {
      const voucherData = { ...voucher.data(), id: voucher.id } as Voucher;

      if (voucherData.email) {
        const voucherMailData = {
          sendDate: this.mailForm.controls.sendDate.value,
          mailDataObj: {
            orgData: {
              township:
                this.townships[
                  this.townships
                    .map((township) => township.id)
                    .indexOf(this.mailForm.controls.township.value)
                ],
              voucher: voucherData,
              voucherGroup:
                this.voucherGroups[
                  this.voucherGroups
                    .map((voucherGroup) => voucherGroup.id)
                    .indexOf(voucherData.voucherGroupId)
                ],
            },
            mailData: {
              mailType: `default`,
              bcc: [`groenebon@gmail.com`],
            },
            userData: {
              email: voucherData.email,
            },
          },
        };
        await addDoc(collection(db, `massMailQueue`), voucherMailData);
      }
    });
    this.sending = false;
    this.dialogRef.close();
  }

  errorCheck() {
    let errorBool = false;
    if (this.mailForm.invalid) {
      this.mailForm.markAllAsTouched();
      errorBool = true;
    }
    if (this.voucherGroupForm.invalid) {
      this.voucherGroupForm.markAllAsTouched();
      errorBool = true;
    }
    let atLeastOneCheckBoxTrue = false;
    this.voucherGroupFormFields.forEach((formFieldInfo) => {
      if (this.voucherGroupForm.controls[formFieldInfo.id].value) {
        atLeastOneCheckBoxTrue = true;
      }
    });
    if (!atLeastOneCheckBoxTrue) {
      this.snackBar.open('Minstens een campagne moet geselecteerd zijn', 'X', {
        duration: 5000,
      });
      errorBool = true;
    }
    return errorBool;
  }
}
