// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .edit-sepa-dialog-title {
  padding: 0;
}

::ng-deep .edit-sepa-dialog-content {
  padding: 16px 0 0 0 !important;
}
::ng-deep .edit-sepa-dialog-content #form {
  display: flex;
  flex-direction: column;
}
::ng-deep .edit-sepa-dialog-content #form .pin {
  width: 100%;
}
::ng-deep .edit-sepa-dialog-content #form .pin input {
  -webkit-text-security: disc;
}

::ng-deep .edit-sepa-dialog-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/organisation-management/edit-organisation/edit-sepa/edit-sepa.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,8BAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;AAEJ;AADI;EACE,WAAA;AAGN;AAFM;EACE,2BAAA;AAIR;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AACF","sourcesContent":["::ng-deep .edit-sepa-dialog-title {\n  padding: 0;\n}\n\n::ng-deep .edit-sepa-dialog-content {\n  padding: 16px 0 0 0 !important;\n  #form {\n    display: flex;\n    flex-direction: column;\n    .pin {\n      width: 100%;\n      input {\n        -webkit-text-security: disc;\n      }\n    }\n  }\n}\n\n::ng-deep .edit-sepa-dialog-actions {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
