import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrgUser, PlanningUser, TownshipUser, User } from '../interfaces';
import { DeleteUserComponent } from './dialogs/delete-user/delete-user.component';
import { ManageUserComponent } from './dialogs/manage-user/manage-user.component';
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { environment } from '../../environments/environment';
import { db } from '../app.component';
import {
  ManagementRights,
  OrgUserRights,
  PlanningUserRights,
  TeamUserRights,
  TownshipUserRights,
} from '../enums';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  env = environment;
  townshipId = localStorage.getItem('township') as string;
  users: TownshipUser[] | OrgUser[] | PlanningUser[];
  rights: any;
  organisationId: string;
  userId: string;
  loaded = false;

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar) {}

  async ngOnInit(): Promise<void> {
    this.userId = getAuth().currentUser.uid;
    let userQuery;
    if (this.env.env == 'township') {
      userQuery = query(
        collection(db, `township/${this.townshipId}/users`),
        orderBy('email')
      );
      this.rights = { ...ManagementRights, ...TownshipUserRights };
    } else if (this.env.env == 'planning') {
      userQuery = query(
        collection(db, `township/${this.townshipId}/planningUsers`),
        orderBy('email')
      );
      this.rights = {
        ...PlanningUserRights,
        ...TeamUserRights,
        coachExternal: 'Coach (extern)',
      };
    } else if (this.env.env == 'org') {
      const userDoc = doc(db, `users/${this.userId}`);
      const userData = (await getDoc(userDoc)).data() as User;
      this.organisationId = userData.organisation;
      userQuery = query(
        collection(
          db,
          `township/${this.townshipId}/organisations/${this.organisationId}/users`
        ),
        orderBy('email')
      );
      this.rights = { ...ManagementRights, ...OrgUserRights };
    }
    onSnapshot(userQuery, (querySnapshot) => {
      this.users = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        this.users.push({ id: doc.id, ...data });
      });
    });
    this.loaded = true;
  }

  editUser(user?) {
    this.dialog.open(ManageUserComponent, {
      width: '475px',
      data: { user, orgId: this.organisationId, ownUserId: this.userId },
    });
  }

  deleteUser(user) {
    if (user && user.rights == 'owner') {
      return this.snackBar.open('Je kan de eigenaar niet verwijderen.', 'X', {
        duration: 3000,
      });
    }
    if (user && user.id === this.userId) {
      return this.snackBar.open('Je kan jezelf niet verwijderen.', 'X', {
        duration: 3000,
      });
    }
    this.dialog.open(DeleteUserComponent, {
      width: '475px',
      data: { user, orgId: this.organisationId },
      autoFocus: false,
    });
  }
}
