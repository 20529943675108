// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page #address-form {
  width: 100%;
}
.page #address-form .form-inputs-buttons {
  display: flex;
  align-items: center;
  width: 100%;
}
.page #address-form .form-inputs-buttons .postal-form-field {
  max-width: 200px;
  min-width: 120px;
  width: 100%;
  margin: 0 10px 0 0;
}
.page #address-form .form-inputs-buttons .house-number-form-field {
  max-width: 200px;
  min-width: 60px;
  width: 100%;
  margin: 0 10px 0 0;
}
.page #address-form .form-inputs-buttons .addition-form-field {
  max-width: 200px;
  min-width: 60px;
  width: 100%;
  margin: 0 10px 0 0;
}
.page #address-form .form-inputs-buttons .form-buttons {
  display: flex;
  align-items: center;
}
.page #address-form .checkboxContainer {
  margin-bottom: 30px;
}
.page #address-form .checkboxContainer mat-checkbox {
  margin-right: 12px;
  width: 238px;
}
.page .address-list {
  padding: 0px;
}
.page .address-list .address-li {
  display: flex;
  min-height: 48px;
  align-items: center;
}
.page .address-list .address-li div {
  width: 25%;
}
.page .address-list .address-li .button-wrapper {
  width: 5%;
}
.page .action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 30px 0 0 0;
}
.page .action-buttons button {
  margin: 0 5px 0 0;
}
.page .action-buttons button:last-of-type {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/characteristics/characteristics.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AACN;AAAM;EACE,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;AAER;AAAM;EACE,gBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;AAER;AAAM;EACE,gBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;AAER;AAAM;EACE,aAAA;EACA,mBAAA;AAER;AACI;EACE,mBAAA;AACN;AAAM;EACE,kBAAA;EACA,YAAA;AAER;AAEE;EACE,YAAA;AAAJ;AACI;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;AACN;AAAM;EACE,UAAA;AAER;AAAM;EACE,SAAA;AAER;AAEE;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AACI;EACE,iBAAA;AACN;AACI;EACE,SAAA;AACN","sourcesContent":[".page {\n  #address-form {\n    width: 100%;\n    .form-inputs-buttons {\n      display: flex;\n      align-items: center;\n      width: 100%;\n      .postal-form-field {\n        max-width: 200px;\n        min-width: 120px;\n        width: 100%;\n        margin: 0 10px 0 0;\n      }\n      .house-number-form-field {\n        max-width: 200px;\n        min-width: 60px;\n        width: 100%;\n        margin: 0 10px 0 0;\n      }\n      .addition-form-field {\n        max-width: 200px;\n        min-width: 60px;\n        width: 100%;\n        margin: 0 10px 0 0;\n      }\n      .form-buttons {\n        display: flex;\n        align-items: center;\n      }\n    }\n    .checkboxContainer {\n      margin-bottom: 30px;\n      mat-checkbox {\n        margin-right: 12px;\n        width: 238px;\n      }\n    }\n  }\n  .address-list {\n    padding: 0px;\n    .address-li {\n      display: flex;\n      min-height: 48px;\n      align-items: center;\n      div {\n        width: 25%;\n      }\n      .button-wrapper {\n        width: 5%;\n      }\n    }\n  }\n  .action-buttons {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    margin: 30px 0 0 0;\n    button {\n      margin: 0 5px 0 0;\n    }\n    button:last-of-type {\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
