import { Component, Input, OnInit } from '@angular/core';
import { GeneralStatistics, Widget } from 'src/app/interfaces';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { checkAndUpdateStats } from '../../bigquery-helper';
import moment from 'moment';
import {
  collection,
  deleteField,
  doc,
  getDocs,
  getDoc,
  setDoc,
  where,
  query,
} from 'firebase/firestore';
import { calculateWidgetHeight } from '../../widgets-helper';

@Component({
  selector: 'widget-homes-preserved',
  templateUrl: './widget-homes-preserved.component.html',
  styleUrls: ['./widget-homes-preserved.component.scss'],
})
export class WidgetHomesPreservedComponent implements OnInit {
  @Input() widget: Widget;
  @Input() statistics: Observable<GeneralStatistics>;
  townshipId = localStorage.getItem('township') as string;
  now: Date;
  fromDate: Date;
  totalVouchers: number;
  claimedVouchers: number;
  remainingDays: number;
  barPercentage: string;
  vouchersClaimed: Observable<any>;
  formattedFromdate: any;
  claimedVouchersSince: any;
  widgetHeight: any;
  paidVouchers: number;
  paidClaimedVouchers: number;

  constructor(public db: AngularFirestore, public http: HttpClient) {}

  async ngOnInit(): Promise<void> {
    this.now = new Date();
    this.fromDate = this.widget.settings.fromDate.toDate();
    this.vouchersClaimed = this.widget.settings.vouchers;

    const params = {
      townshipId: this.townshipId,
      fromDate: this.fromDate,
      widgetId: this.widget.id,
    };

    checkAndUpdateStats(this.widget, 'bigqueryHomesPreserved', params);

    const widgetInfo = this.db
      .doc<any>(`township/${this.townshipId}/widgets/${this.widget.id}`)
      .valueChanges();
    widgetInfo.subscribe((data) => {
      this.claimedVouchersSince = data.settings.vouchers;
    });

    this.statistics.subscribe((statistics) => {
      this.claimedVouchers = statistics.claimedVouchers;
      this.paidVouchers = statistics.paidVouchers;
      this.paidClaimedVouchers = this.claimedVouchers + this.paidVouchers;
      this.totalVouchers = statistics.totalVouchers;
      let percentageOfIncrease =
        (this.paidClaimedVouchers / this.totalVouchers) * 100;

      if (percentageOfIncrease < 1) {
        percentageOfIncrease = Math.ceil(percentageOfIncrease);
      } else if (percentageOfIncrease > 1 && percentageOfIncrease < 99) {
        percentageOfIncrease = Math.round(percentageOfIncrease);
      } else {
        percentageOfIncrease = Math.floor(percentageOfIncrease);
      }
      this.barPercentage = `${0 + percentageOfIncrease}%`;
    });

    this.formattedFromdate = moment(this.fromDate).format('YYYY-MM-DD');

    const widgetInfoHeight = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${localStorage.getItem('township')}/widgets/${
            this.widget.id
          }`
        )
      )
    ).data();
    this.widgetHeight = calculateWidgetHeight(widgetInfoHeight.height, 34);
  }
}
