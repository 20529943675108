import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { initializeApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
import { Township } from 'src/app/interfaces';
const app = initializeApp(environment.firebase);
const db = getFirestore(app);
const functions = getFunctions(app, 'europe-west1');

interface DialogData {
  commissionFile: any;
  townshipId: string;
}

@Component({
  selector: 'app-calculate-monthly-cost',
  templateUrl: './calculate-monthly-cost.component.html',
  styleUrls: ['./calculate-monthly-cost.component.scss'],
})
export class CalculateMonthlyCostComponent implements OnInit {
  dateForm: UntypedFormGroup;
  years = [];
  months = [
    { month: 'Januari', period: 1 },
    { month: 'Februari', period: 2 },
    { month: 'Maart', period: 3 },
    { month: 'April', period: 4 },
    { month: 'Mei', period: 5 },
    { month: 'Juni', period: 6 },
    { month: 'Juli', period: 7 },
    { month: 'Augustus', period: 8 },
    { month: 'September', period: 9 },
    { month: 'Oktober', period: 10 },
    { month: 'November', period: 11 },
    { month: 'December', period: 12 },
  ];
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log('data', this.data);
    this.dateForm = this.fb.group({
      year: [, [Validators.required]],
      month: [, [Validators.required]],
    });
    this.getYears();
  }

  getYears() {
    const year = new Date().getFullYear();
    this.dateForm.controls.year.setValue(year);
    for (let i = 0; i < 5; i += 1) {
      this.years.push(Number(year) - i);
    }
    return this.years.sort((a, b) => a - b);
  }

  async calculateMonthlyCost() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    const formValues = this.dateForm.value;
    console.log('formValues', formValues);
    const momentToCalc = moment([formValues.year, formValues.month - 1, 1]);
    console.log('moment', momentToCalc.toDate());
    console.log('ID', this.data.townshipId);
    console.log('moment formatted', momentToCalc.format('YYYY-MM'));
    const monthlyDoc = await getDoc(
      doc(
        db,
        `/globalSettings/townships/commissionSettings/${
          this.data.townshipId
        }/monthlyCosts/${momentToCalc.format('YYYY-MM')}`
      )
    );
    console.log('EXISTS', monthlyDoc.exists());
    if (monthlyDoc.exists()) {
      const dialogRef = this.dialog.open(WarningDialogComponent, {
        width: '475px',
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        console.log('result', result);
        if (result) {
          this.snackbar.open('De berekening wordt nu uitgevoerd', 'X', {
            duration: 5000,
          });
          const callable = httpsCallable(
            functions,
            'httpCalculateMonthlyCost',
            { timeout: 540000 }
          );
          await callable({
            townshipId: this.data.townshipId,
            monthToCalc: momentToCalc.format('YYYY-MM'),
          })
            .then((result) => {
              console.log('result:', result);
              this.snackbar.open('De maandelijkse kosten zijn berekend.', 'X', {
                duration: 5000,
              });
              this.loading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    } else {
      this.snackbar.open('De berekening wordt nu uitgevoerd', 'X', {
        duration: 5000,
      });
      const callable = httpsCallable(functions, 'httpCalculateMonthlyCost', {
        timeout: 540000,
      });
      callable({
        townshipId: this.data.townshipId,
        monthToCalc: momentToCalc.format('YYYY-MM-DD'),
      })
        .then((result) => {
          console.log('result:', result);
          this.snackbar.open('De maandelijkse kosten zijn berekend.', 'X', {
            duration: 5000,
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.snackbar.open('Er is een onbekende fout opgetreden', 'X', {
            duration: 300000, // 5 Minutes
          });
          this.loading = false;
        });
    }
  }
}
