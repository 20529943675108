// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outlined-button {
  border: 0 !important;
}

.title {
  margin: 0 !important;
}

.mat-icon-position {
  margin-left: 5px;
  cursor: pointer;
}

.dialog-form {
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
}

.settings-button {
  width: 360px;
  margin: 8px 0;
  margin-bottom: 16px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
}

mat-dialog-actions {
  padding: 0;
}

::ng-deep .edit-township-file-form {
  padding: 16px 0 0 0 !important;
}

.upload-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/general-settings/dialog/edit-township-file/edit-township-file.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;AACF","sourcesContent":[".outlined-button {\n  border: 0 !important;\n}\n\n.title {\n  margin: 0 !important;\n}\n\n.mat-icon-position {\n  margin-left: 5px;\n  cursor: pointer;\n}\n\n.dialog-form {\n  display: flex;\n  flex-direction: column;\n  padding: 10px 0 0 0;\n}\n\n.settings-button {\n  width: 360px;\n  margin: 8px 0;\n  margin-bottom: 16px;\n}\n\n.loading-spinner {\n  display: flex;\n  justify-content: center;\n}\n\nmat-dialog-actions {\n  padding: 0;\n}\n\n::ng-deep .edit-township-file-form {\n  padding: 16px 0 0 0 !important;\n}\n\n.upload-wrapper {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
