// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper {
  width: 85%;
  margin: 0 auto;
}
.content-wrapper .content-split {
  display: flex;
  gap: 15px;
}
.content-wrapper .content-split > div {
  flex: 1;
}
.content-wrapper .content-split mat-form-field {
  width: 100%;
}
.content-wrapper .content-split .upload-button {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 20px 0 0 0;
}
.content-wrapper .content-split .particularity-form {
  margin: 50px 0 0 0;
}
.content-wrapper .content-split .svg-preview {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.content-wrapper .content-split .svg-preview > div {
  width: 70%;
}
.content-wrapper .content-split .svg-preview .svg-wrapper {
  aspect-ratio: 1/1;
  outline: 1px solid black;
  align-content: center;
}
.content-wrapper .content-split .svg-preview .svg-wrapper img {
  width: 100%;
}
@media only screen and (max-width: 700px) and (min-width: 0px) {
  .content-wrapper .content-split .svg-preview {
    display: none;
  }
}
.content-wrapper .action-buttons {
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/app/planning-settings/pages/manage-particularity/manage-particularity.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,cAAA;AACF;AAAE;EACE,aAAA;EACA,SAAA;AAEJ;AADI;EACE,OAAA;AAGN;AADI;EACE,WAAA;AAGN;AADI;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;AAGN;AADI;EACE,kBAAA;AAGN;AADI;EACE,aAAA;EACA,yBAAA;EACA,WAAA;AAGN;AAFM;EACE,UAAA;AAIR;AAFM;EACE,iBAAA;EACA,wBAAA;EACA,qBAAA;AAIR;AAHQ;EACE,WAAA;AAKV;AADI;EACE;IACE,aAAA;EAGN;AACF;AAAE;EACE,cAAA;EACA,aAAA;EACA,8BAAA;AAEJ","sourcesContent":[".content-wrapper {\n  width: 85%;\n  margin: 0 auto;\n  .content-split {\n    display: flex;\n    gap: 15px;\n    > div {\n      flex: 1;\n    }\n    mat-form-field {\n      width: 100%;\n    }\n    .upload-button {\n      display: flex;\n      align-items: center;\n      gap: 5px;\n      margin: 20px 0 0 0;\n    }\n    .particularity-form {\n      margin: 50px 0 0 0;\n    }\n    .svg-preview {\n      display: flex;\n      justify-content: flex-end;\n      width: 100%;\n      > div {\n        width: 70%;\n      }\n      .svg-wrapper {\n        aspect-ratio: 1 / 1;\n        outline: 1px solid black;\n        align-content: center;\n        img {\n          width: 100%;\n        }\n      }\n    }\n    @media only screen and (max-width: 700px) and (min-width: 0px) {\n      .svg-preview {\n        display: none;\n      }\n    }\n  }\n  .action-buttons {\n    margin: 50px 0;\n    display: flex;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
