// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-text {
  padding: 16px 0 0 0;
}
.dialog-text .send-voucher-form {
  display: flex;
  flex-direction: column;
}
.dialog-text .send-voucher-form mat-form-field {
  width: 100%;
}

::ng-deep .send-voucher-form-actions {
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.spinner-button mat-icon:not(.icon-button) {
  width: 0 !important;
  height: 0 !important;
}
.spinner-button mat-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

p.annuleren {
  font-weight: bold;
  color: #005c75;
  margin-right: 20px;
  cursor: pointer;
}

.error-margin {
  margin-top: 15px;
}

.icon-display {
  transform: scale(1.4);
  color: #40484b;
}

::ng-deep .send-voucher-dialog-title {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/voucher-management/send-voucher/send-voucher.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;AAEJ;AADI;EACE,WAAA;AAGN;;AACA;EACE,UAAA;EACA,aAAA;EACA,yBAAA;AAEF;;AAEE;EACE,mBAAA;EACA,oBAAA;AACJ;AACE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAGA;EACE,iBAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,qBAAA;EACA,cAAA;AAAF;;AAGA;EACE,UAAA;AAAF","sourcesContent":[".dialog-text {\n  padding: 16px 0 0 0;\n  .send-voucher-form {\n    display: flex;\n    flex-direction: column;\n    mat-form-field {\n      width: 100%;\n    }\n  }\n}\n::ng-deep .send-voucher-form-actions {\n  padding: 0;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.spinner-button {\n  mat-icon:not(.icon-button) {\n    width: 0 !important;\n    height: 0 !important;\n  }\n  mat-spinner {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    margin-top: -10px;\n    margin-left: -10px;\n  }\n}\n\np.annuleren {\n  font-weight: bold;\n  color: #005c75;\n  margin-right: 20px;\n  cursor: pointer;\n}\n\n.error-margin {\n  margin-top: 15px;\n}\n\n.icon-display {\n  transform: scale(1.4);\n  color: #40484b;\n}\n\n::ng-deep .send-voucher-dialog-title {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
