import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-mfa-confirmation',
  templateUrl: './delete-mfa-confirmation.component.html',
  styleUrls: ['./delete-mfa-confirmation.component.scss'],
})
export class DeleteMfaConfirmationComponent {
  constructor(public dialogRef: MatDialogRef<DeleteMfaConfirmationComponent>) {}
  close(confirm: boolean) {
    this.dialogRef.close(confirm);
  }
}
