import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  townshipName: string;
}

@Component({
  selector: 'app-confirm-delete-domains',
  templateUrl: './delete-township-file.component.html',
  styleUrls: ['./delete-township-file.component.scss'],
})
export class DeleteTownshipFileComponent implements OnInit {
  townshipName = this.data.townshipName;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
}
