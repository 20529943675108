import { Component, Input, OnInit } from '@angular/core';
import { Widget } from 'src/app/interfaces';
import moment from 'moment-timezone';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  collection,
  deleteField,
  doc,
  getDocs,
  getDoc,
  setDoc,
  where,
  query,
} from 'firebase/firestore';
import { calculateWidgetHeight } from '../../widgets-helper';

@Component({
  selector: 'widget-action-running-time',
  templateUrl: './widget-action-running-time.component.html',
  styleUrls: ['./widget-action-running-time.component.scss'],
})
export class WidgetActionRunningTimeComponent implements OnInit {
  @Input() widget: Widget;
  now: Date;
  startDate: Date;
  endDate: Date;
  totalDaysBetween: number;
  currentDaysBetween: number;
  remainingDays: number;
  barPercentage: string;
  widgetHeight: any;

  constructor(public db: AngularFirestore) {}

  async ngOnInit(): Promise<void> {
    this.now = new Date();
    this.startDate = this.widget.settings.startDate.toDate();
    this.endDate = this.widget.settings.endDate.toDate();
    this.totalDaysBetween = moment(this.endDate).diff(
      moment(this.startDate),
      'days'
    );
    this.currentDaysBetween = moment(this.now).diff(
      moment(this.startDate),
      'days'
    );
    this.remainingDays = this.totalDaysBetween - this.currentDaysBetween;
    if (this.remainingDays < 0) {
      this.remainingDays = 0;
    }
    // console.log('totalDaysBetween', this.totalDaysBetween);
    // console.log('currentDaysBetween', this.currentDaysBetween);
    // console.log('remainingDays', this.remainingDays);
    const percentageOfDecrease =
      (this.remainingDays / this.totalDaysBetween) * 100;
    this.barPercentage = `${100 - percentageOfDecrease}%`;

    const widgetInfo = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${localStorage.getItem('township')}/widgets/${
            this.widget.id
          }`
        )
      )
    ).data();
    this.widgetHeight = calculateWidgetHeight(widgetInfo.height, 34);
  }
}
