import { collection, getDocs } from 'firebase/firestore';
import moment from 'moment';
import {
  AppointmentStatisticUser,
  AppointmentType,
  DayPart,
} from '../interfaces';
import { db } from '../app.component';

const townshipId = localStorage.getItem('township') as string;

export function capitalizeFirstLetter(val: string) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export async function getPlanningUsersStatistics(
  planningUsersStatisticsYearWeek: string
) {
  const statsRef = collection(
    db,
    `township/${townshipId}/appointmentStatistics/${planningUsersStatisticsYearWeek}/users`
  );
  const statsDocs = await getDocs(statsRef);
  const planningUsersStatistics: AppointmentStatisticUser[] = [];
  statsDocs.forEach((statDoc) => {
    const statUser = statDoc.data() as AppointmentStatisticUser;
    statUser.id = statDoc.id;
    planningUsersStatistics.push(statUser);
  });
  console.log('planningUsersStatistics', planningUsersStatistics);
  return planningUsersStatistics;
}

export function calculateMaximumAppointmentsInDayPart(
  dayPart: DayPart,
  appointmentType: AppointmentType
) {
  const dayPartMinutes =
    (dayPart.endHour - dayPart.startHour) * 60 +
    (dayPart.endMinutes - dayPart.startMinutes);
  const durationPerAppointment =
    appointmentType.durationHours * 60 + appointmentType.durationMinutes + 15;
  const maximumAppointments = Math.floor(
    dayPartMinutes / durationPerAppointment
  );
  console.log('maximumAppointments', maximumAppointments);
  return maximumAppointments;
}
