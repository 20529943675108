import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  Auth,
  getAuth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/app/app.component';
import { CustomValidators } from 'src/app/validators/custom-validators';
import { DeleteMfaConfirmationComponent } from './dialogs/delete-mfa-confirmation/delete-mfa-confirmation.component';

@Component({
  selector: 'app-multi-factor',
  templateUrl: './multi-factor.component.html',
  styleUrls: ['./multi-factor.component.scss'],
})
export class MultiFactorComponent {
  phoneMultiFactorEnabled: boolean = false;
  enablePhoneMultiFactor: boolean = false;
  auth: Auth;
  phoneForm: UntypedFormGroup;
  verificationId: string;
  reauthRequired: boolean = false;
  recapthaVerifier: RecaptchaVerifier;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.auth = getAuth();
    const multifactorUser = multiFactor(this.auth.currentUser);
    if (multifactorUser.enrolledFactors.length > 0) {
      this.phoneMultiFactorEnabled = multifactorUser.enrolledFactors.some(
        (multiFactorInfo) => {
          return multiFactorInfo.factorId === 'phone';
        }
      );
    }
    this.phoneForm = this.fb.group({
      enabled: [this.enablePhoneMultiFactor, Validators.required],
    });
    if (this.phoneMultiFactorEnabled) {
      this.enablePhoneMultiFactor = true;
      this.phoneForm.controls.enabled.patchValue(true);
      const phoneMultiFactor = multifactorUser.enrolledFactors.find(
        (multiFactor) => {
          return multiFactor.factorId === 'phone';
        }
      );
      this.addPhoneNumber(phoneMultiFactor['phoneNumber']);
    }
  }

  addPhoneNumber(phoneNumber?: string) {
    this.phoneForm.addControl(
      'phoneNumber',
      new UntypedFormControl(phoneNumber ?? null, [
        Validators.required,
        CustomValidators.phoneValidator,
      ])
    );
  }

  errorMessage(control) {
    switch (control) {
      case 'phoneNumber':
        if (this.phoneForm.controls.phoneNumber.hasError('phoneValidator')) {
          return 'Het ingevulde telefoonnummer is incorrect';
        }
        if (this.phoneForm.controls.phoneNumber.hasError('required')) {
          return 'Een telefoonnummer is vereist';
        }
        break;
      case 'smsCode':
        if (this.phoneForm.controls.smsCode.hasError('required')) {
          return 'Een sms code is vereist';
        }
        break;
      case 'password':
        if (this.phoneForm.controls.password.hasError('required')) {
          return 'Een wachtwoord is vereist';
        }
        break;
    }
  }

  async verifyPhoneNumber() {
    if (this.phoneForm.invalid) {
      return this.phoneForm.markAllAsTouched();
    }
    let phoneNumber: string = this.phoneForm.value.phoneNumber;
    if (phoneNumber.substring(0, 1) === '0') {
      phoneNumber = phoneNumber.substring(1);
      phoneNumber = `+31${phoneNumber}`;
    }
    if (this.phoneForm.controls.smsCode) {
      try {
        const cred = PhoneAuthProvider.credential(
          this.verificationId,
          this.phoneForm.value.smsCode
        );
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        await multiFactor(this.auth.currentUser).enroll(multiFactorAssertion);
        this.snackbar.open(
          'Verificatie in twee stappen staat voor dit account nu aan.',
          'X',
          {
            duration: 5000,
          }
        );
        this.router.navigate(['/account']);
      } catch (error) {
        console.log(error);
        if (error.code === 'auth/invalid-verification-code') {
          this.snackbar.open('Incorrecte code.', 'X', {
            duration: 5000,
          });
        }
      }
    }
    if (!this.phoneForm.controls.smsCode) {
      try {
        const recaptchaVerifier = new RecaptchaVerifier(
          'verify-button',
          {
            'size': 'invisible',
          },
          this.auth
        );
        this.recapthaVerifier = recaptchaVerifier;
        const multiFactorSession = await multiFactor(
          this.auth.currentUser
        ).getSession();

        const phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session: multiFactorSession,
        };
        const phoneAuthProvider = new PhoneAuthProvider(this.auth);
        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );
        this.reauthRequired = false;
        this.phoneForm.addControl(
          'smsCode',
          new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(6),
            Validators.minLength(6),
          ])
        );
      } catch (error) {
        console.log('ERROR: ', error);
        if (error.code == 'auth/requires-recent-login') {
          this.reauthRequired = true;
          this.phoneForm.addControl('password', new UntypedFormControl());
        } else {
          this.snackbar.open(
            'Er is iets verkeerd gegaan, probeer het later nog eens of contacteer de beheerder',
            'X',
            {
              duration: 5000,
            }
          );
        }
      }
    }
  }

  async reauthUser() {
    try {
      const credential = EmailAuthProvider.credential(
        this.auth.currentUser.email,
        this.phoneForm.value.password
      );
      await reauthenticateWithCredential(this.auth.currentUser, credential);
      location.reload();
    } catch (error) {
      console.log(error);
      if (error.code === 'auth/wrong-password') {
        this.snackbar.open('Incorrect wachtwoord.', 'X', {
          duration: 5000,
        });
      }
    }
  }

  async deleteMfa() {
    const dialogRef = this.dialog.open(DeleteMfaConfirmationComponent, {
      width: '350px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const callable = httpsCallable(functions, 'accountDeleteMfa');
        const result = await callable({ userId: this.auth.currentUser.uid });
        console.log('result:', result);
        if (result.data['status'] == 'success') {
          this.snackbar.open(
            'Verificatie in twee stappen staat voor dit account nu uit.',
            'X',
            {
              duration: 5000,
            }
          );
          location.reload();
        }
      }
    });
  }
}
