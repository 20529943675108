import { inject, NgModule } from '@angular/core';
import {
  Routes,
  Router,
  RouterModule,
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/compat/auth-guard';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { FirstTimeComponent } from './auth/first-time/first-time.component';
import { environment } from 'src/environments/environment';
import { DashboardTownshipComponent } from './dashboard-township/dashboard-township.component';
import { VoucherManagementComponent } from './voucher-management/voucher-management.component';
import { OrganisationManagementComponent } from './organisation-management/organisation-management.component';
import { SettingsComponent } from './settings/settings.component';
import { TagsComponent } from './tags/tags.component';
import { OrdersComponent } from './orders/orders.component';
import { PaymentReferencesComponent } from './payment-references/payment-references.component';
import { PromotionMaterialsComponent } from './promotion-materials/promotion-materials.component';
import { FontsComponent } from './fonts/fonts.component';
import { ExternalVoucherManagementComponent } from './external-voucher-management/external-voucher-management.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { UsersComponent } from './users/users.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { CharacteristicsComponent } from './characteristics/characteristics.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { ThemesComponent } from './themes/themes.component';
import { CheckTownshipVouchersComponent } from './check-township-vouchers/check-township-vouchers.component';
import { GroupLinkComponent } from './group-links/group-links.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { StatisticsManagementComponent } from './statistics/statistics-management/statistics-management.component';
import { SingleWidgetComponent } from './statistics/single-widget/single-widget.component';
import { AccountComponent } from './account/account.component';
import { MultiFactorComponent } from './account/multi-factor/multi-factor.component';
import { PlanningComponent } from './planning/planning.component';
import { DayPartsComponent } from './day-parts/day-parts.component';
import { AppointmentTypesComponent } from './appointment-types/appointment-types.component';
import { PlanningSettingsComponent } from './planning-settings/planning-settings.component';
import { PermissionGuard } from './permissions.guard';
import { concatMap, from, last, Observable, takeWhile } from 'rxjs';
import { ManageAppointmentTypesComponent } from './appointment-types/manage-appointment-types/manage-appointment-types.component';
import { ManageTeamUserComponent } from './users/pages/manage-team-user/manage-team-user.component';
import { ManageParticularityComponent } from './planning-settings/pages/manage-particularity/manage-particularity.component';
import { DayPartEditComponent } from './day-parts/pages/day-part-edit/day-part-edit.component';
import { ExceptionEditComponent } from './day-parts/pages/exception-edit/exception-edit.component';
import { PlanningTeamComponent } from './planning-team/planning-team.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ManageScheduleExceptionComponent } from './schedule/pages/manage-schedule-exception/manage-schedule-exception.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDefault = () =>
  redirectLoggedInTo([environment.defaultRoute.id]);

interface AsyncGuard extends CanActivateFn {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree>;
}

function executeGuardsInOrder(guards): CanActivateFn {
  return (route, state) => {
    const guardInstances = guards.map(inject) as AsyncGuard[];
    return from(guardInstances).pipe(
      concatMap((guard) => guard.canActivate(route, state)),
      takeWhile((value) => value === true, true),
      last()
    );
  };
}

// tslint:disable-next-line: prefer-const
let routes: Routes = [];
const allRoutes = {
  account: {
    path: 'account',
    component: AccountComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  multifactor: {
    path: 'account/multiFactor',
    component: MultiFactorComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  login: {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToDefault },
  },
  registration: {
    path: 'registration',
    component: RegistrationComponent,
  },
  firsttime: {
    path: 'first-time',
    component: FirstTimeComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  dashboardtownship: {
    path: 'dashboard-township',
    component: DashboardTownshipComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  settings: {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin'],
    },
  },
  vouchermanagement: {
    path: 'voucher-management',
    component: VoucherManagementComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin'],
    },
  },
  externalvouchermanagement: {
    path: 'external-voucher-management',
    component: ExternalVoucherManagementComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  organisationmanagement: {
    path: 'organisation-management',
    component: OrganisationManagementComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin', 'employee'],
    },
  },
  tags: {
    path: 'tags',
    component: TagsComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  orders: {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin'],
    },
  },
  paymentreferences: {
    path: 'payment-references',
    component: PaymentReferencesComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin', 'financial'],
    },
  },
  promotionmaterials: {
    path: 'promotion-materials/:organisationId',
    component: PromotionMaterialsComponent,
  },
  fonts: {
    path: 'fonts',
    component: FontsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  helpdesk: {
    path: 'helpdesk',
    component: HelpdeskComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin', 'helpdesk'],
    },
  },
  users: {
    path: 'users',
    component: UsersComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin'],
    },
  },
  generalSettings: {
    path: 'general-settings',
    component: GeneralSettingsComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  assignments: {
    path: 'assignments',
    component: AssignmentsComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin', 'employee'],
    },
  },
  characteristics: {
    path: 'characteristics',
    component: CharacteristicsComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin'],
    },
  },
  themes: {
    path: 'themes',
    component: ThemesComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  groupLinks: {
    path: 'group-links',
    component: GroupLinkComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  checktownshipvouchers: {
    path: 'check-vouchers',
    component: CheckTownshipVouchersComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin', 'controller'],
    },
  },
  statistics: {
    path: 'statistics',
    component: StatisticsComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      // permissions: ['owner', 'admin', 'statistics'],
    },
  },
  widget: {
    path: 'statistics/widget/:townshipId/:widgetId',
    component: SingleWidgetComponent,
  },
  statisticsmanagement: {
    path: 'statistics-management',
    component: StatisticsManagementComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      // permissions: ['owner', 'admin', 'statistics'],
    },
  },
  planning: {
    path: 'planning',
    component: PlanningComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin', 'planner'],
    },
  },
  planningTeam: {
    path: 'planning',
    component: PlanningTeamComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['coach', 'handyman'],
    },
  },
  dayparts: {
    path: 'day-parts',
    component: DayPartsComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin', 'planner'],
    },
  },
  managedaypart: {
    path: 'day-parts/manage-day-part/:id',
    component: DayPartEditComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin'],
    },
  },
  manageexception: {
    path: 'day-parts/manage-exception/:id',
    component: ExceptionEditComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin', 'planner'],
    },
  },
  appointmenttypes: {
    path: 'appointment-types',
    component: AppointmentTypesComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin'],
    },
  },
  manageappointmenttypes: {
    path: 'appointment-types/:id',
    component: ManageAppointmentTypesComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin'],
    },
  },
  manageteamuser: {
    path: 'users/manage-team-user/:id',
    component: ManageTeamUserComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin'],
    },
  },
  planningsettings: {
    path: 'planner-settings',
    component: PlanningSettingsComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin'],
    },
  },
  manageparticularity: {
    path: 'planner-settings/particularity/:id',
    component: ManageParticularityComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['owner', 'admin'],
    },
  },
  schedule: {
    path: 'schedule',
    component: ScheduleComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['coach', 'handyman'],
    },
  },
  scheduleexception: {
    path: 'schedule/exception/:id',
    component: ManageScheduleExceptionComponent,
    canActivate: [
      executeGuardsInOrder([AngularFireAuthGuard, PermissionGuard]),
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      permissions: ['coach', 'handyman'],
    },
  },
};
// environment.routes.forEach(route => {
//   routes.push(allRoutes[route]);
// });
// routes.push({
//   path: '**',
//   redirectTo: 'profile',
//   canActivate: [AngularFireAuthGuard],
//   data: { authGuardPipe: redirectUnauthorizedToLogin },
// });

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(router: Router) {
    const config = router.config;
    environment.routes.forEach((route) => {
      config.push(allRoutes[route]);
    });
    config.push({
      path: '**',
      redirectTo: environment.defaultRoute.path,
      data: { authGuardPipe: redirectUnauthorizedToLogin },
    });
    router.resetConfig(config);
  }
}
