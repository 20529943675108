import { deleteField, where } from 'firebase/firestore';

const townshipId = localStorage.getItem('township');

export function getThemeInputHelpers(group: string, field: string) {
  return themeInputFormFields[group][field];
}
export function getThemeStep(group: string) {
  return themeInputFormFields[group].step;
}
export function getThemeRow(group: string) {
  return themeInputFormFields[group].row;
}
export function getGroupOrder(group: string) {
  const array = Object.keys(themeInputFormFields);
  return array.indexOf(group);
}
export function getInputOrder(group: string, field: string) {
  const array = Object.keys(themeInputFormFields[group]);
  return array.indexOf(field);
}
export function getGroups() {
  return Object.keys(themeInputFormFields);
}
export async function resetThemeInputForm() {
  themeInputFormFields = await JSON.parse(
    JSON.stringify(defaultThemeInputFormFields)
  );
}
let themeInputFormFields = {};
const defaultThemeInputFormFields = {
  basicInfo: {
    step: 1,
    row: 1,
    name: {
      type: 'text',
    },
    description: {
      type: 'textField',
      validators: [
        {
          validator: 'maxLength',
          value: 255,
        },
      ],
    },
    slogan: {
      type: 'text',
    },
    type: {
      type: 'select',
      options: [
        { text: 'Aanvragen', value: 'request' },
        { text: 'Activeren', value: 'claim' },
        { text: 'Opvragen', value: 'requestActivated' },
        { text: 'Verkopen', value: 'sell' }, // SHOULD ONLY BE VISIBLE IF PAYMENTSETUP IS COMPLETED
      ],
    },
    couponValue: {
      type: 'number',
    },
    suffix: {
      type: 'text',
    },
    validUntilDate: {
      type: 'validUntilDate',
    },
    validUntilTime: {
      type: 'date',
    },
    validUntilTimeValue: {
      type: 'number',
    },
    validUntilTimeType: {
      type: 'select',
      options: [
        { text: 'Dag', value: 'day' },
        { text: 'Week', value: 'week' },
        { text: 'Maand', value: 'month' },
      ],
    },
    terms: {
      type: 'text',
    },
    termsUrl: {
      type: 'text',
    },
  },
  extraInfo: {
    step: 1,
    row: 1,
    labels: {
      type: 'chips',
      dbField: 'tagIds',
      dbOptions: {
        path: `township/${townshipId}/tags`,
        text: 'name',
        value: 'id',
      },
    },
    groupLink: {
      type: 'text',
    },
    groupLinkTxt: {
      type: 'text',
    },
    expiredNote: {
      type: 'text',
    },
    claimedNote: {
      type: 'text',
    },
    groupContactEmail: {
      type: 'email',
    },
    activateFromType: {
      type: 'text',
    },
    addressType: {
      type: 'select',
      dbOptions: {
        path: `township/${townshipId}/addresTypes`,
        value: 'type',
        text: 'type',
      },
    },
    requestCouponDays: {
      type: 'number',
    },
    startDate: {
      type: 'startDate',
    },
    endDate: {
      type: 'endDate',
    },
    startTime: {
      type: 'time',
    },
    endTime: {
      type: 'time',
    },

    postal: {
      type: 'text',
      validators: [
        {
          validator: 'postal',
        },
      ],
    },
    houseNumber: {
      type: 'text',
    },
    houseNumberAddition: {
      type: 'text',
    },
  },
  extraDocument: {
    step: 1,
    row: 1,
    groupPhoneNumber: {
      type: 'phone',
    },
    arrangementEmail: {
      type: 'email',
    },
  },
  guestOptions: {
    step: 1,
    row: 1,
    guestsHaveToPay: {
      type: 'bool',
    },
    maxVouchers: {
      type: 'number',
      validators: [
        {
          validator: 'maxPlanVouchers',
        },
        {
          validator: 'numberInput',
          minus: false,
          decimal: 0,
        },
      ],
      // validators: 'max',
    },
    voucherPrice: {
      type: 'number',
      validators: [
        {
          validator: 'numberInput',
          minus: false,
          decimal: 2,
        },
      ],
    },
    guestsHaveToguestPaymentUrlPayAmount: {
      type: 'url',
    },
  },
  backgroundColor: {
    step: 2,
    row: 1,
    backgroundColor: {
      type: 'color',
    },
  },
  themeImage: {
    step: 2,
    row: 1,
    themeImage: {
      type: 'image',
      dbOptions: {
        path: `themes`,
        type: 'doc',
        docValue: 'themeImages',
      },
    },
  },
  fonts: {
    step: 2,
    row: 1,
    titleFont: {
      type: 'fonts',
      default: deleteField(),
      // options: [{ text: 'Roboto-Regular.ttf', value: 'default' }],
      options: [],
      dbOptions: {
        path: `fonts`,
        filter: ['type', '==', 'title'],
        value: 'storageRefPath',
        text: 'name',
      },
    },
    defaultTextFont: {
      type: 'fonts',
      default: deleteField(),
      // options: [{ text: 'Roboto-Regular.ttf', value: 'default' }],
      options: [],
      dbOptions: {
        path: `fonts`,
        filter: ['type', '==', 'defaultText'],
        value: 'storageRefPath',
        text: 'name',
      },
    },
  },
  titleColor: {
    step: 2,
    row: 1,
    titleColor: {
      type: 'color',
    },
  },
  textColor: {
    step: 2,
    row: 1,
    defaultTextColor: {
      type: 'color',
    },
  },
  voucherOptions: {
    step: 3,
    row: 1,
    sendSmsCoupon: {
      type: 'bool',
    },
    claimInstantly: {
      type: 'bool',
    },
    forceSpentFullAmount: {
      type: 'bool',
    },
    dontSyncWithApi: {
      type: 'bool',
    },
    multipleUses: {
      type: 'bool',
    },
    scannable: {
      default: true,
      type: 'bool',
    },
    nameOnVoucher: {
      type: 'bool',
    },
    priceOnTicket: {
      type: 'bool',
    },
  },
  requestOptions: {
    step: 4,
    row: 1,
    verifyPhone: {
      type: 'bool',
    },
    hideVoucherRemainingInfo: {
      type: 'bool',
    },
    requestName: {
      type: 'bool',
    },
    requestPhone: {
      type: 'bool',
    },
    requestIdentification: {
      type: 'bool',
    },
    uniquePhoneNumbersOnly: {
      type: 'bool',
    },
    uniqueMailsOnly: {
      type: 'bool',
    },
    hideHeaderImage: {
      type: 'bool',
    },
    noAddressChecks: {
      type: 'bool',
    },
    multiplePeople: {
      type: 'bool',
    },
  },
};
