// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .widget-sold-org {
  display: block;
  box-sizing: content-box;
  padding: 16px;
}
::ng-deep .widget-sold-org mat-card-header {
  display: block;
  padding: 0;
}
::ng-deep .widget-sold-org mat-card-header mat-card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::ng-deep .widget-sold-org mat-card-content {
  padding: 0 !important;
}
::ng-deep .widget-sold-org mat-card-content .flex-box {
  flex-direction: column;
}
::ng-deep .widget-sold-org mat-card-content .flex-box ::ng-deep .ngx-charts {
  float: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/statistics/widgets/widget-vouchers-sold-per-org/widget-vouchers-sold-per-org.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,uBAAA;EACA,aAAA;AACF;AAAE;EACE,cAAA;EACA,UAAA;AAEJ;AADI;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAGN;AAAE;EACE,qBAAA;AAEJ;AADI;EACE,sBAAA;AAGN;AAFM;EACE,sBAAA;AAIR","sourcesContent":["::ng-deep .widget-sold-org {\n  display: block;\n  box-sizing: content-box;\n  padding: 16px;\n  mat-card-header {\n    display: block;\n    padding: 0;\n    mat-card-title {\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n  }\n  mat-card-content {\n    padding: 0 !important;\n    .flex-box {\n      flex-direction: column;\n      ::ng-deep .ngx-charts {\n        float: none !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
