// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outlined-button {
  border: 0 !important;
}

.title {
  margin: 0 !important;
}

.dialog-text {
  padding: 10px 24px 0 24px;
}
.dialog-text .township {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/general-settings/dialog/delete-township-file/delete-township-file.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,yBAAA;AACF;AAAE;EACE,iBAAA;AAEJ","sourcesContent":[".outlined-button {\n  border: 0 !important;\n}\n\n.title {\n  margin: 0 !important;\n}\n\n.dialog-text {\n  padding: 10px 24px 0 24px;\n  .township {\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
