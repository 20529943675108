// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-header {
  background-color: var(--primary);
  height: 48px;
  display: flex;
}
.sub-header .sub-header-wrapper {
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sub-header .sub-header-wrapper mat-icon,
.sub-header .sub-header-wrapper span {
  color: white;
}
.sub-header .sub-header-wrapper span {
  margin-left: 18px;
  font-weight: 500;
  font-size: 21px;
}
.sub-header .sub-header-wrapper mat-icon:hover {
  cursor: pointer;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapper .row-wrapper {
  width: 60%;
  margin: auto;
}
.wrapper .row-wrapper mat-card {
  width: 280px;
  margin-top: 12px;
  text-align: center;
}
.wrapper .row-wrapper mat-card mat-card-title {
  color: var(--primary);
  text-align: left;
}
.wrapper .row-wrapper mat-card .promo-img {
  width: 70%;
  height: inherit;
}
.wrapper .row-wrapper mat-card:hover {
  cursor: pointer;
}
.wrapper .row-wrapper h2 {
  color: var(--primary);
}

mat-spinner {
  margin: auto;
  margin-top: 32px;
}`, "",{"version":3,"sources":["webpack://./src/app/promotion-materials/promotion-materials.component.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,YAAA;EACA,aAAA;AACF;AAAE;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;AAEJ;AADI;;EAEE,YAAA;AAGN;AADI;EACE,iBAAA;EACA,gBAAA;EACA,eAAA;AAGN;AADI;EACE,eAAA;AAGN;;AACA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAEF;AADE;EACE,UAAA;EACA,YAAA;AAGJ;AAFI;EACE,YAAA;EAEA,gBAAA;EACA,kBAAA;AAGN;AAFM;EACE,qBAAA;EACA,gBAAA;AAIR;AAFM;EACE,UAAA;EACA,eAAA;AAIR;AADI;EACE,eAAA;AAGN;AAAI;EACE,qBAAA;AAEN;;AAGA;EACE,YAAA;EACA,gBAAA;AAAF","sourcesContent":[".sub-header {\n  background-color: var(--primary);\n  height: 48px;\n  display: flex;\n  .sub-header-wrapper {\n    margin-left: 16px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    mat-icon,\n    span {\n      color: white;\n    }\n    span {\n      margin-left: 18px;\n      font-weight: 500;\n      font-size: 21px;\n    }\n    mat-icon:hover {\n      cursor: pointer;\n    }\n  }\n}\n.wrapper {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  .row-wrapper {\n    width: 60%;\n    margin: auto;\n    mat-card {\n      width: 280px;\n      // height: 356px;\n      margin-top: 12px;\n      text-align: center;\n      mat-card-title {\n        color: var(--primary);\n        text-align: left;\n      }\n      .promo-img {\n        width: 70%;\n        height: inherit;\n      }\n    }\n    mat-card:hover {\n      cursor: pointer;\n    }\n\n    h2 {\n      color: var(--primary);\n    }\n  }\n}\n\nmat-spinner {\n  margin: auto;\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
