import { Component, OnInit } from '@angular/core';
import { DayPart, PlanningUser, PlanningUserException } from '../interfaces';
import moment from 'moment';
import { getAuth } from 'firebase/auth';
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../app.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DayPartDeleteDialogComponent } from '../day-parts/dialog/day-part-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit {
  townshipId: string = localStorage.getItem('township');
  userId: string;
  userMaxWeeklyHours: number;
  loaded: boolean = false;
  dayParts = {};
  dayPartDays = [];
  dayPartsUnavailable: String[] = [];
  exceptions: PlanningUserException[] = [];
  moment = moment;
  tabIndex: number = 0;

  constructor(private dialog: MatDialog, private snackbar: MatSnackBar) {}

  async ngOnInit() {
    const auth = getAuth();
    this.userId = auth.currentUser.uid;
    const user = (
      await getDoc(
        doc(db, `township/${this.townshipId}/planningUsers/${this.userId}`)
      )
    ).data() as PlanningUser;
    this.dayPartsUnavailable = user?.dayPartsUnavailable ?? [];
    this.userMaxWeeklyHours = user?.workData?.maxWeeklyHours ?? 0;
    this.exceptions = user?.exceptions ?? [];
    this.exceptions = this.exceptions.filter((obj) =>
      moment(obj.endDate.toDate())
        .set({ hour: obj.endHour, minutes: obj.endMinutes })
        .isSameOrAfter(moment())
    );
    if (history.state.tabIndex) {
      this.tabIndex = history.state.tabIndex;
      delete history.state.tabIndex;
    }

    const daypartsQuery = query(
      collection(db, `township/${this.townshipId}/dayParts`),
      orderBy('day'),
      orderBy('startHour')
    );
    onSnapshot(daypartsQuery, (querySnapshot) => {
      this.dayParts = [];
      this.dayPartDays = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data() as DayPart;
        if (data.enabled) {
          if (!this.dayParts[data.name]) {
            this.dayParts[data.name] = [];
            this.dayPartDays.push({ name: data.name, day: data.day });
          }
          this.dayParts[data.name].push({ id: doc.id, ...data });
        }
      });
      //move sunday entry (day 0) to the end of the array if it exists
      if (this.dayPartDays.find((obj) => obj.day == 0)) {
        this.dayPartDays.push(this.dayPartDays.shift());
      }
      this.loaded = true;
    });
  }

  async toggleDayPart(dayPartId) {
    const index = this.dayPartsUnavailable.indexOf(dayPartId);
    if (index != -1) {
      this.dayPartsUnavailable.splice(index, 1);
    } else {
      this.dayPartsUnavailable.push(dayPartId);
    }
    const docRef = doc(
      db,
      `township/${this.townshipId}/planningUsers/${this.userId}`
    );
    try {
      await setDoc(
        docRef,
        { dayPartsUnavailable: this.dayPartsUnavailable },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
      this.snackbar.open(
        'Er is iets fout gegaan met het aan- en uitzetten van de beschikbaarheid',
        'X',
        {
          duration: 5000,
        }
      );
      return;
    }
  }

  async deleteException(exception) {
    const dialogRef = this.dialog.open(DayPartDeleteDialogComponent, {
      width: '400px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const index = this.exceptions.indexOf(exception);
        if (index != -1) {
          this.exceptions.splice(index, 1);
          this.exceptions.sort(this.sortObjectsByStartDate);

          const docRef = doc(
            db,
            `/township/${this.townshipId}/planningUsers/${this.userId}`
          );
          try {
            await updateDoc(docRef, { exceptions: this.exceptions });
          } catch (error) {
            console.log(error);
            this.snackbar.open(
              'Er is iets fout gegaan met het verwijderen van de uitzondering',
              'X',
              {
                duration: 5000,
              }
            );
            return;
          }
        }
      }
    });
  }

  availableOnDayPart(dayPartId: string) {
    if (this.dayPartsUnavailable.indexOf(dayPartId) != -1) {
      return false;
    }
    return true;
  }

  getTimeString(dayPart: DayPart) {
    const start = moment().set({
      hour: dayPart.startHour,
      minutes: dayPart.startMinutes,
    });
    const end = moment().set({
      hour: dayPart.endHour,
      minutes: dayPart.endMinutes,
    });
    return start.format('HH:mm').concat(' - ', end.format('HH:mm'));
  }

  ucFirst(string: string) {
    return String(string).charAt(0).toUpperCase() + String(string).slice(1);
  }

  sortObjectsByStartDate(a, b) {
    const dateOne = moment(
      a.startDate.toDate ? a.startDate.toDate() : a.startDate
    ).set({
      hour: a.startHour,
      minutes: a.startMinutes,
    });
    const dateTwo = moment(
      b.startDate.toDate ? b.startDate.toDate() : b.startDate
    ).set({
      hour: b.startHour,
      minutes: b.startMinutes,
    });
    if (dateOne > dateTwo) {
      return -1;
    }
    if (dateOne < dateTwo) {
      return 1;
    }
    return 0;
  }
}
