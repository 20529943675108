import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  organisation: any;
  type: string;
}

@Component({
  selector: 'app-hide-organisation',
  templateUrl: './hide-organisation.component.html',
  styleUrls: ['./hide-organisation.component.scss'],
})
export class HideOrganisation implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {}
}
