// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}
table .red {
  color: red;
}
table .green {
  color: green;
}

::ng-deep .email-data-dialog-title {
  padding: 0;
  display: flex;
  justify-content: space-between;
}

::ng-deep .email-data-dialog-content {
  padding: 16px 0 0 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/helpdesk/dialogs/email-data-dialog/email-data-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,UAAA;AACJ;AACE;EACE,YAAA;AACJ;;AAGA;EACE,UAAA;EACA,aAAA;EACA,8BAAA;AAAF;;AAGA;EACE,8BAAA;AAAF","sourcesContent":["table {\n  width: 100%;\n\n  .red {\n    color: red;\n  }\n  .green {\n    color: green;\n  }\n}\n\n::ng-deep .email-data-dialog-title {\n  padding: 0;\n  display: flex;\n  justify-content: space-between;\n}\n\n::ng-deep .email-data-dialog-content {\n  padding: 16px 0 0 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
