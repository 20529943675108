import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  doc,
  DocumentData,
  DocumentReference,
  setDoc,
} from 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-first-time',
  templateUrl: './first-time.component.html',
  styleUrls: ['./first-time.component.scss'],
})
export class FirstTimeComponent implements OnInit {
  userDoc: DocumentReference<DocumentData>;
  step: number = 1;
  env = environment;
  name: string = environment.name;
  steps: number = environment.stepper.maxSteps;
  prefix: string = environment.prefix;
  environmentDB: string = environment.stepper.environmentDB;
  title: string;
  image: string;
  video: string;
  text: string;
  prefixCheck: boolean = false;

  constructor(
    public afAuth: AngularFireAuth,
    public db: AngularFirestore,
    private router: Router
  ) {}
  ngOnInit() {
    //TEMPORARY UNTIL ALL ENVIRONMENTS HAVE A TUTORIAL
    //blacklist for environments
    if (
      this.env.name === 'Groene Bon' ||
      this.env.name === 'Lokale Bon' ||
      this.env.name == 'Duurzaam Wonen Bon'
    ) {
      this.router.navigate([this.env.defaultRoute.path]);
    }
    //TEMPORARY UNTIL ALL ENVIRONMENTS HAVE A TUTORIAL

    if (this.prefix === 'toegangs') {
      this.prefixCheck = true;
    }
    this.stepperContent(this.step);
  }

  numSequence(number: number): Array<number> {
    let array = Array();
    for (let integer = 1; integer < number + 1; integer++) {
      array.push(integer);
    }
    return array;
  }

  navigateToStep(step) {
    this.step = step;
    this.stepperContent(step);
  }

  previousStep() {
    this.step--;
    this.stepperContent(this.step);
  }

  stepper(value) {
    const element = document.getElementById('step');
    element.classList.remove('animation');
    element.offsetWidth;
    this.step = this.step + value;
    this.stepperContent(this.step);
    element.classList.add('animation');
  }

  done(value) {
    if (value === 1) {
      this.afAuth.user.forEach(async (user) => {
        console.log('user', user);
        if (user) {
          let postData;
          switch (this.environmentDB) {
            case 'groeneBon':
              postData = { tutorial: { groeneBon: true } };
              break;
            case 'groeneBonOrg':
              postData = { tutorial: { groeneBonOrg: true } };
              break;
            case 'lokaleBon':
              postData = { tutorial: { lokaleBon: true } };
              break;
            case 'lokaleBonOrg':
              postData = { tutorial: { lokaleBonOrg: true } };
              break;
            case 'toegangsBon':
              postData = { tutorial: { toegangsBon: true } };
              break;
            case 'toegangsBonOrg':
              postData = { tutorial: { toegangsBonOrg: true } };
              break;
            case 'duurzaamwonenBon':
              postData = { tutorial: { duurzaamwonenBon: true } };
              break;
            case 'duurzaamwonenBonOrg':
              postData = { tutorial: { duurzaamwonenBonOrg: true } };
              break;
          }
          await setDoc(doc(this.db.firestore, `users/${user.uid}`), postData, {
            merge: true,
          });
        }
      });
    }
    this.router.navigate([this.env.defaultRoute.path]);
  }

  stepperContent(value) {
    let key = 'step' + value;
    this.title = this.env.stepper.steps[key].title;
    this.image = this.env.stepper.steps[key].image
      ? this.env.stepper.steps[key].image
      : null;
    this.video = this.env.stepper.steps[key].video
      ? this.env.stepper.steps[key].video
      : null;
    this.text = this.env.stepper.steps[key].text;
  }
}
