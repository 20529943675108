import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  getDocs,
  collection,
  setDoc,
  doc,
  deleteDoc,
  getDoc,
} from 'firebase/firestore';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  townshipId?: string;
}

@Component({
  selector: 'app-address-types-dialog',
  templateUrl: './address-types-dialog.component.html',
  styleUrls: ['./address-types-dialog.component.scss'],
})
export class AddressTypesDialogComponent implements OnInit {
  addressTypes = [];
  addressForm: UntypedFormGroup;
  validAddressForm = false;

  constructor(
    public dialogRef: MatDialogRef<AddressTypesDialogComponent>,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  async ngOnInit(): Promise<void> {
    this.addressForm = this.formBuilder.group({
      type: ['', [Validators.required]],
    });

    this.addressForm.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        if (this.addressForm.value.type) {
          this.validAddressForm = true;
        }
      });
    const querySnapshot = await getDocs(
      collection(
        this.db.firestore,
        `township/${this.data.townshipId}/addresTypes`
      )
    );
    querySnapshot.forEach((doc) => {
      const addressType = doc.data();
      addressType.default = addressType.default ? addressType.default : false;
      this.addressTypes.push({ ...addressType, id: doc.id });
    });
    this.addressTypes.sort();
  }

  close() {
    this.dialogRef.close();
  }

  async addClicked() {
    if (this.addressForm.value.type) {
      const type = this.addressForm.value.type.toString().toLowerCase();
      console.log(this.addressForm.value.type);
      console.log(type);
      // Check if addressType already exists.
      const docRef = doc(
        this.db.firestore,
        `township/${this.data.townshipId}/addresTypes/${this.addressForm.value.type}`
      );
      const docSnapshot = (await getDoc(docRef)).data();
      console.log('docSnapshot', docSnapshot);
      if (docSnapshot) {
        this.snackBar.open(
          `Kenmerk ${this.addressForm.value.type} bestaat al.`,
          'X',
          {
            duration: 5000,
          }
        );
      } else {
        // If addressType doesn't already exist, add it.
        try {
          await setDoc(
            doc(
              this.db.firestore,
              `township/${this.data.townshipId}/addresTypes/${type}`
            ),
            {
              type: type,
              default: false,
            }
          );
          this.addressTypes.push(type);
          this.addressTypes.sort();
          this.snackBar.open(`Kenmerk ${type} is toegevoegd.`, 'X', {
            duration: 5000,
          });
          this.addressForm.reset();
        } catch (error) {
          console.log(error);
          throw error;
        }
      }
    }
  }

  deleteClicked(addressType: any) {
    console.log(addressType);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '375px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('result', result);
      if (result) {
        try {
          await deleteDoc(
            doc(
              this.db.firestore,
              `township/${this.data.townshipId}/addresTypes/${addressType}`
            )
          );
          const index = this.addressTypes.indexOf(addressType, 0);
          this.addressTypes.splice(index, 1);
        } catch (error) {
          console.log(error);
          throw error;
        }
      }
    });
  }

  getError(name: string, form: UntypedFormGroup) {
    const field = form.get(name);
    let error = '';
    if (field?.touched || !field?.pristine) {
      if (field?.hasError('required')) {
        this.validAddressForm = false;
        return (error = 'Dit veld moet ingevuld zijn.');
      }
    }
    return error;
  }
}
