// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .discount-dialog-title {
  padding: 0;
}

::ng-deep .discount-dialog-content {
  padding: 16px 0 0 0 !important;
  overflow: hidden;
}
::ng-deep .discount-dialog-content mat-form-field {
  width: 100%;
}
::ng-deep .discount-dialog-content mat-label {
  margin-bottom: 12px;
}
::ng-deep .discount-dialog-content mat-radio-button {
  margin-bottom: 4px;
}
::ng-deep .discount-dialog-content .white {
  background-color: white;
  color: black;
}
::ng-deep .discount-dialog-content .button-wrapper {
  display: flex;
  flex-direction: column;
}
::ng-deep .discount-dialog-content .button-wrapper .button-wrapper-2 {
  display: flex;
  align-items: center;
}
::ng-deep .discount-dialog-content .import-button {
  width: 186px;
  margin-bottom: 8px;
  text-align: center;
}
::ng-deep .discount-dialog-content p {
  margin-top: 0px;
}
::ng-deep .discount-dialog-content p span {
  color: red;
}
::ng-deep .discount-dialog-content .separator {
  font-size: 15px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  width: 20px;
  text-align: center;
  padding: 0 5px 0 5px;
}

::ng-deep .discount-dialog-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/organisation-management/dialog/discount/discount.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,8BAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,mBAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ;AAAE;EACE,uBAAA;EACA,YAAA;AAEJ;AAAE;EACE,aAAA;EACA,sBAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;AAGN;AAAE;EACE,YAAA;EACA,kBAAA;EACA,kBAAA;AAEJ;AACE;EACE,eAAA;AACJ;AAAI;EACE,UAAA;AAEN;AAEE;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,oBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AADF","sourcesContent":["::ng-deep .discount-dialog-title {\n  padding: 0;\n}\n\n::ng-deep .discount-dialog-content {\n  padding: 16px 0 0 0 !important;\n  overflow: hidden;\n  mat-form-field {\n    width: 100%;\n  }\n  mat-label {\n    margin-bottom: 12px;\n  }\n  mat-radio-button {\n    margin-bottom: 4px;\n  }\n  .white {\n    background-color: white;\n    color: black;\n  }\n  .button-wrapper {\n    display: flex;\n    flex-direction: column;\n    .button-wrapper-2 {\n      display: flex;\n      align-items: center;\n    }\n  }\n  .import-button {\n    width: 186px;\n    margin-bottom: 8px;\n    text-align: center;\n  }\n\n  p {\n    margin-top: 0px;\n    span {\n      color: red;\n    }\n  }\n\n  .separator {\n    font-size: 15px;\n    font-weight: 600;\n    position: relative;\n    overflow: hidden;\n    width: 20px;\n    text-align: center;\n    padding: 0 5px 0 5px;\n  }\n}\n\n::ng-deep .discount-dialog-actions {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
