// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.voucher-value {
  margin-bottom: 12px;
}

.amount-to-pay {
  margin-top: -8px;
  margin-bottom: 16px;
}

#form {
  display: flex;
  flex-direction: column;
}

::ng-deep .check-voucher-dialog-title {
  padding: 0;
}

::ng-deep .check-voucher-dialog-content {
  padding: 16px 0 0 0 !important;
}
::ng-deep .check-voucher-dialog-content .edit-receipt {
  margin-top: 12px;
}
::ng-deep .check-voucher-dialog-content .edit-receipt button {
  width: 100%;
}
::ng-deep .check-voucher-dialog-content .edit-receipt .icon {
  width: 42px;
}
::ng-deep .check-voucher-dialog-content .edit-receipt .add-receipt {
  width: 100%;
}
::ng-deep .check-voucher-dialog-content .edit-receipt .open-receipt {
  margin-top: 0px;
  margin-bottom: 12px;
  margin-left: 4px;
  font-size: 12px;
}

::ng-deep .check-voucher-dialog-actions {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/organisation-management/check-vouchers/check-voucher/check-voucher.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AACA;EACE,gBAAA;EACA,mBAAA;AAEF;;AACA;EACE,aAAA;EACA,sBAAA;AAEF;;AACA;EACE,UAAA;AAEF;;AACA;EACE,8BAAA;AAEF;AADE;EACE,gBAAA;AAGJ;AAFI;EACE,WAAA;AAIN;AAFI;EACE,WAAA;AAIN;AAFI;EACE,WAAA;AAIN;AAFI;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AAIN;;AACA;EACE,UAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AAEF","sourcesContent":[".voucher-value {\n  margin-bottom: 12px;\n}\n.amount-to-pay {\n  margin-top: -8px;\n  margin-bottom: 16px;\n}\n\n#form {\n  display: flex;\n  flex-direction: column;\n}\n\n::ng-deep .check-voucher-dialog-title {\n  padding: 0;\n}\n\n::ng-deep .check-voucher-dialog-content {\n  padding: 16px 0 0 0 !important;\n  .edit-receipt {\n    margin-top: 12px;\n    button {\n      width: 100%;\n    }\n    .icon {\n      width: 42px; // overwrite button\n    }\n    .add-receipt {\n      width: 100%;\n    }\n    .open-receipt {\n      margin-top: 0px;\n      margin-bottom: 12px;\n      margin-left: 4px;\n      font-size: 12px;\n    }\n  }\n}\n\n::ng-deep .check-voucher-dialog-actions {\n  padding: 0;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
