// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper {
  width: 85%;
  margin: 0 auto;
}
.content-wrapper .user-form {
  margin: 50px 0 0 0;
}
.content-wrapper .user-form .row-fields {
  width: 100%;
  display: flex;
  gap: 15px;
}
.content-wrapper .user-form mat-form-field,
.content-wrapper .user-form .row-fields > div {
  width: 100%;
  margin-bottom: 10px;
}
.content-wrapper .user-form mat-slide-toggle {
  margin: 15px 0 10px 0;
}
.content-wrapper .user-form .mat-mdc-chip {
  border-radius: 10px;
}
.content-wrapper .user-form .distance-labels {
  border-radius: 10px;
  margin: 10px 0 20px 0;
}
.content-wrapper .user-form .distance-labels .mat-icon {
  vertical-align: bottom;
}
.content-wrapper .user-form .particularities {
  display: flex;
  gap: 10px;
  margin: 10px 0 20px 0;
}
.content-wrapper .user-form .particularities button {
  outline: 1px solid rgb(100, 100, 100);
}
.content-wrapper .user-form .particularities .particularitySelected {
  background-color: var(--primary);
  color: var(--primary-contrast);
}
.content-wrapper .user-form .dialog-subtitle {
  margin-bottom: 10px;
}
.content-wrapper .user-form .external-coach {
  margin: 10px 0 20px 0;
}
.content-wrapper .action-buttons {
  margin: 30px 0 50px 0;
  display: flex;
  justify-content: space-between;
}

.loading-wrapper .loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/app/users/pages/manage-team-user/manage-team-user.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,cAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AADI;EACE,WAAA;EACA,aAAA;EACA,SAAA;AAGN;AADI;;EAEE,WAAA;EACA,mBAAA;AAGN;AADI;EACE,qBAAA;AAGN;AADI;EACE,mBAAA;AAGN;AADI;EACE,mBAAA;EACA,qBAAA;AAGN;AAFM;EACE,sBAAA;AAIR;AADI;EACE,aAAA;EACA,SAAA;EACA,qBAAA;AAGN;AAFM;EACE,qCAAA;AAIR;AAFM;EACE,gCAAA;EACA,8BAAA;AAIR;AADI;EACE,mBAAA;AAGN;AADI;EACE,qBAAA;AAGN;AAAE;EACE,qBAAA;EACA,aAAA;EACA,8BAAA;AAEJ;;AAEE;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;AACJ","sourcesContent":[".content-wrapper {\n  width: 85%;\n  margin: 0 auto;\n  .user-form {\n    margin: 50px 0 0 0;\n    .row-fields {\n      width: 100%;\n      display: flex;\n      gap: 15px;\n    }\n    mat-form-field,\n    .row-fields > div {\n      width: 100%;\n      margin-bottom: 10px;\n    }\n    mat-slide-toggle {\n      margin: 15px 0 10px 0;\n    }\n    .mat-mdc-chip {\n      border-radius: 10px;\n    }\n    .distance-labels {\n      border-radius: 10px;\n      margin: 10px 0 20px 0;\n      .mat-icon {\n        vertical-align: bottom;\n      }\n    }\n    .particularities {\n      display: flex;\n      gap: 10px;\n      margin: 10px 0 20px 0;\n      button {\n        outline: 1px solid rgb(100, 100, 100);\n      }\n      .particularitySelected {\n        background-color: var(--primary);\n        color: var(--primary-contrast);\n      }\n    }\n    .dialog-subtitle {\n      margin-bottom: 10px;\n    }\n    .external-coach {\n      margin: 10px 0 20px 0;\n    }\n  }\n  .action-buttons {\n    margin: 30px 0 50px 0;\n    display: flex;\n    justify-content: space-between;\n  }\n}\n.loading-wrapper {\n  .loading {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
