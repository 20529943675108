import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-vouchers-export-type',
  templateUrl: './vouchers-export-type.component.html',
  styleUrls: ['./vouchers-export-type.component.scss'],
})
export class VouchersExportTypeComponent {
  vouchersExportTypeForm: FormGroup = new FormGroup({
    type: new FormControl(''),
  });
  constructor(private dialogRef: MatDialogRef<VouchersExportTypeComponent>) {}

  export() {
    if (!this.vouchersExportTypeForm.valid) {
      return;
    }
    this.dialogRef.close({
      type: this.vouchersExportTypeForm.value.type,
    });
  }
}
