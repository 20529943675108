import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { doc, DocumentSnapshot, getDoc, getDocs } from 'firebase/firestore';
import {
  BadlyInsulatedParts,
  Reports,
  SubsidyOptionNames,
} from 'src/app/enums';
import { CellStyleOptions, Township, Voucher } from 'src/app/interfaces';
import * as ExcelJS from 'exceljs';
import moment from 'moment';

@Component({
  selector: 'app-export-report-dialog-component',
  templateUrl: './export-report-dialog-component.html',
  styleUrls: ['./export-report-dialog-component.scss'],
})
export class ExportReportDialogComponent implements OnInit {
  loadingPage: boolean = true;
  township: Township;
  subsidyOptionsNameList: string[] = Object.values(SubsidyOptionNames);
  reports = Reports;
  currentYear: number = new Date().getFullYear();
  voucherGroupFormList: [
    {
      id: string;
      name: string;
      formControl: string;
    }?
  ] = [];

  exportReportForm: FormGroup = new FormGroup({
    selectedReport: new FormControl('', Validators.required),
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
  });
  taxImage: number;

  constructor(
    private snackbar: MatSnackBar,
    private db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  async ngOnInit(): Promise<any> {
    await this.getAndPrepareData();
    this.loadingPage = false;
  }

  async getAndPrepareData() {
    const townshipDoc = await getDoc(
      doc(this.db.firestore, `township/${this.data.townshipId}`)
    );
    this.township = { ...townshipDoc.data(), id: townshipDoc.id } as Township;

    const voucherGroupDocs = await getDocs(
      this.db.firestore
        .collection(`township/${this.data.townshipId}/voucherGroups`)
        .where('sendBurdenOfProof', '==', true)
    );

    voucherGroupDocs.forEach((voucherGroupDoc) => {
      const voucherGroupData = {
        ...voucherGroupDoc.data(),
        id: voucherGroupDoc.id,
      } as Township;
      const pushObject = {
        id: voucherGroupData.id,
        name: voucherGroupData.name,
        formControl: voucherGroupData.id,
      };

      this.exportReportForm.addControl(
        pushObject.formControl,
        new FormControl(false)
      );

      this.voucherGroupFormList.push(pushObject);
    });
  }

  checkCheckboxes() {
    if (this.voucherGroupFormList.length == 0) {
      return true;
    }
    let returnBoolean: boolean = false;
    this.voucherGroupFormList.forEach((voucherGroupForm) => {
      if (this.exportReportForm.controls[voucherGroupForm.formControl].value) {
        returnBoolean = true;
      }
    });
    return returnBoolean;
  }

  exportReport() {
    if (this.exportReportForm.invalid) {
      return this.exportReportForm.markAllAsTouched();
    }

    switch (this.exportReportForm.controls.selectedReport.value) {
      case Reports.sisaReport:
        this.sisaReport();
        break;
    }
  }

  async sisaReport() {
    const startDate = this.exportReportForm.controls.startDate.value;
    const endDate = this.exportReportForm.controls.endDate.value;

    if (startDate > endDate) {
      return this.snackbar.open(
        'Startdatum mag niet later zijn dat de einddatum',
        'X',
        {
          duration: 5000,
        }
      );
    }

    const voucherGroupIdsToCheck = [];

    this.voucherGroupFormList.forEach((voucherGroupForm) => {
      if (this.exportReportForm.controls[voucherGroupForm.formControl].value) {
        voucherGroupIdsToCheck.push(voucherGroupForm.id);
      }
    });

    try {
      const voucherDocs = await getDocs(
        this.db.firestore
          .collection(`township/${this.data.townshipId}/vouchers`)
          .where('paidDate', '>=', startDate)
          .where('paidDate', '<', endDate)
          .where('voucherGroupId', 'in', voucherGroupIdsToCheck)
          .orderBy('paidDate', 'desc')
      );

      const vouchersToExport = this.shouldExportSubsidyOptionInSisaReport(
        voucherDocs.docs
      );

      if (vouchersToExport.length === 0) {
        return this.snackbar.open(
          'Geen bonnen gevonden binnen de gegeven begindatum en einddatum',
          'X',
          {
            duration: 5000,
          }
        );
      } else {
        this.snackbar.open('Genereer rapport…', 'X', {
          duration: 5000,
        });
        this.insertDataInSisaReport(vouchersToExport);
      }
    } catch (e) {
      this.snackbar.open('Oeps! Er is iets misgegaan', 'X', {
        duration: 5000,
      });
    }
  }

  async insertDataInSisaReport(vouchers: Voucher[]) {
    const workbook = new ExcelJS.Workbook();

    this.taxImage = workbook.addImage({
      base64: await this.getImageAsBase64(
        '/assets/images/excel-images/netherlands-tax-full.png'
      ),
      extension: 'jpeg',
    });

    const targetedSupportWorksheet = workbook.addWorksheet(
      'Gerichte ondersteuning'
    );

    this.targetedSupportWorkSheet(targetedSupportWorksheet);

    const improvedHousingWorksheet = workbook.addWorksheet(
      'Verbeterde woningen'
    );

    this.improvedHousingWorksheet(improvedHousingWorksheet, vouchers);

    this.downloadExcelFile(
      workbook,
      'Monitoringsbestand SpUk Lokale Aanpak Isolatie.xlsx'
    );
  }

  targetedSupportWorkSheet(worksheet: ExcelJS.Worksheet) {
    worksheet.views = [{ showGridLines: false }];

    worksheet.columns = [
      { key: 'A', width: 80 },
      { key: 'B', width: 80 },
      { key: 'C', width: 8 },
      { key: 'D', width: 35 },
      { key: 'E', width: 80 },
      { key: 'F', width: 40 },
      { key: 'G', width: 20 },
      { key: 'H', width: 60 },
      { key: 'I', width: 50 },
    ];

    worksheet.mergeCells('G3:H3');

    worksheet.getRow(1).height = 120;

    worksheet.addImage(this.taxImage, {
      tl: { col: 2, row: 0 },
      ext: { width: 303, height: 167 },
      editAs: 'absolute',
    });

    worksheet.getCell('A3').value = 'Monitoring SpUk Lokale Aanpak Isolatie:';
    worksheet.getCell('A3').style = this.getCellStyle({
      bold: true,
      size: 12,
    });

    worksheet.getCell('A4').value =
      "Gemiddelde WOZ waarde van de koopwoningen (in euro's):";
    worksheet.getCell('A4').style = this.getCellStyle();

    worksheet.getCell('A5').value =
      'Totaal aantal koopwoningen in uw gemeente in 2022:';
    worksheet.getCell('A5').style = this.getCellStyle();

    worksheet.getCell('A6').value =
      'Aantal aangevraagde woningen in 2023/2024:';
    worksheet.getCell('A6').style = this.getCellStyle();

    worksheet.getCell('A7').value = 'Monitoringsperiode:';
    worksheet.getCell('A7').style = this.getCellStyle();

    worksheet.getCell('B3').value = this.township.name;
    worksheet.getCell('B3').style = this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
    });

    worksheet.getCell('B4').value = '';
    worksheet.getCell('B4').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('B5').value = '';
    worksheet.getCell('B5').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('B6').value = '';
    worksheet.getCell('B6').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('B7').value = `${moment(
      this.exportReportForm.controls.startDate.value
    ).format('DD/MM/YYYY')} - ${moment(
      this.exportReportForm.controls.endDate.value
    ).format('DD/MM/YYYY')}`;
    worksheet.getCell('B7').style = this.getCellStyle({
      bold: true,
      horizontalAlignment: 'center',
    });

    worksheet.getCell('E3').value =
      'Monitoring: Gerichte ondersteuning in de periode:';
    worksheet.getCell('E3').style = this.getCellStyle({
      bold: true,
      size: 12,
    });

    worksheet.getCell('E4').style = this.getCellStyle();

    worksheet.getCell('E5').value =
      "Totaal aantal geïnformeerde/benaderde eigenaar-bewoners/VvE's:";
    worksheet.getCell('E5').style = this.getCellStyle();

    worksheet.getCell('E6').value = 'Aantal afgegeven energie-adviezen:';
    worksheet.getCell('E6').style = this.getCellStyle();

    worksheet.getCell('E7').value =
      'Aantal begeleide subsidieaanvragen, zowel ISDE als SVVE:';
    worksheet.getCell('E7').style = this.getCellStyle();

    worksheet.getCell('E8').value =
      'Aantal afgegeven financiële adviezen/ondersteuning bij financiering:';
    worksheet.getCell('E8').style = this.getCellStyle();

    worksheet.getCell('E9').value =
      'Aantal georganiseerde straat/wijk/grootschalige aanpakken:';
    worksheet.getCell('E9').style = this.getCellStyle();

    worksheet.getCell('F3').value = 'Grondgebonden woningen';
    worksheet.getCell('F3').style = this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
    });

    worksheet.getCell('F4').style = this.getCellStyle();

    worksheet.getCell('F5').value = '';
    worksheet.getCell('F5').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('F6').value = '';
    worksheet.getCell('F6').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('F7').value = '';
    worksheet.getCell('F7').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('F8').value = '';
    worksheet.getCell('F8').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('F9').value = '';
    worksheet.getCell('F9').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('G3').value = "VvE's";
    worksheet.getCell('G3').style = this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
    });

    worksheet.getCell('G4').value = "Aantal VvE's ";
    worksheet.getCell('G4').style = this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
    });

    worksheet.getCell('G5').value = '';
    worksheet.getCell('G5').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('G6').value = '';
    worksheet.getCell('G6').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('G7').value = '';
    worksheet.getCell('G7').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('G8').value = '';
    worksheet.getCell('G8').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('G9').value = '';
    worksheet.getCell('G9').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('H3').style = this.getCellStyle();

    worksheet.getCell('H4').value = "Totaal aantal woningen in die VvE's";
    this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
    });
    worksheet.getCell('H4').style = this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
    });

    worksheet.getCell('H5').style = this.getCellStyle();

    worksheet.getCell('H6').style = this.getCellStyle();

    worksheet.getCell('H7').style = this.getCellStyle();
    worksheet.getCell('H8').style = this.getCellStyle();

    worksheet.getCell('H9').style = this.getCellStyle();

    worksheet.getCell('I3').value = 'Totaal aantal woningen*';
    worksheet.getCell('I3').style = this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
    });

    worksheet.getCell('I4').style = this.getCellStyle();

    worksheet.getCell('I5').value = '';
    worksheet.getCell('I5').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('I6').value = '';
    worksheet.getCell('I6').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('I7').value = '';
    worksheet.getCell('I7').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('I8').value = '';
    worksheet.getCell('I8').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('I9').value = '';
    worksheet.getCell('I9').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    // worksheet.getCell('I11').value = '* Is het totaal van kolom G en I';
    // worksheet.getCell('I11').style = this.getCellStyle({
    //   bold: true,
    //   size: 12,
    //   hideBorder: true,
    // });

    worksheet.getCell('A12').value = {
      text: 'Zie ook tabblad Verbeterde woningen',
      hyperlink: "#'Verbeterde woningen'!A1",
    };

    worksheet.getCell('A12').style.font = {
      color: { argb: 'FF0000FF' },
      underline: true,
    } as ExcelJS.Font;
  }

  improvedHousingWorksheet(worksheet: ExcelJS.Worksheet, vouchers: Voucher[]) {
    worksheet.views = [{ showGridLines: false }];

    worksheet.columns = [
      { key: 'A', width: 20 },
      { key: 'B', width: 10 },
      { key: 'C', width: 20 },
      { key: 'D', width: 20 },
      { key: 'E', width: 20 },
      { key: 'F', width: 40 },
      { key: 'G', width: 25 },
      { key: 'H', width: 25 },
      { key: 'I', width: 25 },
      { key: 'J', width: 25 },
      { key: 'K', width: 25 },
      { key: 'L', width: 13 },
      { key: 'M', width: 13 },
      { key: 'N', width: 15 },
      { key: 'O', width: 15 },
      { key: 'P', width: 15 },
      { key: 'Q', width: 15 },
      { key: 'R', width: 15 },
      { key: 'S', width: 15 },
      { key: 'T', width: 15 },
      { key: 'U', width: 15 },
      { key: 'V', width: 15 },
      { key: 'W', width: 15 },
      { key: 'X', width: 15 },
      { key: 'Y', width: 15 },
      { key: 'Z', width: 15 },
      { key: 'AA', width: 15 },
      { key: 'AB', width: 15 },
      { key: 'AC', width: 15 },
      { key: 'AD', width: 15 },
      { key: 'AE', width: 15 },
      { key: 'AF', width: 15 },
      { key: 'AG', width: 15 },
      { key: 'AH', width: 15 },
      { key: 'AI', width: 15 },
    ];

    worksheet.getRow(1).height = 120;
    worksheet.getRow(2).height = 100;
    worksheet.getRow(3).height = 50;
    worksheet.getRow(4).height = 40;
    worksheet.getRow(5).height = 120;

    worksheet.mergeCells('A1:R1');
    worksheet.mergeCells('A2:R2');
    worksheet.mergeCells('L4:O4');
    worksheet.mergeCells('P4:S4');
    worksheet.mergeCells('T4:W4');
    worksheet.mergeCells('X4:AE4');
    worksheet.mergeCells('AF4:AI4');
    worksheet.mergeCells('L5:M5');
    worksheet.mergeCells('N5:O5');
    worksheet.mergeCells('P5:Q5');
    worksheet.mergeCells('R5:S5');
    worksheet.mergeCells('T1:U1');
    worksheet.mergeCells('T5:U5');
    worksheet.mergeCells('V5:W5');
    worksheet.mergeCells('X5:Y5');
    worksheet.mergeCells('Z5:AA5');
    worksheet.mergeCells('AB5:AC5');
    worksheet.mergeCells('AD5:AE5');
    worksheet.mergeCells('AF5:AG5');
    worksheet.mergeCells('AH5:AI5');

    worksheet.addImage(this.taxImage, {
      tl: { col: 18, row: 0 },
      ext: { width: 303, height: 167 },
      editAs: 'absolute',
    });

    worksheet.getCell(
      'A1'
    ).value = `Woningbestand Lokale Aanpak Isolatie ${this.currentYear}`;
    worksheet.getCell('A1').style = this.getCellStyle({
      bold: true,
      size: 28,
      color: 'FF007BC7',
      hideBorder: true,
    });

    worksheet.getCell('A2').value =
      "Deze Excel-tabel dient als monitoringsoverzicht voor de SPUk Lokale Aanpak Isolatie en wordt tweemaal per jaar aangeleverd. Bij welke koopwoningen zijn welke energiebesparende isolatiemaatregelen of is er een eventueel energiezuinig ventilatiesysteem aangebracht door derden of via de Doe-Het-Zelf-aanpak. Twee of meer maatregelen uitgevoerd aan dezelfde woning telt als 1 unieke woning. Meerdere maatregelen aan de zelfde woning uitgevoerd in het zelfde jaar mogen onder elkaar geschreven worden, bij uitvoering in een ander jaar kan er onderaan in het bestand een nieuwe regel worden ingevuld. De m2 dienen zo nauwkeurig mogelijk te worden weergegeven, afronden op gehele m2, 0,50 of meer naar boven, minder dan 0,50 naar beneden afronden. Een maatregel telt pas als maatregel als men voldoet aan de isolatie- en minimale oppervlakte-eisen van de ISDE voor eigenaar/bewoners en aan de SVVE voor verenigingen (o.a. VvE's). Bij glas, kozijnpanelen en isolerende deuren is iedere combinatie van isolatiewaarden mogelijk, dit komt overeen met de bestaande subsidieregelingen (ISDE, SVVE). De kolommen F, G en K zijn voorzien van een dropdown menu. Kolom H dient alleen ingevuld te worden als er geen energielabel aanwezig is in de betreffende woning.";
    worksheet.getCell('A2').style = this.getCellStyle({
      hideBorder: true,
    });

    worksheet.columns.forEach((column) => {
      let cellValue = '';
      if (
        column.key !== 'A' &&
        column.key !== 'B' &&
        column.key !== 'C' &&
        column.key !== 'D' &&
        column.key !== 'E' &&
        column.key !== 'F' &&
        column.key !== 'G' &&
        column.key !== 'H' &&
        column.key !== 'I' &&
        column.key !== 'J' &&
        column.key !== 'K'
      ) {
        worksheet.getCell(`${column.key}4`).style = this.getCellStyle({
          horizontalAlignment: 'center',
        });
      }
      switch (column.key) {
        case 'L':
          cellValue =
            'Beide uitvoeringsvormen zijn varianten van dezelfde maatregel, de bewoner kiest voor of dakisolatie of zolder/vlieringvloerisolatie';
          break;
        case 'T':
          cellValue =
            'Beide uitvoeringsvormen zijn varianten van dezelfde maatregel, de bewoner kiest voor vloerisolatie of voor bodemisolatie';
          break;
        case 'X':
          cellValue = 'Minimaal 8 m2 per woning';
          break;
        case 'AF':
          cellValue =
            'Mits van toepassing kiezen voor 1 van de 2 ventilatiesystemen, beiden kan niet.';
          break;
      }
      if (cellValue !== '') {
        worksheet.getCell(`${column.key}4`).value = cellValue;
      }
    });

    worksheet.columns.forEach((column) => {
      let cellValue = '';
      worksheet.getCell(`${column.key}5`).style = this.getCellStyle({
        bold: true,
        cellColor: 'FFF0ECEC',
      });
      switch (column.key) {
        case 'A':
          cellValue = 'Straat';
          break;
        case 'B':
          cellValue = 'nr.';
          break;
        case 'C':
          cellValue = 'Toevoeging';
          break;
        case 'D':
          cellValue = 'Postcode';
          break;
        case 'E':
          cellValue = 'Woonplaats';
          break;
        case 'F':
          cellValue =
            'WOZ-waarde boven of onder gemiddelde WOZ-waarde van de koopwoningen in de betreffende gemeente of de NHG-grens';
          break;
        case 'G':
          cellValue = 'Evt. aanwezig energielabel (sleepmenu D t/m G)';
          break;
        case 'H':
          cellValue =
            'Opsomming van energetisch slechte schil-elementen (minimaal 2)';
          break;
        case 'I':
          cellValue = 'Jaar uitvoering maatregel(en)';
          break;
        case 'J':
          cellValue = 'Toegekend bedrag';
          break;
        case 'K':
          cellValue = 'Uitzondering schuldhulp-sanering (ja/nee)';
          break;
        case 'L':
          cellValue = 'Dakisolatie (in m2) (minimaal 20 m2 per woning)';
          break;
        case 'N':
          cellValue =
            'Zolder/vlieringvloer-isolatie (in m2) (minimaal 20 m2 per woning)';
          break;
        case 'P':
          cellValue = 'Spouwmuurisolatie (in m2), (minimaal 10 m2 per woning)';
          break;
        case 'R':
          cellValue =
            'Gevelisolatie (zowel binnen- als buitengevel-isolatie) (in m2) (minimaal 10 m2 per woning)';
          break;
        case 'T':
          cellValue = 'Vloerisolatie (in m2) (minimaal 20 m2 per woning)';
          break;
        case 'V':
          cellValue = 'Bodemisolatie (in m2) (minimaal 20 m2 per woning)';
          break;
        case 'X':
          cellValue = 'Glas en kozijnpanelen Ug en Up  ≤ 1,2 W/m2K';
          break;
        case 'Z':
          cellValue = 'Isolerende deuren Ud ≤ 1,5 W/m2K';
          break;
        case 'AB':
          cellValue = 'Glas en kozijnpanelen Ug en Up ≤ 0,7 W/m2K';
          break;
        case 'AD':
          cellValue =
            'Isolerende deuren Ud ≤ 1,0 W/m2K i.c.m. nieuwe isolerende kozijnen Uf ≤  1,5 W/m2K';
          break;
        case 'AF':
          cellValue =
            'CO2-gestuurde ventilatie (mits van toepassing maximaal 1 invullen)';
          break;
        case 'AH':
          cellValue =
            'Balansventilatie met WTW (evt. in combinatie met CO2-sturing) (mits van toepassing maximaal 1 invullen)';
          break;
      }
      if (cellValue !== '') {
        worksheet.getCell(`${column.key}5`).value = cellValue;
      }
    });

    let dhzOrNot: boolean = true;
    worksheet.columns.forEach((column) => {
      worksheet.getCell(`${column.key}6`).style = this.getCellStyle({
        cellColor: 'FFF0ECEC',
      });
      if (
        column.key !== 'A' &&
        column.key !== 'B' &&
        column.key !== 'C' &&
        column.key !== 'D' &&
        column.key !== 'E' &&
        column.key !== 'F' &&
        column.key !== 'G' &&
        column.key !== 'H' &&
        column.key !== 'I' &&
        column.key !== 'J' &&
        column.key !== 'K'
      ) {
        worksheet.getCell(`${column.key}6`).value = dhzOrNot ? 'DHZ' : 'Derden';
        dhzOrNot = !dhzOrNot;
      }
    });

    let rowIndex = 7;

    vouchers.forEach((doc: Voucher) => {
      let typeValue = 'Onder';

      if (doc.type) {
        doc.type.forEach((type) => {
          if (type.toLowerCase() == 'woz boven') {
            typeValue = 'Boven';
          }
        });
      }

      let badlyInsulatedParts = [];

      doc.burdenOfProofForm?.badlyInsulatedParts?.forEach(
        (badlyInsulatedPart) => {
          switch (badlyInsulatedPart) {
            case 'flatRoof':
              badlyInsulatedParts.push(BadlyInsulatedParts.flatRoof);
              break;
            case 'roofAttic':
              badlyInsulatedParts.push(BadlyInsulatedParts.roofAttic);
              break;
            case 'facade':
              badlyInsulatedParts.push(BadlyInsulatedParts.facade);
              break;
            case 'floor':
              badlyInsulatedParts.push(BadlyInsulatedParts.floor);
              break;
            case 'glas':
              badlyInsulatedParts.push(BadlyInsulatedParts.glas);
              break;
            default:
              badlyInsulatedParts.push('');
          }
        }
      );

      worksheet.columns.forEach((column) => {
        let columnValue;
        switch (column.key) {
          case 'A':
            columnValue = doc.street;
            break;
          case 'B':
            columnValue = Number(doc.houseNumber);
            break;
          case 'C':
            columnValue = doc.houseNumberAddition;
            break;
          case 'D':
            columnValue = doc.postal;
            break;
          case 'E':
            columnValue = doc.city;
            break;
          case 'F':
            columnValue = typeValue;
            break;
          case 'G':
            columnValue = doc.burdenOfProofForm?.energyLabel;
            break;
          case 'H':
            columnValue = badlyInsulatedParts.join(', ');
            break;
          case 'I':
            columnValue = doc.paidDate.toDate().getFullYear();
            break;
          case 'J':
            columnValue = doc.amountToPayOrg
              ? new Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(doc.amountToPayOrg)
              : '';
            break;
        }
        worksheet.getCell(`${column.key}${rowIndex}`).value = columnValue;
        worksheet.getCell(`${column.key}${rowIndex}`).style =
          this.getCellStyle();
      });

      this.filterSubsidyOptions(doc, worksheet, rowIndex);
      rowIndex++;
    });

    worksheet.columns.forEach((column) => {
      let columnStyle = this.getCellStyle({
        bold: true,
        size: 16,
        horizontalAlignment: 'right',
        cellColor: 'FF90CCE4',
      });
      let columnValue = {
        formula: `SUM(${column.key}7:${column.key}${rowIndex - 1})`,
      };
      if (column.key === 'K') {
        columnStyle = this.getCellStyle({
          bold: true,
          size: 16,
          cellColor: 'FF90CCE4',
        });
        columnValue = {
          formula: '"Totaal: "&SUM(L3:AI3)',
        };
      }

      if (
        column.key !== 'A' &&
        column.key !== 'B' &&
        column.key !== 'C' &&
        column.key !== 'D' &&
        column.key !== 'E' &&
        column.key !== 'F' &&
        column.key !== 'G' &&
        column.key !== 'H' &&
        column.key !== 'I' &&
        column.key !== 'J'
      ) {
        worksheet.getCell(`${column.key}3`).style = columnStyle;
        worksheet.getCell(`${column.key}3`).value = columnValue;
      }
    });
  }

  getCellStyle(options?: CellStyleOptions) {
    return {
      font: {
        name: 'Verdana',
        bold: options?.bold ?? false,
        size: options?.size ?? 10,
        color: { argb: options?.color ?? '' },
      },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: options?.cellColor ?? '' },
      },
      alignment: {
        horizontal: options?.horizontalAlignment ?? 'left',
        vertical: options?.verticalAlignment ?? 'middle',
        wrapText: true,
      },
      border: options?.hideBorder
        ? {}
        : {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'medium' },
          },
    } as ExcelJS.Cell;
  }

  shouldExportSubsidyOptionInSisaReport(voucherDocs: DocumentSnapshot[]) {
    const vouchersToExport = [];
    voucherDocs.forEach((doc: DocumentSnapshot) => {
      const voucher = doc.data() as Voucher;
      voucher.burdenOfProofForm?.subsidyOptions.forEach((subsidyOption) => {
        if (
          this.subsidyOptionsNameList.includes(subsidyOption.name) &&
          voucher.burdenOfProofForm?.status === 'Accepted'
        ) {
          if (
            !vouchersToExport.find(
              (voucherObj) => voucherObj.number === voucher.number
            )
          ) {
            vouchersToExport.push(voucher);
          }
        }
      });
    });
    return vouchersToExport;
  }

  async getImageAsBase64(imagePath: string) {
    return new Promise<string>((resolve, reject) => {
      const img = new Image();

      img.src = imagePath;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(img, 0, 0);

        const base64String = canvas.toDataURL('image/jpeg');

        resolve(base64String.split(',')[1]);
      };

      img.onerror = reject;
    });
  }

  filterSubsidyOptions(
    doc: Voucher,
    workSheet: ExcelJS.Worksheet,
    rowIndex: number
  ) {
    doc.burdenOfProofForm?.subsidyOptions.forEach((subsidyOption) => {
      switch (subsidyOption.name) {
        case SubsidyOptionNames.exteriorRoofInsulation:
        case SubsidyOptionNames.innerRoofInsulation:
          if (subsidyOption.diy) {
            this.insertSubsidyOptionValues(
              'L',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          } else {
            this.insertSubsidyOptionValues(
              'M',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          }
          break;
        case SubsidyOptionNames.atticLoftFloorInsulation:
          if (subsidyOption.diy) {
            this.insertSubsidyOptionValues(
              'N',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          } else {
            this.insertSubsidyOptionValues(
              'O',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          }
          break;
        case SubsidyOptionNames.cavityWallInsulation:
          if (subsidyOption.diy) {
            this.insertSubsidyOptionValues(
              'P',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          } else {
            this.insertSubsidyOptionValues(
              'Q',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          }
          break;
        case SubsidyOptionNames.interiorFacadeInsulation:
        case SubsidyOptionNames.exteriorFacadeInsulation:
          if (subsidyOption.diy) {
            this.insertSubsidyOptionValues(
              'R',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          } else {
            this.insertSubsidyOptionValues(
              'S',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          }
          break;
        case SubsidyOptionNames.insulationGroundFloor:
          if (subsidyOption.diy) {
            this.insertSubsidyOptionValues(
              'T',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          } else {
            this.insertSubsidyOptionValues(
              'U',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          }
          break;
        case SubsidyOptionNames.insulationBottom:
          if (subsidyOption.diy) {
            this.insertSubsidyOptionValues(
              'V',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          } else {
            this.insertSubsidyOptionValues(
              'W',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          }
          break;
        case SubsidyOptionNames.insulatingGlassAndFramePanelsHigh:
          if (subsidyOption.diy) {
            this.insertSubsidyOptionValues(
              'X',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          } else {
            this.insertSubsidyOptionValues(
              'Y',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          }
          break;
        case SubsidyOptionNames.insulatingDoorsHigh:
          if (subsidyOption.diy) {
            this.insertSubsidyOptionValues(
              'Z',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          } else {
            this.insertSubsidyOptionValues(
              'AA',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          }
          break;
        case SubsidyOptionNames.insulatingGlassAndFramePanelsLow:
          if (subsidyOption.diy) {
            this.insertSubsidyOptionValues(
              'AB',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          } else {
            this.insertSubsidyOptionValues(
              'AC',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          }
          break;
        case SubsidyOptionNames.insulatingDoorsLow:
          if (subsidyOption.diy) {
            this.insertSubsidyOptionValues(
              'AD',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          } else {
            this.insertSubsidyOptionValues(
              'AE',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          }
          break;
        case SubsidyOptionNames.co2Ventilation:
          if (subsidyOption.diy) {
            this.insertSubsidyOptionValues(
              'AF',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          } else {
            this.insertSubsidyOptionValues(
              'AG',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          }
          break;
        case SubsidyOptionNames.balanceVentilationWithHeatRecovery:
          if (subsidyOption.diy) {
            this.insertSubsidyOptionValues(
              'AH',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          } else {
            this.insertSubsidyOptionValues(
              'AI',
              doc,
              subsidyOption.mainUnit,
              rowIndex,
              workSheet
            );
          }
      }
    });
  }

  insertSubsidyOptionValues(
    columnKey: string,
    doc,
    subsidyOptionMainUnit: string,
    rowIndex: number,
    workSheet: ExcelJS.Worksheet
  ) {
    if (subsidyOptionMainUnit) {
      try {
        const mainUnitSeparated = subsidyOptionMainUnit
          .replace(/[.]/g, ',')
          .match(/^([0-9]{1,3}){1}([,.]?[0-9]{3})*([,.]{1}[0-9]{1,2})?$/);
        let mainUnit: any = mainUnitSeparated[0];
        if (mainUnitSeparated[3]) {
          mainUnit = `${mainUnit.substring(
            0,
            mainUnit.length - 3
          )}${mainUnitSeparated[3].replace(/[,]/g, '.')}`;
        }

        mainUnit = Number(mainUnit.replace(/[,]/g, ''));

        workSheet.getCell(columnKey + rowIndex).value =
          Number(workSheet.getCell(columnKey + rowIndex).value ?? 0) + mainUnit;
      } catch (error) {
        console.log('error :', error);
        console.log('Voucher number: ', doc.number);
        console.log('Faulty data: ', subsidyOptionMainUnit);
      }
    }
  }

  async downloadExcelFile(workbook: ExcelJS.Workbook, fileName: string) {
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  }
}
