import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  collection,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
  deleteField,
  getDoc,
  updateDoc,
  where,
  query,
} from 'firebase/firestore';
import {
  Theme,
  ThemeInput,
  Township,
  Voucher,
  VoucherGroup,
} from 'src/app/interfaces';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  getGroupOrder,
  getInputOrder,
  getThemeInputHelpers,
  getThemeStep,
  getGroups,
  getThemeRow,
  resetThemeInputForm,
} from './theme-helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, Observable, startWith } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ImageCropperComponent } from '../../themes/dialogs/image-cropper/image-cropper.component';
import Hashids from 'hashids';
import { sleep } from 'src/app/globals';
import { ShareThemedVoucherGroupComponent } from '../share-themed-voucher-group/share-themed-voucher-group.component';
import { SwiperComponent } from 'swiper/angular';
import { CustomValidators } from 'src/app/validators/custom-validators';
import SwiperCore, { Pagination } from 'swiper';
import moment from 'moment';
// import moment from 'moment';

export interface DialogData {
  townshipId: string;
  voucherGroup: VoucherGroup;
  theme?: Theme;
  newGroup?: boolean;
}
interface Step {
  number: number;
  form: FormGroup;
  rows: Row[];
  visible: boolean;
  lastStep?: boolean;
}

interface Row {
  order: number;
  groups: Group[];
  visible: boolean;
}

interface Group {
  id: string;
  order: number;
  title: string;
  width: number;
  visibleFormFields: FormField[];
}
interface FormField {
  id: string;
  label: string;
  required: boolean;
  type:
    | 'text'
    | 'textField'
    | 'number'
    | 'date'
    | 'dateTime'
    | 'select'
    | 'image'
    | 'color';
  placeholder?: string;
  width: number;
  options?: any[];
  order: number;
}
SwiperCore.use([Pagination]);
@Component({
  selector: 'app-themed-voucher-group-edit',
  templateUrl: './themed-voucher-group-edit.component.html',
  styleUrls: ['./themed-voucher-group-edit.component.scss'],
})
export class ThemedVoucherGroupEditComponent implements OnInit {
  widthPadding = 1;
  voucherGroupId: string;
  townshipId = this.data.townshipId;
  hashids: any = new Hashids('', 5, 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789');
  newVoucherGroup = false;
  saving = false;
  selectedTheme: Theme;
  themes?: Theme[];
  steps: Step[] = [];
  totalSteps: number;
  currentStep: Step;
  currentStepIndex: number;
  colorPickers: any = {};
  labelsUponStartup: string[] = [];

  chipLists: any = {};
  separatorKeysCodes: number[] = [ENTER, COMMA];

  voucherImg: File;
  voucherImgUrl: string;
  voucherImageCurrent: string = '';
  voucherImgAlreadyUploaded: boolean;
  selectedVoucherImageUrl: string = '';
  themeImages: any;
  selectedThemeImage: any;
  maxVouchersPerGroup: number;

  @ViewChild('customSwiper', { static: false }) customSwiper: SwiperComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public db: AngularFirestore,
    public dialogRef: MatDialogRef<ThemedVoucherGroupEditComponent>,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private storage: AngularFireStorage
  ) {
    console.log('data', data);
  }

  async ngOnInit(): Promise<void> {
    await resetThemeInputForm();
    const township = (
      await getDoc(doc(this.db.firestore, `township/${this.townshipId}`))
    ).data() as Township;

    this.maxVouchersPerGroup = township.plan.maxVouchersPerGroup;
    if (this.data.theme) {
      this.selectedTheme = this.data.theme;
      this.buildThemeForm();
    }
    this.newVoucherGroup = this.data.newGroup;
    if (this.newVoucherGroup) {
      this.voucherGroupId = this.db.createId();
    } else {
      this.voucherGroupId = this.data.voucherGroup.id;
    }
    if (!this.selectedTheme) {
      const themesRef = collection(this.db.firestore, 'themes');
      const themeDocs = await getDocs(themesRef);
      const themes = [];
      themeDocs.forEach((themeDoc) => {
        const theme = themeDoc.data() as Theme;
        theme.id = themeDoc.id;
        theme.ref = themeDoc.ref;
        if (theme.env === environment.name) {
          themes.push(theme);
        }
      });
      this.themes = themes;
      console.log('this.themes', this.themes);
    }

    if (localStorage.getItem('filePath')) {
      this.storage.ref(localStorage.getItem('filePath')).delete();
      localStorage.removeItem('filePath');
    }
    console.log('chiplists', this.chipLists);
    console.log('colorpickers', this.colorPickers);
  }

  buildThemeForm() {
    // should build form now
    const steps: Step[] = [];
    const dbOptionsToGet = [];
    const groups = getGroups();
    Object.keys(this.selectedTheme).forEach(async (groupKey) => {
      if (groups.includes(groupKey)) {
        const group = this.selectedTheme[groupKey];
        console.log('groupKey', groupKey);
        const stepNumber = getThemeStep(groupKey);
        console.log('stepNumber', stepNumber);
        const rowNumber = getThemeRow(groupKey);
        const stepExists = steps.find((step) => {
          return step.number === stepNumber;
        });
        if (!stepExists) {
          steps.push({
            number: stepNumber,
            form: new FormGroup({}),
            rows: [],
            visible: false,
          });
        }
        const currentStep = steps.find((step) => {
          return step.number === stepNumber;
        });
        const rowExists = currentStep.rows.find((row) => {
          return row.order === rowNumber;
        });
        if (!rowExists) {
          currentStep.rows.push({
            order: rowNumber,
            groups: [],
            visible: false,
          });
        }
        const currentRow = currentStep.rows.find((row) => {
          return row.order === rowNumber;
        });
        const groupExists = currentRow.groups.find((group) => {
          return group.id === groupKey;
        });
        if (!groupExists) {
          currentRow.groups.push({
            id: groupKey,
            order: getGroupOrder(groupKey),
            title: group.title,
            width: group.width,
            visibleFormFields: [],
          });
        }
        const currentGroup = currentRow.groups.find((group) => {
          return group.id === groupKey;
        });
        console.log('currentStep', currentStep);
        if (group.inputs) {
          Object.keys(group.inputs).forEach((fieldKey) => {
            console.log('fieldKey', fieldKey);
            const validators = [];

            const themeInput: ThemeInput =
              this.selectedTheme[groupKey].inputs[fieldKey];

            const themeInputHelper = getThemeInputHelpers(groupKey, fieldKey);
            if (themeInputHelper) {
              themeInputHelper.validators?.forEach((validator) => {
                switch (validator.validator) {
                  case 'postal':
                    validators.push(CustomValidators.postalValidator);
                    break;
                  case 'maxLength':
                    validators.push(Validators.maxLength(validator.value));
                    break;
                  case 'maxPlanVouchers':
                    validators.push(
                      CustomValidators.maxValueValidator(
                        this.maxVouchersPerGroup
                      )
                    );
                    break;
                  case 'numberInput':
                    validators.push(
                      CustomValidators.numberInput(
                        validator.minus,
                        false,
                        validator.decimal
                      )
                    );
                    break;
                }
              });
              if (themeInput.required) {
                validators.push(Validators.required);
              }
              currentStep.form.addControl(
                fieldKey,
                new FormControl(
                  {
                    value: themeInput.defaultValue,
                    disabled: themeInput.disabled,
                  },
                  validators
                )
              );
              console.log(
                `new formcontrol for input ${fieldKey}`,
                currentStep.form.controls[fieldKey]
              );
              let options;
              if (themeInputHelper.options || themeInputHelper.dbOptions) {
                options = themeInputHelper.options
                  ? themeInputHelper.options
                  : [];
                if (themeInputHelper.dbOptions) {
                  dbOptionsToGet.push({
                    step: currentStep,
                    id: fieldKey,
                    type: themeInputHelper.type,

                    options,
                    dbOptions: themeInputHelper.dbOptions,
                  });
                  // console.log('should get dbOptions', dbOptionsToGet);
                }
              }
              if (themeInputHelper.type === 'color') {
                this.colorPickers[fieldKey] = false;
              }
              if (themeInputHelper.type === 'image') {
                if (this.data.voucherGroup) {
                  if (this.data.voucherGroup.uploadedVoucherImageUrl) {
                    this.voucherImgAlreadyUploaded = true;
                    this.voucherImgUrl =
                      this.data.voucherGroup.uploadedVoucherImageUrl;
                    this.voucherImageCurrent = this.voucherImgUrl;
                  }
                }
              }
              if (themeInputHelper.type === 'chips') {
                this.chipLists[fieldKey] = {};
                this.chipLists[fieldKey].dbField = themeInputHelper.dbField;
                if (options) {
                  this.chipLists[fieldKey].allChips = options;
                }
              }
              if (themeInput.visible) {
                currentRow.visible = true;
                currentStep.visible = true;
                currentGroup.visibleFormFields.push({
                  id: fieldKey,
                  label: themeInput.label,
                  required: themeInput.required,
                  placeholder: themeInput.placeholder,
                  width: themeInput.width,
                  type: themeInputHelper.type,
                  options,
                  order: getInputOrder(groupKey, fieldKey),
                });
              }
            } else {
              console.warn(`no support yet for ${groupKey} ${fieldKey}`);
            }
          });
        }
      }
    });
    console.log('steps', steps);
    const filteredSteps = steps
      .filter((step) => {
        return step.visible;
      })
      .sort((a, b) => a.number - b.number);

    filteredSteps.forEach((step) => {
      step.rows = step.rows.filter((row) => {
        return row.visible;
      });
      step.rows = step.rows.sort(function (a, b) {
        return a.order - b.order;
      });
      step.rows.forEach((row) => {
        row.groups.sort(function (a, b) {
          return a.order - b.order;
        });
        row.groups.forEach((group) => {
          group.visibleFormFields.sort(function (a, b) {
            return a.order - b.order;
          });
        });
      });
    });
    console.log('filteredSteps', filteredSteps);

    filteredSteps[filteredSteps.length - 1].lastStep = true;
    this.steps = filteredSteps;
    this.currentStep = filteredSteps[0];
    this.currentStepIndex = 1;
    console.log('dbOptionsToGet before loadDbOptions', dbOptionsToGet);
    this.loadDbOptions(dbOptionsToGet);

    if (this.data.voucherGroup) {
      console.log('this.data.voucherGroup', this.data.voucherGroup);
      const patchObj = { ...this.data.voucherGroup } as any;
      if (patchObj.validUntilDate) {
        patchObj.validUntilDate = patchObj.validUntilDate.toDate();
      }
      if (patchObj.startDate) {
        patchObj.startDate = patchObj.startDate.toDate();
        patchObj.startTime = moment(patchObj.startDate).format('HH:mm');
      }
      if (patchObj.endDate) {
        patchObj.endDate = patchObj.endDate.toDate();
        patchObj.endTime = moment(patchObj.endDate).format('HH:mm');
      }
      if (patchObj.voucherPrice) {
        patchObj.voucherPrice = patchObj.voucherPrice.toFixed(2);
      }
      this.steps.forEach((step) => {
        step.form.patchValue(patchObj);
        if (step.form.controls.couponValue) {
          step.form.controls.couponValue.disable();
        }
      });
    }

    this.currentStep.form.controls.guestsHaveToPay.valueChanges.subscribe(
      (val) => {
        if (val) {
          this.currentStep.form.controls.voucherPrice.addValidators(
            Validators.required
          );
        } else {
          this.currentStep.form.controls.voucherPrice.removeValidators(
            Validators.required
          );
        }
        this.currentStep.form.controls.voucherPrice.updateValueAndValidity();
      }
    );
  }

  async loadDbOptions(optionsToGet: any[]) {
    console.log('optionsToGet', optionsToGet);
    for (let i = 0; i < optionsToGet.length; i++) {
      const option = optionsToGet[i];
      console.log('dbPath', option.dbOptions.path);
      console.log('dbFilter', option.dbOptions.filter);
      const ref = collection(this.db.firestore, option.dbOptions.path);
      const filter = option.dbOptions.filter;
      const optionsFromDb = filter
        ? await getDocs(query(ref, where(filter[0], filter[1], filter[2])))
        : await getDocs(ref);
      console.log('optionsFromDb', optionsFromDb.docs.length);
      optionsFromDb.forEach((optionDoc) => {
        const optionData = optionDoc.data();
        optionData.id = optionDoc.id;
        // console.log('optionData.id', optionData.id);
        // console.log('option.dbOptions', option.dbOptions);
        // console.log('optionData foreach', optionData);
        let optionChip;
        if (option.dbOptions.type === 'doc') {
          if (option.dbOptions.docValue === 'themeImages') {
            optionChip = {
              id: optionData.id,
              images: optionData.images,
              selectedThemeImageUrl: optionData.selectedThemeImageUrl,
            };
          }
        } else {
          optionChip = {
            id: optionData.id,
            value: optionData[option.dbOptions.value],
            text: optionData[option.dbOptions.text],
            valueField: option.dbOptions.value,
            textField: option.dbOptions.text,
          };
        }
        option.options.push(optionChip);
      });
      if (option.dbOptions.type === 'doc') {
        if (option.dbOptions.docValue === 'themeImages') {
          if (option.options) {
            const selectedTheme = option.options.find(
              ({ id }) => id === `${this.selectedTheme.id}`
            );
            this.themeImages = selectedTheme.images;

            if (
              selectedTheme.selectedThemeImageUrl &&
              selectedTheme.selectedThemeImageUrl != null
            ) {
              this.selectedVoucherImageUrl = this.data.voucherGroup
                ?.selectedVoucherImageUrl
                ? this.data.voucherGroup.selectedVoucherImageUrl
                : selectedTheme.selectedThemeImageUrl;
            } else {
              this.selectedVoucherImageUrl = this.data.voucherGroup
                ?.selectedVoucherImageUrl
                ? this.data.voucherGroup.selectedVoucherImageUrl
                : null;
            }
          }
        }
      }
      if (option.type === 'fonts') {
        console.log('font options', option.options);
        this.sortFonts(option.options);
      }
      if (option.type === 'chips') {
        const chip = this.chipLists[option.id];
        if (option.options) {
          this.chipLists[option.id].allChips = option.options;
          this.chipLists[option.id].selectedChips = [];
          this.chipLists[option.id].formControl = new FormControl();
          let filteredChips: Observable<string[]> =
            this.currentStep.form.controls[option.id].valueChanges.pipe(
              startWith(null),
              map((chip: string | null) =>
                chip
                  ? this._filter(chip, this.chipLists[option.id].allChips)
                  : this.chipLists[option.id].allChips.slice()
              )
            );
          this.chipLists[option.id].filteredChips = filteredChips;
          const alreadySelectedChips = [];
          if (
            chip.dbField &&
            this.data.voucherGroup &&
            this.data.voucherGroup[chip.dbField]
          ) {
            this.data.voucherGroup[chip.dbField].forEach((field: string) => {
              const chipThatShouldBeSelected = this.chipLists[
                option.id
              ].allChips.find((searchChip: any) => {
                if (searchChip.value === field) {
                  return searchChip;
                }
              });
              if (chipThatShouldBeSelected) {
                alreadySelectedChips.push(chipThatShouldBeSelected);
              }
            });
          }
          if (alreadySelectedChips.length > 0) {
            this.chipLists[option.id].selectedChips = alreadySelectedChips;
          }
        }
      }
      console.log('option', option);
    }
  }
  selectTheme(theme: Theme) {
    this.selectedTheme = theme;
  }

  sortFonts(array) {
    array.sort((font1, font2) => {
      const a = font1.text.name;
      const b = font2.text.name;
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }

  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }

  async uploadedFile(event, type) {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      width: '700px',
      data: {
        file: event.target.files[0],
        ratio: '4 / 3',
        resizeWidth: 500,
        resizeHeight: 375,
        staticWidth: 500,
        staticHeight: 375,
        cropperMaxWidth: 0,
        cropperMaxHeight: 0,
        disableResizeSquares: true,
        containWithinAspectRatio: true,
        other: {
          type: type,
          upscale: true,
          upscaleWidth: 500,
        },
      },
      autoFocus: false,
    });
    console.log(event.target.files);
    if (event.target.files[0]) {
      dialogRef.afterClosed().subscribe(async (res) => {
        console.log('res', res);
        if (res && res.result === 'success') {
          let file = res.image;
          if (res.other.type === 'voucherImage') {
            if (file) {
              if (file.size > 2048000) {
                this.snackBar.open(
                  'Dit bestand moet onder de 2 MB zijn.',
                  'X',
                  {
                    duration: 5000,
                  }
                );
                return;
              } else {
                this.voucherImg = file;
                const filePath = `voucherGroups/temp/${this.voucherGroupId}/voucherImage`;
                const uploadTask = await this.storage.upload(
                  filePath,
                  this.voucherImg
                );
                localStorage.setItem('filePath', filePath);
                this.voucherImageCurrent =
                  await uploadTask.ref.getDownloadURL();
                this.voucherImgUrl = this.voucherImageCurrent;
                this.selectedVoucherImageUrl = this.voucherImgUrl;
              }
            }
          }
        } else {
          event.target.value = null;
        }
      });
    }
  }

  deleteFile(type: string, extension?: string): void {
    let fileUrl;
    if (type === 'voucherImage') {
      fileUrl = `voucherGroups/${this.voucherGroupId}/voucherGroupImage`;
    }
    const file = this.storage.ref(fileUrl).delete();
    file.subscribe((result) => {
      this.snackBar.open(`Afbeelding verwijderd`, 'X', {
        duration: 5000,
      });
    });
    this.voucherImgUrl = null;
    this.voucherImgAlreadyUploaded = false;
    this.selectedVoucherImageUrl = null;
    setDoc(
      doc(
        this.db.firestore,
        `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}`
      ),
      {
        selectedVoucherImageUrl: deleteField(),
        uploadedVoucherImageUrl: deleteField(),
      },
      { merge: true }
    );
  }

  openFile(type: string) {
    if (type === 'voucherImage') {
      window.open(this.voucherImgUrl);
    }
  }

  selectFile(type, url?) {
    if (type === 'voucherImage') {
      this.selectedVoucherImageUrl = this.voucherImgUrl;
    } else {
      this.selectedVoucherImageUrl = url;
    }
    console.log('selectedVoucherImageUrl', this.selectedVoucherImageUrl);
  }

  navigateToPreviousThemeImage() {
    this.customSwiper.swiperRef.slidePrev();
  }

  navigateToNextThemeImage() {
    this.customSwiper.swiperRef.slideNext();
  }

  selected(formField: string, event: any) {
    console.log('formField', formField);
    console.log('option.value', event.option.value);
    const value = event.option.value;
    const el = <HTMLInputElement>document.getElementById(formField); // cast HTMLElement to HTMLInputElement because HTMLElement does not have value property

    const foundChip = this.chipLists[formField].selectedChips.find((chip) => {
      if (chip.text === value.text) {
        return chip;
      }
    });

    if (!foundChip) {
      this.chipLists[formField].selectedChips.push(value);
      el.value = '';
      this.chipLists[formField].formControl.setValue(null);
      console.log('selectedChips', this.chipLists[formField].selectedChips);
    } else {
      el.value = '';
      this.chipLists[formField].formControl.setValue(null);
      this.snackBar.open('Dit label hangt al aan deze campagne.', 'X', {
        duration: 5000,
      });
    }
  }

  remove(formField: string, chip: any) {
    console.log('formField', formField);
    console.log('chip', chip);
    const index = this.chipLists[formField].selectedChips.indexOf(chip);
    if (index >= 0) {
      this.chipLists[formField].selectedChips.splice(index, 1);
    }
  }

  _filter(value: string, list: any[]): string[] {
    console.log('value in filter', value);
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return list.filter((chip) =>
        chip.text.toLowerCase().includes(filterValue)
      );
    }
  }

  next() {
    if (!this.currentStep.form.valid) {
      console.log(this.currentStep.form);
      return this.currentStep.form.markAllAsTouched();
    }
    if (this.currentStepIndex === this.steps.length) {
      this.save();
    } else {
      this.currentStepIndex++;
      this.currentStep = this.steps[this.currentStepIndex - 1];
    }
  }

  prev() {
    if (this.currentStepIndex === 1) {
      return;
    }
    this.currentStepIndex--;
    this.currentStep = this.steps[this.currentStepIndex - 1];
  }

  navigateToStep(stepIndex) {
    if (this.currentStep.form.invalid) {
      return this.currentStep.form.markAllAsTouched();
    }
    this.currentStepIndex = stepIndex;
    this.currentStep = this.steps[this.currentStepIndex - 1];
  }

  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const form = this.getFormData();

    if (typeof form.postal === 'string') {
      form.postal = form.postal.toUpperCase().replaceAll(' ', '');
    }
    if (
      form.startDate &&
      form.startTime &&
      typeof form.startTime === 'string'
    ) {
      form.startDate = this.createDateWithHourMinute(
        form.startDate,
        form.startTime
      );
    }
    if (form.endDate && form.endTime && typeof form.endTime === 'string') {
      form.endDate = this.createDateWithHourMinute(form.endDate, form.endTime);
    }
    if (form.voucherPrice && typeof form.voucherPrice === 'string') {
      form.voucherPrice = Number(form.voucherPrice.replace(',', '.'));
    }
    delete form['startTime'];
    delete form['endTime'];

    console.log('form', form);
    form.isConcept = false;
    form.themeId = this.selectedTheme.id;
    form.themeRef = this.selectedTheme.ref;
    // form.startDate = moment(form.startDate).toDate();
    // form.endDate = moment(form.endDate).toDate();
    const promises = [];
    promises.push(
      setDoc(
        doc(
          this.db.firestore,
          `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}`
        ),
        form,
        { merge: true }
      )
    );
    if (this.chipLists.labels) {
      console.log('labels', this.chipLists.labels);
      if (this.data.voucherGroup?.tagIds) {
        this.data.voucherGroup.tagIds.forEach((id) => {
          if (form.tagIds.includes(id)) {
            promises.push(
              deleteDoc(
                doc(
                  this.db.firestore,
                  `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}/tags/${id}`
                )
              )
            );
          }
        });
      }
      this.chipLists.labels.selectedChips.forEach((chip: any) => {
        promises.push(
          setDoc(
            doc(
              this.db.firestore,
              `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}/tags/${chip.id}`
            ),
            {
              id: chip.id,
              name: chip.text,
            }
          )
        );
      });
    }
    if (this.voucherImageCurrent) {
      if (localStorage.getItem('filePath')) {
        const filePath = `voucherGroups/${this.voucherGroupId}/voucherGroupImage`;
        const uploadTask = await this.storage.upload(filePath, this.voucherImg);
        this.voucherImgUrl = await uploadTask.ref.getDownloadURL();
        this.storage.ref(localStorage.getItem('filePath')).delete();
        localStorage.removeItem('filePath');
        setDoc(
          doc(
            this.db.firestore,
            `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}`
          ),
          {
            uploadedVoucherImageUrl: this.voucherImgUrl,
          },
          { merge: true }
        );
      }
    }
    if (this.selectedVoucherImageUrl) {
      setDoc(
        doc(
          this.db.firestore,
          `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}`
        ),
        {
          selectedVoucherImageUrl: this.selectedVoucherImageUrl,
        },
        { merge: true }
      );
    }
    const generatedVouchers = this.data?.voucherGroup?.generatedVouchers ?? 0;
    const maxVouchers = form.maxVouchers ?? 0;
    console.log('generatedVouchers', generatedVouchers);
    console.log('maxVouchers', maxVouchers);
    if (generatedVouchers < maxVouchers) {
      console.log('should generate vouchers');
      await this.generateVouchers(
        promises,
        generatedVouchers,
        maxVouchers,
        form
      ),
        { merge: true };
    }
    console.log('promises.length', promises.length);
    await Promise.all(promises);
    const voucherGroup = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}`
        )
      )
    ).data() as VoucherGroup;
    this.dialogRef.close();
    voucherGroup.id = this.voucherGroupId;
    this.dialog.open(ShareThemedVoucherGroupComponent, {
      width: '500px',
      data: {
        voucherGroup,
        townshipId: this.data.townshipId,
        theme: this.selectedTheme,
      },
    });
  }

  createDateWithHourMinute(date: Date, timeInput: string) {
    const hourString = timeInput.substring(0, 2);
    const minuteString = timeInput.substring(3, 5);
    console.log('hourString:', hourString);
    console.log('minuteString:', minuteString);
    const hour = Number(hourString);
    const minute = Number(minuteString);
    return new Date(date.setHours(hour, minute));
  }

  async saveAsConcept() {
    const form = this.getFormData();
    console.log('form', form);
    form.isConcept = true;
    form.themeId = this.selectedTheme.id;
    form.themeRef = this.selectedTheme.ref;
    // form.startDate = moment(form.startDate).toDate();
    // form.endDate = moment(form.endDate).toDate();
    const promises = [];
    promises.push(
      setDoc(
        doc(
          this.db.firestore,
          `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}`
        ),
        form,
        { merge: true }
      )
    );
    if (this.chipLists.labels) {
      console.log('labels', this.chipLists.labels);
      if (this.data.voucherGroup?.tagIds) {
        this.data.voucherGroup.tagIds.forEach((id) => {
          if (form.tagIds.includes(id)) {
            promises.push(
              deleteDoc(
                doc(
                  this.db.firestore,
                  `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}/tags/${id}`
                )
              )
            );
          }
        });
      }
      this.chipLists.labels.selectedChips.forEach((chip: any) => {
        promises.push(
          setDoc(
            doc(
              this.db.firestore,
              `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}/tags/${chip.id}`
            ),
            {
              id: chip.id,
              name: chip.text,
            }
          )
        );
      });
    }
    if (this.voucherImageCurrent) {
      if (localStorage.getItem('filePath')) {
        const filePath = `voucherGroups/${this.voucherGroupId}/voucherGroupImage`;
        const uploadTask = await this.storage.upload(filePath, this.voucherImg);
        this.voucherImgUrl = await uploadTask.ref.getDownloadURL();
        this.storage.ref(localStorage.getItem('filePath')).delete();
        localStorage.removeItem('filePath');
        setDoc(
          doc(
            this.db.firestore,
            `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}`
          ),
          {
            uploadedVoucherImageUrl: this.voucherImgUrl,
          },
          { merge: true }
        );
      }
    }
    if (this.selectedVoucherImageUrl) {
      setDoc(
        doc(
          this.db.firestore,
          `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}`
        ),
        {
          selectedVoucherImageUrl: this.selectedVoucherImageUrl,
        },
        { merge: true }
      );
    }

    console.log('promises.length', promises.length);
    await Promise.all(promises);
    this.dialogRef.close();
  }

  getFormData() {
    let formValues: any = {};
    this.steps.forEach((step) => {
      // console.log('step', step);
      if (step.form) {
        Object.keys(step.form.value).forEach((key) => {
          console.log('key', key);
          console.log('step.form.value[key]', step.form.value[key]);
          if (step.form?.value[key]) {
            formValues[key] = step.form?.value[key];
          } else {
            formValues[key] = deleteField();
          }
        });
      }
    });
    Object.keys(this.chipLists).forEach((key) => {
      // console.log('key', key);
      const chip = this.chipLists[key];
      // console.log('chip', chip);
      if (chip.dbField) {
        const array = [];
        chip.selectedChips.forEach((chip: any) => {
          array.push(chip.value);
        });
        formValues[chip.dbField] = array;
      }
    });
    return formValues;
  }

  async generateVouchers(
    promises: any[],
    alreadyGeneratedVouchers: number,
    maxVouchers: number,
    voucherGroup: VoucherGroup
  ) {
    this.snackBar.open('Bonnen worden gegenereerd, even geduld a.u.b.', 'X', {
      duration: 3000,
    });
    const township = (
      await getDoc(doc(this.db.firestore, `township/${this.townshipId}`))
    ).data() as Township;
    const amountToGenerate = maxVouchers - alreadyGeneratedVouchers;
    let numberToHash = alreadyGeneratedVouchers + 1;
    const prefix = (township.prefix ?? '').toUpperCase();
    const suffix =
      voucherGroup.suffix ??
      this.data?.voucherGroup?.suffix ??
      (await this.createSuffix(promises, township));
    promises.push(
      updateDoc(
        doc(
          this.db.firestore,
          `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}`
        ),
        {
          generatedVouchers: maxVouchers,
        }
      )
    );
    for (var i = 0; i < amountToGenerate; i++) {
      const newNumber =
        prefix + this.hashids.encodeHex(numberToHash.toString()) + suffix;
      let newVoucher = {} as Voucher;
      newVoucher = {
        number: newNumber,
        activateDate: null,
        claimDate: null,
        paidDate: null,
        reminderSend: false,
        voucherGroupId: this.voucherGroupId,
        value: voucherGroup.couponValue ?? null,
        distributed: null,
      };

      // This if activates every 100 vouchers
      if (Math.floor(numberToHash / 100) === numberToHash / 100) {
        // This is here to ensure we don't ddos our own servers :P Nah but seriously without this we'll get errors when attempting to save too many vouchers in quick succession
        promises.push(sleep(200));
      }
      const ref = doc(
        this.db.firestore,
        `township/${this.townshipId}/vouchers/${newNumber}`
      );
      promises.push(setDoc(ref, newVoucher));
      numberToHash++;
    }
  }

  async createSuffix(promises: any[], township: Township) {
    const suffixCounter = (township.suffixCounter ?? 0) + 1;
    const townshipRef = doc(this.db.firestore, `township/${this.townshipId}`);
    await updateDoc(townshipRef, { suffixCounter });
    const sliceCount =
      suffixCounter.toString().length > 3 ? suffixCounter.toString().length : 3;
    const suffix = `000${suffixCounter}`.slice(-sliceCount);
    console.log('suffix', suffix);
    promises.push(
      updateDoc(
        doc(
          this.db.firestore,
          `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}`
        ),
        {
          suffix,
        }
      )
    );
    return suffix;
  }
  getError(form: FormGroup, name: string) {
    const field = form.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      if (field.hasError('maxValue')) {
        return 'De ingevulde waarde is te hoog.';
      }
      if (field.hasError('postal')) {
        return 'Je moet een correcte postcode invullen.';
      }
      return '';
    }
  }
}
