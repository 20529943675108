import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { setDoc, doc, deleteField } from 'firebase/firestore';

export interface DialogData {
  postal?: string;
  houseNumber?: number;
  houseNumberAddition?: string;
  types?: string[];
  addressTypes?: string[];
  townshipId?: string;
  pin?: string;
  phonenumber?: string;
}

@Component({
  selector: 'app-edit-characteristics-dialog',
  templateUrl: './edit-characteristics-dialog.component.html',
  styleUrls: ['./edit-characteristics-dialog.component.scss'],
})
export class EditCharacteristicsDialogComponent implements OnInit {
  waitingForResponse = false;
  characteristicsForm: UntypedFormGroup;
  addressTypes = [];
  addressTypesChecked = [];
  allAddressTypes = [];

  constructor(
    public dialogRef: MatDialogRef<EditCharacteristicsDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.characteristicsForm = this.formBuilder.group({
      postal: [{ value: this.data.postal, disabled: true }],
      houseNumber: [{ value: this.data.houseNumber, disabled: true }],
      houseNumberAddition: [
        { value: this.data.houseNumberAddition, disabled: true },
      ],
    });
    // addressTypes that are on the address.
    if (this.data.pin) {
      this.characteristicsForm.addControl(
        'pin',
        new UntypedFormControl(this.data.pin)
      );
    }
    if (this.data.phonenumber) {
      this.characteristicsForm.addControl(
        'phonenumber',
        new UntypedFormControl(this.data.phonenumber)
      );
    }
    if (this.data.types) {
      this.data.types.forEach((type) => {
        this.addressTypesChecked.push(type);
        this.characteristicsForm.addControl(
          type,
          new UntypedFormControl({ checked: true })
        );
        this.characteristicsForm.controls[type].disable();
      });
    }
    // addressTypes the organisation has rights to use.
    this.data.addressTypes.forEach((type) => {
      this.addressTypes.push(type);
      this.characteristicsForm.addControl(type, new UntypedFormControl());
      this.characteristicsForm.controls[type].enable();
    });
    // merge the addressType arrays together and filter out duplicates.
    this.allAddressTypes = this.addressTypesChecked.concat(this.addressTypes);
    this.allAddressTypes = [
      ...new Set([...this.addressTypesChecked, ...this.addressTypes]),
    ];
  }

  addressTypeChecked(event: MatCheckboxChange, value: string) {
    if (event.checked) {
      this.addressTypesChecked.push(value);
    } else if (!event.checked) {
      const index = this.addressTypesChecked.indexOf(value, 0);
      this.addressTypesChecked.splice(index, 1);
    }
  }

  async save() {
    this.waitingForResponse = true;
    const form = this.characteristicsForm.value;
    console.log('form', form);
    try {
      const additionValue =
        this.data.houseNumberAddition === '-'
          ? ''
          : this.data.houseNumberAddition;

      const id = `${this.data.postal}${this.data.houseNumber}${additionValue}`;
      console.log(id);
      await setDoc(
        doc(
          this.db.firestore,
          `township/${this.data.townshipId}/addresses/${id}`
        ),
        {
          houseNumber: this.data.houseNumber,
          postal: this.data.postal,
          houseNumberAddition: additionValue,
          type: this.addressTypesChecked,
          pin: form.pin ? form.pin : deleteField(),
          phonenumber: form.phonenumber ? form.phonenumber : deleteField(),
        },
        { merge: true }
      );
      const addressObj = {
        houseNumber: this.data.houseNumber,
        postal: this.data.postal,
        houseNumberAddition: additionValue,
        type: this.addressTypesChecked,
        pin: form.pin,
        phonenumber: form.phonenumber,
      };
      this.dialogRef.close(addressObj);
      this.waitingForResponse = false;
    } catch (error) {
      return error;
    }
  }
}
