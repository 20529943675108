// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .confirmation-code-dialog-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header {
  display: flex;
}
.header .title {
  margin: 0;
}

::ng-deep .confirmation-code-form-content {
  padding: 0 !important;
  overflow-x: hidden;
}
::ng-deep .confirmation-code-form-content #form {
  display: flex;
  flex-direction: column;
}
::ng-deep .confirmation-code-form-content div {
  text-align: center;
}
::ng-deep .confirmation-code-form-content div img {
  max-width: 100%;
  height: 100%;
}
::ng-deep .confirmation-code-form-content .barcodeImg {
  height: 100px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/voucher-management/dialogs/confirmation-code/confirmation-code.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;AAEF;AADE;EACE,SAAA;AAGJ;;AACA;EACE,qBAAA;EACA,kBAAA;AAEF;AADE;EACE,aAAA;EACA,sBAAA;AAGJ;AADE;EACE,kBAAA;AAGJ;AAFI;EACE,eAAA;EACA,YAAA;AAIN;AADE;EACE,aAAA;EACA,kBAAA;AAGJ","sourcesContent":["::ng-deep .confirmation-code-dialog-actions {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n.header {\n  display: flex;\n  .title {\n    margin: 0;\n  }\n}\n\n::ng-deep .confirmation-code-form-content {\n  padding: 0 !important;\n  overflow-x: hidden;\n  #form {\n    display: flex;\n    flex-direction: column;\n  }\n  div {\n    text-align: center;\n    img {\n      max-width: 100%;\n      height: 100%;\n    }\n  }\n  .barcodeImg {\n    height: 100px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
