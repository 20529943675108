import { initializeApp } from 'firebase/app';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import { Widget } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';
import {
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
} from 'firebase/functions';
const townshipId = localStorage.getItem('township');
const app = initializeApp(environment.firebase);
const db = getFirestore(app);
const functions = getFunctions(app, 'europe-west3');

export async function checkAndUpdateStats(
  widget: Widget,
  functionName: string,
  params: any,
  ignoreLastUpdate?: boolean
) {
  const lastUpdateTime = widget.lastUpdateTime
    ? widget.lastUpdateTime.toDate()
    : new Date('2000-01-01');
  const date1DayAgo = moment().subtract(24, 'hours');
  if (date1DayAgo.isAfter(lastUpdateTime) || ignoreLastUpdate) {
    const widgetRef = doc(db, `township/${townshipId}/widgets/${widget.id}`);
    await updateDoc(widgetRef, { lastUpdateTime: new Date() });
    const callable = httpsCallable(functions, functionName, {
      timeout: 500000,
    });
    const result = await callable(params);
    return result;
  }
  return Promise<HttpsCallableResult<unknown>>;
}

export async function getInitialStats(
  widgetId: string,
  functionName: string,
  params: any
) {
  const widgetRef = doc(db, `township/${townshipId}/widgets/${widgetId}`);
  await updateDoc(widgetRef, { lastUpdateTime: new Date() });
  const callable = httpsCallable(functions, functionName);
  const result = await callable(params);
  return result;
}
