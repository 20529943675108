import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { lastValueFrom } from 'rxjs';
import { TownshipUser } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';
import { doc, updateDoc } from 'firebase/firestore';

export interface DialogData {
  user: TownshipUser;
  orgId?: string;
  ownUserId: string;
}

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
})
export class ManageUserComponent implements OnInit {
  townshipId = localStorage.getItem('township') as string;
  organisationId: string;
  newUser: boolean;
  userForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    rights: new UntypedFormControl('', [Validators.required]),
  });
  saving: boolean;
  rightsWarning: string;

  constructor(
    public dialogRef: MatDialogRef<ManageUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public db: AngularFirestore,
    private http: HttpClient,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    console.log('data', this.data);
    if (this.data.orgId) {
      this.organisationId = this.data.orgId;
      this.userForm.addControl(
        'name',
        new UntypedFormControl('', [Validators.required])
      );
    }
    console.log('organisationId', this.organisationId);
    if (this.data.user) {
      this.newUser = false;
      this.userForm.patchValue(this.data.user);
      this.userForm.controls.email.disable();
      if (this.data.user.rights === 'owner') {
        this.userForm.controls.rights.disable();
        this.rightsWarning =
          'Je kan de rechten van de eigenaar niet aanpassen.';
      }
      if (this.data.user.id === this.data.ownUserId) {
        this.userForm.controls.rights.disable();
        this.rightsWarning = 'Je kan de rechten van jezelf niet aanpassen.';
      }
    } else {
      this.newUser = true;
      this.userForm.addControl(
        'password',
        new UntypedFormControl('', [Validators.required])
      );
    }
  }

  async save() {
    if (this.saving || !this.userForm.valid) {
      this.userForm.markAllAsTouched();
      return;
    }
    this.saving = true;
    const form = this.userForm.value;
    if (this.newUser) {
      const requestUrl = `${environment.functionsUrl}/accountManageTownshipUser`;
      const result = (
        (await lastValueFrom(
          this.http.post(requestUrl, {
            data: {
              type: 'create',
              townshipId: this.townshipId,
              organisationId: this.organisationId,
              email: form.email,
              password: form.password,
              rights: form.rights,
              name: form.name,
            },
          })
        )) as any
      ).data;
      console.log('result', result);
      if (result.status == 'done') {
        this.dialogRef.close();
      } else {
        if (result.status == 'email_already_exists') {
          this.snackbar.open(
            'Er bestaat al een account op dit e-mailadres.',
            'X',
            {
              duration: 5000,
            }
          );
        } else {
          this.snackbar.open('Er is een onbekende fout opgetreden.', 'X', {
            duration: 5000,
          });
        }
      }
      this.saving = false;
    } else {
      const userId = this.data.user.id;
      const userRef = doc(
        this.db.firestore,
        this.organisationId
          ? `township/${this.townshipId}/organisations/${this.organisationId}/users/${userId}`
          : `township/${this.townshipId}/users/${userId}`
      );
      console.log('userRef.path', userRef.path);
      await updateDoc(userRef, form);
      this.dialogRef.close();
    }
  }
}
