import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  voucherGroup: any;
}

@Component({
  selector: 'app-finish-vouchergroup',
  templateUrl: './finish-vouchergroup.component.html',
  styleUrls: ['./finish-vouchergroup.component.scss'],
})
export class FinishVouchergroupComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
}
