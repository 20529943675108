// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  margin: 12px;
}

.card-wrapper {
  padding: 8px 12px 0 12px;
}

.card {
  min-width: 275px;
  border-radius: 24px !important;
  background-color: #fefcf4 !important;
  border: 1px solid rgba(0, 0, 0, 0.4);
  height: 54px;
  padding: 8px;
  box-sizing: border-box;
}
.card mat-card-header {
  align-items: center;
  height: 64px;
  border-radius: 4px 4px 0 0;
}
.card mat-card-header mat-card-title {
  margin-bottom: 0;
}
.card .card-title {
  color: var(--primary);
  font-size: 18px;
  font-weight: 500;
  padding: 8px;
  display: flex;
  align-items: center;
}
.card mat-card-actions {
  padding: 8px 16px;
}
.card mat-card-actions button {
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/app/tags/tags.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;;AAIA;EACE,wBAAA;AADF;;AAIA;EACE,gBAAA;EACA,8BAAA;EACA,oCAAA;EACA,oCAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;AADF;AAGE;EACE,mBAAA;EACA,YArBY;EAsBZ,0BAAA;AADJ;AAGI;EACE,gBAAA;AADN;AAIE;EACE,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAFJ;AAKE;EACE,iBAAA;AAHJ;AAKI;EACE,yBAAA;AAHN","sourcesContent":["$header-height: 64px;\n\n.page {\n  margin: 12px;\n}\n\n.card-wrapper {\n  padding: 8px 12px 0 12px;\n}\n\n.card {\n  min-width: 275px;\n  border-radius: 24px !important;\n  background-color: #fefcf4 !important;\n  border: 1px solid rgba(0, 0, 0, 0.4);\n  height: 54px;\n  padding: 8px;\n  box-sizing: border-box;\n\n  mat-card-header {\n    align-items: center;\n    height: $header-height;\n    border-radius: 4px 4px 0 0;\n\n    mat-card-title {\n      margin-bottom: 0;\n    }\n  }\n  .card-title {\n    color: var(--primary);\n    font-size: 18px;\n    font-weight: 500;\n    padding: 8px;\n    display: flex;\n    align-items: center;\n  }\n\n  mat-card-actions {\n    padding: 8px 16px;\n\n    button {\n      text-transform: uppercase;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
