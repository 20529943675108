// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .dialog-title {
  padding: 0;
}

::ng-deep .dialog-content {
  padding: 16px 0 0 0 !important;
}

::ng-deep .dialog-actions {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/account/multi-factor/dialogs/delete-mfa-confirmation/delete-mfa-confirmation.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AACA;EACE,8BAAA;AAEF;;AAAA;EACE,UAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AAGF","sourcesContent":["::ng-deep .dialog-title {\n  padding: 0;\n}\n::ng-deep .dialog-content {\n  padding: 16px 0 0 0 !important;\n}\n::ng-deep .dialog-actions {\n  padding: 0;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
