import { Component, OnInit, ViewChild } from '@angular/core';
import {
  collection,
  getDocs,
  getCountFromServer,
  query,
  where,
  limit,
  Query,
  DocumentData,
  startAfter,
  endBefore,
  limitToLast,
  orderBy,
  updateDoc,
  doc,
  arrayUnion,
  QueryConstraint,
} from 'firebase/firestore';
import { User, getAuth } from 'firebase/auth';
import { db } from '../app.component';
import { Voucher, VoucherGroup } from '../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { BurdenOfProofDialogComponent } from './dialogs/burden-of-proof-dialog/burden-of-proof-dialog.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VoucherCheckStatus } from '../enums';

@Component({
  selector: 'app-check-township-vouchers',
  templateUrl: './check-township-vouchers.component.html',
  styleUrls: ['./check-township-vouchers.component.scss'],
})
export class CheckTownshipVouchersComponent implements OnInit {
  @ViewChild('parentCheckbox') parentCheckbox: any;
  townshipId = localStorage.getItem('township') as string;
  uncontrolledVoucherCount: number;
  acceptedVoucherCount: number;
  rejectedVoucherCount: number;
  expiredVoucherCount: number;
  uncontrolledVouchers: Voucher[];
  acceptedVouchers: Voucher[];
  rejectedVouchers: Voucher[];
  expiredVouchers: Voucher[];
  voucherGroups: VoucherGroup[];
  uncontrolledVouchersForm: FormGroup = this.fb.group({});
  lastVisibleUncontrolledVoucher;
  lastVisibleAcceptedVoucher;
  lastVisibleRejectedVoucher;
  lastVisibleExpiredVoucher;
  firstVisibleUncontrolledVoucher;
  firstVisibleAcceptedVoucher;
  firstVisibleRejectedVoucher;
  firstVisibleExpiredVoucher;
  pageNumber: number = 1;
  uncontrolledVouchersChecked: number = 0;
  user: User;
  searchForm: UntypedFormGroup = this.fb.group({
    voucherNumber: [''],
    postal: [''],
    houseNumber: [''],
    houseNumberAddition: [''],
  });
  searching: boolean;
  queryConstraints: QueryConstraint[] = [];

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit(): Promise<void> {
    this.voucherGroups = (
      await getDocs(collection(db, `township/${this.townshipId}/voucherGroups`))
    ).docs.map((doc) => {
      return { id: doc.id, ...doc.data() } as VoucherGroup;
    });
    const auth = getAuth();
    this.user = auth.currentUser;
    await this.init();
  }

  async init() {
    this.uncontrolledVoucherCount = (
      await getCountFromServer(
        query(
          collection(db, `township/${this.townshipId}/vouchers`),
          where('burdenOfProofForm.status', '==', 'Submitted'),
          where('voucherGroupId', '!=', 'expired'),
          ...this.queryConstraints
        )
      )
    ).data().count;

    this.acceptedVoucherCount = (
      await getCountFromServer(
        query(
          collection(db, `township/${this.townshipId}/vouchers`),
          where('burdenOfProofForm.status', '==', 'Accepted'),
          where('voucherGroupId', '!=', 'expired'),
          ...this.queryConstraints
        )
      )
    ).data().count;

    this.rejectedVoucherCount = (
      await getCountFromServer(
        query(
          collection(db, `township/${this.townshipId}/vouchers`),
          where('burdenOfProofForm.status', '==', 'Rejected'),
          where('voucherGroupId', '!=', 'expired'),
          ...this.queryConstraints
        )
      )
    ).data().count;

    this.expiredVoucherCount = (
      await getCountFromServer(
        query(
          collection(db, `township/${this.townshipId}/vouchers`),
          where('burdenOfProofForm.status', 'in', [
            'Submitted',
            'Rejected',
            'Concept',
          ]),
          where('voucherGroupId', '==', 'expired'),
          ...this.queryConstraints
        )
      )
    ).data().count;

    await this.initUncontrolledVouchers(
      query(
        collection(db, `township/${this.townshipId}/vouchers`),
        where('burdenOfProofForm.status', '==', 'Submitted'),
        orderBy('burdenOfProofLastEditDate'),
        where('status', 'in', ['paid', 'active', 'claimed']),
        ...this.queryConstraints,
        limit(100)
      )
    );
    await this.initAcceptedVouchers(
      query(
        collection(db, `township/${this.townshipId}/vouchers`),
        orderBy('claimDate'),
        where('burdenOfProofForm.status', '==', 'Accepted'),
        where('status', 'in', ['paid', 'active', 'claimed']),
        ...this.queryConstraints,
        limit(100)
      )
    );
    await this.initRejectedVouchers(
      query(
        collection(db, `township/${this.townshipId}/vouchers`),
        orderBy('claimDate'),
        where('burdenOfProofForm.status', '==', 'Rejected'),
        where('status', 'in', ['paid', 'active', 'claimed']),
        ...this.queryConstraints,
        limit(100)
      )
    );
    await this.initExpiredVouchers(
      query(
        collection(db, `township/${this.townshipId}/vouchers`),
        orderBy('claimDate'),
        where('burdenOfProofForm.status', 'in', [
          'Submitted',
          'Rejected',
          'Concept',
        ]),
        where('voucherGroupId', '==', 'expired'),
        ...this.queryConstraints,
        limit(100)
      )
    );

    this.uncontrolledVouchersForm.valueChanges.subscribe(() => {
      this.uncontrolledVouchersChecked = 0;
      for (const field in this.uncontrolledVouchersForm.controls) {
        const control = this.uncontrolledVouchersForm.controls[field];
        if (control.value) {
          this.uncontrolledVouchersChecked++;
        }
      }
      if (this.uncontrolledVouchersChecked === 0) {
        this.parentCheckbox.checked = false;
      }
    });
  }

  paginateNext(tabName: string) {
    this.pageNumber++;
    switch (tabName) {
      case 'uncontrolled':
        this.initUncontrolledVouchers(
          query(
            collection(db, `township/${this.townshipId}/vouchers`),
            orderBy('burdenOfProofLastEditDate'),
            where('burdenOfProofForm.status', '==', 'Submitted'),
            where('status', 'in', ['paid', 'active', 'claimed']),
            ...this.queryConstraints,
            startAfter(this.lastVisibleUncontrolledVoucher),
            limit(100)
          )
        );
        break;
      case 'accepted':
        this.initAcceptedVouchers(
          query(
            collection(db, `township/${this.townshipId}/vouchers`),
            orderBy('claimDate'),
            where('burdenOfProofForm.status', '==', 'Accepted'),
            where('status', 'in', ['paid', 'active', 'claimed']),
            ...this.queryConstraints,
            startAfter(this.lastVisibleAcceptedVoucher),
            limit(100)
          )
        );
        break;
      case 'rejected':
        this.initRejectedVouchers(
          query(
            collection(db, `township/${this.townshipId}/vouchers`),
            orderBy('claimDate'),
            where('burdenOfProofForm.status', '==', 'Rejected'),
            where('status', 'in', ['paid', 'active', 'claimed']),
            ...this.queryConstraints,
            startAfter(this.lastVisibleRejectedVoucher),
            limit(100)
          )
        );
        break;
      case 'expired':
        this.initExpiredVouchers(
          query(
            collection(db, `township/${this.townshipId}/vouchers`),
            orderBy('claimDate'),
            where('burdenOfProofForm.status', 'in', [
              'Submitted',
              'Rejected',
              'Concept',
            ]),
            where('voucherGroupId', '==', 'expired'),
            ...this.queryConstraints,
            startAfter(this.lastVisibleExpiredVoucher),
            limit(100)
          )
        );
        break;
    }
  }

  paginateBack(tabName: string) {
    if (this.pageNumber <= 1) {
      return;
    }
    this.pageNumber--;
    switch (tabName) {
      case 'uncontrolled':
        this.initUncontrolledVouchers(
          query(
            collection(db, `township/${this.townshipId}/vouchers`),
            orderBy('burdenOfProofLastEditDate'),
            where('burdenOfProofForm.status', '==', 'Submitted'),
            where('status', 'in', ['paid', 'active', 'claimed']),
            ...this.queryConstraints,
            endBefore(this.firstVisibleUncontrolledVoucher),
            limitToLast(100)
          )
        );
        break;
      case 'accepted':
        this.initAcceptedVouchers(
          query(
            collection(db, `township/${this.townshipId}/vouchers`),
            orderBy('claimDate'),
            where('burdenOfProofForm.status', '==', 'Accepted'),
            where('status', 'in', ['paid', 'active', 'claimed']),
            ...this.queryConstraints,
            endBefore(this.firstVisibleAcceptedVoucher),
            limitToLast(100)
          )
        );
        break;
      case 'rejected':
        this.initRejectedVouchers(
          query(
            collection(db, `township/${this.townshipId}/vouchers`),
            orderBy('claimDate'),
            where('burdenOfProofForm.status', '==', 'Rejected'),
            where('status', 'in', ['paid', 'active', 'claimed']),
            ...this.queryConstraints,
            endBefore(this.firstVisibleRejectedVoucher),
            limitToLast(100)
          )
        );
        break;
      case 'expired':
        this.initExpiredVouchers(
          query(
            collection(db, `township/${this.townshipId}/vouchers`),
            orderBy('claimDate'),
            where('burdenOfProofForm.status', 'in', [
              'Submitted',
              'Rejected',
              'Concept',
            ]),
            where('voucherGroupId', '==', 'expired'),
            ...this.queryConstraints,
            endBefore(this.firstVisibleExpiredVoucher),
            limitToLast(100)
          )
        );
        break;
    }
  }

  checkAllVouchers(event: MatCheckboxChange) {
    for (const field in this.uncontrolledVouchersForm.controls) {
      const control = this.uncontrolledVouchersForm.controls[field];
      control.setValue(event.checked);
    }
  }

  async initUncontrolledVouchers(
    uncontrolledVoucherQuery: Query<DocumentData>
  ) {
    const docsSnapshot = await getDocs(uncontrolledVoucherQuery);
    this.lastVisibleUncontrolledVoucher =
      docsSnapshot.docs[docsSnapshot.docs.length - 1];
    this.firstVisibleUncontrolledVoucher = docsSnapshot.docs[0];
    this.uncontrolledVouchers = docsSnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() } as Voucher;
    });
    this.uncontrolledVouchers.forEach((voucher) => {
      this.uncontrolledVouchersForm.addControl(voucher.id, new FormControl());
    });
  }

  async initAcceptedVouchers(acceptedVoucherQuery: Query<DocumentData>) {
    const docsSnapshot = await getDocs(acceptedVoucherQuery);
    this.lastVisibleAcceptedVoucher =
      docsSnapshot.docs[docsSnapshot.docs.length - 1];
    this.acceptedVouchers = docsSnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() } as Voucher;
    });
  }

  async initRejectedVouchers(rejectedVoucherQuery: Query<DocumentData>) {
    const docsSnapshot = await getDocs(rejectedVoucherQuery);
    this.lastVisibleRejectedVoucher =
      docsSnapshot.docs[docsSnapshot.docs.length - 1];
    this.rejectedVouchers = docsSnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() } as Voucher;
    });
  }

  async initExpiredVouchers(expiredVoucherQuery: Query<DocumentData>) {
    const docsSnapshot = await getDocs(expiredVoucherQuery);
    this.lastVisibleExpiredVoucher =
      docsSnapshot.docs[docsSnapshot.docs.length - 1];
    this.expiredVouchers = docsSnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() } as Voucher;
    });
  }

  openBurdenOfProofDialog(voucher: Voucher) {
    const dialogRef = this.dialog.open(BurdenOfProofDialogComponent, {
      width: '95%',
      height: '95%',
      data: {
        voucherId: voucher.id,
        townshipId: this.townshipId,
      },
      autoFocus: false,
    });
  }

  getVoucherGroup(id: string): VoucherGroup {
    const voucherGroup: VoucherGroup = this.voucherGroups.find(
      (voucherGroup) => voucherGroup.id == id
    );
    return voucherGroup;
  }

  getVoucherGroupName(id: string): string {
    const voucherGroup = this.getVoucherGroup(id);
    return voucherGroup.name;
  }

  getFormattedDate(date): string {
    if (!date) {
      return 'N/A';
    }
    if (typeof date === 'string') {
      return moment(date).format('DD-MM-YYYY');
    }
    const correctDate = date.toDate();
    return moment(correctDate).format('DD-MM-YYYY');
  }

  getClaimedAmount(voucher: Voucher) {
    let number: number;
    const voucherGroupId =
      voucher.originalVoucherGroupId ?? voucher.voucherGroupId;
    const voucherGroup: VoucherGroup = this.voucherGroups.find(
      (voucherGroup) => voucherGroup.id == voucherGroupId
    );
    if (voucherGroup.cashback) {
      number = Number(voucher.amountToPayOrg);
    } else {
      number = Number(voucher.originalValue - voucher.value);
    }
    number = Number(number.toString().replace(',', '.'));
    if (!isNaN(number)) {
      if (number.toString().indexOf('.') == -1) {
        return number;
      }
      return number.toFixed(2);
    }
    return '';
  }

  onTabChange() {
    this.pageNumber = 1;
  }

  async acceptAllVouchers() {
    moment.locale('nl');
    const promises = [];
    const now = new Date();

    const newHistory = {
      'email': this.user.email,
      'date': now,
      'status': 'Accepted',
      'claimDate': now,
      'completeRemarks': `Dit bewijsformulier is op ${moment(now).format(
        'LLLL'
      )} door ${this.user.email} automatisch goedgekeurd.`,
      'manualControl': false,
    };

    for (const field in this.uncontrolledVouchersForm.controls) {
      const control = this.uncontrolledVouchersForm.controls[field];
      if (control.value) {
        promises.push(
          await updateDoc(
            doc(db, `township/${this.townshipId}/vouchers/${field}`),
            {
              'voucherCheckStatus': VoucherCheckStatus.accepted,
              'burdenOfProofForm.status': 'Accepted',
              'burdenOfProofForm.complete': true,
              'burdenOfProofForm.completeRemarks': `Dit bewijsformulier is op ${moment(
                now
              ).format('LLLL')} door ${
                this.user.email
              } automatisch goedgekeurd.`,
              'burdenOfProofFormHistory': arrayUnion(newHistory),
            }
          )
        );
      }
    }
    if (promises.length === 0) {
      return this.snackBar.open(
        'Je hebt geen bewijslastformulieren geselecteerd om goed te keuren',
        'X',
        {
          duration: 5000,
        }
      );
    }
    await Promise.all(promises);
    this.snackBar.open(
      'Alle geselecteerde bewijslastformulieren zijn automatisch goedgekeurd',
      'X',
      {
        duration: 5000,
      }
    );
    await this.init();
  }

  async rejectAllVouchers() {
    moment.locale('nl');
    const promises = [];
    const now = new Date();

    const newHistory = {
      'email': this.user.email,
      'date': now,
      'status': 'Rejected',
      'claimDate': null,
      'completeRemarks': `Dit bewijsformulier is op ${moment(now).format(
        'LLLL'
      )} door ${this.user.email} automatisch afgekeurd.`,
      'reasonOfRejection': `Dit bewijsformulier is op ${moment(now).format(
        'LLLL'
      )} automatisch afgekeurd.`,
      'manualControl': false,
    };

    for (const field in this.uncontrolledVouchersForm.controls) {
      const control = this.uncontrolledVouchersForm.controls[field];
      if (control.value) {
        promises.push(
          await updateDoc(
            doc(db, `township/${this.townshipId}/vouchers/${field}`),
            {
              'voucherCheckStatus': VoucherCheckStatus.declined,
              'burdenOfProofForm.status': 'Rejected',
              'burdenOfProofForm.complete': false,
              'burdenOfProofForm.completeRemarks': `Dit bewijsformulier is op ${moment(
                now
              ).format('LLLL')} door ${this.user.email} automatisch afgekeurd.`,
              'burdenOfProofForm.reasonOfRejection': `Dit bewijsformulier is op ${moment(
                now
              ).format('LLLL')} automatisch afgekeurd.`,
              'burdenOfProofFormHistory': arrayUnion(newHistory),
            }
          )
        );
      }
    }
    if (promises.length === 0) {
      return this.snackBar.open(
        'Je hebt geen bewijslastformulieren geselecteerd om af te keuren',
        'X',
        {
          duration: 5000,
        }
      );
    }
    await Promise.all(promises);
    this.snackBar.open(
      'Alle geselecteerde bewijslastformulieren zijn automatisch afgekeurd',
      'X',
      {
        duration: 5000,
      }
    );
    await this.init();
  }

  async search() {
    if (this.searching) {
      return;
    }
    this.searching = true;
    if (this.searchForm.value.postal) {
      this.searchForm.controls.postal.patchValue(
        this.searchForm.value.postal.replace(/\s/g, '').toUpperCase()
      );
    }
    if (this.searchForm.value.voucherNumber) {
      this.searchForm.controls.voucherNumber.patchValue(
        this.searchForm.value.voucherNumber.replace(/\s/g, '').toUpperCase()
      );
    }
    if (this.searchForm.value.houseNumberAddition) {
      this.searchForm.controls.houseNumberAddition.patchValue(
        this.searchForm.value.houseNumberAddition
          .replace(/\s/g, '')
          .toLowerCase()
      );
    }

    const form = this.searchForm.value;
    this.queryConstraints = [];
    if (form.voucherNumber) {
      this.queryConstraints.push(where('number', '==', form.voucherNumber));
    } else if (!form.postal && form.houseNumber) {
      this.snackBar.open(
        'Er kan niet gezocht worden op huisnummer zonder postcode.',
        'X',
        {
          duration: 5000,
        }
      );
    } else if (!form.houseNumber && form.houseNumberAddition) {
      this.snackBar.open(
        'Er kan niet gezocht worden op toevoeging zonder huisnummer.',
        'X',
        {
          duration: 5000,
        }
      );
    } else if (form.postal) {
      this.queryConstraints.push(where('postal', '==', form.postal));
      if (form.houseNumber) {
        this.queryConstraints.push(
          where('houseNumber', '==', form.houseNumber)
        );
        if (form.houseNumberAddition) {
          this.queryConstraints.push(
            where('houseNumberAddition', '==', form.houseNumberAddition)
          );
        }
      }
    }
    this.uncontrolledVouchersForm = this.fb.group({});
    this.uncontrolledVouchersChecked = 0;
    this.parentCheckbox.checked = false;
    await this.init();
    this.searching = false;
  }

  async resetForm() {
    if (this.searching) {
      return;
    }
    this.searching = true;
    this.searchForm.reset({
      voucherNumber: '',
      postal: '',
      houseNumber: '',
      houseNumberAddition: '',
    });
    this.queryConstraints = [];
    this.uncontrolledVouchersForm = this.fb.group({});
    this.uncontrolledVouchersChecked = 0;
    this.parentCheckbox.checked = false;
    await this.init();
    this.searching = false;
  }
}
