import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Township } from '../../../interfaces';
import { environment } from '../../../../environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import {
  deleteField,
  DocumentReference,
  DocumentData,
  setDoc,
} from 'firebase/firestore';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { doc, getDoc } from 'firebase/firestore';

@Component({
  selector: 'app-setup-payment-dialog',
  templateUrl: './setup-payment-dialog.component.html',
  styleUrls: ['./setup-payment-dialog.component.scss'],
})
export class SetupPaymentDialogComponent implements OnInit {
  env = environment;
  personalForm: UntypedFormGroup;
  paymentForm: UntypedFormGroup;
  mollieFormGroup: UntypedFormGroup;
  validMollieForm = true;
  validPersonalForm = true;
  validPaymentForm = true;

  townshipDoc: DocumentReference<DocumentData>;
  townshipId = localStorage.getItem('township');

  waiting = false;
  @ViewChild('stepper')
  private stepper: MatStepper;

  constructor(
    private formBuilder: UntypedFormBuilder,
    // @Inject(MAT_DIALOG_DATA) public townshipId: Township,
    private snackBar: MatSnackBar,
    public db: AngularFirestore,
    public dialogRef: MatDialogRef<SetupPaymentDialogComponent>
  ) {}

  async ngOnInit() {
    this.mollieFormGroup = this.formBuilder.group({
      requireProvider: [],
      merchantKey: [],
      merchantId: [],
      idealMerchantId: [],
      shopId: [],
      mollieProfileId: [],
      paymentSystem: [],
    });
    this.personalForm = this.formBuilder.group({
      requirePersonalData: [],
      street: ['', [Validators.required]],
      houseNumber: ['', [Validators.required]],
      houseNumberAddition: ['', []],
      postal: [
        '',
        [Validators.required, Validators.maxLength(7), Validators.minLength(6)],
      ],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      city: ['', [Validators.required]],
      name: ['', [Validators.required]],
    });
    this.paymentForm = this.formBuilder.group({
      requireSEPA: [],
      kvk: ['', []],
      vat: ['', []],
      bankAccount: ['', []],
      bic: [''],
      debtorId: [''],
      invoicesSent: [],
    });
    this.townshipDoc = doc(this.db.firestore, `township/${this.townshipId}`);
    const townshipData = (await getDoc(this.townshipDoc)).data() as Township;
    // township.subscribe((data) => {
    console.log('townshipData', townshipData.mollieProfileId);
    if (townshipData.invoiceInfo) {
      this.personalForm.patchValue(townshipData.invoiceInfo);
      this.paymentForm.patchValue(townshipData.invoiceInfo);
      if (townshipData.paymentSystem) {
        this.mollieFormGroup.patchValue({
          paymentSystem: townshipData.paymentSystem,
          requireProvider: true,
        });
        switch (townshipData.paymentSystem) {
          case 'sisow':
            if (townshipData.sisow.shopId) {
              this.mollieFormGroup.patchValue({
                shopId: townshipData.sisow.shopId,
              });
            }
            this.mollieFormGroup.patchValue({
              merchantKey: townshipData.sisow.merchantKey,
              merchantId: townshipData.sisow.merchantId,
            });
            break;
          case 'ideal':
            this.mollieFormGroup.patchValue({
              idealMerchantId: townshipData.idealMerchantId,
            });
            break;
          case 'mollie':
            this.mollieFormGroup.patchValue({
              mollieProfileId: townshipData.mollieProfileId,
            });
            break;
        }
      }
    }
    // });
    this.mollieFormGroup.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        if (
          this.mollieFormGroup.value.requireProvider &&
          !this.personalForm.value.name
        ) {
          this.validPersonalForm = false;
          if (
            (this.mollieFormGroup.value.paymentSystem === 'mollie' &&
              this.mollieFormGroup.value.mollieProfileId) ||
            (this.mollieFormGroup.value.paymentSystem === 'sisow' &&
              this.mollieFormGroup.value.merchantKey &&
              this.mollieFormGroup.value.merchantId) ||
            (this.mollieFormGroup.value.paymentSystem === 'ideal' &&
              this.mollieFormGroup.value.idealMerchantId)
          ) {
            this.validMollieForm = true;
          } else {
            this.validMollieForm = false;
          }
        } else {
          if (this.mollieFormGroup.touched || !this.mollieFormGroup.pristine) {
            if (!this.mollieFormGroup.value.mollieProfileId) {
              this.mollieFormGroup.patchValue({
                mollieProfileId: townshipData.mollieProfileId
                  ? townshipData.mollieProfileId
                  : null,
              });
            }
            if (!this.mollieFormGroup.value.merchantKey) {
              this.mollieFormGroup.patchValue({
                merchantKey: townshipData.sisow.merchantKey
                  ? townshipData.sisow.merchantKey
                  : null,
              });
            }
            if (!this.mollieFormGroup.value.merchantId) {
              this.mollieFormGroup.patchValue({
                merchantId: townshipData.sisow.merchantId
                  ? townshipData.sisow.merchantId
                  : null,
              });
            }
            if (!this.mollieFormGroup.value.idealMerchantId) {
              this.mollieFormGroup.patchValue({
                idealMerchantId: townshipData.idealMerchantId
                  ? townshipData.idealMerchantId
                  : null,
              });
            }
          }
          this.validMollieForm = true;
          this.validPersonalForm = true;
        }
      });
    this.personalForm.valueChanges.subscribe(() => {
      if (this.mollieFormGroup.value.requireProvider) {
        if (
          this.personalForm.value.name &&
          this.personalForm.value.street &&
          this.personalForm.value.postal &&
          this.personalForm.value.city &&
          this.personalForm.value.houseNumber &&
          this.personalForm.value.phone &&
          this.personalForm.value.email &&
          this.personalForm.valid
        ) {
          this.validPersonalForm = true;
        } else {
          this.validPersonalForm = false;
        }
      } else {
        if (this.personalForm.value.requirePersonalData) {
          if (
            this.personalForm.value.name &&
            this.personalForm.value.street &&
            this.personalForm.value.postal &&
            this.personalForm.value.city &&
            this.personalForm.value.houseNumber &&
            this.personalForm.value.phone &&
            this.personalForm.value.email &&
            this.personalForm.valid
          ) {
            this.validPersonalForm = true;
          } else {
            this.validPersonalForm = false;
          }
        } else {
          this.validPersonalForm = true;
        }
      }
    });
    this.paymentForm.valueChanges.subscribe(() => {
      if (this.paymentForm.value.requireSEPA) {
        if (
          this.paymentForm.value.kvk &&
          this.paymentForm.value.vat &&
          this.paymentForm.value.bankAccount
        ) {
          this.validPaymentForm = true;
        } else {
          this.validPaymentForm = false;
        }
      } else {
        this.validPaymentForm = true;
      }
    });
  }

  connectToMollie(): void {
    window.open(
      `${this.env.functionsUrl}/mollieAuthRequest?townshipId=${this.townshipId}`
    );
  }

  getError(name, form: UntypedFormGroup) {
    const field = form.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      if (field.hasError('email')) {
        return 'Je moet een geldig e-mailadres invullen.';
      }
      if (field.hasError('pattern')) {
        return 'Je moet een geldig postcode invullen.';
      }
      if (field.hasError('minlength')) {
        return 'Je moet een geldig telefoonnummer invullen.';
      }
      return '';
    }
  }

  async save(): Promise<any> {
    this.waiting = true;
    const vatNumber = this.paymentForm.value.vat.toLowerCase();
    console.log('vatNumber', vatNumber);
    if (this.paymentForm.value.requireSEPA) {
      if (!vatNumber.includes('nl') && !vatNumber.includes('b')) {
        this.paymentForm.controls.vat.setErrors({
          notMatched: true,
        });
        this.waiting = false;
        return;
      }
    } else {
      delete this.paymentForm.value.vat;
    }
    const personalData = this.personalForm.value;
    const paymentData = this.paymentForm.value;
    const invoiceInfo = { ...personalData, ...paymentData };

    let sepaSettingsComplete = false;
    if (this.paymentForm.value.bankAccount) {
      sepaSettingsComplete = true;
    }

    invoiceInfo.bic = this.paymentForm.value.bic
      ? this.paymentForm.value.bic
      : deleteField();

    // this can be done al lot easier. code coming from when, no time to rewrite. if time left over wil rewrite it
    if (this.mollieFormGroup.value.requireProvider) {
      const paymentSystem = this.mollieFormGroup.value.paymentSystem;
      console.log('paymentSystem', paymentSystem);
      if (paymentSystem === 'mollie') {
        const mollieProfileId = this.mollieFormGroup.value.mollieProfileId;
        await setDoc(
          doc(this.db.firestore, `/township/${this.townshipId}/`),
          {
            invoiceInfo,
            paymentSystem,
            sepaSettingsComplete,
            mollieProfileId: mollieProfileId,
          },
          { merge: true }
        );
      } else if (paymentSystem === 'ideal') {
        const idealMerchantId = this.mollieFormGroup.value.idealMerchantId;
        await setDoc(
          doc(this.db.firestore, `/township/${this.townshipId}/`),
          {
            invoiceInfo,
            paymentSystem,
            sepaSettingsComplete,
            idealMerchantId,
          },
          { merge: true }
        );
      } else if (paymentSystem === 'sisow') {
        const merchantKey = this.mollieFormGroup.value.merchantKey;
        const merchantId = this.mollieFormGroup.value.merchantId;
        const shopId = this.mollieFormGroup.value.shopId
          ? this.mollieFormGroup.value.shopId
          : '';
        await setDoc(
          doc(this.db.firestore, `/township/${this.townshipId}/`),
          {
            invoiceInfo,
            paymentSystem,
            sepaSettingsComplete,
            sisow: {
              merchantKey,
              merchantId,
              shopId,
            },
          },
          { merge: true }
        );
      } else {
        await setDoc(
          doc(this.db.firestore, `/township/${this.townshipId}/`),
          {
            invoiceInfo,
            sepaSettingsComplete,
          },
          { merge: true }
        );
      }
    } else {
      await setDoc(
        doc(this.db.firestore, `/township/${this.townshipId}/`),
        {
          paymentSystem: deleteField(),
          sisow: deleteField(),
          idealMerchantId: deleteField(),
          mollieProfileId: deleteField(),
        },
        { merge: true }
      );
    }
    this.waiting = false;
    this.snackBar.open('Betaalinstellingen zijn ingesteld', 'X', {
      duration: 5000,
    });
    if (
      (this.mollieFormGroup.value.merchantKey &&
        this.mollieFormGroup.value.merchantId &&
        this.personalForm.value.name &&
        sepaSettingsComplete) ||
      (this.mollieFormGroup.value.mollieProfileId &&
        this.personalForm.value.name &&
        sepaSettingsComplete)
    ) {
      this.dialogRef.close('succeed');
    } else {
      this.dialogRef.close();
    }
  }
}
