import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  deleteField,
  deleteDoc,
} from 'firebase/firestore';
import { environment } from 'src/environments/environment';
import { GroupLink } from '../interfaces';
import { DeleteGroupLinkComponent } from './dialog/delete-group-link/delete-group-link.component';

import { EditGroupLinkDialogComponent } from './dialog/edit-group-link-dialog/edit-group-link-dialog.component';
@Component({
  selector: 'app-group-links',
  templateUrl: './group-links.component.html',
  styleUrls: ['./group-links.component.scss'],
})
export class GroupLinkComponent implements OnInit {
  townshipId = localStorage.getItem('township');
  organisationId = localStorage.getItem('organisationId');
  env = environment;
  groupLinks: GroupLink[] = [];

  constructor(public db: AngularFirestore, public dialog: MatDialog) {}

  async ngOnInit() {
    const groupLinkDocs = (
      await getDocs(
        collection(this.db.firestore, `township/${this.townshipId}/groupLinks`)
      )
    ).docs;

    groupLinkDocs.forEach((groupDoc) => {
      let group = groupDoc.data() as GroupLink;
      group = { ...group, id: groupDoc.id };
      this.groupLinks.push(group);
    });

    this.sortGroupLinks();
  }

  openDialog(group?: GroupLink) {
    let dialogRef;
    if (group) {
      dialogRef = this.dialog.open(EditGroupLinkDialogComponent, {
        width: '350px',
        data: {
          id: group.id,
          name: group.name,
          text: group.text,
          order: group.order,
          townshipId: this.townshipId,
        },
      });
    } else {
      dialogRef = this.dialog.open(EditGroupLinkDialogComponent, {
        width: '350px',
        data: {
          townshipId: this.townshipId,
        },
      });
    }
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (group) {
          const oldGroupLinks = this.groupLinks.find((obj) => {
            return obj.id === result.id;
          });
          const index = this.groupLinks.indexOf(oldGroupLinks, 0);
          this.groupLinks.splice(index, 1);
        }
        this.groupLinks.push(result);
        this.sortGroupLinks();
      }
    });
  }

  async delete(groupLink: GroupLink) {
    const dialogRef = this.dialog.open(DeleteGroupLinkComponent, {
      width: '475px',
      data: { groupLink },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('result', result);
      if (result) {
        const q = query(
          collection(
            this.db.firestore,
            `township/${this.townshipId}/voucherGroups`
          ),
          where('groupLink', '==', groupLink.name)
        );
        const allGroupLinks = await getDocs(q);
        const promises: any[] = [];
        allGroupLinks.forEach((groupLink) => {
          promises.push(
            updateDoc(
              doc(
                this.db.firestore,
                `township/${this.townshipId}/voucherGroups`,
                `${groupLink.id}`
              ),
              {
                groupLink: deleteField(),
              }
            )
          );
        });
        promises.push(
          deleteDoc(
            doc(
              this.db.firestore,
              `township/${this.townshipId}/groupLinks`,
              `${groupLink.id}`
            )
          )
        );
        await Promise.all(promises);
        const oldGroupLinks = this.groupLinks.find((obj) => {
          return obj.id === groupLink.id;
        });
        const index = this.groupLinks.indexOf(oldGroupLinks, 0);
        this.groupLinks.splice(index, 1);
      }
    });
  }

  sortGroupLinks() {
    this.groupLinks.sort((groupLink1, groupLink2) => {
      const a = groupLink1.name;
      const b = groupLink2.name;
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }
}
