import { Component, OnInit } from '@angular/core';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { db } from '../app.component';
import moment from 'moment';
import { DayPart, DayPartException, PlanningUser } from '../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { DayPartDeleteDialogComponent } from './dialog/day-part-delete-dialog.component';
import { getAuth } from 'firebase/auth';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-day-parts',
  templateUrl: './day-parts.component.html',
  styleUrls: ['./day-parts.component.scss'],
})
export class DayPartsComponent implements OnInit {
  loaded: boolean = false;
  townshipId: string = localStorage.getItem('township');
  dayParts: DayPart[];
  exceptions: DayPartException[];
  moment = moment;
  isPlanner: boolean = false;
  tabIndex: number = 0;

  repeatValues = {
    never: 'Nooit',
    year: 'Jaarlijks',
    month: 'Maandelijks',
    week: 'Wekelijks',
  };

  constructor(private dialog: MatDialog, private snackbar: MatSnackBar) {}

  async ngOnInit(): Promise<void> {
    const auth = getAuth();
    const user = (
      await getDoc(
        doc(
          db,
          `township/${this.townshipId}/planningUsers/${auth.currentUser.uid}`
        )
      )
    ).data() as PlanningUser;
    if (user?.rights === 'planner') {
      this.isPlanner = true;
    }
    if (history.state.tabIndex) {
      this.tabIndex = history.state.tabIndex;
      delete history.state.tabIndex;
    }

    const daypartsQuery = query(
      collection(db, `township/${this.townshipId}/dayParts`),
      orderBy('day'),
      orderBy('startHour')
    );
    onSnapshot(daypartsQuery, (querySnapshot) => {
      this.dayParts = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data() as DayPart;
        this.dayParts.push({ id: doc.id, ...data });
      });
      //move all sunday entries (day 0) to the end of the array
      this.dayParts.filter((obj) => {
        if (obj.day == 0) {
          this.dayParts.push(this.dayParts.shift());
        }
      });
    });

    const exceptionsQuery = query(
      collection(db, `township/${this.townshipId}/dayPartsExceptions`),
      orderBy('date')
    );
    onSnapshot(exceptionsQuery, (querySnapshot) => {
      this.exceptions = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data() as DayPartException;
        data.repeatValue = data.repeatValue ?? 'never';
        this.exceptions.push({ id: doc.id, ...data });
      });
    });
    this.loaded = true;
  }

  deleteException(item) {
    const dialogRef = this.dialog.open(DayPartDeleteDialogComponent, {
      width: '400px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const docRef = `township/${this.townshipId}/dayPartsExceptions/${item.id}`;
        await deleteDoc(doc(db, docRef));
      }
    });
  }

  getTimeString(data) {
    const start = moment().set({
      hour: data.startHour,
      minutes: data.startMinutes,
    });
    const end = moment().set({
      hour: data.endHour,
      minutes: data.endMinutes,
    });
    return start.format('HH:mm').concat(' - ', end.format('HH:mm'));
  }

  async toggleDayPart(event, dayPart: DayPart) {
    const docRef = doc(
      db,
      `/township/${this.townshipId}/dayParts/${dayPart.id}`
    );
    try {
      await setDoc(docRef, { enabled: event.checked }, { merge: true });
    } catch (error) {
      console.log(error);
      this.snackbar.open(
        'Er is iets fout gegaan met het aan- en uitzetten van het dagdeel',
        'X',
        {
          duration: 5000,
        }
      );
      return;
    }
  }
}
