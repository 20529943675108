// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form {
  width: 85%;
  margin: 0 auto 0 auto;
}
.form .form-wrapper .row {
  display: flex;
  margin-top: 1rem;
  gap: 10px;
}
.form .form-wrapper .row .color-picker {
  width: 100%;
}
.form .form-wrapper .row .color-picker .colors {
  display: flex;
  margin-top: 5px;
}
.form .form-wrapper .row .color-picker .color {
  cursor: pointer;
  margin-right: 15px;
}
.form .form-wrapper .row .color-picker .chosen-color {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid black;
}

.subtitle {
  font-weight: bold;
}

.type-subtitle {
  margin-bottom: 1rem;
}

.button-row {
  margin: 3rem 0 0 0;
  display: flex;
  justify-content: space-between;
}

mat-form-field {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/appointment-types/manage-appointment-types/manage-appointment-types.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,qBAAA;AACF;AACI;EACE,aAAA;EACA,gBAAA;EACA,SAAA;AACN;AAAM;EACE,WAAA;AAER;AADQ;EACE,aAAA;EACA,eAAA;AAGV;AADQ;EACE,eAAA;EACA,kBAAA;AAGV;AADQ;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AAGV;;AAGA;EACE,iBAAA;AAAF;;AAEA;EACE,mBAAA;AACF;;AACA;EACE,kBAAA;EACA,aAAA;EACA,8BAAA;AAEF;;AAAA;EACE,WAAA;AAGF","sourcesContent":[".form {\n  width: 85%;\n  margin: 0 auto 0 auto;\n  .form-wrapper {\n    .row {\n      display: flex;\n      margin-top: 1rem;\n      gap: 10px;\n      .color-picker {\n        width: 100%;\n        .colors {\n          display: flex;\n          margin-top: 5px;\n        }\n        .color {\n          cursor: pointer;\n          margin-right: 15px;\n        }\n        .chosen-color {\n          width: 24px;\n          height: 24px;\n          border-radius: 100%;\n          border: 1px solid black;\n        }\n      }\n    }\n  }\n}\n.subtitle {\n  font-weight: bold;\n}\n.type-subtitle{\n  margin-bottom: 1rem;\n}\n.button-row {\n  margin: 3rem 0 0 0;\n  display: flex;\n  justify-content: space-between;\n}\nmat-form-field {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
