import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Widget } from 'src/app/interfaces';

export interface DialogData {
  widget: Widget;
}

@Component({
  selector: 'app-widget-delete',
  templateUrl: './widget-delete.component.html',
  styleUrls: ['./widget-delete.component.scss'],
})
export class WidgetDeleteComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
}
