// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outlined-button {
  border: 0 !important;
}

.title {
  margin: 0 !important;
}

.dialog-text {
  padding: 10px 24px 0 24px;
}

.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.loading-spinner {
  display: flex;
  justify-content: center;
}

.checkbox-form {
  padding: 0 0 10px 0;
}
.checkbox-form .adjusted-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkbox-form .adjusted-checkbox .checkbox-and-info {
  display: flex;
  align-items: center;
}
.checkbox-form .adjusted-checkbox .checkbox-and-info .checkbox-data {
  margin: 0 0 0 10px;
  display: flex;
  flex-direction: column;
}
.checkbox-form .adjusted-checkbox .checkbox-and-info .checkbox-data .checkbox-number {
  font-weight: bold;
}
.checkbox-form .adjusted-checkbox .checkbox-and-info .checkbox-data .checkbox-date {
  color: rgba(0, 0, 0, 0.7);
}
.checkbox-form .adjusted-checkbox .invoice-already-downloaded {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/general-settings/dialog/create-invoice/create-invoice.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,gDAAA;EACA,qCAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,mBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;AAGN;AAFM;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;AAIR;AAHQ;EACE,iBAAA;AAKV;AAHQ;EACE,yBAAA;AAKV;AADI;EACE,UAAA;AAGN","sourcesContent":[".outlined-button {\n  border: 0 !important;\n}\n\n.title {\n  margin: 0 !important;\n}\n\n.dialog-text {\n  padding: 10px 24px 0 24px;\n}\n\n.mat-button-disabled {\n  background-color: rgba(0, 0, 0, 0.12) !important;\n  color: rgba(0, 0, 0, 0.26) !important;\n}\n\n.loading-spinner {\n  display: flex;\n  justify-content: center;\n}\n\n.checkbox-form {\n  padding: 0 0 10px 0;\n  .adjusted-checkbox {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    .checkbox-and-info {\n      display: flex;\n      align-items: center;\n      .checkbox-data {\n        margin: 0 0 0 10px;\n        display: flex;\n        flex-direction: column;\n        .checkbox-number {\n          font-weight: bold;\n        }\n        .checkbox-date {\n          color: rgba(0, 0, 0, 0.7);\n        }\n      }\n    }\n    .invoice-already-downloaded {\n      color: red;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
