// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .vouchers-export-type-dialog-dialog-title {
  padding: 0;
}

::ng-deep .vouchers-export-type-dialog-dialog-content {
  padding: 16px 0 0 0 !important;
}

::ng-deep .vouchers-export-type-dialog-dialog-actions {
  padding: 0;
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/voucher-management/dialogs/vouchers-export-type/vouchers-export-type.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AAEE;EACE,8BAAA;AACJ;;AAEE;EACE,UAAA;EACA,aAAA;EACA,yBAAA;AACJ","sourcesContent":["::ng-deep .vouchers-export-type-dialog-dialog-title {\n    padding: 0;\n  }\n  \n  ::ng-deep .vouchers-export-type-dialog-dialog-content {\n    padding: 16px 0 0 0 !important;\n  }\n  \n  ::ng-deep .vouchers-export-type-dialog-dialog-actions {\n    padding: 0;\n    display: flex;\n    justify-content: flex-end;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
