// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  border: 1px solid #bfc8cc;
  border-radius: 8px !important;
  padding: 24px 24px 8px 24px;
  box-shadow: none !important;
}

.header-row {
  display: flex;
  flex-direction: row;
}
.header-row .title {
  margin: 0 0 12px 0 !important;
}

.travel-duration {
  display: flex;
  align-items: center;
  margin: 4px 0 16px;
}
.travel-duration mat-icon {
  margin: 0 16px 4px 0;
}

.route {
  margin-bottom: 12px;
}
.route .buttons {
  display: flex;
}

.info-row {
  display: flex;
  flex-direction: row;
}
.info-row .info-child {
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/planning-team/components/team-appointment-info-card/team-appointment-info-card.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,6BAAA;EACA,2BAAA;EACA,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,6BAAA;AACJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AAAF;AACE;EACE,oBAAA;AACJ;;AAGA;EACE,mBAAA;AAAF;AACE;EACE,aAAA;AACJ;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;AAEE;EACE,OAAA;AAAJ","sourcesContent":[".card {\n  border: 1px solid #bfc8cc;\n  border-radius: 8px !important;\n  padding: 24px 24px 8px 24px;\n  box-shadow: none !important;\n}\n\n.header-row {\n  display: flex;\n  flex-direction: row;\n\n  .title {\n    margin: 0 0 12px 0 !important;\n  }\n}\n\n.travel-duration {\n  display: flex;\n  align-items: center;\n  margin: 4px 0 16px;\n  mat-icon {\n    margin: 0 16px 4px 0;\n  }\n}\n\n.route {\n  margin-bottom: 12px;\n  .buttons {\n    display: flex;\n  }\n}\n\n.info-row {\n  display: flex;\n  flex-direction: row;\n\n  .info-child {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
