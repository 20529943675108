import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// For testing the REGEX's and making new ones: https://regex101.com
// IBAN Information: https://www.iban.com/structure LAST CHECKED 09-02-2024
// Swift Codes: https://bank.codes/swift-code/

export class CustomValidators {
  static cannotContainSpace(c: AbstractControl): ValidationErrors | null {
    return cannotContainSpace(c);
  }
  static numberInput(
    minus: boolean,
    variableDecimal: boolean,
    decimal: number
  ): ValidatorFn {
    return numberInput(minus, variableDecimal, decimal);
  }
  static emailValidator(c: AbstractControl): ValidationErrors | null {
    return emailValidator(c);
  }
  static phoneValidator(c: AbstractControl): ValidationErrors | null {
    return phoneValidator(c);
  }
  static postalValidator(c: AbstractControl): ValidationErrors | null {
    return postalValidator(c);
  }
  static streetNameValidator(c: AbstractControl): ValidationErrors | null {
    return streetNameValidator(c);
  }
  static alphaNumericOnlyValidator(
    c: AbstractControl
  ): ValidationErrors | null {
    return alphaNumericOnlyValidator(c);
  }
  static ibanValidator(c: AbstractControl): ValidationErrors | null {
    return ibanValidator(c);
  }
  static bicValidator(c: AbstractControl): ValidationErrors | null {
    return bicValidator(c);
  }
  static maxValueValidator(maxValue: number): ValidatorFn {
    return maxValueValidator(maxValue);
  }
  static requiredFileType(type: string): ValidatorFn {
    return requiredFileType(type);
  }
  static arrayNotEmpty(): ValidatorFn {
    return arrayNotEmpty();
  }
}

function isEmptyInputValue(value: any): boolean {
  return (
    value == null ||
    ((typeof value === 'string' || Array.isArray(value)) && value.length === 0)
  );
}

function cannotContainSpace(c: AbstractControl) {
  const REGEX = /[\s]/g;

  if (isEmptyInputValue(c.value)) {
    return null;
  }

  return REGEX.test(c.value)
    ? {
        cannotContainSpace: true,
      }
    : null;
}

export function numberInput(
  minus: boolean,
  variableDecimal: boolean,
  decimal: number
): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    const REGEX = new RegExp(
      `^(-{${minus ? 1 : 0}})?[0-9]+([.,][0-9]{${
        variableDecimal ? '1,' : ''
      }${decimal}})?$`
    );

    //if minus === true and decimal === 2 the regex will return true on these formats:
    // -123.00
    // -123,00
    // -123
    // 123.00
    // 123,00
    // 123

    //if minus === false and decimal === 2 the regex will return true on these formats:
    //123,00
    //123.00
    //123

    //if minus === false and decimal === 0 the regex will return true on these formats:
    //123

    if (isEmptyInputValue(c.value)) {
      return null;
    }

    return REGEX.test(c.value.toString())
      ? null
      : {
          numberInput: true,
        };
  };
}

function emailValidator(c: AbstractControl) {
  const REGEX =
    /^([a-zA-Z0-9]+(?:[._+-][a-zA-Z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/;

  // all the email formats this regex will return true on:
  //test@mail.nl
  //test@mail.com
  //test@mail.co.uk
  //test@mail.de
  //T.est@mail.nl
  //test+test@mail.nl
  //T.est+test@mail.co.uk

  if (isEmptyInputValue(c.value)) {
    return null;
  }

  return REGEX.test(c.value)
    ? null
    : {
        emailValidator: true,
      };
}

function phoneValidator(c: AbstractControl) {
  const REGEX =
    /^((\+|00(s|s?-s?)?)31(s|s?-s?)?((0)[-s]?)?|0)[1-9]((s|s?-s?|\s)?[0-9])((s|s?-s?)?[0-9])((\s|s?-s?)?[0-9])((\s|s?-s?)?[0-9])((\s|s?-s?)?[0-9])s?[0-9]((\s|s?-s?)?[0-9])s?[0-9]$/;

  // all the phone number formats this regex will return true on:
  // 06 123 456 78
  // 06-123 456 78
  // 06-123-456-78
  // 06 12 34 56 78
  // 06-12 34 56 78
  // 06-12-34-56-78
  // 06 12345678
  // 06-12345678
  // 0612345678

  // +316 123 456 78
  // +316-108 275 59
  // +316-108-275-59
  // +316 12 34 56 78
  // +316-12 34 56 78
  // +316-12-34-56-78
  // +316 12345678
  // +316-12345678
  // +31612345678

  if (isEmptyInputValue(c.value)) {
    return null;
  }

  return REGEX.test(c.value)
    ? null
    : {
        phoneValidator: true,
      };
}

function postalValidator(c: AbstractControl) {
  const REGEX = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/;

  // all the postal formats this regex will return true on:
  // 1000 AA
  // 1000AA
  // 1000 Aa
  // 1000Aa
  // 1000 aA
  // 1000aA
  // 1000 aa
  // 1000aa

  if (isEmptyInputValue(c.value)) {
    return null;
  }

  return REGEX.test(c.value)
    ? null
    : {
        postalValidator: true,
      };
}

function streetNameValidator(c: AbstractControl) {
  const REGEX = /^([1-9][e][\s])?((?:[a-zA-Z][\.])(?:[\s]?))*([a-zA-Z-\s\']+)$/;

  // all the street name formats this regex will return true on:
  // A.A. de Lannoy-Willemsstraat
  // van der Duyn van Maasdamstraat
  // 1e Glanshof
  // 1e Kekerstraat
  // A. Eversplein
  // A.A.H. Struijckenkade
  // Aakhof

  if (isEmptyInputValue(c.value)) {
    return null;
  }

  return REGEX.test(c.value)
    ? null
    : {
        streetNameValidator: true,
      };
}

function alphaNumericOnlyValidator(c: AbstractControl) {
  const REGEX = /^([A-Za-z0-9]*)$/;

  return REGEX.test(c.value)
    ? null
    : {
        alphaNumericOnlyValidator: true,
      };
}

function ibanValidator(c: AbstractControl) {
  // all the iban formats this regex will return true on:
  // RU0204452560040702810412345678901
  // RU02 0445 2560 0407 0281 0412 3456 7890 1
  // DE75512108001245126199
  // DE75 5121 0800 1245 1261 99
  // NL02ABNA0123456789
  // NL02 ABNA 0123 4567 89
  // NO8330001234567
  // NO83 3000 1234 567
  // BE71096123456769
  // BE71 0961 2345 6769

  //FOR VERSION 3, IBAN checksum validation.
  // https://www.ibantest.com/en/how-is-the-iban-check-digit-calculated
  // https://stackoverflow.com/questions/21928083/iban-validation-check

  if (isEmptyInputValue(c.value)) {
    return null;
  }

  let FourDigitGroupings: number = 2;
  let lastDigitGrouping: boolean = true;
  const countryCode: string = c.value.substring(0, 2).toUpperCase();

  const countryWithIbanLength15: string[] = ['NO'];
  const countryWithIbanLength16: string[] = ['BE'];
  const countryWithIbanLength18: string[] = [
    'FO',
    'GL',
    'DK',
    'FI',
    'NL',
    'SD',
    'FK',
  ];
  const countryWithIbanLength19: string[] = ['MK', 'SI'];
  const countryWithIbanLength20: string[] = [
    'AT',
    'BA',
    'EE',
    'KZ',
    'XK',
    'LT',
    'LU',
    'MN',
  ];
  const countryWithIbanLength21: string[] = ['HR', 'LV', 'LI', 'CH'];
  const countryWithIbanLength22: string[] = [
    'BH',
    'BG',
    'CR',
    'GE',
    'DE',
    'IE',
    'ME',
    'RS',
    'GB',
    'VA',
  ];
  const countryWithIbanLength23: string[] = [
    'GI',
    'IL',
    'TL',
    'AE',
    'IQ',
    'SO',
  ];
  const countryWithIbanLength24: string[] = [
    'AD',
    'CZ',
    'MD',
    'PK',
    'RO',
    'SA',
    'SK',
    'ES',
    'SE',
    'TN',
    'VG',
  ];
  const countryWithIbanLength25: string[] = [
    'AO',
    'CV',
    'GW',
    'LY',
    'MZ',
    'PT',
    'ST',
  ];
  const countryWithIbanLength26: string[] = ['DZ', 'IR', 'IS', 'TR'];
  const countryWithIbanLength27: string[] = [
    'BI',
    'CF',
    'CG',
    'CM',
    'DJ',
    'FR',
    'GA',
    'GQ',
    'GR',
    'IT',
    'KM',
    'MC',
    'MG',
    'MR',
    'SM',
    'TD',
  ];
  const countryWithIbanLength28: string[] = [
    'AL',
    'AZ',
    'BF',
    'BJ',
    'BY',
    'CI',
    'CY',
    'DO',
    'GT',
    'HU',
    'HN',
    'LB',
    'MA',
    'ML',
    'NE',
    'PL',
    'SN',
    'SV',
    'TG',
    'NI',
  ];
  const countryWithIbanLength29: string[] = ['BR', 'EG', 'PS', 'QA', 'UA'];
  const countryWithIbanLength30: string[] = ['JO', 'KW', 'MU'];
  const countryWithIbanLength31: string[] = ['MT', 'SC'];
  const countryWithIbanLength32: string[] = ['LC'];
  const countryWithIbanLength33: string[] = ['RU'];

  switch (countryCode) {
    case getCountryCodeLength(countryWithIbanLength15, countryCode):
      FourDigitGroupings = 1;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength16, countryCode):
      FourDigitGroupings = 2;
      lastDigitGrouping = false;
      break;
    case getCountryCodeLength(countryWithIbanLength18, countryCode):
      FourDigitGroupings = 2;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength19, countryCode):
      FourDigitGroupings = 2;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength20, countryCode):
      FourDigitGroupings = 3;
      lastDigitGrouping = false;
      break;
    case getCountryCodeLength(countryWithIbanLength21, countryCode):
      FourDigitGroupings = 3;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength22, countryCode):
      FourDigitGroupings = 3;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength23, countryCode):
      FourDigitGroupings = 3;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength24, countryCode):
      FourDigitGroupings = 4;
      lastDigitGrouping = false;
      break;
    case getCountryCodeLength(countryWithIbanLength25, countryCode):
      FourDigitGroupings = 4;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength26, countryCode):
      FourDigitGroupings = 4;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength27, countryCode):
      FourDigitGroupings = 4;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength28, countryCode):
      FourDigitGroupings = 5;
      lastDigitGrouping = false;
      break;
    case getCountryCodeLength(countryWithIbanLength29, countryCode):
      FourDigitGroupings = 5;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength30, countryCode):
      FourDigitGroupings = 5;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength31, countryCode):
      FourDigitGroupings = 5;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength32, countryCode):
      FourDigitGroupings = 6;
      lastDigitGrouping = false;
      break;
    case getCountryCodeLength(countryWithIbanLength33, countryCode):
      FourDigitGroupings = 6;
      lastDigitGrouping = true;
      break;
    default:
      break;
  }

  const REGEX = new RegExp(
    `^([a-zA-Z]{2})([0-9]{2})\\s?([a-zA-Z0-9]{4})((\\s?[0-9]{4}){${FourDigitGroupings}})((\\s?[0-9]{1,3})){${
      lastDigitGrouping ? 1 : 0
    }}$`
  );

  if (REGEX.test(c.value)) {
    const ibanSeparated = c.value
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '')
      .match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

    const lettersToNumbers = (
      (ibanSeparated[3] + ibanSeparated[1] + '00') as any
    ).replace(/[A-Z]/g, function (letter) {
      return letter.charCodeAt(0) - 55;
    });

    let checksum = String(98 - Number(BigInt(lettersToNumbers) % 97n));

    checksum = checksum.length == 1 ? '0' + checksum : checksum;

    const REGEXCheckSum = new RegExp(
      `^([a-zA-Z]{2})(${checksum})\\s?([a-zA-Z0-9]{4})((\\s?[0-9]{4}){${FourDigitGroupings}})((\\s?[0-9]{1,3})){${
        lastDigitGrouping ? 1 : 0
      }}$`
    );

    return REGEXCheckSum.test(c.value)
      ? null
      : {
          ibanValidator: true,
        };
  } else {
    return {
      ibanValidator: true,
    };
  }
}

function getCountryCodeLength(array: any[], item: string) {
  return array.includes(item) ? item : 'none found';
}

function bicValidator(c: AbstractControl) {
  const REGEX = /^[a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/;

  // all the BIC formats this regex will return true on:
  // AAAABBCCDDD
  // RABONL2U

  if (isEmptyInputValue(c.value)) {
    return null;
  }

  return REGEX.test(c.value)
    ? null
    : {
        bicValidator: true,
      };
}

export function maxValueValidator(maxValue: number): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    const value = c.value;

    if (isEmptyInputValue(c.value)) {
      return null;
    }

    return value > maxValue ? { maxValue: true } : null;
  };
}

export function requiredFileType(type: string): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    let file = c.value;
    const requiredType = type.toLowerCase();

    if (file) {
      file = file.name.split('.')[1].toLowerCase();
    } else {
      return null;
    }

    return requiredType !== file
      ? {
          requiredFileType: true,
        }
      : null;
  };
}

export function arrayNotEmpty(): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    return !c.value || c.value.length == 0 ? { arrayNotEmpty: true } : null;
  };
}
