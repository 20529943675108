import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Location } from '@angular/common';
import { initializeApp } from 'firebase/app';
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import {
  Organisation,
  OrgUser,
  PlanningUser,
  Township,
  TownshipUser,
  User,
  DataStoreExport,
  ScreenSizeExport,
} from './interfaces';
import {
  ManagementRights,
  OrgUserRights,
  PlanningUserRights,
  TeamUserRights,
  TownshipUserRights,
} from './enums';
import { MatDialog } from '@angular/material/dialog';
import { SendVoucherComponent } from './voucher-management/send-voucher/send-voucher.component';
import { ManageVoucherComponent } from './voucher-management/manage-voucher/manage-voucher.component';
import { UpgradeDialogComponent } from './nav-bar/dialogs/upgrade-dialog/upgrade-dialog.component';
import { ChangeTownshipDialogComponent } from './dashboard-township/dialog/change-township-dialog/change-township-dialog.component';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HostListener } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

export const app = initializeApp(environment.firebase);
export const db = getFirestore(app);
export const functions = getFunctions(app, 'europe-west1');
export const auth = getAuth(app);
export const dataStore: DataStoreExport = {
  rootUser: null,
  localUser: null,
  township: null,
  organisation: null,
  isMemberOfMultipleOrgs: null,
  isSuperUser: null,
  isOwner: null,
  isAdministrator: null,
  isHelpdeskUser: null,
  isFinancialUser: null,
  isControllerUser: null,
  isStatisticsUser: null,
  avatarUrl: null,
};
export const screenSize: ScreenSizeExport = {
  height: null,
  width: null,
};
export let particularities = [];
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public pageTitle: string;
  environmentString: string = null;
  envImage: string = null;
  showNavbar: boolean = false;
  showSideNav: boolean = false;
  sideNavMode: string = 'side';
  env = environment;
  sideNavRoutes = [];
  dataStore: DataStoreExport = dataStore;
  screenSize: ScreenSizeExport = screenSize;

  constructor(
    private titleService: Title,
    public router: Router,
    private location: Location,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.router.events.subscribe((event) => {
      if (
        !this.location.path().includes('/statistics/widget/') &&
        !this.location.path().includes('/email')
      ) {
        this.showNavbar = true;
      } else {
        this.showNavbar = false;
      }
      if (this.env.env === 'team') {
        if (
          !this.location.path().includes('/login') &&
          !this.location.path().includes('/registration') &&
          screenSize.width >= 580
        ) {
          this.showSideNav = true;
        } else {
          this.showSideNav = false;
        }
      }
      if (this.env.env === 'planning') {
        if (
          !this.location.path().includes('/login') &&
          !this.location.path().includes('/registration') &&
          screenSize.width >= 580
        ) {
          this.showSideNav = true;
          this.sideNavMode = 'side';
        } else {
          this.showSideNav = false;
          this.sideNavMode = 'over';
        }
      }
    });
    this.getScreenSize();
    this.titleService.setTitle(environment.name);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    screenSize.height = window.innerHeight;
    screenSize.width = window.innerWidth;
    if (this.env.env === 'team') {
      if (
        !this.location.path().includes('/login') &&
        !this.location.path().includes('/registration') &&
        screenSize.width >= 580
      ) {
        this.showSideNav = true;
      } else {
        this.showSideNav = false;
      }
    }
    if (this.env.env === 'planning') {
      if (
        !this.location.path().includes('/login') &&
        !this.location.path().includes('/registration') &&
        screenSize.width >= 580
      ) {
        this.showSideNav = true;
        this.sideNavMode = 'side';
      } else {
        this.showSideNav = false;
        this.sideNavMode = 'over';
      }
    }
  }

  ngOnInit(): void {
    switch (this.env.prefix) {
      case 'lokale':
        this.envImage =
          'https://firebasestorage.googleapis.com/v0/b/wemaron-vouchers.appspot.com/o/img%2Fbackgrounds%2FLokaleBonBackground.png?alt=media&token=2210eab6-fb26-49f3-ac91-9702e7aed0de';
        break;
      case 'groene':
        this.envImage =
          'https://firebasestorage.googleapis.com/v0/b/wemaron-vouchers.appspot.com/o/img%2Fbackgrounds%2FGroeneBonBackground.png?alt=media&token=99190bae-2ea3-4a42-9570-92b3c4717e79';
        break;
      case 'toegangs':
        this.envImage =
          'https://firebasestorage.googleapis.com/v0/b/wemaron-vouchers.appspot.com/o/img%2Fbackgrounds%2FToegangsBonBackground.png?alt=media&token=430ffb29-ce3f-438c-804d-42586c5f35db';
        break;
    }

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        let rootUser = {
          ...(await getDoc(doc(db, `users/${user.uid}`))).data(),
          id: user.uid,
        } as User;
        const unsub = onSnapshot(doc(db, `users/${user.uid}`), (doc) => {
          rootUser = { ...doc.data(), id: user.uid } as User;
        });

        let isSuperUser = false;
        if (rootUser.rights === 'admin') {
          isSuperUser = true;
        }

        let isMemberOfMultipleOrgs = false;
        if (rootUser.orgRights && rootUser.orgRights.length > 1) {
          isMemberOfMultipleOrgs = true;
        }

        try {
          const townshipDoc = await getDoc(
            doc(db, `township/${rootUser.township}`)
          );
          const township = {
            ...townshipDoc.data(),
            id: townshipDoc.id,
          } as Township;

          switch (this.env.env) {
            case 'township':
              await this.townshipAuth(rootUser, isSuperUser, township);
              this.environmentString = 'Beheeromgeving';
              break;
            case 'org':
              await this.orgAuth(rootUser, isSuperUser);
              this.environmentString = 'Ondernemersomgeving';
              break;
            case 'planning':
              await this.planningAuth(rootUser, isSuperUser, township);
              this.environmentString = 'Planomgeving';
              break;
            case 'team':
              await this.teamAuth(rootUser, isSuperUser, township);
              this.environmentString = 'Teamomgeving';
              break;
          }

          onSnapshot(
            query(
              collection(db, `township/${township.id}/planningParticularities`),
              orderBy('default', 'desc')
            ),
            (querySnapshot) => {
              const particularitiesArray = [];
              querySnapshot.forEach((doc) => {
                const data = doc.data();
                particularitiesArray.push({ id: doc.id, ...data });
                this.iconRegistry.addSvgIcon(
                  doc.id,
                  this.sanitizer.bypassSecurityTrustResourceUrl(data.iconPath)
                );
              });
              particularities = particularitiesArray;
            }
          );

          switch (dataStore.localUser?.rights) {
            case 'owner':
              dataStore.isOwner = true;
            case 'admin':
              dataStore.isAdministrator = true;
            case 'helpdesk':
              dataStore.isHelpdeskUser = true;
              break;
            case 'financial':
              dataStore.isFinancialUser = true;
              break;
            case 'controller':
              dataStore.isControllerUser = true;
              break;
            case 'statistics':
              dataStore.isStatisticsUser = true;
              break;
          }

          dataStore.rootUser = rootUser;
          dataStore.isSuperUser = isSuperUser;
          dataStore.isMemberOfMultipleOrgs = isMemberOfMultipleOrgs;
          dataStore.township = township;
        } catch (error) {
          console.log('Error: ', error);
          this.logout(
            `Er is iets fout gegaan, probeer het later nog eens of neem contact op`
          );
        }

        this.sideNavRoutes.forEach((element) => {
          if (this.router.url.includes(element.route)) {
            this.sideNavRoutes[this.sideNavRoutes.indexOf(element)].active =
              true;
          }
        });
      }
    });
  }

  async townshipAuth(rootUser: User, isSuperUser: boolean, township: Township) {
    const townshipUserDoc = await getDoc(
      doc(db, `township/${rootUser.township}/users/${rootUser.id}`)
    );
    const localUser = {
      ...townshipUserDoc.data(),
      id: townshipUserDoc.id,
    } as TownshipUser;

    let avatarUrl = null;
    if (rootUser.township && township?.useLogoImage) {
      avatarUrl = township.logoImageUrl;
    }

    const allowedRights = [];
    for (var key of Object.keys({
      ...ManagementRights,
      ...TownshipUserRights,
    })) {
      allowedRights.push(key);
    }

    if (
      (!isSuperUser &&
        !allowedRights.find((key) => key === localUser.rights)) ||
      rootUser.organisation
    ) {
      // Sign user out if not a township environment user
      this.logout('Uw account heeft geen rechten in deze omgeving.');
    } else {
      dataStore.localUser = localUser;
      dataStore.avatarUrl = avatarUrl;
      this.sideNavRoutes = [];
    }
  }

  async orgAuth(rootUser: User, isSuperUser: boolean) {
    const organisationDoc = await getDoc(
      doc(
        db,
        `township/${rootUser.township}/organisations/${rootUser.organisation}`
      )
    );
    const organisation = {
      ...organisationDoc.data(),
      id: organisationDoc.id,
    } as Organisation;

    const townshipUserDoc = await getDoc(
      doc(
        db,
        `township/${rootUser.township}/organisations/${rootUser.organisation}/users/${rootUser.id}`
      )
    );
    const localUser = {
      ...townshipUserDoc.data(),
      id: townshipUserDoc.id,
    } as OrgUser;

    let avatarUrl = null;
    if (organisation.useHeaderImage) {
      avatarUrl = organisation.headerImageUrl;
    }

    const allowedRights = [];
    for (var key of Object.keys({
      ...ManagementRights,
      ...OrgUserRights,
    })) {
      allowedRights.push(key);
    }

    if (
      !isSuperUser ||
      (!allowedRights.find((key) => key === localUser.rights) &&
        !rootUser.organisation)
    ) {
      // Sign user out if not an organisation environment user
      this.logout('Uw account heeft geen rechten in deze omgeving.');
    } else {
      dataStore.localUser = localUser;
      dataStore.organisation = organisation;
      dataStore.avatarUrl = avatarUrl;
      this.sideNavRoutes = [];
    }
  }

  async planningAuth(rootUser: User, isSuperUser: boolean, township: Township) {
    const userDataTownship = (
      await getDoc(
        doc(db, `township/${rootUser.township}/users/${rootUser.id}`)
      )
    ).data() as TownshipUser;
    const localUser = (
      await getDoc(
        doc(db, `township/${rootUser.township}/planningUsers/${rootUser.id}`)
      )
    ).data() as PlanningUser;

    const allowedRights = [];
    for (var key of Object.keys({
      ...ManagementRights,
      ...PlanningUserRights,
    })) {
      allowedRights.push(key);
    }

    if (
      !(
        isSuperUser ||
        ((allowedRights.find((key) => key === userDataTownship?.rights) ||
          allowedRights.find((key) => key === localUser.rights)) &&
          township.usesPlanningEnvironment &&
          !rootUser.organisation)
      )
    ) {
      // Sign out user if not a planning environment user
      this.logout('Uw account heeft geen rechten in deze omgeving.');
    } else {
      dataStore.localUser = localUser;
      this.sideNavRoutes = [
        {
          name: 'Planning',
          route: '/planning',
          icon: 'today',
        },
        {
          name: 'Dagdelen',
          route: '/day-parts',
          icon: 'calendar_view_day',
        },
      ];
      if (localUser?.rights !== 'Planner' || rootUser.rights === 'admin') {
        this.sideNavRoutes.push(
          {
            name: 'Afspraaktypen',
            route: '/appointment-types',
            icon: 'event_note',
          },
          {
            name: 'Team',
            route: '/users',
            icon: 'groups',
          },
          {
            name: 'Instellingen',
            route: '/planner-settings',
            icon: 'settings',
          }
        );
      }
    }
  }

  async teamAuth(rootUser: User, isSuperUser: boolean, township: Township) {
    const localUser = (
      await getDoc(
        doc(db, `township/${rootUser.township}/planningUsers/${rootUser.id}`)
      )
    ).data() as PlanningUser;

    const allowedRights = [];
    for (var key of Object.keys({
      ...TeamUserRights,
    })) {
      allowedRights.push(key);
    }

    if (
      !(
        isSuperUser ||
        (allowedRights.find((key) => key === localUser?.rights) &&
          township.usesPlanningEnvironment &&
          !rootUser.organisation)
      )
    ) {
      // Sign out user if not a team environment user
      this.logout('Uw account heeft geen rechten in deze omgeving.');
    } else {
      dataStore.localUser = localUser;
      this.sideNavRoutes = [
        {
          name: 'Planning',
          route: '/planning',
          icon: 'today',
        },
        {
          name: 'Afspraken',
          route: '/appointments',
          icon: 'inventory',
        },
        {
          name: 'Rooster',
          route: '/schedule',
          icon: 'perm_contact_calendar',
        },
        // {
        //   name: 'Inbox',
        //   route: '/appointment-types',
        //   icon: 'inbox',
        // },
      ];
    }
  }

  openLinkManagement() {
    let url = 'https://dev.beheer.lokalebon.nl';
    if (location.origin.includes('planning')) {
      url = location.origin.replace('planning', 'beheer');
    }
    window.open(url, '_blank');
  }

  async logout(message?: string) {
    await auth.signOut().then(() => {
      if (message) {
        this.snackbar.open(message, 'X', {
          duration: 5000,
        });
      }

      for (var key of Object.keys(dataStore)) {
        dataStore[key] = null;
      }
      this.sideNavRoutes = [];
      this.router.navigate(['/login']);
    });
  }

  openSendVoucherDialog() {
    this.dialog.open(SendVoucherComponent, {
      width: '450px',
      autoFocus: false,
    });
  }

  openEditVoucherDialog(type: string) {
    this.dialog.open(ManageVoucherComponent, {
      width: '450px',
      data: { type },
    });
  }

  showUpdate() {
    this.dialog.open(UpgradeDialogComponent, {
      width: '500px',
    });
  }

  changeTownship(userId: string) {
    const dialogRef = this.dialog.open(ChangeTownshipDialogComponent, {
      width: '650px',
    });
    dialogRef.afterClosed().subscribe(async (townshipId) => {
      if (townshipId) {
        localStorage.setItem('township', townshipId);
        const data: any = { township: townshipId };

        if (dataStore.rootUser.orgRights) {
          const org = dataStore.rootUser.orgRights.find((org) => {
            return org.townshipId === townshipId;
          });
          if (org.organisationId) {
            data['organisation'] = org.organisationId;
          }
        }

        await setDoc(doc(db, `users/${userId}`), data, {
          merge: true,
        });

        window.location.reload();
      }
    });
  }
}
