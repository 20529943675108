import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralStatistics, Widget } from '../interfaces';
import { widgets } from './widgets-helper';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  townshipId = localStorage.getItem('township');
  env = environment;
  allWidgets = widgets;
  // widgets: Observable<Widget[]>;
  generalStatisticsObs: Observable<GeneralStatistics>;
  widgets: Widget[] = [];
  loaded = false;

  constructor(public db: AngularFirestore) {}

  async ngOnInit() {
    //localstorage is checked, so if the user changes something to specific widgets, it refreshes. Fix for frontend.
    if (localStorage.getItem('rfWidget')) {
      localStorage.removeItem('rfWidget');
      location.reload();
    }
    const widgetRef = collection(
      this.db.firestore,
      `township/${this.townshipId}/widgets`
    );
    const widgetQuery = query(
      widgetRef,
      where('disabled', '==', false),
      orderBy('order')
    );
    const widgetCollection = await getDocs(widgetQuery);
    widgetCollection.forEach((doc) => {
      const widget = doc.data() as Widget;
      widget.id = doc.id;
      this.widgets.push(widget);
    });
    this.generalStatisticsObs = this.db
      .doc<GeneralStatistics>(`township/${this.townshipId}/statistics/general`)
      .valueChanges();
    this.generalStatisticsObs.subscribe((statistics) => {
      this.loaded = true;
    });
  }
}
