import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import {
  VoucherGroup,
  Township,
  Tag,
  Address,
  Voucher,
  SubsidyOption,
} from 'src/app/interfaces';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Observable,
  ReplaySubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  take,
} from 'rxjs';
import {
  Timestamp,
  addDoc,
  collection,
  deleteField,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
} from 'firebase/firestore';
import { environment } from '../../../environments/environment';
import { EditGroupLinkDialogComponent } from 'src/app/group-links/dialog/edit-group-link-dialog/edit-group-link-dialog.component';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { getFileExtension } from 'src/app/globals';
import { SelectPdfFontsComponent } from 'src/app/settings/dialog/select-pdf-fonts/select-pdf-fonts.component';
import * as XLSX from 'xlsx';
import { GenerateVouchersComponent } from '../dialogs/generate-vouchers/generate-vouchers.component';
import { UpgradeDialogComponent } from 'src/app/nav-bar/dialogs/upgrade-dialog/upgrade-dialog.component';
import { HttpClient } from '@angular/common/http';
import Hashids from 'hashids';
import moment from 'moment';
import { CustomValidators } from 'src/app/validators/custom-validators';
export interface DialogData {
  townshipId: string;
  voucherGroup: VoucherGroup;
  newGroup?: boolean;
}

@Component({
  selector: 'app-voucher-group-edit',
  templateUrl: './voucher-group-edit.component.html',
  styleUrls: ['./voucher-group-edit.component.scss'],
})
export class VoucherGroupEditComponent implements OnInit {
  loading: boolean = true;
  saving: boolean = false;
  env = environment;
  currentTab: number = 0;
  dialogTitle = this.data.newGroup
    ? 'Nieuwe campagne aanmaken'
    : 'Campagne aanpassen';
  voucherGroupId: string = this.data.newGroup
    ? this.db.createId()
    : this.data.voucherGroup.id;
  townshipId = this.data.townshipId;

  tabsArray = [
    'Campagne info',
    'Bon',
    'Bon opties',
    'Berichtgeving',
    'Ontwerp',
  ];
  autoAddressValidator = CustomValidators.arrayNotEmpty();
  townshipData: Township;
  voucherGroupFormCampaign = this.fb.group({
    name: [, Validators.required],
    type: ['request', Validators.required],
    usesExternalVouchers: [{ value: false, disabled: false }],
    ambitionLevel: ['none'],
    //tags, non-form
    groupLink: [],
    hideVoucherGroupLink: [],
    addressType: [[]],
    activateFromType: ['activateOnRequest'],
    usableFrom: [],
    costCenter: [, CustomValidators.alphaNumericOnlyValidator],
  });
  voucherGroupFormVoucher = this.fb.group({
    couponValue: [, Validators.required],
    maxVouchers: [, [Validators.min(1), Validators.pattern('^[0-9]*$')]], // TODO fix validator
    altTextCouponValue: [, Validators.maxLength(12)], // TODO fix validator
    slogan: [, Validators.maxLength(40)], // TODO fix validator
    description: [''],
    terms: ['', Validators.required],
    termsURL: [''],
    suffix: [''],
    startDate: [, [Validators.required]],
    validUntilDate: [],
    requestCouponDays: [0],
    validUntilTime: [],
    validUntilTimeValue: [{ value: 1, disabled: true }],
    validUntilTimeType: [{ value: 'months', disabled: true }],
    updateOnlyActivatedVouchers: [],
  });
  voucherGroupFormVoucherOptions = this.fb.group({
    //persoonsgegevens
    requestName: [],
    requestPhone: [],
    requestIdentification: [],
    dontRequestAddress: [{ value: false, disabled: true }],
    //bij gebruik
    uniqueMailsOnly: [],
    uniquePhoneNumbersOnly: [],
    verifyPhone: [{ value: false, disabled: true }],
    noAddressChecks: [],
    hideVoucherRemainingInfo: [],
    sendSmsCoupon: [{ value: false, disabled: true }],
    claimInstantly: [],
    hideHeaderImage: [],
    forceSpentFullAmount: [],
    dontSyncWithApi: [],
    delaySend: [],
    multipleUses: [],
    sendBurdenOfProof: [],
    burdenOfProofDays: [],
    burdenOfProofUntilDate: [],
    dontSendUponActivation: [],
    questionaireLink: [],
    questionaireLinkText: [],
    sellType: [],
    personalisedNote: [{ value: false, disabled: true }],
    voucherPrice: [],
    administrationCost: [],
    paymentProvider: [],
    redirectUrl: [],
    autoVoucherRequest: [],
    autoVoucherRequestVoucherGroup: [],
    autoAddressTypeAllotment: [],
    addressTypesCtrl: [],
    autoAddressTypes: [
      ,
      this.data.voucherGroup && this.data.voucherGroup.autoAddressTypeAllotment
        ? [this.autoAddressValidator]
        : [],
    ],
    cashback: [],
    applicantMustCompleteRequest: [],
    dontSendBarcode: [],
    sendPkPass: [],
    dontSendExpireMail: [false],
    dontSendClaimMail: [false],
  });
  voucherGroupFormMessaging = this.fb.group({
    expiredNote: [],
    claimedNote: [],
    voucherReminderTime: [0],
    voucherReminderMail: [],
    amountVoucherReminder: [{ value: false, disabled: true }],
    groupContactEmail: [],
    groupPhoneNumber: [],
    arrangementEmail: [],
  });
  voucherGroupFormDesign = this.fb.group({
    primaryColor: [
      '#1a697f',
      [Validators.maxLength(7), Validators.minLength(7), Validators.required], // TODO fix validator
    ],
    accentColor: [
      '#fc9f5b',
      [Validators.maxLength(7), Validators.minLength(7), Validators.required], // TODO fix validator
    ],
    //upload PDF, non-form
    //upload image, non-form
    //upload email header image, non-form
    //upload extra pdf, non-form

    //import vouchers, non-form
    //generate vouchers, non-form
    //generate external vouchers, non-form

    //econobis
    // campagneId: [],
    // measureId: [],
  });
  paymentSetup: boolean = false;
  groupLinks: any[] = [];
  voucherGroups: any[] = [];
  addressesType: Address[] = [];
  maxVouchers: number = 10000;
  availablePaymentProviders: { value: string; display: string }[] = [];
  voucherReminderOptions = [
    { val: 0, text: 'Nooit' },
    { val: 7, text: '7 dagen' },
    { val: 14, text: '14 dagen' },
    { val: 30, text: '30 dagen' },
    { val: 60, text: '60 dagen' },
  ];
  accentToggle: boolean = false;
  primaryToggle: boolean = false;
  generating: boolean = false;
  generated: boolean = false;
  generatedVouchers: any = [];
  voucherPrefix: string;
  vouchersToBeGenerated: number;
  shouldGetExternalVouchers: boolean = false;
  hashids: any = new Hashids('', 5, 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789');

  //Everything uploads
  //PDF
  pdfFileAlreadyUploaded: boolean = false;
  pdfFileUploaded: File;
  deletePdf: boolean = false;
  //Image
  imageFileAlreadyUploaded: boolean = false;
  imageFileUploaded: File;
  imageUrl: string;
  deleteImage: boolean = false;
  //Header image
  headerImgFileAlreadyUploaded: boolean = false;
  headerImgFileUploaded: File;
  headerImgUrl: string;
  deleteHeaderImg: boolean = false;
  //Arrangement
  arrangementFileAlreadyUploaded: boolean = false;
  arrangementFileUploaded: File;
  arrangementFileUrl: string;
  deleteArrangementFile: boolean = false;
  //Vouchers
  vouchersFileUploaded: File;
  //Other
  pdfFonts: any;
  storeData: any;
  worksheet: any;
  json: any;

  // Everything tags
  @ViewChild('tagInput', { static: false })
  tagInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new UntypedFormControl();
  allTags: Observable<Tag[]>;
  voucherGroupTags: Observable<Tag[]>;
  private allTagsCollection: AngularFirestoreCollection<Tag>;
  private voucherGroupTagsCollection: AngularFirestoreCollection<Tag>;
  selectedTags = [];
  filteredTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  public tagFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredTagsCtrl: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  subsidyOptionsCtrl = new UntypedFormControl();
  subsidyOptions: SubsidyOption[] = [];
  filteredSubsidyOptions: Observable<SubsidyOption[]>;
  selectedSubsidyOptions: SubsidyOption[] = [];
  @ViewChild('subsidyInput')
  subsidyInput: ElementRef<HTMLInputElement>;
  // AddressTypes chips
  @ViewChild('addressTypesInput')
  addressTypesInput: ElementRef<HTMLInputElement>;
  // addressTypesCtrl = new UntypedFormControl();
  addressTypes = [];
  allAddressTypes = [];
  filteredAddressTypes: Observable<String[]>;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VoucherGroupEditComponent>,
    private storage: AngularFireStorage,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    await this.getAndSortData();
    this.setupControllers();
    this.loading = false;
  }

  changeTab(indexNumber?: number) {
    this.currentTab = indexNumber;
  }

  nextTab() {
    let formValid: boolean = false;
    switch (this.currentTab) {
      case 0:
        this.voucherGroupFormCampaign.markAllAsTouched();
        formValid = this.voucherGroupFormCampaign.valid;
        break;
      case 1:
        this.voucherGroupFormVoucher.markAllAsTouched();
        formValid = this.voucherGroupFormVoucher.valid;
        break;
      case 2:
        this.voucherGroupFormVoucherOptions.markAllAsTouched();
        formValid = this.voucherGroupFormVoucherOptions.valid;
        break;
      case 3:
        this.voucherGroupFormMessaging.markAllAsTouched();
        formValid = this.voucherGroupFormMessaging.valid;
        break;
      case 4:
        this.voucherGroupFormDesign.markAllAsTouched();
        formValid = this.voucherGroupFormDesign.valid;
        break;
    }
    this.currentTab = formValid ? this.currentTab + 1 : this.currentTab;
  }

  async selected(event: MatAutocompleteSelectedEvent): Promise<void> {
    const value = event.option.value as Tag;
    const foundTag = await this.selectedTags.find((lfTag) => {
      if (lfTag.id === value.id) {
        return lfTag;
      }
    });

    if (!foundTag) {
      this.selectedTags.push(value);
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue('');
    } else {
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue('');
      this.snackBar.open('Dit label hangt al aan deze campagne.', 'X', {
        duration: 5000,
      });
    }
  }

  remove(tag: Tag): void {
    const index = this.selectedTags.indexOf(tag);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }

  async selectSubsidyOption(
    event: MatAutocompleteSelectedEvent
  ): Promise<void> {
    const value = event.option.value as SubsidyOption;
    const foundTag = await this.selectedSubsidyOptions.find((lfTag) => {
      if (lfTag.id === value.id) {
        return lfTag;
      }
    });
    if (!foundTag) {
      this.selectedSubsidyOptions.push(value);
      this.subsidyInput.nativeElement.value = '';
      this.subsidyOptionsCtrl.setValue('');
    } else {
      this.subsidyOptionsCtrl.setValue('');
      this.snackBar.open('Deze toepassing hangt al aan deze groep.', 'X', {
        duration: 5000,
      });
    }
  }

  removeSubsidyOption(subsidyOption: SubsidyOption): void {
    const index = this.selectedSubsidyOptions.indexOf(subsidyOption);
    if (index >= 0) {
      this.selectedSubsidyOptions.splice(index, 1);
    }
  }

  async addAddressType(event: MatAutocompleteSelectedEvent): Promise<void> {
    const value = event.option.value as string;
    if (value === '') {
      return;
    }
    const foundTag = await this.addressTypes.find((type) => {
      if (type.toLowerCase() === value.toLowerCase()) {
        return type;
      }
    });
    if (!foundTag) {
      this.addressTypes.push(value);
      this.addressTypesInput.nativeElement.value = '';
      this.voucherGroupFormVoucherOptions.controls.addressTypesCtrl.setValue(
        ''
      );
    } else {
      this.voucherGroupFormVoucherOptions.controls.addressTypesCtrl.setValue(
        ''
      );
      this.snackBar.open('Deze toepassing hangt al aan deze campagne.', 'X', {
        duration: 5000,
      });
    }
  }

  removeAddressType(addressType: string): void {
    const index = this.addressTypes.indexOf(addressType);
    if (index >= 0) {
      this.addressTypes.splice(index, 1);
    }
    this.voucherGroupFormVoucherOptions.controls.autoAddressTypes.updateValueAndValidity();
  }

  filterAddressType(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allAddressTypes.filter((addressType: string) =>
      addressType.toLowerCase().includes(filterValue)
    );
  }

  filterSubsidyOptions(value: string): SubsidyOption[] {
    if (typeof value !== 'string') {
      return;
    }
    const filterValue = value.toLowerCase();

    return this.subsidyOptions.filter((subOption) =>
      subOption.name.toLowerCase().includes(filterValue)
    );
  }

  newGroupLink() {
    const dialogRef = this.dialog.open(EditGroupLinkDialogComponent, {
      width: '350px',
      data: {
        townshipId: this.townshipId,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.groupLinks.push(result.name);
        this.sortGroupLinks();
        this.voucherGroupFormCampaign.controls.groupLink.setValue(result.name);
      }
    });
  }

  sortGroupLinks() {
    this.groupLinks.sort((groupLink1, groupLink2) => {
      const a = groupLink1;
      const b = groupLink2;
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }

  sortVoucherGroups() {
    this.voucherGroups.sort((voucherGroup1, voucherGroup2) => {
      const a = voucherGroup1.name;
      const b = voucherGroup2.name;
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }

  async getAndSortData() {
    this.townshipData = (
      await getDoc(doc(this.db.firestore, `township/${this.data.townshipId}`))
    ).data() as Township;

    const groupLinkDocs = (
      await getDocs(
        collection(this.db.firestore, `township/${this.townshipId}/groupLinks`)
      )
    ).docs;
    groupLinkDocs.forEach((groupDoc) => {
      const group = groupDoc.data();
      if (group.name) {
        this.groupLinks.push(group.name);
      }
    });
    const subsidyOptionDocs = (
      await getDocs(
        collection(this.db.firestore, `/globalSettings/global/subsidyOptions`)
      )
    ).docs;
    subsidyOptionDocs.forEach((subDoc) => {
      const subsidyOption = {
        id: subDoc.id,
        ...(subDoc.data() as SubsidyOption),
      };
      this.subsidyOptions.push(subsidyOption);
    });
    this.filteredSubsidyOptions = this.subsidyOptionsCtrl.valueChanges.pipe(
      startWith(null),
      map((subOption: string | null) =>
        subOption
          ? this.filterSubsidyOptions(subOption)
          : this.subsidyOptions.slice()
      )
    );
    if (!this.data.newGroup && this.data.voucherGroup.groupLink) {
      const groupLink = this.groupLinks.find((groupLink) => {
        return groupLink === this.data.voucherGroup.groupLink;
      });
      if (!groupLink) {
        await addDoc(
          collection(
            this.db.firestore,
            `township/${this.townshipId}/groupLinks/`
          ),
          {
            name: this.data.voucherGroup.groupLink,
            text: this.data.voucherGroup.groupLinkTxt
              ? this.data.voucherGroup.groupLinkTxt
              : '',
            order: 10,
          }
        );
        this.groupLinks.push(this.data.voucherGroup.groupLink);
      }
    }
    this.sortGroupLinks();

    const addressTypeDocs = (
      await getDocs(
        collection(
          this.db.firestore,
          `/township/${this.townshipId}/addresTypes`
        )
      )
    ).docs;
    addressTypeDocs.forEach((addressTypeDoc) => {
      const addressType = addressTypeDoc.data();
      this.allAddressTypes.push(addressType.type);
    });
    this.filteredAddressTypes =
      this.voucherGroupFormVoucherOptions.controls.addressTypesCtrl.valueChanges.pipe(
        map((addressType: string | null) =>
          addressType
            ? this.filterAddressType(addressType)
            : this.allAddressTypes.slice()
        )
      );

    const voucherGroupDocs = (
      await getDocs(
        collection(
          this.db.firestore,
          `township/${this.townshipId}/voucherGroups`
        )
      )
    ).docs;
    voucherGroupDocs.forEach((voucherGroupDoc) => {
      const voucherGroup = voucherGroupDoc.data() as VoucherGroup;
      this.voucherGroups.push({ ...voucherGroup, id: voucherGroupDoc.id });
    });
    this.sortVoucherGroups();
    if (
      !this.data.newGroup &&
      this.data.voucherGroup.autoVoucherRequestVoucherGroup
    ) {
      const voucherGroup = this.voucherGroups.find((voucherGroup) => {
        if (
          voucherGroup.id ===
          this.data.voucherGroup.autoVoucherRequestVoucherGroup
        ) {
          return voucherGroup;
        }
      });
      this.voucherGroupFormVoucherOptions.patchValue({
        autoVoucherRequestVoucherGroup: voucherGroup,
      });
    }

    this.voucherGroupTagsCollection = this.db.collection<Tag>(
      `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}/tags`
    );
    this.voucherGroupTags = this.voucherGroupTagsCollection
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Tag;
            data.id = a.payload.doc['id'];
            return data;
          })
        ),
        take(1)
      );
    this.voucherGroupTags.subscribe((value) => {
      value.forEach((tag) => {
        this.selectedTags.push(tag);
      });
    });
    this.allTagsCollection = this.db.collection<Tag>(
      `township/${this.townshipId}/tags`
    );
    this.allTags = this.allTagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          data.id = a.payload.doc['id'];
          return data;
        })
      ),
      take(1)
    );
    const combinedFilter = combineLatest([
      this.tagCtrl.valueChanges,
      this.allTags,
    ]);
    combinedFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        const filteredTags = [];
        const input = values[0];
        const allTags = values[1];
        allTags.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });
        this.filteredTags.next(filteredTags);
      });
    this.tagCtrl.setValue('');

    const addressRef = this.db
      .collection<Township>(`township/${this.townshipId}/addresTypes`)
      .get();
    addressRef.subscribe(async (addresses: any) => {
      addresses.forEach((adres) => {
        const data = adres.data() as any;
        this.addressesType.push(
          data.type.charAt(0).toUpperCase() + data.type.slice(1)
        );
      });
      this.addressesType = [...new Set(this.addressesType)];
    });

    if (
      this.townshipData.invoiceInfo &&
      this.townshipData.invoiceInfo.bankAccount
    ) {
      this.paymentSetup = true;
      if (this.data.newGroup) {
        if (this.townshipData.primaryColor) {
          this.voucherGroupFormDesign.controls.primaryColor.setValue(
            this.townshipData.primaryColor
          );
        }
        if (this.townshipData.accentColor) {
          this.voucherGroupFormDesign.controls.accentColor.setValue(
            this.townshipData.accentColor
          );
        }
      }
    }
    if (this.townshipData.paymentSystem === 'mollie') {
      this.availablePaymentProviders.push({
        value: 'mollie',
        display: 'Mollie',
      });
    }
    if (this.townshipData.paymentSystem === 'ideal') {
      this.availablePaymentProviders.push({
        value: 'ideal',
        display: 'iDEAL',
      });
    }
    if (this.townshipData.paymentSystem === 'sisow') {
      this.availablePaymentProviders.push({
        value: 'sisow',
        display: 'Sisow',
      });
    }

    if (this.data.voucherGroup) {
      console.log('this.data.voucherGroup', this.data.voucherGroup);
      const patchObj = { ...this.data.voucherGroup } as any;
      if (this.data.voucherGroup.validUntilDate) {
        patchObj.validUntilDate =
          this.data.voucherGroup.validUntilDate.toDate();
      }
      if (patchObj.useCustomPdfTemplate) {
        this.pdfFileAlreadyUploaded = true;
      }
      if (patchObj.useHeaderImage) {
        this.headerImgFileAlreadyUploaded = true;
        this.headerImgUrl = this.data.voucherGroup.headerImageUrl;
      }
      if (patchObj.imageUrl) {
        this.imageFileAlreadyUploaded = true;
        this.imageUrl = this.data.voucherGroup.imageUrl;
      }
      if (patchObj.usesArrangement) {
        this.arrangementFileAlreadyUploaded = true;
        this.arrangementFileUrl = patchObj.arrangementPdfUrl;
      }
      if (this.data.voucherGroup.totalVouchers > 0) {
        this.voucherGroupFormVoucherOptions.controls.forceSpentFullAmount.disable();
        this.voucherGroupFormVoucherOptions.controls.dontSyncWithApi.disable();
      }
      if (patchObj.startDate) {
        patchObj.startDate = this.data.voucherGroup.startDate.toDate();
      }
      if (patchObj.endDate) {
        patchObj.endDate = this.data.voucherGroup.endDate.toDate();
      }
      if (this.data.voucherGroup.burdenOfProofUntilDate) {
        patchObj.burdenOfProofUntilDate =
          this.data.voucherGroup.burdenOfProofUntilDate.toDate();
      }
      const selectedSubsidyOptions = [];
      if (this.data.voucherGroup.subsidyOptions) {
        this.data.voucherGroup.subsidyOptions.forEach((id) => {
          selectedSubsidyOptions.push(
            this.subsidyOptions.find((subOption) => {
              return subOption.id === id;
            })
          );
        });
      }
      this.voucherGroupFormCampaign.patchValue(patchObj);
      this.voucherGroupFormVoucher.patchValue(patchObj);
      this.voucherGroupFormVoucherOptions.patchValue(patchObj);
      this.voucherGroupFormMessaging.patchValue(patchObj);
      this.voucherGroupFormDesign.patchValue(patchObj);
      this.selectedSubsidyOptions = selectedSubsidyOptions;
      this.addressTypes = this.data.voucherGroup.autoAddressTypes ?? [];
    }
  }

  setupControllers() {
    if (this.voucherGroupFormVoucher.controls.validUntilTime.value) {
      this.voucherGroupFormVoucher.get('validUntilTimeType').enable();
      this.voucherGroupFormVoucher.get('validUntilTimeValue').enable();
    }
    this.voucherGroupFormVoucher.controls.validUntilTime.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.voucherGroupFormVoucher.get('validUntilTimeType').enable();
          this.voucherGroupFormVoucher.get('validUntilTimeValue').enable();
        } else {
          this.voucherGroupFormVoucher.get('validUntilTimeType').disable();
          this.voucherGroupFormVoucher.get('validUntilTimeValue').disable();
        }
      }
    );
    if (this.data.voucherGroup?.totalVouchers > 0) {
      this.voucherGroupFormCampaign.get('usesExternalVouchers').disable();
    }
    if (this.voucherGroupFormVoucherOptions.controls.requestPhone.value) {
      this.voucherGroupFormVoucherOptions.get('verifyPhone').enable();
      this.voucherGroupFormVoucherOptions.get('sendSmsCoupon').enable();
    }
    this.voucherGroupFormVoucherOptions.controls.requestPhone.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.voucherGroupFormVoucherOptions.get('verifyPhone').enable();
          this.voucherGroupFormVoucherOptions.get('sendSmsCoupon').enable();
        } else {
          this.voucherGroupFormVoucherOptions
            .get('verifyPhone')
            .setValue(false);
          this.voucherGroupFormVoucherOptions.get('verifyPhone').disable();
          this.voucherGroupFormVoucherOptions
            .get('sendSmsCoupon')
            .setValue(false);
          this.voucherGroupFormVoucherOptions.get('sendSmsCoupon').disable();
        }
      }
    );
    if (this.voucherGroupFormVoucherOptions.controls.noAddressChecks.value) {
      this.voucherGroupFormVoucherOptions.get('dontRequestAddress').enable();
    }
    this.voucherGroupFormVoucherOptions.controls.noAddressChecks.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.voucherGroupFormVoucherOptions
            .get('dontRequestAddress')
            .enable();
        } else {
          this.voucherGroupFormVoucherOptions
            .get('dontRequestAddress')
            .setValue(false);
          this.voucherGroupFormVoucherOptions
            .get('dontRequestAddress')
            .disable();
        }
      }
    );
    this.voucherGroupFormVoucherOptions.controls.sellType.valueChanges.subscribe(
      (value) => {
        if (value === 'physical') {
          this.voucherGroupFormVoucherOptions.get('personalisedNote').enable();
        } else {
          this.voucherGroupFormVoucherOptions.get('personalisedNote').disable();
        }
      }
    );
    if (this.voucherGroupFormMessaging.controls.voucherReminderMail.value) {
      this.voucherGroupFormMessaging.get('amountVoucherReminder').enable();
    }
    this.voucherGroupFormMessaging.controls.voucherReminderMail.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.voucherGroupFormMessaging.get('amountVoucherReminder').enable();
        } else {
          this.voucherGroupFormMessaging.get('amountVoucherReminder').disable();
        }
      }
    );
    this.voucherGroupFormVoucherOptions.controls.autoVoucherRequest.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.voucherGroupFormVoucherOptions.controls.autoVoucherRequestVoucherGroup.addValidators(
            Validators.required
          );
        } else {
          this.voucherGroupFormVoucherOptions.controls.autoVoucherRequestVoucherGroup.removeValidators(
            Validators.required
          );
          this.voucherGroupFormVoucherOptions.controls.autoVoucherRequestVoucherGroup.updateValueAndValidity();
        }
      }
    );
    if (this.voucherGroupFormVoucherOptions.controls.sendBurdenOfProof.value) {
      this.voucherGroupFormVoucherOptions.controls.burdenOfProofUntilDate.addValidators(
        Validators.required
      );
    }
    this.voucherGroupFormVoucherOptions.controls.sendBurdenOfProof.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.voucherGroupFormVoucherOptions.controls.burdenOfProofUntilDate.addValidators(
            Validators.required
          );
        } else {
          this.voucherGroupFormVoucherOptions.controls.burdenOfProofUntilDate.removeValidators(
            Validators.required
          );
          this.voucherGroupFormVoucherOptions.controls.burdenOfProofUntilDate.updateValueAndValidity();
        }
      }
    );
    if (this.voucherGroupFormVoucherOptions.controls.dontRequestAddress.value) {
      this.voucherGroupFormVoucherOptions.controls.autoAddressTypeAllotment.setValue(
        false
      );
    }
    this.voucherGroupFormVoucherOptions.controls.dontRequestAddress.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.voucherGroupFormVoucherOptions.controls.autoAddressTypeAllotment.setValue(
            false
          );
        }
      }
    );
    this.voucherGroupFormVoucherOptions.controls.autoAddressTypeAllotment.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.voucherGroupFormVoucherOptions.controls.autoAddressTypes.addValidators(
            this.autoAddressValidator
          );
        } else {
          this.voucherGroupFormVoucherOptions.controls.autoAddressTypes.removeValidators(
            this.autoAddressValidator
          );
          this.voucherGroupFormVoucherOptions.controls.autoAddressTypes.updateValueAndValidity();
        }
      }
    );
  }

  openFileInput(id) {
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }

  setDelete(type: string): void {
    let name: string = '';
    switch (type) {
      case 'pdf':
        name = 'PDF';
        this.deletePdf = true;
        break;
      case 'image':
        name = 'Afbeelding';
        this.deleteImage = true;
        break;
      case 'headerImg':
        name = 'Header afbeelding';
        this.deleteHeaderImg = true;
        break;
      case 'arrangement':
        name = 'Extra PDF';
        this.deleteArrangementFile = true;
        break;
    }
    this.snackBar.open(
      `Druk op opslaan om de ${name} definitief te verwijderen`,
      'X',
      {
        duration: 5000,
      }
    );
  }

  deleteFile(type: string): void {
    let segment;
    let name: string = '';
    let extension = '';
    switch (type) {
      case 'pdf':
        name = 'PDF';
        segment = 'pdf-templates';
        extension = '.pdf';
        break;
      case 'headerImg':
        name = 'E-mail header';
        segment = 'images';
        break;
    }
    const fileUrl = `${this.townshipId}/${segment}/${this.voucherGroupId}${extension}`;
    const file = this.storage.ref(fileUrl).delete();
    file.subscribe((result) => {
      this.snackBar.open(`${name} verwijderd`, 'X', {
        duration: 5000,
      });
    });
  }

  async openFile(type: string): Promise<any> {
    switch (type) {
      case 'pdf':
        let fileUrl = 'pdf-templates/groene-bon.pdf';
        if (this.pdfFileUploaded || this.pdfFileAlreadyUploaded) {
          fileUrl = `${this.townshipId}/pdf-templates/${this.voucherGroupId}.pdf`;
        }
        const file = this.storage.ref(fileUrl).getDownloadURL();
        file.subscribe((url) => {
          window.open(url);
        });
        break;
      case 'image':
        if (this.imageFileAlreadyUploaded) {
          window.open(this.imageUrl);
        }
        break;
      case 'headerImg':
        if (this.headerImgFileAlreadyUploaded) {
          window.open(this.headerImgUrl);
        }
        break;
      case 'arrangement':
        if (this.arrangementFileUrl) {
          window.open(this.arrangementFileUrl);
        }
        break;
    }
  }

  uploadedFile(event, id) {
    const file = event.target.files[0] as File;
    if (!file) {
      return;
    }
    switch (id) {
      case 'vouchers':
        this.vouchersFileUploaded = file;
        console.log('file.name', file.name);
        var fileExt = file.name.split('.').pop();
        this.worksheet = null;
        this.json = null;
        switch (fileExt) {
          case 'xlsx':
            this.readExcel();
            break;
          case 'json':
            this.readJson();
            break;
        }
        break;
      case 'pdf':
        if (file.size > 5120000) {
          this.snackBar.open('Dit bestand moet onder de 5 MB zijn.', 'X', {
            duration: 5000,
          });
          return;
        }
        const dialogRef = this.dialog.open(SelectPdfFontsComponent, {
          width: '373px',
          data: { file: file },
        });
        dialogRef.afterClosed().subscribe(async (result) => {
          if (result) {
            this.pdfFonts = result;
            this.pdfFileUploaded = file;
          } else {
            event.target.value = null;
          }
        });
        break;
      case 'image':
        if (file.size > 2048000) {
          this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
            duration: 5000,
          });
          return;
        }
        this.imageFileUploaded = file;
        break;
      case 'headerImg':
        if (file.size > 2048000) {
          this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
            duration: 5000,
          });
          return;
        }
        this.headerImgFileUploaded = file;
        break;
      case 'arrangement':
        if (file.size > 5120000) {
          this.snackBar.open('Dit bestand moet onder de 5 MB zijn.', 'X', {
            duration: 5000,
          });
          return;
        }
        this.arrangementFileUploaded = file;
        break;
    }
  }

  readJson() {
    const readFile = new FileReader();

    readFile.onload = (e) => {
      console.log('e', e.target.result);
      let lines = e.target.result as string;
      this.json = JSON.parse(lines);
      console.log('json', this.json);
    };

    readFile.readAsText(this.vouchersFileUploaded);
  }

  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = e.target.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];
    };
    readFile.readAsArrayBuffer(this.vouchersFileUploaded);
  }

  generateVouchers(type: string) {
    this.generating = true;
    const ref = this.db.doc<Township>(`township/${this.townshipId}`).get();
    ref.subscribe((result) => {
      const data = result.data();
      const planId =
        typeof data.plan !== 'undefined' && data.plan.id ? data.plan.id : null;

      this.voucherPrefix = data.prefix;
      this.generatedVouchers = [];

      const dialogRef = this.dialog.open(GenerateVouchersComponent, {
        width: '350px',
        data: { voucherGroupId: this.voucherGroupId, type: type },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (planId === 'free' && result > 10) {
          this.dialog.open(UpgradeDialogComponent, {
            width: '500px',
          });
          return;
        }
        if (typeof result === 'undefined') {
          this.generating = false;
          return;
        }
        this.generated = true;
        this.generating = false;
        if ((type = 'external')) {
          this.shouldGetExternalVouchers = true;
        }
        this.voucherGroupFormCampaign.get('usesExternalVouchers').disable();
        this.vouchersToBeGenerated = result;
      });
    });
  }

  async save() {
    if (this.saving || !this.validateForms()) {
      return;
    }
    this.saving = true;
    const saveObj = {
      ...this.voucherGroupFormCampaign.value,
      ...this.voucherGroupFormVoucher.value,
      ...this.voucherGroupFormVoucherOptions.value,
      ...this.voucherGroupFormMessaging.value,
      ...this.voucherGroupFormDesign.value,
    };
    const saveSelectedSubsidyOptions = [];
    this.selectedSubsidyOptions.forEach((subOption) => {
      saveSelectedSubsidyOptions.push(subOption.id);
    });
    saveObj.subsidyOptions = saveSelectedSubsidyOptions;
    if (!saveObj.autoVoucherRequest) {
      saveObj.autoVoucherRequestVoucherGroup = null;
    }
    if (
      this.voucherGroupFormCampaign.controls.usesExternalVouchers?.status ===
      'DISABLED'
    ) {
      saveObj.usesExternalVouchers =
        this.voucherGroupFormCampaign.controls.usesExternalVouchers.value;
    }

    if (saveObj.termsURL && !this.urlCheck(saveObj.termsURL, 'terms')) {
      this.snackBar.open('De voorwaarden URL is geen URL', 'X', {
        duration: 5000,
      });
      this.saving = false;
      return;
    }
    if (
      saveObj.redirectUrl &&
      !this.urlCheck(saveObj.redirectUrl, 'redirect')
    ) {
      this.snackBar.open('De redirect URL is geen URL', 'X', {
        duration: 5000,
      });
      this.saving = false;
      return;
    }
    if (
      saveObj.questionaireLink &&
      !this.urlCheck(saveObj.questionaireLink, 'questionaire')
    ) {
      this.snackBar.open('De vragenlijst URL is geen URL', 'X', {
        duration: 5000,
      });
      this.saving = false;
      return;
    }

    if (this.generated) {
      this.snackBar.open('Bonnen worden gegenereerd, even geduld a.u.b.', 'X', {
        duration: 5000,
      });
      const generationBatch = [];
      generationBatch.push(this.db.firestore.batch());
      let operationCounter = 0;
      let batchIndex = 0;
      this.generating = true;
      const totalVouchersCountRef = collection(
        this.db.firestore,
        `township/${this.townshipId}/vouchers`
      );
      const totalVouchersCount = (
        await getCountFromServer(totalVouchersCountRef)
      ).data().count;
      console.log('totalVouchersCount', totalVouchersCount);
      let latestNumber = totalVouchersCount;
      latestNumber += 1;

      const totalNumber = this.vouchersToBeGenerated;
      const suffix = saveObj.suffix;
      do {
        const newVoucherName =
          this.voucherPrefix.toUpperCase() +
          this.hashids.encodeHex(latestNumber.toString()) +
          suffix.toUpperCase();
        let newVoucher = {} as Voucher;
        newVoucher = {
          number: newVoucherName,
          activateDate: null,
          claimDate: null,
          paidDate: null,
          reminderSend: false,
          voucherGroupId: this.voucherGroupId,
          originalValue: saveObj.couponValue,
          value: saveObj.couponValue,
          distributed: null,
        };
        latestNumber += 1;
        this.generatedVouchers.push(newVoucher);

        const ref = this.db
          .collection(`township/${this.townshipId}/vouchers`)
          .doc(newVoucher.number);
        generationBatch[batchIndex].set(ref.ref, newVoucher);
        operationCounter++;

        if (operationCounter == 500) {
          generationBatch.push(this.db.firestore.batch());
          batchIndex++;
          operationCounter = 0;
        }
      } while (this.generatedVouchers.length !== totalNumber);
      this.generating = false;
      this.generated = true;
      generationBatch.forEach(async (batch) => {
        await batch.commit();
      });
    }

    if (this.deletePdf) {
      this.deleteFile('pdf');
    }

    if (this.deleteHeaderImg) {
      this.deleteFile('headerImg');
    }
    const tagBatch = this.db.firestore.batch();

    if (!saveObj.maxVouchers && this.maxVouchers) {
      saveObj.maxVouchers = this.maxVouchers;
    }
    if (!saveObj.noAddressChecks) {
      saveObj.dontRequestAddress = false;
    }
    if (saveObj.dontRequestAddress) {
      saveObj.autoAddressTypes = [];
    }
    if (!saveObj.verifyPhone) {
      saveObj.verifyPhone = false;
    }
    if (saveObj.ambitionLevel === 'none') {
      saveObj.ambitionLevel = null;
    }
    if (saveObj.costCenter) {
      saveObj.costCenter = saveObj.costCenter.trim().toUpperCase();
    }

    if (!saveObj.validUntilTime) {
      saveObj.validUntilTimeType = deleteField();
      saveObj.validUntilTimeValue = deleteField();
    }

    if (saveObj.validUntilDate) {
      saveObj.validUntilDate = new Date(
        saveObj.validUntilDate.setHours(23, 59)
      );
    }

    if (saveObj.burdenOfProofUntilDate) {
      saveObj.burdenOfProofUntilDate = new Date(
        saveObj.burdenOfProofUntilDate.setHours(23, 59)
      );
    }

    // Check tags for changes
    await this.voucherGroupTags.forEach(async (tags) => {
      tags.forEach(async (tag) => {
        const foundTag = await this.selectedTags.find((lfTag) => {
          if (lfTag.id === tag.id) {
            return lfTag;
          }
        });
        if (!foundTag) {
          tagBatch.delete(
            this.db.doc(
              `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}/tags/${tag.id}`
            ).ref
          );
        }
      });
    });
    saveObj.tagIds = [];
    if (this.selectedTags) {
      this.selectedTags.forEach((tag) => {
        saveObj.tagIds.push(tag.id);
        tagBatch.set(
          this.db.doc(
            `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}/tags/${tag.id}`
          ).ref,
          tag
        );
      });
    }
    if (saveObj.validUntilDate) {
      const validUntilDateInSeconds = saveObj.validUntilDate.setHours(23, 59);
      saveObj.validUntilDate = Timestamp.fromMillis(validUntilDateInSeconds);
    }

    // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = deleteField();
      }
    });

    if (saveObj.voucherReminderTime === 0) {
      saveObj.voucherReminderTime = deleteField();
    }

    let vouchersFileError;
    const voucherPromises = [];
    if (this.json) {
      const dateStringKeys = [
        'activateDate',
        'claimDate',
        'paidDate',
        'distributedDate',
        'validUntilDate',
        'appointMentDate',
      ];
      this.json.forEach((voucher: Voucher) => {
        if (!isNaN(voucher.value) && voucher.number) {
          const voucherRef = this.db
            .collection(`township/${this.townshipId}/vouchers`)
            .doc(voucher.number);
          voucher.voucherGroupId = this.voucherGroupId; // Updates voucherGroupId to this voucherGroup
          Object.keys(voucher).forEach((key) => {
            const value = voucher[key];
            if (moment(value).isValid() && dateStringKeys.includes(key)) {
              // Transforms Datestring into Date
              voucher[key] = moment(value).toDate();
            }
          });
          console.log('voucher', voucher);
          voucherPromises.push(voucherRef.set(voucher, { merge: true }));
        } else if (voucher.number) {
          vouchersFileError = true;
        }
      });
    }
    if (this.worksheet) {
      const spreadsheet = {};
      Object.keys(this.worksheet).forEach((key) => {
        try {
          if (
            key !== '!ref' &&
            key !== '!margins' &&
            key !== '!autofilter' &&
            key !== '!merges'
          ) {
            const rowId = key.match(/\d+/g).toString();
            const colId = key.match(/[a-zA-Z]+/g).toString();
            if (!spreadsheet[rowId]) {
              spreadsheet[rowId] = {};
            }
            spreadsheet[rowId][colId] = this.worksheet[key].w;
          }
        } catch (error) {
          console.log('key with error:', key);
          console.error(error);
        }
      });
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'vouchernummer':
            columnNames[key] = 'number';
            break;
          case 'huidige waarde':
            columnNames[key] = 'value';
            break;
          case 'originele waarde':
            columnNames[key] = 'originalValue';
            break;
          case 'e-mail':
            columnNames[key] = 'email';
            break;
          case 'te betalen waarde':
            columnNames[key] = 'amountToPayOrg';
            break;
          case 'waarde kassabon':
            columnNames[key] = 'receiptValue';
            break;
          case 'postcode':
            columnNames[key] = 'postal';
            break;
          case 'kassabon/factuur url':
            columnNames[key] = 'imageUrl';
            break;
          case 'huisnummer':
            columnNames[key] = 'houseNumber';
            break;
          case 'toevoeging':
            columnNames[key] = 'houseNumberAddition';
            break;
          case 'kenmerk':
            columnNames[key] = 'type';
            break;
          case 'type':
            columnNames[key] = 'type';
            break;
          case 'uitgegeven':
            columnNames[key] = 'distributed';
            break;
          case 'claimbedrijf':
            columnNames[key] = 'claimOrganisation';
            break;
          case 'claimdatum':
            columnNames[key] = 'claimDate';
            break;
          case 'activatiebedrijf':
            columnNames[key] = 'activateOrganisation';
            break;
          case 'activatiedatum':
            columnNames[key] = 'activateDate';
            break;
          case 'betaaldatum':
            columnNames[key] = 'paidDate';
            break;
          case 'vervaldatum':
            columnNames[key] = 'validUntilDate';
            break;
          case 'herinnering verstuurd':
            columnNames[key] = 'reminderSend';
            break;
          case 'opmerkingen':
            columnNames[key] = 'notes';
            break;
          case 'straat':
            columnNames[key] = 'street';
            break;
          case 'woonplaats':
            columnNames[key] = 'city';
            break;
          case 'kostenplaats':
            columnNames[key] = 'costCenter';
            break;
          case 'verzilveringswaarde':
            columnNames[key] = 'claimAmount';
            break;
          case 'opwaardeerwaarde':
            columnNames[key] = 'topUpAmount';
            break;
          case 'retourwaarde':
            columnNames[key] = 'refundAmount';
            break;
        }
      });
      delete spreadsheet[1];
      Object.keys(spreadsheet).forEach((key) => {
        const rowObj = {} as Voucher;
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          if (colName && spreadsheet[key][colKey].length !== 0) {
            rowObj[colName] = spreadsheet[key][colKey];
            // now check if current field needs extra processing, overriding rowObj2
            if (
              colName === 'value' ||
              colName === 'amountToPayOrg' ||
              colName === 'receiptValue' ||
              colName === 'originalValue'
            ) {
              rowObj[colName] = Number(spreadsheet[key][colKey]);
            }
            if (
              colName === 'activateDate' ||
              colName === 'claimDate' ||
              colName === 'paidDate' ||
              colName === 'validUntilDate'
            ) {
              const dateArray = spreadsheet[key][colKey].split('-');
              const dateString =
                dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
              const timestamp = Date.parse(dateString);
              let date = null;
              if (isNaN(timestamp) === false) {
                if (colName === 'activateDate') {
                  date = new Date(new Date(timestamp).setHours(0o0, 0o0));
                } else {
                  date = new Date(new Date(timestamp).setHours(23, 59));
                }
              } else {
                console.log('date failure on this voucher:', rowObj);
                vouchersFileError = true;
              }
              rowObj[colName] = date;
              console.log('date', date);
            }
            if (colName === 'claimOrganisation') {
              rowObj[colName] = spreadsheet[key][colKey];
            }
            if (colName === 'type') {
              rowObj[colName] = spreadsheet[key][colKey].split();
            }
          }
        });

        // These line puts undefined dates to null so that it can be queried on with where
        if (!rowObj.activateDate) {
          rowObj.activateDate = null;
        }
        if (!rowObj.claimDate) {
          rowObj.claimDate = null;
        }
        if (!rowObj.paidDate) {
          rowObj.paidDate = null;
        }
        if (!rowObj.distributed) {
          rowObj.distributed = null;
          rowObj.activateFromType = false;
        } else {
          rowObj.distributed = true;
        }
        if (!rowObj.reminderSend) {
          rowObj.reminderSend = false;
        } else {
          rowObj.reminderSend = true;
        }

        if (!rowObj.city) {
          rowObj.city = null;
        }
        if (!rowObj.street) {
          rowObj.street = null;
        }
        if (!rowObj.postal) {
          rowObj.postal = null;
        }
        if (!rowObj.houseNumber) {
          rowObj.houseNumber = null;
        }
        if (!rowObj.houseNumberAddition) {
          rowObj.houseNumberAddition = null;
        }
        if (!rowObj.type) {
          rowObj.type = null;
        }
        if (!rowObj.costCenter) {
          rowObj.costCenter = null;
        }

        rowObj.voucherGroupId = this.voucherGroupId;

        // Only upload voucher if it has both of the required values
        if (!isNaN(rowObj.value) && rowObj.number) {
          const voucherRef = this.db
            .collection(`township/${this.townshipId}/vouchers`)
            .doc(rowObj['number']);

          voucherPromises.push(voucherRef.set(rowObj, { merge: true }));
        } else if (rowObj.number) {
          vouchersFileError = true;
        }
      });
    }
    if (!vouchersFileError) {
      // Upload files
      if (this.pdfFileUploaded) {
        const filePath = `${this.townshipId}/pdf-templates/${this.voucherGroupId}.pdf`;
        await this.storage.upload(filePath, this.pdfFileUploaded);
        saveObj.useCustomPdfTemplate = true;
        saveObj.pdfFonts = this.pdfFonts;
      }
      if (this.arrangementFileUploaded) {
        const filePath = `${this.townshipId}/pdf-templates/${this.voucherGroupId}-arrangement.pdf`;
        saveObj.arrangementPdfName = this.arrangementFileUploaded.name;
        const uploadTask = await this.storage.upload(
          filePath,
          this.arrangementFileUploaded
        );
        saveObj.usesArrangement = true;
        saveObj.arrangementPdfUrl = await uploadTask.ref.getDownloadURL();
      }
      if (this.headerImgFileUploaded) {
        const filePath = `${this.townshipId}/images/${
          this.voucherGroupId
        }-headerImage.${getFileExtension(this.headerImgFileUploaded)}`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.headerImgFileUploaded
        );
        saveObj.headerImageUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useHeaderImage = true;
      }
      if (this.imageFileUploaded) {
        const filePath = `${this.townshipId}/images/${
          this.voucherGroupId
        }-image.${getFileExtension(this.imageFileUploaded)}`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.imageFileUploaded
        );
        saveObj.imageUrl = await uploadTask.ref.getDownloadURL();
      }

      if (this.deletePdf) {
        saveObj.useCustomPdfTemplate = false;
      }
      if (this.deleteHeaderImg) {
        saveObj.headerImageUrl = '';
        saveObj.useHeaderImage = false;
      }
      if (this.deleteImage) {
        saveObj.imageUrl = '';
      }
      if (this.deleteArrangementFile) {
        saveObj.arrangementPdfUrl = '';
        saveObj.arrangementPdfName = '';
        saveObj.usesArrangement = false;
      }

      if (saveObj.activateFromType !== 'activateOnDate') {
        saveObj.usableFrom = null;
      }

      if (this.shouldGetExternalVouchers) {
        saveObj.externalVoucherGroupId =
          this.townshipData.externalVoucherGroupId;
      }
      await this.db
        .collection(`township/${this.townshipId}/voucherGroups/`)
        .doc(this.voucherGroupId)
        .set(saveObj, { merge: true });
      await tagBatch.commit();
      await Promise.all(voucherPromises);
      if (voucherPromises.length > this.maxVouchers - 1) {
        this.dialog.open(UpgradeDialogComponent, {
          width: '500px',
        });
      }
      if (this.shouldGetExternalVouchers) {
        const requestUrl = `${environment.functionsUrl}/httpGetExternalVouchers`;
        this.http
          .post(requestUrl, {
            townshipId: this.townshipId,
            voucherGroupId: this.voucherGroupId,
            externalVoucherGroupId: this.townshipData.externalVoucherGroupId,
            amount: this.vouchersToBeGenerated,
            value: this.voucherGroupFormVoucher.value.couponValue,
          })
          .subscribe((response) => {
            console.log('res', response);
            this.dialogRef.close();
          });
      } else {
        this.dialogRef.close();
      }
    } else {
      this.snackBar.open(
        'Eén of meer vouchers bevatten niet alle vereisde velden: waarde of vouchernummer.',
        'X',
        {
          duration: 5000,
        }
      );
      this.saving = false;
    }
    this.generated = false;
  }

  isObject(obj) {
    return obj === Object(obj);
  }

  urlCheck(saveObj: string, controller: string) {
    let invalidUrl = false;

    const expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const regex = new RegExp(expression);

    if (!saveObj.match(regex)) {
      invalidUrl = true;
    }

    if (invalidUrl) {
      switch (controller) {
        case 'terms':
          this.voucherGroupFormVoucher.controls.termsURL.setErrors({
            notMatched: true,
          });
          break;
        case 'redirect':
          this.voucherGroupFormVoucherOptions.controls.redirectUrl.setErrors({
            notMatched: true,
          });
          break;
        case 'questionaire':
          this.voucherGroupFormVoucherOptions.controls.questionaireLink.setErrors(
            {
              notMatched: true,
            }
          );
          break;
      }
      this.saving = false;
      return false;
    } else {
      return true;
    }
  }

  validateForms() {
    let formValid: boolean = true;
    let goto: number = 0;
    this.voucherGroupFormCampaign.markAllAsTouched();
    this.voucherGroupFormVoucher.markAllAsTouched();
    this.voucherGroupFormVoucherOptions.markAllAsTouched();
    this.voucherGroupFormMessaging.markAllAsTouched();
    this.voucherGroupFormDesign.markAllAsTouched();

    if (!this.voucherGroupFormDesign.valid) {
      formValid = false;
      goto = 4;
    }
    if (!this.voucherGroupFormMessaging.valid) {
      formValid = false;
      goto = 3;
    }
    if (!this.voucherGroupFormVoucherOptions.valid) {
      formValid = false;
      goto = 2;
    }
    if (!this.voucherGroupFormVoucher.valid) {
      formValid = false;
      goto = 1;
    }
    if (!this.voucherGroupFormCampaign.valid) {
      formValid = false;
      goto = 0;
    }

    if (!formValid) {
      this.currentTab = goto;
      this.snackBar.open('Niet alle velden zijn correct ingevuld', 'X', {
        duration: 5000,
      });
    }

    return formValid;
  }
}
