// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.normal-title {
  margin-bottom: 0;
}

mat-dialog-content {
  height: 100%;
  padding: 24px 0 0 0 !important;
  color: black !important;
}

mat-dialog-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (min-width: 1200px) {
  .dialog-wrapper {
    margin: 0 12%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/planning/dialogs/appointment-details/appointment-details.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,8BAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":[".normal-title {\n  margin-bottom: 0;\n}\n\nmat-dialog-content {\n  height: 100%;\n  padding: 24px 0 0 0 !important;\n  color: black !important;\n}\n\nmat-dialog-actions {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n@media screen and (min-width: 1200px) {\n  .dialog-wrapper {\n    margin: 0 12%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
