import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  doc,
  getDoc,
  setDoc,
  getDocs,
  collection,
  query,
  where,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
  Organisation,
  PaymentReferences,
  Township,
  User,
  Voucher,
  VoucherGroup,
} from 'src/app/interfaces';
import { MatSelectionList } from '@angular/material/list';
import { HttpClient } from '@angular/common/http';
import { MatStepper } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SepaConfirmationComponent } from '../sepa-confirmation/sepa-confirmation.component';
import * as XLSX from 'xlsx';
import { FileSaverService } from 'ngx-filesaver';
import * as xmlbuilder from 'xmlbuilder2';
import * as sha1 from 'js-sha1';
import moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import { getBytes, getStorage, ref } from 'firebase/storage';

@Component({
  selector: 'app-sepa',
  templateUrl: './sepa.component.html',
  styleUrls: ['./sepa.component.scss'],
})
export class SepaComponent implements OnInit {
  costCenterFormGroup: FormGroup;
  sepaFormGroup: FormGroup;
  dateFormGroup: FormGroup;
  downloadFormGroup: FormGroup;
  user: User;
  paymentReferenceData: PaymentReferences;
  organisations: Organisation[] = [];
  townshipId = localStorage.getItem('township');
  allCostCenters: string[] = [];

  selectTxt: string;
  selectedOrganisations: any[] = [];
  isAllSelected = false;

  dateNow = moment();
  dateString = this.dateNow.format('DD-MM-YYYY.HH:mm:ss');
  paymentReferenceId = '';
  allOrganisations: Organisation[] = [];

  loading: boolean;
  checkboxValue: string;
  responseData: any;
  displayedColumns: string[] = ['Ondernemer', 'Aantal', 'Bedrag'];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  sepaSettingsComplete: boolean;
  env = environment;

  downloadXml: any;
  townshipData: Township;
  checkSum: string;

  @ViewChild('selectedOrganisations', { static: true })
  private allSelected: MatSelectionList;
  @ViewChild('stepper')
  private stepper: MatStepper;
  stepIndex = 0;
  checkedDate: Date;
  constructor(
    private fb: FormBuilder,
    private db: AngularFirestore,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<SepaComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { paymentReference: PaymentReferences },
    private _FileSaverService: FileSaverService // private xml2js: xml2js
  ) {
    if (data.paymentReference) {
      this.stepIndex = 3;
    }
  }

  async ngOnInit() {
    this.costCenterFormGroup = this.fb.group({
      costCenters: [[], Validators.required],
    });

    this.sepaFormGroup = this.fb.group({
      selectedOrganisations: [, Validators.required],
      selectAll: [],
    });

    this.dateFormGroup = this.fb.group({
      paymentReferenceId: [this.paymentReferenceId],
      selectedDate: [this.dateNow.add(7, `days`).toDate()],
    });
    this.downloadFormGroup = this.fb.group({
      paymentReferenceId: [this.paymentReferenceId],
      excel: [true],
      sepa: [],
      invoice: [false],
      // markVouchersAsPaid: [],
    });

    const voucherGroups = await getDocs(
      collection(this.db.firestore, `township/${this.townshipId}/voucherGroups`)
    );
    voucherGroups.forEach((voucherGroupDoc) => {
      const voucherGroup = voucherGroupDoc.data() as VoucherGroup;
      if (
        voucherGroup.costCenter &&
        !this.allCostCenters.includes(voucherGroup.costCenter)
      ) {
        this.allCostCenters.push(voucherGroup.costCenter);
      }
    });

    const userId = getAuth().currentUser.uid;
    this.user = (
      await getDoc(doc(this.db.firestore, `users/${userId}`))
    ).data() as User;

    if (this.data.paymentReference) {
      this.paymentReferenceId = this.data.paymentReference.id;
      this.loading = true;
      this.paymentReferenceData = (
        await getDoc(
          doc(
            this.db.firestore,
            `township/${this.townshipId}/paymentReferences/${this.paymentReferenceId}`
          )
        )
      ).data() as PaymentReferences;
      if (this.paymentReferenceData.organisations) {
        for (const organisation of this.paymentReferenceData.organisations) {
          const organisationData = (
            await getDoc(
              doc(
                this.db.firestore,
                `township/${this.townshipId}/organisations/${organisation.organisationId}`
              )
            )
          ).data() as Organisation;
          organisationData.id = organisation.organisationId;
          this.selectedOrganisations.push(organisationData);
        }
      }
      this.createTable(this.paymentReferenceData);
      this.loading = false;
    } else {
      await this.removePaymentReference();
    }

    this.selectTxt = 'Selecteer alle ondernemers';
    const orgs = await getDocs(
      collection(
        this.db.firestore,
        `/township/${this.townshipId}/organisations`
      )
    );
    orgs.forEach((org) => {
      const orgData = org.data() as Organisation;
      orgData.id = org.id;
      if (orgData.hidden !== true) {
        this.organisations.push(orgData);
      }
    });
    this.organisations.forEach((org) => {
      this.allOrganisations.push(org);
    });
    this.sortOrgs();

    this.townshipData = (
      await getDoc(
        doc(this.db.firestore, `township/${localStorage.getItem('township')}`)
      )
    ).data() as Township;
    this.downloadFormGroup.controls.sepa.setValue(
      this.townshipData.sepaSettingsComplete
    );
    if (!this.townshipData.sepaSettingsComplete) {
      this.downloadFormGroup.controls.sepa.disable();
    }
  }

  onCostCenterChange(event: any) {
    const costCenter = event.options[0].value;
    const costCenterPaymentRefString =
      costCenter === `none` ? `` : `${costCenter}-`;
    this.paymentReferenceId = `${costCenterPaymentRefString}${this.dateString}`;
  }

  onOrgsChange(event: any) {
    const value = event.options[0].value;

    if (value === 'selectAll') {
      if (this.isAllSelected) {
        this.allSelected.deselectAll();
        this.isAllSelected = false;
        this.selectTxt = 'Selecteer alle ondernemers';
        this.selectedOrganisations = [];
      } else {
        this.allSelected.selectAll();
        this.isAllSelected = true;
        this.selectTxt = 'Maak actie ongedaan';
        this.selectedOrganisations = this.allOrganisations;
      }
      return;
    }
    if (event.options[0].selected) {
      this.selectedOrganisations.push(value);
    } else if (!event.options[0].selected) {
      this.selectedOrganisations.splice(
        this.selectedOrganisations.indexOf(value),
        1
      );
    }
  }

  nextStep() {
    if (this.loading) {
      return;
    }
    if (this.stepper.selectedIndex === 0) {
      if (this.costCenterFormGroup.value.costCenters.length === 0) {
        this.snackBar.open('Je moet een kostenplaats selecteren', 'X', {
          duration: 5000,
        });
        return;
      } else {
        return this.stepper.next();
      }
    }
    if (this.stepper.selectedIndex >= 1) {
      const selectedOrganisations = [...this.selectedOrganisations];
      if (this.selectedOrganisations.length === 0) {
        this.snackBar.open('Je moet een ondernemer selecteren', 'X', {
          duration: 5000,
        });
        return;
      }
      selectedOrganisations.forEach((organisation) => {
        if (!organisation.sepaSettingsComplete) {
          this.selectedOrganisations.splice(
            this.selectedOrganisations.indexOf(organisation),
            1
          );
          if (this.selectedOrganisations.length === 0) {
            this.snackBar.open(
              'Je hebt geen geldige ondernemer geselecteerd',
              'X',
              {
                duration: 5000,
              }
            );
          }
        }
      });
    }
    if (this.selectedOrganisations.length > 0) {
      this.stepper.next();
      this.loading = true;
      const postData = {
        selectedCostCenter: this.costCenterFormGroup.value.costCenters[0],
        selectedOrganisations: this.selectedOrganisations,
        townshipId: this.townshipId,
        setSepaPaymentReference: true,
        paymentReference: this.paymentReferenceId,
        dateToday: this.dateNow.toDate(),
      };
      const requestUrl = `${environment.functionsUrl}/httpSetPaymentreference`;
      let res: Observable<any>;
      res = this.http.post(requestUrl, postData);
      res.subscribe(async (result) => {
        try {
          const paymentReferenceRef = doc(
            this.db.firestore,
            `/township/${this.townshipId}/paymentReferences/${result.message}`
          );
          const paymentRef = (
            await getDoc(paymentReferenceRef)
          ).data() as PaymentReferences;

          this.createTable(paymentRef);
          this.loading = false;
        } catch (e) {
          console.error(e);
        }
      });
    }
  }

  previousStep() {
    this.stepper.previous();
  }

  async finish(markAsPaid?) {
    if (this.loading) {
      this.snackBar.open('Even geduld, A.U.B', 'X', {
        duration: 3000,
      });
      return;
    }
    const sepaChecked = this.downloadFormGroup.value.sepa;
    const excelChecked = this.downloadFormGroup.value.excel;
    const invoiceChecked = this.downloadFormGroup.value.invoice;
    // const markVouchersAsPaid = this.downloadFormGroup.value.markVouchersAsPaid;
    if (!sepaChecked && !excelChecked && !invoiceChecked) {
      this.snackBar.open('Er is geen methode gekozen', 'X', {
        duration: 5000,
      });
      return;
    }

    if (
      this.data.paymentReference &&
      this.data.paymentReference.status === 'done'
    ) {
      this.checkedDate = this.data.paymentReference.date.toDate();
    } else {
      this.checkedDate = this.dateFormGroup.value.selectedDate;
    }
    if (!markAsPaid) {
      await this.downloadFiles(excelChecked, sepaChecked, invoiceChecked);
    } else {
      const dialogRef = this.dialog.open(SepaConfirmationComponent, {
        width: '425px',
        data: {
          sepaChecked,
          excelChecked,
          invoiceChecked,
        },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.loading = true;
          const postData = {
            townshipId: this.townshipId,
            paymentReferenceId: this.paymentReferenceId,
            paidByDate: this.checkedDate,
          };
          const requestUrl = `${environment.functionsUrl}/httpFinishPaymentreference`;
          let res: Observable<any>;
          res = this.http.post(requestUrl, postData);
          res.subscribe(async (result) => {
            try {
              console.log('result', result);
              if (result.message === 'succeed') {
                this.paymentReferenceData = (
                  await getDoc(
                    doc(
                      this.db.firestore,
                      `township/${this.townshipId}/paymentReferences/${this.paymentReferenceId}`
                    )
                  )
                ).data() as PaymentReferences;
                await this.downloadFiles(
                  excelChecked,
                  sepaChecked,
                  invoiceChecked
                );
                this.dialogRef.close();
                this.loading = false;
              } else {
                this.snackBar.open('Er is een onbekende fout opgetreden.', '', {
                  duration: 5000,
                });
                this.loading = false;
              }
            } catch (e) {
              console.error(e);
              this.snackBar.open('Er is een onbekende fout opgetreden.', '', {
                duration: 5000,
              });
              this.loading = false;
            }
          });
        }
      });
    }
  }

  async downloadFiles(excelChecked, sepaChecked, invoiceChecked) {
    try {
      if (sepaChecked) {
        // create XML file and transfer SEPA data into XML file
        const xmlToInject = await this.initSepaPayment(
          this.paymentReferenceId,
          this.townshipId,
          this.paymentReferenceData
        );
        if (xmlToInject) {
          await this.createXmlFile(
            xmlToInject,
            `${this.paymentReferenceId}.xml`
          );
        }
      }
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'Er is iets mis gegaan met het genereren van het sepa bestand, probeer het later opnieuw of neem contact op met WeMaron.',
        '',
        {
          duration: 5000,
        }
      );
    }
    if (excelChecked) {
      await this.exportPaymentReference(
        this.paymentReferenceId,
        this.paymentReferenceData
      );
    }
    if (invoiceChecked) {
      await this.exportInvoice(this.paymentReferenceData);
    }
    this.addVoucherPaymentIds(
      this.paymentReferenceId,
      this.paymentReferenceData
    );
  }

  toggle(event: any) {
    this.checkboxValue = event.value;
  }

  async exportInvoice(paymentRefDocData) {
    const commissionSettings = paymentRefDocData.commissionSettings;
    if (!commissionSettings) {
      return;
    }
    const storage = getStorage();
    const pdfRef = ref(storage, commissionSettings.invoiceTemplateOrganisation);
    const formBytes = await getBytes(pdfRef);

    let forLoopIndex = 1;

    for (const organisation of paymentRefDocData.organisations) {
      let organisationObj = {};
      const pdfDoc = await PDFDocument.load(formBytes);
      const form = pdfDoc.getForm();

      organisationObj['Datum'] = paymentRefDocData.date
        ? moment(paymentRefDocData.date.toDate()).format('DD-MM-YYYY')
        : '';

      organisationObj['Betalingskenmerk'] = organisation.id
        ? organisation.id
        : '';

      organisationObj['Ondernemer'] = organisation.name
        ? organisation.name
        : '';

      organisationObj['Referentienummer betalingsverplichting'] =
        organisation.paymentRef ? organisation.paymentRef : '';

      organisationObj['Totale waarde'] = organisation.totalPaidAmount
        ? organisation.totalPaidAmount
        : 0;

      const btwPercentage = commissionSettings?.btwPercentage
        ? this.getPercentage(commissionSettings.btwPercentage)
        : 0;
      const commissionPercentageOrganisation =
        commissionSettings?.commissionPercentageOrganisation
          ? this.getPercentage(
              commissionSettings.commissionPercentageOrganisation
            )
          : 0;

      const grossCommissionOrg =
        commissionSettings?.commissionPercentageOrganisation
          ? Number(
              organisation.totalPaidAmount *
                commissionPercentageOrganisation *
                (1 + btwPercentage)
            )
          : 0;
      organisationObj['Courtage inhouding'] = Number(grossCommissionOrg);
      const netCommissionOrg = Number(
        organisationObj['Courtage inhouding'] / (1 + btwPercentage)
      );

      organisationObj['Courtage netto'] = Number(netCommissionOrg);

      const btw = Number(
        organisationObj['Courtage inhouding'] -
          organisationObj['Courtage netto']
      );
      organisationObj['Courtage BTW'] = Number(btw);

      form
        .getTextField('organisationName')
        .setText(organisationObj['Ondernemer']);
      form.getTextField('organisationName').enableReadOnly();

      form
        .getTextField('invoiceDate')
        .setText(organisationObj['Datum'].toString());
      form.getTextField('invoiceDate').enableReadOnly();

      const invoiceNumber = `WMN${organisationObj[
        'Referentienummer betalingsverplichting'
      ].replace(/[-:\.]/g, '')}${forLoopIndex}`;
      form.getTextField('invoiceNumber').setText(invoiceNumber);
      form.getTextField('invoiceNumber').enableReadOnly();

      form
        .getTextField('courtageOrganisationPercentage')
        .setText(
          commissionSettings.commissionPercentageOrganisation
            .toString()
            .replace('.', ',')
        );
      form.getTextField('courtageOrganisationPercentage').enableReadOnly();

      form
        .getTextField('paymentReference')
        .setText(organisationObj['Referentienummer betalingsverplichting']);
      form.getTextField('paymentReference').enableReadOnly();

      form
        .getTextField('brutoOrganisation')
        .setText(
          organisationObj['Totale waarde']
            .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
            .replace('€', '')
        );
      form.getTextField('brutoOrganisation').enableReadOnly();

      form
        .getTextField('courtageOrganisationNetto')
        .setText(
          organisationObj['Courtage netto']
            .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
            .replace('€', '')
        );
      form.getTextField('courtageOrganisationNetto').enableReadOnly();

      form
        .getTextField('btwPercentage')
        .setText(commissionSettings.btwPercentage.toString().replace('.', ','));
      form.getTextField('btwPercentage').enableReadOnly();

      form
        .getTextField('btwOrganisation')
        .setText(
          organisationObj['Courtage BTW']
            .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
            .replace('€', '')
        );
      form.getTextField('btwOrganisation').enableReadOnly();

      form
        .getTextField('courtageOrganisation')
        .setText(
          organisationObj['Courtage inhouding']
            .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
            .replace('€', '')
        );
      form.getTextField('courtageOrganisation').enableReadOnly();

      const url = window.URL.createObjectURL(
        new Blob([(await pdfDoc.save()).buffer], { type: 'application/pdf' })
      );
      const htmlAnchor = document.createElement('a');
      htmlAnchor.style.display = 'none';
      htmlAnchor.href = url;
      htmlAnchor.download = `${invoiceNumber}.pdf`;
      document.body.appendChild(htmlAnchor);
      htmlAnchor.click();
      URL.revokeObjectURL(url);
      forLoopIndex = forLoopIndex + 1;
    }
  }

  async exportPaymentReference(
    paymentReferenceId: String,
    newPaymentReference
  ) {
    let totalPaidAmount = 0;
    let totalVouchers = 0;
    let totalGrossCommissionOrg: number = 0;
    let totalNetAmount: number = 0;
    let totalNetCommissionOrg: number = 0;
    let totalBTW: number = 0;
    const paymentReferenceArray = [];
    const commissionSettings = newPaymentReference.commissionSettings;

    let indexForLoop = 1;

    newPaymentReference.organisations.forEach(async (organisation) => {
      console.log('organisation', organisation);
      if (organisation.vouchers.length > 0) {
        let indexForVoucherLoop = 1;
        organisation.vouchers.forEach((voucher) => {
          let organisationObj = {};

          organisationObj['Datum'] = newPaymentReference.date
            ? moment(newPaymentReference.date.toDate()).format('DD-MM-YYYY')
            : '';

          organisationObj['Kostenplaats'] =
            newPaymentReference.selectedCostCenter
              ? newPaymentReference.selectedCostCenter
              : '';

          organisationObj['Betalingskenmerk'] = organisation.id
            ? `WMN${organisation.id.replace(
                /[-:\. ]/g,
                ''
              )}${indexForLoop}${indexForVoucherLoop}`
            : '';

          organisationObj['Ondernemer'] = organisation.name
            ? organisation.name
            : '';

          organisationObj['Referentienummer betalingsverplichting'] =
            voucher.organisationReference
              ? voucher.organisationReference
              : organisation.paymentRef
              ? organisation.paymentRef
              : '';

          organisationObj['IBAN'] = organisation.iban ? organisation.iban : '';

          organisationObj['Naam bankrekeninghouder'] =
            organisation.bankAccountName ? organisation.bankAccountName : '';

          organisationObj['Totale waarde'] = voucher.amountToPayOrg
            ? voucher.amountToPayOrg
            : 0;

          organisationObj['Totale bonnen'] = 1;

          const btwPercentage = commissionSettings?.btwPercentage
            ? this.getPercentage(commissionSettings.btwPercentage)
            : 0;
          const commissionPercentageOrganisation =
            commissionSettings?.commissionPercentageOrganisation
              ? this.getPercentage(
                  commissionSettings.commissionPercentageOrganisation
                )
              : 0;
          const grossCommissionOrg =
            commissionSettings?.commissionPercentageOrganisation
              ? Number(
                  voucher.amountToPayOrg *
                    commissionPercentageOrganisation *
                    (1 + btwPercentage)
                ).toFixed(2)
              : 0;

          organisationObj['Courtage inhouding'] = Number(grossCommissionOrg);

          const netAmount = Number(
            voucher.amountToPayOrg - organisationObj['Courtage inhouding']
          ).toFixed(2);
          organisationObj['Netto uitbetaling'] = Number(netAmount);

          const netCommissionOrg = Number(
            organisationObj['Courtage inhouding'] / (1 + btwPercentage)
          ).toFixed(2);
          organisationObj['Courtage netto'] = Number(netCommissionOrg);

          const btw = Number(
            organisationObj['Courtage inhouding'] -
              organisationObj['Courtage netto']
          ).toFixed(2);
          organisationObj['Courtage BTW'] = Number(btw);

          totalGrossCommissionOrg += Number(
            organisationObj['Courtage inhouding']
          );
          totalNetAmount += Number(organisationObj['Netto uitbetaling']);
          totalNetCommissionOrg += Number(organisationObj['Courtage netto']);
          totalBTW += Number(organisationObj['Courtage BTW']);

          indexForVoucherLoop = indexForVoucherLoop + 1;
          paymentReferenceArray.push(organisationObj);
        });
        indexForLoop = indexForLoop + 1;
      } else if (organisation.organisationId === 'cashback') {
        let indexForVoucherLoop = 1;
        newPaymentReference.cashbacks.forEach((cashback) => {
          let organisationObj = {};

          organisationObj['Datum'] = newPaymentReference.date
            ? moment(newPaymentReference.date.toDate()).format('DD-MM-YYYY')
            : '';

          organisationObj['Kostenplaats'] =
            newPaymentReference.selectedCostCenter
              ? newPaymentReference.selectedCostCenter
              : '';

          organisationObj['Betalingskenmerk'] = organisation.id
            ? `WMN${organisation.id.replace(
                /[-:\. ]/g,
                ''
              )}${indexForLoop}${indexForVoucherLoop}`
            : '';

          organisationObj['Ondernemer'] = organisation.name
            ? organisation.name
            : '';

          organisationObj['Referentienummer betalingsverplichting'] =
            organisation.paymentRef ? organisation.paymentRef : '';

          organisationObj['IBAN'] = cashback.iban ? cashback.iban : '';

          organisationObj['Naam bankrekeninghouder'] = cashback.bankAccountName
            ? cashback.bankAccountName
            : '';

          organisationObj['Totale waarde'] = cashback.totalPaidAmount
            ? cashback.totalPaidAmount
            : 0;

          organisationObj['Totale bonnen'] = 1;

          const btwPercentage = 0;
          const grossCommissionOrg = 0;

          organisationObj['Courtage inhouding'] = Number(grossCommissionOrg);

          const netAmount = Number(
            cashback.totalPaidAmount - organisationObj['Courtage inhouding']
          ).toFixed(2);
          organisationObj['Netto uitbetaling'] = Number(netAmount);

          const netCommissionOrg = Number(
            organisationObj['Courtage inhouding'] / (1 + btwPercentage)
          ).toFixed(2);
          organisationObj['Courtage netto'] = Number(netCommissionOrg);

          const btw = Number(
            organisationObj['Courtage inhouding'] -
              organisationObj['Courtage netto']
          ).toFixed(2);
          organisationObj['Courtage BTW'] = Number(btw);

          totalGrossCommissionOrg += Number(
            organisationObj['Courtage inhouding']
          );
          totalNetAmount += Number(organisationObj['Netto uitbetaling']);
          totalNetCommissionOrg += Number(organisationObj['Courtage netto']);
          totalBTW += Number(organisationObj['Courtage BTW']);

          indexForVoucherLoop = indexForVoucherLoop + 1;
          paymentReferenceArray.push(organisationObj);
        });
        indexForLoop = indexForLoop + 1;
      } else {
        let organisationObj = {};

        organisationObj['Datum'] = newPaymentReference.date
          ? moment(newPaymentReference.date.toDate()).format('DD-MM-YYYY')
          : '';

        organisationObj['Kostenplaats'] = newPaymentReference.selectedCostCenter
          ? newPaymentReference.selectedCostCenter
          : '';

        organisationObj['Betalingskenmerk'] = organisation.id
          ? `WMN${organisation.id.replace(/[-:\. ]/g, '')}${indexForLoop}`
          : '';

        organisationObj['Ondernemer'] = organisation.name
          ? organisation.name
          : '';

        organisationObj['Referentienummer betalingsverplichting'] =
          organisation.paymentRef ? organisation.paymentRef : '';

        organisationObj['IBAN'] = organisation.iban ? organisation.iban : '';

        organisationObj['Naam bankrekeninghouder'] =
          organisation.bankAccountName ? organisation.bankAccountName : '';

        organisationObj['Totale waarde'] = organisation.totalPaidAmount
          ? organisation.totalPaidAmount
          : 0;

        organisationObj['Totale bonnen'] = organisation.totalVouchers
          ? organisation.totalVouchers
          : 0;

        const btwPercentage = commissionSettings?.btwPercentage
          ? this.getPercentage(commissionSettings.btwPercentage)
          : 0;
        const commissionPercentageOrganisation =
          commissionSettings?.commissionPercentageOrganisation
            ? this.getPercentage(
                commissionSettings.commissionPercentageOrganisation
              )
            : 0;

        const grossCommissionOrg =
          commissionSettings?.commissionPercentageOrganisation
            ? Number(
                organisation.totalPaidAmount *
                  commissionPercentageOrganisation *
                  (1 + btwPercentage)
              ).toFixed(2)
            : 0;

        organisationObj['Courtage inhouding'] = Number(grossCommissionOrg);

        const netAmount = Number(
          organisation.totalPaidAmount - organisationObj['Courtage inhouding']
        ).toFixed(2);
        organisationObj['Netto uitbetaling'] = Number(netAmount);

        const netCommissionOrg = Number(
          organisationObj['Courtage inhouding'] / (1 + btwPercentage)
        ).toFixed(2);
        organisationObj['Courtage netto'] = Number(netCommissionOrg);

        const btw = Number(
          organisationObj['Courtage inhouding'] -
            organisationObj['Courtage netto']
        ).toFixed(2);
        organisationObj['Courtage BTW'] = Number(btw);

        totalGrossCommissionOrg += Number(
          organisationObj['Courtage inhouding']
        );
        totalNetAmount += Number(organisationObj['Netto uitbetaling']);
        totalNetCommissionOrg += Number(organisationObj['Courtage netto']);
        totalBTW += Number(organisationObj['Courtage BTW']);

        indexForLoop = indexForLoop + 1;
        paymentReferenceArray.push(organisationObj);
      }
    });

    totalPaidAmount = newPaymentReference.totalPaidAmount;
    totalVouchers = newPaymentReference.totalVouchers;
    if (paymentReferenceArray.length >= 0) {
      const organisationObj = {};
      organisationObj['Datum'] = '';
      organisationObj['Kostenplaats'] = '';
      organisationObj['Betalingskenmerk'] = '';
      organisationObj['Ondernemer'] = '';
      organisationObj['Referentienummer betalingsverplichting'] = '';
      organisationObj['IBAN'] = '';
      organisationObj['Naam bankrekeninghouder'] = '';
      organisationObj['Totale waarde'] = totalPaidAmount ? totalPaidAmount : 0;
      organisationObj['Totale bonnen'] = totalVouchers ? totalVouchers : 0;
      organisationObj['Courtage inhouding'] = totalGrossCommissionOrg;
      organisationObj['Netto uitbetaling'] = totalNetAmount;
      organisationObj['Courtage netto'] = totalNetCommissionOrg;
      organisationObj['Courtage BTW'] = totalBTW;

      paymentReferenceArray.push(organisationObj);

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        paymentReferenceArray
      ); // converts a DOM TABLE element to a worksheet

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Vouchers');
      // /* save to file */
      XLSX.writeFile(wb, environment.name + '.' + paymentReferenceId + '.xlsx');
    } else {
      // Nothing to export
      this.snackBar.open(
        'Er gaat iets mis bij het exporteren van de excel.',
        'X',
        {
          duration: 5000,
        }
      );
    }
  }

  async initSepaPayment(
    paymentReference: string,
    townshipId: string,
    paymentReferenceData: PaymentReferences
  ) {
    const townshipIdData = (
      await getDoc(doc(this.db.firestore, `township/${townshipId}`))
    ).data() as Township;

    const sepa = this.buildSepa(
      paymentReferenceData.organisations,
      paymentReferenceData.cashbacks,
      paymentReference,
      paymentReferenceData,
      townshipIdData
    );

    return sepa;
  }

  getCurrencyString(number) {
    if (number.toString().indexOf('.') == -1) {
      return `€${number.toString()},-`;
    }
    return `€${number.toFixed(2).replace('.', ',')}`;
  }

  async createXmlFile(data, fileName) {
    const blob = new Blob([data]);
    this.checkSum = sha1(blob);
    await setDoc(
      doc(
        this.db.firestore,
        `township/${this.townshipId}/paymentReferences/${this.paymentReferenceId}`
      ),
      { checkSum: this.checkSum },
      { merge: true }
    );
    this._FileSaverService.save(blob, fileName);
    const checkSumString = `Checksum: ${this.checkSum}`;
    this._FileSaverService.save(
      new Blob([checkSumString]),
      `Checksum ${this.paymentReferenceId}.txt`
    );
    this.dialogRef.close();
  }

  async buildSepa(
    organisations: any[],
    cashbacks: any[],
    paymentReference: string,
    paymentReferenceData: PaymentReferences,
    townshipData: Township
  ) {
    const reqExtractDate = this.checkedDate;
    const commissionSettings = paymentReferenceData.commissionSettings;

    const formattedDate = `${reqExtractDate.getFullYear()}-${(
      '0' +
      (reqExtractDate.getMonth() + 1)
    ).slice(-2)}-${('0' + reqExtractDate.getDate()).slice(-2)}`;
    let totalPaidAmountNetto = 0;
    let numberOfTransactions = 0;

    // create header info for xml file
    const bic = townshipData.invoiceInfo.bic
      ? townshipData.invoiceInfo.bic
      : 'NOTPROVIDED';

    let creditorArr = [];
    let indexForLoop = 1;
    for (const organisation of organisations) {
      for (const selectedOrg of this.selectedOrganisations) {
        if (
          organisation.organisationId === selectedOrg.id &&
          organisation.organisationId !== 'cashback'
        ) {
          if (!selectedOrg.sepaSettingsComplete) {
            return this.snackBar.open(
              `De organisatie ${organisation.name} heeft de betaal gegevens nog niet volledig ingevuld`,
              'X',
              {
                duration: 5000,
              }
            );
          }
          if (organisation.vouchers.length > 0) {
            organisation.vouchers.forEach((voucher) => {
              const btwPercentage = commissionSettings?.btwPercentage
                ? this.getPercentage(commissionSettings.btwPercentage)
                : 0;
              const commissionPercentageOrganisation =
                commissionSettings?.commissionPercentageOrganisation
                  ? this.getPercentage(
                      commissionSettings.commissionPercentageOrganisation
                    )
                  : 0;
              const grossCommissionOrg =
                commissionSettings?.commissionPercentageOrganisation
                  ? Number(
                      voucher.amountToPayOrg *
                        commissionPercentageOrganisation *
                        (1 + btwPercentage)
                    ).toFixed(2)
                  : 0;

              const netAmount = Number(
                voucher.amountToPayOrg - Number(grossCommissionOrg)
              ).toFixed(2);
              voucher.nettoAmountPaid = netAmount;
              voucher.grossCommission = Number(grossCommissionOrg);
              totalPaidAmountNetto =
                totalPaidAmountNetto + voucher.amountToPayOrg;
              const bic = selectedOrg.sepaSettings?.bic ?? 'NOTPROVIDED';

              // check if bank data is filled in
              const paymentReferenceFixed =
                selectedOrg.paymentReference || paymentReference;
              const creditorBuildObjArray = [
                {
                  PmtId: {
                    EndToEndId: voucher.organisationReference
                      ? voucher.organisationReference
                      : this.paymentReferenceId,
                  },
                  Amt: {
                    InstdAmt: {
                      '@Ccy': 'EUR',
                      '#': voucher.nettoAmountPaid,
                    },
                  },
                  CdtrAgt: {
                    FinInstnId: {
                      BIC: bic,
                    },
                  },
                  Cdtr: {
                    Nm: selectedOrg.name,
                  },
                  CdtrAcct: {
                    Id: {
                      IBAN:
                        selectedOrg.debtorIban || selectedOrg.sepaSettings.iban,
                    },
                  },
                  RmtInf: {
                    Ustrd: `WMN${paymentReferenceFixed.replace(
                      /[-:\.]/g,
                      ''
                    )}${indexForLoop}`,
                  },
                },
              ];
              const commissionObject = {
                PmtId: {
                  EndToEndId: this.paymentReferenceId,
                },
                Amt: {
                  InstdAmt: {
                    '@Ccy': 'EUR',
                    '#': voucher.grossCommission,
                  },
                },
                CdtrAgt: {
                  FinInstnId: {
                    BIC: bic,
                  },
                },
                Cdtr: {
                  Nm: commissionSettings.operatorName,
                },
                CdtrAcct: {
                  Id: {
                    IBAN: commissionSettings.operatorBankAccount,
                  },
                },
                RmtInf: {
                  Ustrd: `WMN${paymentReferenceFixed.replace(
                    /[-:\.]/g,
                    ''
                  )}${indexForLoop}`,
                },
              };

              if (voucher.grossCommission > 0) {
                creditorBuildObjArray.push(commissionObject);
                numberOfTransactions++;
              }
              creditorArr.push(creditorBuildObjArray);
              indexForLoop++;
              numberOfTransactions++;
            });
          } else {
            const btwPercentage = commissionSettings?.btwPercentage
              ? this.getPercentage(commissionSettings.btwPercentage)
              : 0;
            const commissionPercentageOrganisation =
              commissionSettings?.commissionPercentageOrganisation
                ? this.getPercentage(
                    commissionSettings.commissionPercentageOrganisation
                  )
                : 0;
            const grossCommissionOrg =
              commissionSettings?.commissionPercentageOrganisation
                ? Number(
                    organisation.totalPaidAmount *
                      commissionPercentageOrganisation *
                      (1 + btwPercentage)
                  ).toFixed(2)
                : 0;

            const netAmount = Number(
              organisation.totalPaidAmount - Number(grossCommissionOrg)
            ).toFixed(2);
            organisation.nettoAmountPaid = netAmount;
            organisation.grossCommission = Number(grossCommissionOrg);
            totalPaidAmountNetto =
              totalPaidAmountNetto + organisation.totalPaidAmount;
            const bic = selectedOrg.sepaSettings?.bic ?? 'NOTPROVIDED';
            // check if bank data is filled in
            const paymentReferenceFixed =
              selectedOrg.paymentReference || paymentReference;
            const creditorBuildObjArray = [
              {
                PmtId: {
                  EndToEndId: this.paymentReferenceId,
                },
                Amt: {
                  InstdAmt: {
                    '@Ccy': 'EUR',
                    '#': organisation.nettoAmountPaid,
                  },
                },
                CdtrAgt: {
                  FinInstnId: {
                    BIC: bic,
                  },
                },
                Cdtr: {
                  Nm: selectedOrg.name,
                },
                CdtrAcct: {
                  Id: {
                    IBAN:
                      selectedOrg.debtorIban || selectedOrg.sepaSettings.iban,
                  },
                },
                RmtInf: {
                  Ustrd: `WMN${paymentReferenceFixed.replace(
                    /[-:\.]/g,
                    ''
                  )}${indexForLoop}`,
                },
              },
            ];
            const commissionObject = {
              PmtId: {
                EndToEndId: this.paymentReferenceId,
              },
              Amt: {
                InstdAmt: {
                  '@Ccy': 'EUR',
                  '#': organisation.grossCommission,
                },
              },
              CdtrAgt: {
                FinInstnId: {
                  BIC: bic,
                },
              },
              Cdtr: {
                Nm: commissionSettings.operatorName,
              },
              CdtrAcct: {
                Id: {
                  IBAN: commissionSettings.operatorBankAccount,
                },
              },
              RmtInf: {
                Ustrd: `WMN${paymentReferenceFixed.replace(
                  /[-:\.]/g,
                  ''
                )}${indexForLoop}`,
              },
            };

            if (organisation.organisationId != 'cashback') {
              if (organisation.grossCommission > 0) {
                creditorBuildObjArray.push(commissionObject);
                numberOfTransactions++;
              }
              creditorArr.push(creditorBuildObjArray);
              indexForLoop++;
              numberOfTransactions++;
            }
          }
        }
      }
    }
    for (const cashback of cashbacks) {
      // compare and add
      const bic = 'NOTPROVIDED';
      // check if bank data is filled in
      const paymentReferenceFixed =
        cashback.paymentReference || paymentReference;
      const creditorBuildObjArray = [
        {
          PmtId: {
            EndToEndId: this.paymentReferenceId,
          },
          Amt: {
            InstdAmt: {
              '@Ccy': 'EUR',
              '#': cashback.totalPaidAmount,
            },
          },
          CdtrAgt: {
            FinInstnId: {
              BIC: bic,
            },
          },
          Cdtr: {
            Nm: cashback.bankAccountName,
          },
          CdtrAcct: {
            Id: {
              IBAN: cashback.iban,
            },
          },
          RmtInf: {
            Ustrd: `WMN${paymentReferenceFixed.replace(
              /[-:\.]/g,
              ''
            )}${indexForLoop}`,
          },
        },
      ];
      totalPaidAmountNetto = totalPaidAmountNetto + cashback.totalPaidAmount;
      creditorArr.push(creditorBuildObjArray);
      indexForLoop++;
      numberOfTransactions++;
    }

    const buildObj = {
      Document: {
        '@xmlns': 'urn:iso:std:iso:20022:tech:xsd:pain.001.001.03',
        '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
        '@xsi:schemaLocation':
          'urn:iso:std:iso:20022:tech:xsd:pain.001.001.03 pain.001.001.03.xsd',
        CstmrCdtTrfInitn: {
          GrpHdr: {
            MsgId: paymentReference,
            CreDtTm: `${new Date().toISOString()}`,
            NbOfTxs: `${numberOfTransactions}`,
            CtrlSum: totalPaidAmountNetto.toFixed(2),
            InitgPty: {
              Nm: townshipData.name,
            },
          },
          PmtInf: {
            PmtInfId: paymentReference,
            PmtMtd: 'TRF',
            BtchBookg: true,
            NbOfTxs: `${numberOfTransactions}`,
            CtrlSum: totalPaidAmountNetto.toFixed(2),
            PmtTpInf: {
              SvcLvl: {
                Cd: 'SEPA',
              },
            },
            ReqdExctnDt: formattedDate, // required execution date?
            Dbtr: {
              Nm: townshipData.name,
            },
            DbtrAcct: {
              Id: {
                IBAN: townshipData.invoiceInfo.bankAccount,
              },
            },
            DbtrAgt: {
              FinInstnId: {
                BIC: bic,
              },
            },
            ChrgBr: 'SLEV',
            CdtTrfTxInf: [],
          },
        },
      },
    } as any;

    buildObj.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.push(creditorArr);

    const xmlSepaHeader = xmlbuilder.create(buildObj);
    return xmlSepaHeader.end({ prettyPrint: true });
  }

  async addVoucherPaymentIds(paymentReferenceId, paymentRefDocData) {
    let voucherUpdateArray = [];
    let indexForLoop = 1;

    paymentRefDocData.organisations.forEach(async (organisation) => {
      if (organisation.vouchers.length > 0) {
        let indexForVoucherLoop = 1;
        organisation.vouchers.forEach((voucher) => {
          const paymentId = organisation.id
            ? `WMN${organisation.id.replace(
                /[-:\.]/g,
                ''
              )}${indexForLoop}${indexForVoucherLoop}`
            : '';
          const id = voucher.voucherId ? voucher.voucherId : 'none';

          voucherUpdateArray.push({
            isVoucher: true,
            id: id,
            paymentId: paymentId,
          });
          indexForVoucherLoop = indexForVoucherLoop + 1;
        });
        indexForLoop = indexForLoop + 1;
      } else if (organisation.organisationId === 'cashback') {
        let indexForVoucherLoop = 1;
        paymentRefDocData.cashbacks.forEach(() => {
          const paymentId = organisation.id
            ? `WMN${organisation.id.replace(
                /[-:\.]/g,
                ''
              )}${indexForLoop}${indexForVoucherLoop}`
            : '';
          const id = organisation.organisationId
            ? organisation.organisationId
            : 'none';

          voucherUpdateArray.push({
            isVoucher: false,
            id: id,
            paymentId: paymentId,
          });
          indexForVoucherLoop = indexForVoucherLoop + 1;
        });
        indexForLoop = indexForLoop + 1;
      } else {
        const paymentId = organisation.id
          ? `WMN${organisation.id.replace(/[-:\.]/g, '')}${indexForLoop}`
          : '';
        const id = organisation.organisationId
          ? organisation.organisationId
          : 'none';

        voucherUpdateArray.push({
          isVoucher: false,
          id: id,
          paymentId: paymentId,
        });
        indexForLoop = indexForLoop + 1;
      }
    });

    const voucherDocsRef = query(
      collection(this.db.firestore, `township/${this.townshipId}/vouchers/`),
      where('paymentReference', '==', paymentReferenceId)
    );
    const vouchers = await getDocs(voucherDocsRef);
    const batch = this.db.firestore.batch();

    voucherUpdateArray.forEach((updateData) => {
      if (updateData.isVoucher) {
        const voucherDoc = vouchers.docs.find(
          (voucher) => updateData.id === voucher.id
        );
        if (voucherDoc) {
          const voucherData = voucherDoc.data() as Voucher;
          if (!voucherData.paymentId) {
            batch.update(voucherDoc.ref as any, {
              paymentId: updateData.paymentId,
            });
          }
        }
      } else {
        vouchers.forEach((voucherDoc) => {
          const voucherData = voucherDoc.data() as Voucher;
          if (
            updateData.id != 'none' &&
            voucherData.claimOrganisationId === updateData.id &&
            !voucherData.paymentId
          ) {
            batch.update(voucherDoc.ref as any, {
              paymentId: updateData.paymentId,
            });
          }
        });
      }
    });
    batch.commit();
  }

  async removePaymentReference() {
    this.loading = true;
    const requestUrl = `${environment.functionsUrl}/httpDeletePaymentReference`;
    let res: Observable<any>;
    const postData = {
      townshipId: this.townshipId,
      paymentReference: this.paymentReferenceId,
      // selectedOrganisations: this.selectedOrganisations,
    };
    res = this.http.post(requestUrl, postData);
    res.subscribe(async (result) => {
      this.loading = false;
    });
  }

  async createTable(paymentRef: PaymentReferences) {
    const tableArray = [];
    paymentRef.organisations.forEach((organisation) => {
      const newObj = {} as any;
      newObj.Ondernemer = organisation.name;
      newObj.Aantal = organisation.totalVouchers;
      newObj.Bedrag = organisation.totalPaidAmount;
      tableArray.push(newObj);
    });

    let totalVouchers = 0;
    let totalAmount = 0;
    tableArray.forEach((obj) => {
      totalAmount += obj.Bedrag;
      totalVouchers += obj.Aantal;
    });
    tableArray.push({
      Ondernemer: 'Totaal',
      Bedrag: totalAmount,
      Aantal: totalVouchers,
    });
    this.responseData = tableArray;
  }

  sortOrgs() {
    this.organisations.sort((org1, org2) => {
      const a = org1.name.toLowerCase();
      const b = org2.name.toLowerCase();
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }

  getPercentage(number: number): number {
    return number / 100;
  }

  decimalFixer(value: number): number {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }
}
