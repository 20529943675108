import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { doc, getDoc } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { Widget } from 'src/app/interfaces';
import { checkAndUpdateStats } from '../../bigquery-helper';
import { calculateWidgetHeight } from '../../widgets-helper';

@Component({
  selector: 'widget-transactions',
  templateUrl: './widget-transactions.component.html',
  styleUrls: ['./widget-transactions.component.scss'],
})
export class WidgetTransactionsComponent implements OnInit {
  @Input() widget: Widget;
  statistics: Observable<any>;
  townshipId = localStorage.getItem('township');
  organisations: any[] = [{ name: 'chart', series: [] }];
  colorScheme = { domain: ['#1a697f'] };
  amountToShow: number = 4;
  opacity: number = 1;
  widgetHeight: any;
  constructor(public db: AngularFirestore) {}

  async ngOnInit() {
    if (this.widget.settings?.amountToShow) {
      this.amountToShow = this.widget.settings.amountToShow;
    }

    checkAndUpdateStats(this.widget, 'bigqueryTransactionsPerOrg', {
      townshipId: this.townshipId,
    });

    this.statistics = this.db
      .doc<any>(`township/${this.townshipId}/statistics/calcTransactionsPerOrg`)
      .valueChanges();
    this.statistics.subscribe((stats) => {
      console.log('calcTransactionsPerOrg', stats);
      stats.totalsListPerOrg.forEach((stat) => {
        this.organisations[0].series.push({
          name: stat.claimOrganisation,
          value: stat.orgAmount,
          shownValue: Number(stat.orgAmount).toLocaleString('nl-NL', {
            style: 'currency',
            currency: 'EUR',
          }),
        });
      });
      this.sortOrgs();
      console.log('transactionsOrganisations', this.organisations);
      if (this.amountToShow > this.organisations[0].series.length) {
        this.amountToShow = this.organisations[0].series.length;
      }

      if (this.organisations[0].series.length > this.amountToShow) {
        this.organisations[0].series = this.organisations[0].series.slice(
          0,
          this.amountToShow
        );
      }

      const divideBy = this.opacity / this.amountToShow;
      for (let i = 0; i <= this.amountToShow; i++) {
        this.opacity = this.opacity - divideBy;
        const colorCodeRGBA = `26, 105, 127, ${this.opacity}`;
        this.colorScheme.domain.push(this.RGBAToHexA(colorCodeRGBA, false));
      }
      console.log('colorscheme', this.colorScheme);
    });

    const widgetInfo = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${localStorage.getItem('township')}/widgets/${
            this.widget.id
          }`
        )
      )
    ).data();
    this.widgetHeight = calculateWidgetHeight(widgetInfo.height, 34);
  }

  RGBAToHexA(rgba: string, forceRemoveAlpha: boolean) {
    return (
      '#' +
      rgba
        .replace(/^rgba?\(|\s+|\)$/g, '')
        .split(',')
        .filter((string, index) => !forceRemoveAlpha || index !== 3)
        .map((string) => parseFloat(string))
        .map((number, index) =>
          index === 3 ? Math.round(number * 255) : number
        )
        .map((number) => number.toString(16))
        .map((string) => (string.length === 1 ? '0' + string : string))
        .join('')
    );
  }

  sortOrgs() {
    this.organisations[0].series.sort((org1, org2) => {
      const a = org2.value;
      const b = org1.value;
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }
}
