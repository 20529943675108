// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adjust-password {
  margin: 18px 6px 6px 6px;
}

.no-margin {
  margin-left: 0px;
}

.no-margin-p {
  margin: 0;
}

.agree {
  margin-bottom: 0;
}

.warning {
  margin: 6px 0px 0px 0px;
}

.date-from-to {
  display: flex;
  flex-direction: column;
}

.green {
  color: green;
  cursor: pointer;
}

.red {
  color: red;
  cursor: pointer;
}

#form {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/app/general-settings/dialog/change-password/change-password.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;EACA,eAAA;AACF;;AACA;EACE,UAAA;EACA,eAAA;AAEF;;AACA;EACE,aAAA;EACA,sBAAA;AAEF","sourcesContent":[".adjust-password {\n  margin: 18px 6px 6px 6px;\n}\n\n.no-margin {\n  margin-left: 0px;\n}\n\n.no-margin-p {\n  margin: 0;\n}\n\n.agree {\n  margin-bottom: 0;\n}\n\n.warning {\n  margin: 6px 0px 0px 0px;\n}\n\n.date-from-to {\n  display: flex;\n  flex-direction: column;\n}\n\n.green {\n  color: green;\n  cursor: pointer;\n}\n.red {\n  color: red;\n  cursor: pointer;\n}\n\n#form {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
