import { Component } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { httpsCallable } from 'firebase/functions';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { functions, db } from 'src/app/app.component';
import { Township } from 'src/app/interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-user-to-township',
  templateUrl: './add-user-to-township.component.html',
  styleUrls: ['./add-user-to-township.component.scss'],
})
export class AddUserToTownshipComponent {
  userForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    township: new UntypedFormControl('', [Validators.required]),
    rights: new UntypedFormControl('', [Validators.required]),
  });
  saving: boolean;
  townships: Township[] = [];

  constructor(private snackbar: MatSnackBar) {}

  async ngOnInit() {
    const townshipDocs = (
      await getDocs(query(collection(db, 'township'), orderBy('name')))
    ).docs;
    townshipDocs.forEach((doc) => {
      const township = doc.data() as Township;
      this.townships.push({ ...township, id: doc.id });
    });
  }

  async save() {
    if (this.saving) {
      return;
    }
    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      return;
    }
    try {
      this.saving = true;
      const callable = httpsCallable(functions, 'accountManageTownshipUser');
      await callable({
        type: 'create',
        townshipId: this.userForm.value.township,
        email: this.userForm.value.email,
        rights: this.userForm.value.rights,
      });
      this.snackbar.open(`De gebruiker is toegevoegd aan de township`, 'X', {
        duration: 5000,
      });
      this.saving = false;
    } catch (error) {
      console.log('ERROR:', error);
      this.snackbar.open('Er is iets misgegaan', 'X', {
        duration: 5000,
      });
    }
  }
}
