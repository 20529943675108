// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .export-report-dialog-content {
  padding: 16px 0px 0px 0px !important;
}
::ng-deep .export-report-dialog-content .select-field {
  width: 100%;
}
::ng-deep .export-report-dialog-content .row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
}
::ng-deep .export-report-dialog-content .row .start-date-input,
::ng-deep .export-report-dialog-content .row .end-date-input {
  flex: 1;
}
::ng-deep .export-report-dialog-content .checkbox-list {
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  max-height: 300px;
  overflow-y: auto;
}

::ng-deep .export-report-dialog-actions {
  padding: 0px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
}
::ng-deep .export-report-dialog-actions button {
  font-weight: 600;
}
::ng-deep .export-report-dialog-actions .cancel-button {
  background: transparent;
  border: 1px solid #9e9d98;
  color: var(--primary);
}`, "",{"version":3,"sources":["webpack://./src/app/settings/dialog/export-report-dialog/export-report-dialog-component.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;AACF;AAAE;EACE,WAAA;AAEJ;AACE;EACE,aAAA;EACA,8BAAA;EACA,eAAA;EACA,QAAA;AACJ;AAAI;;EAEE,OAAA;AAEN;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;;AAGA;EACE,yBAAA;EACA,aAAA;EACA,8BAAA;AAAF;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,uBAAA;EACA,yBAAA;EACA,qBAAA;AADJ","sourcesContent":["::ng-deep .export-report-dialog-content {\n  padding: 16px 0px 0px 0px !important;\n  .select-field {\n    width: 100%;\n  }\n\n  .row {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n    gap: 5px;\n    .start-date-input,\n    .end-date-input {\n      flex: 1;\n    }\n  }\n  .checkbox-list {\n    display: flex;\n    flex-direction: column;\n    padding: 0 0 10px 0;\n    margin: 0 0 10px 0;\n    max-height: 300px;\n    overflow-y: auto;\n  }\n}\n\n::ng-deep .export-report-dialog-actions {\n  padding: 0px 0px 20px 0px;\n  display: flex;\n  justify-content: space-between;\n\n  button {\n    font-weight: 600;\n  }\n\n  .cancel-button {\n    background: transparent;\n    border: 1px solid #9e9d98;\n    color: var(--primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
