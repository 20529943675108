// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .widget-total-worth-sold {
  display: block;
  box-sizing: content-box;
  padding: 16px;
}
::ng-deep .widget-total-worth-sold .fitcontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
::ng-deep .widget-total-worth-sold .fitcontent mat-card-header {
  padding: 0;
}
::ng-deep .widget-total-worth-sold .fitcontent mat-card-header mat-card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::ng-deep .widget-total-worth-sold .fitcontent mat-card-content {
  padding: 0;
}
::ng-deep .widget-total-worth-sold .fitcontent mat-card-content .value-now {
  font-size: 24px;
  font-weight: 600;
}
::ng-deep .widget-total-worth-sold .fitcontent mat-card-content .value-then {
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/app/statistics/widgets/widget-total-worth-sold-vouchers/widget-total-worth-sold-vouchers.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,uBAAA;EACA,aAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AAEJ;AADI;EACE,UAAA;AAGN;AAFM;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAIR;AADI;EACE,UAAA;AAGN;AAFM;EACE,eAAA;EACA,gBAAA;AAIR;AAFM;EACE,eAAA;EACA,yBAAA;AAIR","sourcesContent":["::ng-deep .widget-total-worth-sold {\n  display: block;\n  box-sizing: content-box;\n  padding: 16px;\n  .fitcontent {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    mat-card-header {\n      padding: 0;\n      mat-card-title {\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n      }\n    }\n    mat-card-content {\n      padding: 0;\n      .value-now {\n        font-size: 24px;\n        font-weight: 600;\n      }\n      .value-then {\n        margin-top: 5px;\n        color: rgba(0, 0, 0, 0.5);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
