// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .send-mass-mail-form-title {
  padding: 0;
}

::ng-deep .send-mass-mail-form-content {
  padding: 10px !important;
}
::ng-deep .send-mass-mail-form-content .dialog-form {
  display: flex;
  flex-direction: column;
}
::ng-deep .send-mass-mail-form-content .dialog-form .campaign-checkbox {
  display: flex;
  align-items: center;
}

::ng-deep .send-mass-mail-form-actions {
  padding: 0;
}
::ng-deep .send-mass-mail-form-actions .outlined-button {
  border: 0 !important;
}

.loading-spinner {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/general-settings/dialog/mass-mail/mass-mail.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,wBAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;AAGN;;AAEA;EACE,UAAA;AACF;AAAE;EACE,oBAAA;AAEJ;;AAEA;EACE,aAAA;EACA,uBAAA;AACF","sourcesContent":["::ng-deep .send-mass-mail-form-title {\n  padding: 0;\n}\n\n::ng-deep .send-mass-mail-form-content {\n  padding: 10px !important;\n  .dialog-form {\n    display: flex;\n    flex-direction: column;\n    .campaign-checkbox {\n      display: flex;\n      align-items: center;\n    }\n  }\n}\n\n::ng-deep .send-mass-mail-form-actions {\n  padding: 0;\n  .outlined-button {\n    border: 0 !important;\n  }\n}\n\n.loading-spinner {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
