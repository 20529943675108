import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  setDoc,
  doc,
  deleteField,
  addDoc,
  collection,
  query,
  where,
  updateDoc,
  getDocs,
} from 'firebase/firestore';

export interface DialogData {
  id?: string;
  name?: string;
  text?: string;
  order?: number;
  townshipId: string;
}

@Component({
  selector: 'app-edit-group-link-dialog',
  templateUrl: './edit-group-link-dialog.component.html',
  styleUrls: ['./edit-group-link-dialog.component.scss'],
})
export class EditGroupLinkDialogComponent implements OnInit {
  waitingForResponse: boolean = false;
  groupLinkForm: UntypedFormGroup;
  editOrNew: string = 'Uitgiftegroep aanmaken';

  constructor(
    public dialogRef: MatDialogRef<EditGroupLinkDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    public db: AngularFirestore,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.groupLinkForm = this.formBuilder.group({
      name: [this.data.name],
      text: [this.data.text],
      order: [this.data.order],
    });
    if (this.data.id) {
      this.editOrNew = 'Uitgiftegroep aanpassen';
    }
  }

  async save() {
    this.waitingForResponse = true;
    let groupLinkObj;
    const form = this.groupLinkForm.value;
    try {
      if (this.data.id) {
        await setDoc(
          doc(
            this.db.firestore,
            `township/${this.data.townshipId}/groupLinks/${this.data.id}`
          ),
          {
            name: form.name,
            text: form.text ? form.text : deleteField(),
            order: form.order ? form.order : deleteField(),
          },
          { merge: true }
        );
        groupLinkObj = {
          id: this.data.id,
          name: form.name,
          text: form.text,
          order: form.order,
        };
        if (this.data.name != form.name) {
          const q = query(
            collection(
              this.db.firestore,
              `township/${this.data.townshipId}/voucherGroups`
            ),
            where('groupLink', '==', this.data.name)
          );
          const allGroupLinks = await getDocs(q);
          allGroupLinks.forEach(async (groupLink) => {
            await updateDoc(
              doc(
                this.db.firestore,
                `township/${this.data.townshipId}/voucherGroups`,
                `${groupLink.id}`
              ),
              {
                groupLink: form.name,
              }
            );
          });
        }
      } else {
        await addDoc(
          collection(
            this.db.firestore,
            `township/${this.data.townshipId}/groupLinks/`
          ),
          {
            name: form.name,
            text: form.text,
            order: form.order,
          }
        );
        groupLinkObj = {
          name: form.name,
          text: form.text,
          order: form.order,
        };
      }

      this.dialogRef.close(groupLinkObj);
      this.waitingForResponse = false;
    } catch (error) {
      this._snackBar.open(
        'Er is iets fout gegaan, probeer later opnieuw.',
        'X',
        {
          duration: 5000,
        }
      );
      this.waitingForResponse = false;
    }
  }
}
