import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Township } from 'src/app/interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import { doc, getDoc } from 'firebase/firestore';

@Component({
  selector: 'app-edit-sepa',
  templateUrl: './edit-sepa.component.html',
  styleUrls: ['./edit-sepa.component.scss'],
})
export class EditSepaComponent implements OnInit {
  pinForm: UntypedFormGroup;
  townshipId = localStorage.getItem('township');
  township: Township;

  constructor(
    public db: AngularFirestore,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EditSepaComponent>,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.pinForm = this.fb.group({
      authenticationCode: [, [Validators.required]],
    });
  }

  async checkPin() {
    if (this.pinForm.valid) {
      const pinCode = this.pinForm.value.authenticationCode;
      this.township = (
        await getDoc(doc(this.db.firestore, `/township/${this.townshipId}`))
      ).data() as Township;
      console.log('township id', this.townshipId);
      const defaultPin = (
        await getDoc(doc(this.db.firestore, '/admin/sepa-pin'))
      ).data() as any;
      if (pinCode === this.township.pinCode) {
        this.dialogRef.close(true);
      } else if (pinCode === defaultPin.pin) {
        this.dialogRef.close(true);
      } else {
        this.snackBar.open('Foutieve pin', 'X', {
          duration: 3000,
        });
      }
    }
  }
}
