import { Component, OnInit } from '@angular/core';
import {
  startOfDay,
  endOfDay,
  endOfMonth,
  isSameDay,
  isSameMonth,
  startOfMonth,
  endOfWeek,
  startOfWeek,
} from 'date-fns';
import { map, Observable, Subject, Subscription } from 'rxjs';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import moment from 'moment';
import { Appointment, AppointmentType, PlanningUser } from '../interfaces';
import { FormBuilder, FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { MatDialog } from '@angular/material/dialog';
import { capitalizeFirstLetter } from '../planning/helper';
import { getAuth } from 'firebase/auth';
import { screenSize } from '../app.component';
import { TeamAppointmentDetailsComponent } from './dialogs/team-appointment-details/team-appointment-details.component';

const colors: Record<string, EventColor> = {
  blue: {
    primary: '#05677E',
    secondary: '#B6EBFF',
    secondaryText: 'black',
  },
  green: {
    primary: '#4A8C67',
    secondary: '#ADF2C6',
    secondaryText: 'black',
  },
};

@Component({
  selector: 'app-planning',
  templateUrl: './planning-team.component.html',
  styleUrls: ['./planning-team.component.scss'],
})
export class PlanningTeamComponent implements OnInit {
  screenSize = screenSize;
  CalendarView = CalendarView;
  moment = moment;
  capitalizeFirstLetter = capitalizeFirstLetter;
  loaded: boolean = false;
  activeDayIsOpen: boolean = false;
  view: CalendarView = CalendarView.Week;
  calendarStartHour: number = 8;
  calendarEndHour: number = 18;
  hourSegments: number = 60 / 15; // Second number should be how many minutes for a segment

  viewDate: Date = new Date();
  viewWeekNumber: number = 0;
  viewBeginDate: Date = new Date();
  viewEndDate: Date = new Date();

  refresh = new Subject<void>();
  townshipId = localStorage.getItem('township') as string;

  selectedAppointmentTypeIdsForm = this.fb.group({});

  appointmentTypes: AppointmentType[] = [];

  appointments: Observable<Appointment[]>;
  appointmentSubscription: Subscription;
  unfilteredAppointments: Appointment[] = [];
  planningUsers: PlanningUser[] = [];

  events: CalendarEvent[] = [];
  userId: string = getAuth().currentUser.uid;

  constructor(
    private fb: FormBuilder,
    public db: AngularFirestore,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    moment.locale('nl');
    await this.getAppointmentTypes();
    this.selectedAppointmentTypeIdsForm.valueChanges.subscribe((val) => {
      this.filterEvents();
    });
    await this.getPlanningUsers();
    this.fetchEvents();
    this.loaded = true;
  }

  async getAppointmentTypes() {
    const appointmentTypesRef = collection(
      this.db.firestore,
      `township/${this.townshipId}/appointmentTypes`
    );
    const appointmentTypesDocs = await getDocs(
      query(appointmentTypesRef, orderBy('name'))
    );
    appointmentTypesDocs.forEach((appointmentDoc) => {
      const appointmentType = appointmentDoc.data() as AppointmentType;
      appointmentType.id = appointmentDoc.id;
      this.selectedAppointmentTypeIdsForm.addControl(
        appointmentType.id,
        new FormControl(true)
      );
      this.appointmentTypes.push(appointmentType);
    });
  }

  async getPlanningUsers() {
    const usersRef = collection(
      this.db.firestore,
      `township/${this.townshipId}/planningUsers`
    );
    const userDocs = await getDocs(
      query(usersRef, where('rights', '!=', 'planner'))
    );
    userDocs.forEach((userDoc) => {
      const user = userDoc.data() as PlanningUser;
      user.id = userDoc.id;
      this.planningUsers.push(user);
    });
    this.planningUsers.sort((userA, userB) => {
      if (userA.firstName.toLowerCase() > userB.firstName.toLowerCase()) {
        return 1;
      }
      return -1;
    });
  }

  async fetchEvents() {
    this.updateStartEndDate();
    const appointmentsCollection = this.db.collection(
      `township/${this.townshipId}/appointments`,
      (ref) =>
        ref
          .where('start', '>', this.viewBeginDate)
          .where('start', '<', this.viewEndDate)
          .where('planningUserIds', 'array-contains', this.userId)
    );
    this.appointments = appointmentsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as object;
          if (data['start']) {
            data['start'] = data['start'].toDate();
          }
          if (data['end']) {
            data['end'] = data['end'].toDate();
          }
          const appointmentData = data as Appointment;
          const id = a.payload.doc.id;
          return { id, ...appointmentData } as Appointment;
        })
      )
    );
    this.appointmentSubscription = this.appointments.subscribe(
      (appointments) => {
        const unfilteredAppointments = [];
        appointments.forEach((appointment) => {
          unfilteredAppointments.push(appointment);
        });
        this.unfilteredAppointments = unfilteredAppointments;
        this.filterEvents();
      }
    );
  }

  updateStartEndDate() {
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view];

    this.viewWeekNumber = moment(this.viewDate).week();
    this.viewBeginDate = getStart(this.viewDate, { weekStartsOn: 1 });
    this.viewEndDate = getEnd(this.viewDate, { weekStartsOn: 1 });
  }

  filterEvents() {
    const visibleAppointmentTypes = this.selectedAppointmentTypeIdsForm
      .value as any;
    const events: CalendarEvent[] = [];
    this.unfilteredAppointments.forEach((appointment) => {
      if (visibleAppointmentTypes[appointment.appointmentTypeId]) {
        let title = appointment.appointmentTypeName;
        appointment.planningUserNames?.forEach((name) => {
          title = `${title} ${name}`;
        });
        events.push({
          start: moment(appointment.start).add(-15, 'minutes').toDate(),
          end: appointment.start,
          title: `Reistijd +15 min`,
          color: { ...colors.green },
        });
        events.push({
          start: appointment.start,
          end: appointment.end,
          title: title,
          color: { ...colors.blue },
          meta: { appointment },
        });
        events.push({
          start: appointment.end,
          end: moment(appointment.end).add(15, 'minutes').toDate(),
          title: `Reistijd +15 min`,
          color: { ...colors.green },
        });
      }
    });
    this.events = events;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  setView(view: CalendarView) {
    this.view = view;
    this.fetchEvents();
  }

  dateChanged($event: { value: any }) {
    const newDateValue = $event.value;
    this.viewDate = newDateValue;
    this.fetchEvents();
  }

  appointmentDetails(event: CalendarEvent): void {
    this.dialog.open(TeamAppointmentDetailsComponent, {
      data: {
        appointment: event.meta.appointment,
        planningUsers: this.planningUsers,
      },
      disableClose: true,
      panelClass: ['fullscreen-dialog'],
    });
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
