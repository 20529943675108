import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/app/app.component';
import { PlanningUser, TownshipUser } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';
import {
  ManagementRights,
  OrgUserRights,
  PlanningUserRights,
  TeamUserRights,
  TownshipUserRights,
} from 'src/app/enums';

export interface DialogData {
  user: TownshipUser | PlanningUser;
  orgId?: string;
}

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss'],
})
export class DeleteUserComponent implements OnInit {
  townshipId = localStorage.getItem('township') as string;
  rights = {
    ...ManagementRights,
    ...TownshipUserRights,
    ...OrgUserRights,
    ...PlanningUserRights,
    ...TeamUserRights,
  };
  env = environment.env;
  saving: boolean;

  constructor(
    public dialogRef: MatDialogRef<DeleteUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  async delete() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    this.dialogRef.disableClose = true;
    let callable;
    if (this.env == 'township' || this.env == 'org') {
      callable = httpsCallable(functions, 'accountManageTownshipUser');
    } else if (this.env == 'planning') {
      callable = httpsCallable(functions, 'accountDeletePlanningUser');
    }
    try {
      const result = await callable({
        townshipId: this.townshipId,
        email: this.data.user.email,
      });
      if (result.data['status'] == 'unknown_error_deleting') {
        this.snackbar.open('Het verwijderen van de gebruiker is mislukt', 'X', {
          duration: 5000,
        });
      }
    } catch (e) {
      console.log(e);
      this.snackbar.open('Er is een onbekende fout opgetreden', 'X', {
        duration: 5000,
      });
    }
    this.dialogRef.close();
  }
}
