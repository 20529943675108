// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-datetimepicker-calendar {
  height: -moz-fit-content !important;
  height: fit-content !important;
}

::ng-deep .mat-datetimepicker-calendar-header {
  display: none;
}

.title {
  margin-bottom: 0;
}

.mat-mdc-dialog-content {
  padding: 10px 0 0 0;
}
.mat-mdc-dialog-content .widget-form {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}
.mat-mdc-dialog-content .widget-form .disable-toggle-wrapper {
  display: flex;
  justify-content: flex-end;
}
.mat-mdc-dialog-content .widget-form .disable-toggle-wrapper .disable-toggle {
  margin: 0 0 20px 0;
}
.mat-mdc-dialog-content .widget-form .form-field-wrapper,
.mat-mdc-dialog-content .widget-form .edit-widget-form-field {
  width: 100%;
}
.mat-mdc-dialog-content .widget-form ::ng-deep .suffix-edit .mat-mdc-form-field-icon-suffix {
  padding: 0 14px 0 4px;
}

.mat-mdc-dialog-actions {
  padding: 10px 0 0 0;
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/statistics/statistics-management/widget-manage/widget-manage.component.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;EAAA,8BAAA;AACF;;AACA;EACE,aAAA;AAEF;;AACA;EACE,gBAAA;AAEF;;AAAA;EACE,mBAAA;AAGF;AAFE;EACE,sBAAA;EACA,sBAAA;EACA,aAAA;AAIJ;AAHI;EACE,aAAA;EACA,yBAAA;AAKN;AAJM;EACE,kBAAA;AAMR;AAHI;;EAEE,WAAA;AAKN;AAFM;EACE,qBAAA;AAIR;;AACA;EACE,mBAAA;EACA,aAAA;EACA,yBAAA;AAEF","sourcesContent":["::ng-deep .mat-datetimepicker-calendar {\n  height: fit-content !important;\n}\n::ng-deep .mat-datetimepicker-calendar-header {\n  display: none;\n}\n\n.title {\n  margin-bottom: 0;\n}\n.mat-mdc-dialog-content {\n  padding: 10px 0 0 0;\n  .widget-form {\n    flex-direction: column;\n    box-sizing: border-box;\n    display: flex;\n    .disable-toggle-wrapper {\n      display: flex;\n      justify-content: flex-end;\n      .disable-toggle {\n        margin: 0 0 20px 0;\n      }\n    }\n    .form-field-wrapper,\n    .edit-widget-form-field {\n      width: 100%;\n    }\n    ::ng-deep .suffix-edit {\n      .mat-mdc-form-field-icon-suffix {\n        padding: 0 14px 0 4px;\n      }\n    }\n  }\n}\n.mat-mdc-dialog-actions {\n  padding: 10px 0 0 0;\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
