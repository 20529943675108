// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  margin: 0 !important;
}

.image {
  background-color: grey;
  height: 225px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 30px;
  border-radius: 10px;
}

.send-text {
  margin-bottom: 20px;
}

.copy-link {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.copy-link input {
  width: 70%;
  border: 1px solid rgb(163, 161, 161);
  padding: 12px;
  margin: 0 16px 0 0;
}
.copy-link .copy-button {
  font-size: 12px;
  height: 30px;
}

.dialog-end-stepper {
  justify-content: space-between;
}

.dialog-end-details {
  justify-content: flex-end;
}

input {
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/voucher-management/share-themed-voucher-group/share-themed-voucher-group.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,4BAAA;EACA,2BAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;AACF;AAAE;EACE,UAAA;EACA,oCAAA;EACA,aAAA;EACA,kBAAA;AAEJ;AAAE;EACE,eAAA;EACA,YAAA;AAEJ;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".title {\n  margin: 0 !important;\n}\n\n.image {\n  background-color: grey;\n  height: 225px;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  margin-bottom: 30px;\n  border-radius: 10px;\n}\n\n.send-text {\n  margin-bottom: 20px;\n}\n\n.copy-link {\n  margin-bottom: 20px;\n  display: flex;\n  align-items: center;\n  input {\n    width: 70%;\n    border: 1px solid rgb(163, 161, 161);\n    padding: 12px;\n    margin: 0 16px 0 0;\n  }\n  .copy-button {\n    font-size: 12px;\n    height: 30px;\n  }\n}\n\n.dialog-end-stepper {\n  justify-content: space-between;\n}\n\n.dialog-end-details {\n  justify-content: flex-end;\n}\n\ninput {\n  padding: 5px;\n}\n\n// .share-buttons {\n//   .whatsapp-icon {\n//     font-size: 20px;\n//   }\n//   .fb-icon {\n//     font-size: 18px;\n//   }\n// }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
