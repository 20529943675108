// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .order-edit-dialog .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::ng-deep .order-edit-dialog .header .title {
  margin: 0;
}
::ng-deep .order-edit-dialog .header .close-button {
  margin: 0 -12px 0 0;
}
::ng-deep .order-edit-dialog .mat-mdc-dialog-content {
  padding: 0 0 5px 0;
}
::ng-deep .order-edit-dialog .mat-mdc-dialog-content .vouchers {
  margin-top: 8px;
}
::ng-deep .order-edit-dialog .mat-mdc-dialog-content .vouchers .voucher {
  font-size: 12px;
}
::ng-deep .order-edit-dialog .mat-mdc-dialog-content #form {
  margin-top: 16px;
  padding: 10px 0 5px 0;
}
::ng-deep .order-edit-dialog .mat-mdc-dialog-content #form .voucher-code-field {
  width: 100%;
}
::ng-deep .order-edit-dialog .mat-mdc-dialog-content #form .voucher-code-field .mat-mdc-form-field-error-wrapper {
  top: -20px;
}
::ng-deep .order-edit-dialog .mat-mdc-dialog-actions {
  margin-top: 10px;
  padding: 0px;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-end;
}
::ng-deep .order-edit-dialog .mat-mdc-dialog-actions .outlined-button {
  font-size: 14px !important;
}
::ng-deep .order-edit-dialog .mat-mdc-dialog-actions span {
  font-weight: 500;
}
::ng-deep .order-edit-dialog .mat-mdc-dialog-actions button {
  margin: 0 0 6px 0;
}
::ng-deep .order-edit-dialog .mat-mdc-dialog-actions button:last-child {
  margin: 0;
}

mat-divider {
  margin-bottom: 8px;
  margin-top: 16px;
}

.divider-text {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/dialogs/order/order.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAJ;AACI;EACE,SAAA;AACN;AACI;EACE,mBAAA;AACN;AAEE;EACE,kBAAA;AAAJ;AACI;EACE,eAAA;AACN;AAAM;EACE,eAAA;AAER;AACI;EACE,gBAAA;EACA,qBAAA;AACN;AAAM;EACE,WAAA;AAER;AADQ;EACE,UAAA;AAGV;AAEE;EACE,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,qBAAA;AAAJ;AACI;EACE,0BAAA;AACN;AACI;EACE,gBAAA;AACN;AACI;EACE,iBAAA;AACN;AACI;EACE,SAAA;AACN;;AAIA;EACE,kBAAA;EACA,gBAAA;AADF;;AAGA;EACE,SAAA;AAAF","sourcesContent":["::ng-deep .order-edit-dialog {\n  .header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    .title {\n      margin: 0;\n    }\n    .close-button {\n      margin: 0 -12px 0 0;\n    }\n  }\n  .mat-mdc-dialog-content {\n    padding: 0 0 5px 0;\n    .vouchers {\n      margin-top: 8px;\n      .voucher {\n        font-size: 12px;\n      }\n    }\n    #form {\n      margin-top: 16px;\n      padding: 10px 0 5px 0;\n      .voucher-code-field {\n        width: 100%;\n        .mat-mdc-form-field-error-wrapper {\n          top: -20px;\n        }\n      }\n    }\n  }\n  .mat-mdc-dialog-actions {\n    margin-top: 10px;\n    padding: 0px;\n    flex-wrap: nowrap;\n    flex-direction: column;\n    align-items: flex-end;\n    .outlined-button {\n      font-size: 14px !important;\n    }\n    span {\n      font-weight: 500;\n    }\n    button {\n      margin: 0 0 6px 0;\n    }\n    button:last-child {\n      margin: 0;\n    }\n  }\n}\n\nmat-divider {\n  margin-bottom: 8px;\n  margin-top: 16px;\n}\n.divider-text {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
