import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { GeneralStatistics, Widget } from 'src/app/interfaces';
import { checkAndUpdateStats } from '../../bigquery-helper';
import {
  collection,
  deleteField,
  doc,
  getDocs,
  getDoc,
  setDoc,
  where,
  query,
} from 'firebase/firestore';
import { calculateWidgetHeight } from '../../widgets-helper';

@Component({
  selector: 'widget-daily',
  templateUrl: './widget-daily.component.html',
  styleUrls: ['./widget-daily.component.scss'],
})
export class WidgetDailyComponent implements OnInit {
  @Input() widget: Widget;
  dailyStatistics: Observable<any>;
  townshipId = localStorage.getItem('township');
  activatedVouchers: number;
  activeVoucherGroups: number;
  claimedVouchers: number;
  organisations: number;
  widgetHeight: any;

  constructor(public db: AngularFirestore) {}

  async ngOnInit(): Promise<void> {
    checkAndUpdateStats(this.widget, 'bigqueryStatisticsOfToday', {
      townshipId: this.townshipId,
    });
    this.dailyStatistics = this.db
      .doc<any>(`township/${this.townshipId}/statistics/daily`)
      .valueChanges();
    this.dailyStatistics.subscribe((statistics) => {
      this.activatedVouchers = statistics.activatedVouchers;
      this.activeVoucherGroups = statistics.activeVoucherGroups;
      this.claimedVouchers = statistics.claimedVouchers;
      this.organisations = statistics.visibleOrganisations;
      console.log('dailyStatistics', statistics);
    });

    const widgetInfo = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${localStorage.getItem('township')}/widgets/${
            this.widget.id
          }`
        )
      )
    ).data();
    this.widgetHeight = calculateWidgetHeight(widgetInfo.height, 34);
  }
}
