// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .widget-transactions {
  display: block;
  box-sizing: content-box;
  padding: 16px;
}
::ng-deep .widget-transactions mat-card-header {
  padding: 0;
}
::ng-deep .widget-transactions mat-card-content {
  padding: 0;
  height: calc(100% - 30px);
}
::ng-deep .widget-transactions mat-card-content .flex-box {
  height: 100%;
}
::ng-deep .widget-transactions mat-card-content .flex-box .room-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
::ng-deep .widget-transactions mat-card-content .flex-box .room-between .most-recent {
  font-size: 12px;
  opacity: 0.8;
  margin: -5px 0 10px 0;
}
::ng-deep .widget-transactions mat-card-content .flex-box .room-between .scroll-wrapper {
  height: 100%;
  overflow-y: auto;
}
::ng-deep .widget-transactions mat-card-content .flex-box .room-between .scroll-wrapper .organisation {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  justify-content: space-between;
}
::ng-deep .widget-transactions mat-card-content .flex-box .room-between .scroll-wrapper .organisation .org-wrapper {
  display: flex;
  align-items: center;
}
::ng-deep .widget-transactions mat-card-content .flex-box .room-between .scroll-wrapper .organisation .org-wrapper .org-dot {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-right: 10px;
}
::ng-deep .widget-transactions mat-card-content .flex-box .room-between .chart-height {
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/statistics/widgets/widget-transactions/widget-transactions.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,uBAAA;EACA,aAAA;AACF;AAAE;EACE,UAAA;AAEJ;AAAE;EACE,UAAA;EACA,yBAAA;AAEJ;AADI;EACE,YAAA;AAGN;AAFM;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;AAIR;AAHQ;EACE,eAAA;EACA,YAAA;EACA,qBAAA;AAKV;AAHQ;EACE,YAAA;EACA,gBAAA;AAKV;AAJU;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,8BAAA;AAMZ;AALY;EACE,aAAA;EACA,mBAAA;AAOd;AANc;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;AAQhB;AAHQ;EACE,YAAA;AAKV","sourcesContent":["::ng-deep .widget-transactions {\n  display: block;\n  box-sizing: content-box;\n  padding: 16px;\n  mat-card-header {\n    padding: 0;\n  }\n  mat-card-content {\n    padding: 0;\n    height: calc(100% - 30px);\n    .flex-box {\n      height: 100%;\n      .room-between {\n        display: flex;\n        flex-direction: column;\n        justify-content: space-between;\n        height: 100%;\n        .most-recent {\n          font-size: 12px;\n          opacity: 0.8;\n          margin: -5px 0 10px 0;\n        }\n        .scroll-wrapper {\n          height: 100%;\n          overflow-y: auto;\n          .organisation {\n            display: flex;\n            align-items: center;\n            margin: 0 0 10px 0;\n            justify-content: space-between;\n            .org-wrapper {\n              display: flex;\n              align-items: center;\n              .org-dot {\n                height: 14px;\n                width: 14px;\n                border-radius: 50%;\n                margin-right: 10px;\n              }\n            }\n          }\n        }\n        .chart-height {\n          height: 50px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
