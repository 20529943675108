// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .widget-action-running-time {
  display: block;
  box-sizing: content-box;
  padding: 16px;
}
::ng-deep .widget-action-running-time .fitcontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
::ng-deep .widget-action-running-time mat-card-header {
  padding: 0;
}
::ng-deep .widget-action-running-time mat-card-header mat-card-title .mat-card-main-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::ng-deep .widget-action-running-time mat-card-header mat-card-title .mat-card-sub-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -10px;
  font-size: 16px;
}
::ng-deep .widget-action-running-time mat-card-content {
  padding: 0;
}
::ng-deep .widget-action-running-time mat-card-content .flex-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
::ng-deep .widget-action-running-time mat-card-content .flex-box .empty-bar {
  flex-grow: 1;
  background-color: #fefcf4;
  height: 16px;
  border-radius: 16px;
}
::ng-deep .widget-action-running-time mat-card-content .flex-box .empty-bar .filling {
  border-radius: 16px;
  background-color: var(--accent);
  height: 100%;
}
::ng-deep .widget-action-running-time mat-card-content .flex-box .total-vouchers {
  margin-left: 15px;
  font-size: 20px;
  font-weight: bold;
}
::ng-deep .widget-action-running-time mat-card-content .flex-box span {
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/app/statistics/widgets/widget-action-running-time/widget-action-running-time.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,uBAAA;EACA,aAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AAEJ;AAAE;EACE,UAAA;AAEJ;AAAM;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAER;AAAM;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,iBAAA;EACA,eAAA;AAER;AAEE;EACE,UAAA;AAAJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACN;AAAM;EACE,YAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;AAER;AADQ;EACE,mBAAA;EACA,+BAAA;EACA,YAAA;AAGV;AAAM;EACE,iBAAA;EACA,eAAA;EACA,iBAAA;AAER;AAAM;EACE,uBAAA;EAAA,kBAAA;AAER","sourcesContent":["::ng-deep .widget-action-running-time {\n  display: block;\n  box-sizing: content-box;\n  padding: 16px;\n  .fitcontent {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }\n  mat-card-header {\n    padding: 0;\n    mat-card-title {\n      .mat-card-main-title {\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n      }\n      .mat-card-sub-title {\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        margin-top: -10px;\n        font-size: 16px;\n      }\n    }\n  }\n  mat-card-content {\n    padding: 0;\n    .flex-box {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      .empty-bar {\n        flex-grow: 1;\n        background-color: #fefcf4;\n        height: 16px;\n        border-radius: 16px;\n        .filling {\n          border-radius: 16px;\n          background-color: var(--accent);\n          height: 100%;\n        }\n      }\n      .total-vouchers {\n        margin-left: 15px;\n        font-size: 20px;\n        font-weight: bold;\n      }\n      span {\n        width: fit-content;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
