import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from '../../../environments/environment';
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { User as firebaseUser } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { db, functions } from 'src/app/app.component';
import { httpsCallable } from 'firebase/functions';

export interface User {
  email: string;
  firstName: string;
  phone: string;
  acceptPrivacy: boolean;
}

export interface Plan {
  name: string;
  maxVolunteers: number;
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  env = environment;
  name: string = environment.name;
  title = environment.login.title.register;
  description = environment.login.description.register;
  image: string = environment.login.image;
  signupForm: UntypedFormGroup;
  awaitingResponse = false;
  showPassword: boolean;
  showConfirmPassword: boolean;
  toegangsbon: boolean = false;
  registrationComplete: boolean = false;
  user: firebaseUser;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private afdb: AngularFirestore
  ) {}

  ngOnInit() {
    if (environment.prefix === 'toegangs') {
      this.toegangsbon = true;
    }
    this.signupForm = this.fb.group({
      organisationName: [, Validators.required],
      email: [, Validators.required],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });

    this.signupForm.controls.password.valueChanges.subscribe((value) => {
      this.signupForm.controls.confirmPassword.reset();
    });

    this.signupForm.controls.confirmPassword.valueChanges.subscribe((value) => {
      if (value !== this.signupForm.controls.password.value) {
        this.signupForm.controls.confirmPassword.setErrors({
          passwordsAreNotEqual: true,
        });
      }
    });
  }

  async signup() {
    if (this.signupForm.invalid) {
      this.signupForm.markAllAsTouched();
    }

    if (this.awaitingResponse) {
      console.log('already awaiting response');
      return;
    }
    this.awaitingResponse = true;
    const signupData = this.signupForm.value;
    console.log('signupData', signupData);
    // if (!this.toegangsbon) {
    //   if (
    //     !this.urlCheck(signupData.url, 'url') ||
    //     !this.urlCheck(signupData.termsUrl, 'terms')
    //   ) {
    //     this.awaitingResponse = false;
    //     return;
    //   }
    // }
    if (signupData.password === signupData.confirmPassword) {
      const auth = getAuth();
      await createUserWithEmailAndPassword(
        auth,
        signupData.email,
        signupData.password
      )
        .then(async (res) => {
          console.log('USERRESPONSE:', res);
          const townshipId = this.afdb.createId();
          const user = res.user;
          const userDoc = doc(db, `/users/${user.uid}`);
          await setDoc(
            userDoc,
            {
              email: signupData.email,
              township: townshipId,
            },
            { merge: true }
          );
          let prefixImg;
          let postData;
          if (this.toegangsbon) {
            prefixImg = environment.prefixImgToegang;
            postData = {
              creatorEmail: signupData.email,
              creatorId: user.uid,
              townshipId,
              townshipData: {
                name: signupData.organisationName,
                voucherPrefix: environment.prefix,
                voucherImg: prefixImg,
                url: '',
                termsUrl: '',
                primaryColor: '#1a697f',
                accentColor: '#fc9f5b',
                prefix: '',
                paymentDone: 0,
                pinCode: '0000',
                usesSendGrid: true,
              },
            };
          } else {
            prefixImg =
              environment.prefix === 'groene'
                ? environment.prefixImgGroene
                : environment.prefixImgLokale;
            postData = {
              creatorEmail: signupData.email,
              creatorId: user.uid,
              townshipId,
              townshipData: {
                id: townshipId,
                name: signupData.organisationName,
                url: '',
                termsUrl: '',
                voucherPrefix: environment.prefix,
                voucherImg: prefixImg,
                primaryColor: '#1a697f',
                accentColor: '#fc9f5b',
                prefix: '',
                paymentDone: 0,
                pinCode: '0000',
                usesSendGrid: true,
              },
            };
          }
          console.log('environment', environment);
          const callable = httpsCallable(functions, 'httpCreateTownship');
          console.log('postData', postData);
          await callable(postData).then(async (response) => {
            console.log('RESPONSE:', response);

            const result = response.data['status'] as string;
            this.awaitingResponse = undefined;
            console.log('result', result);
            if (result === 'success') {
              await sendEmailVerification(user);
              this.registrationComplete = true;
              this.user = user;
            } else {
              this.snackBar.open(
                'Een onbekende fout is opgetreden, neem contact op of probeer het later nog een keer.',
                '',
                {
                  duration: 10000,
                }
              );
            }
          });
          // await batch.commit();
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error('errorCode', errorCode);
          console.error('errorMessage', errorMessage);
          switch (errorCode) {
            case 'auth/email-already-in-use':
              this.signupForm.controls.email.setErrors({
                emailAlreadyInUse: true,
              });

              break;
            case 'auth/weak-password':
              this.signupForm.controls.password.setErrors({
                weakPassword: true,
              });
              break;
            default:
              this.snackBar.open('Er is een onbekende fout opgetreden.', '', {
                duration: 5000,
              });
          }
          this.awaitingResponse = false;
          // ...
        });
    }
  }

  getError(name) {
    const field = this.signupForm.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      if (field.hasError('emailAlreadyInUse')) {
        return 'Er is al een account aan dit e-mailadres gekoppeld.';
      }
      if (field.hasError('weakPassword')) {
        return 'Je wachtwoord moet minimaal 6 karakters zijn.';
      }
      if (field.hasError('passwordsAreNotEqual')) {
        return 'Wachtwoorden komen niet overeen.';
      }
      return '';
    }
  }

  async resendVerificationEmail() {
    await sendEmailVerification(this.user);
  }

  // urlCheck(saveObj, controller) {
  //   let invalidUrl = false;

  //   const expression =
  //     /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  //   const regex = new RegExp(expression);

  //   if (saveObj.match(regex)) {
  //     // console.log('valid URL', saveObj);
  //   } else {
  //     invalidUrl = true;
  //   }
  //   if (invalidUrl) {
  //     if (controller === 'terms') {
  //       this.signupForm.controls.termsUrl.setErrors({
  //         notMatched: true,
  //       });
  //     } else {
  //       this.signupForm.controls.url.setErrors({
  //         notMatched: true,
  //       });
  //     }
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
}
