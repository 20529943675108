import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { SettingsComponent } from '../../settings.component';

@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss'],
})
export class ImportExportComponent implements OnInit {
  importExportDialog: UntypedFormGroup;
  options: any[] = [
    { name: 'Exporteren', value: 'export', checked: true },
    { name: 'Importeren', value: 'import', checked: false },
  ];
  selectedOption: {
    name: string;
    value: string;
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SettingsComponent>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.selectedOption = {
      name: 'Exporteren',
      value: 'export',
    };

    this.importExportDialog = this.fb.group({
      type: [this.selectedOption.value, Validators.required],
    });
    this.importExportDialog.controls.type.valueChanges.subscribe((changed) => {
      if (changed.value === 'export') {
        this.selectedOption = { name: 'Exporteren', value: 'export' };
      } else {
        this.selectedOption = { name: 'Importeren', value: 'import' };
      }
    });
  }

  save() {
    const saveObj = { type: this.selectedOption.value } as any;
    this.dialogRef.close(saveObj);
  }
}
