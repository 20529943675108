// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page .account-options-wrapper {
  width: 85%;
  margin: 0 auto 0 auto;
}
.page .account-options-wrapper .container {
  display: flex;
  border-bottom: black 1px solid;
  border-left: #1a697f 4px solid;
  padding: 0 0 0 10px;
  background-color: #fbfaed !important;
  transition: all 0.15s ease-out;
  cursor: pointer;
}
.page .account-options-wrapper .container .column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page .account-options-wrapper .container .column .next-button {
  padding: 8px !important;
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 20;
}
.page .account-options-wrapper .container .column .multi-factor-status {
  display: flex;
  align-items: center;
}
.page .account-options-wrapper .container .column .multi-factor-status mat-icon {
  margin-right: 10px;
  border-radius: 50%;
  color: white;
}
.page .account-options-wrapper .container .column .multi-factor-status .check-icon {
  color: var(--master);
}
.page .account-options-wrapper .container .column .multi-factor-status .close-icon {
  color: var(--warn);
}
.page .account-options-wrapper .container .column:last-child {
  align-items: flex-end;
}
.page .account-options-wrapper .container:hover {
  filter: brightness(0.95);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/account/account.component.scss"],"names":[],"mappings":"AACE;EACE,UAAA;EACA,qBAAA;AAAJ;AACI;EACE,aAAA;EACA,8BAAA;EACA,8BAAA;EACA,mBAAA;EACA,oCAAA;EACA,8BAAA;EACA,eAAA;AACN;AAAM;EACE,OAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAER;AADQ;EACE,uBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;AAGV;AADQ;EACE,aAAA;EACA,mBAAA;AAGV;AAFU;EACE,kBAAA;EACA,kBAAA;EACA,YAAA;AAIZ;AAFU;EACE,oBAAA;AAIZ;AAFU;EACE,kBAAA;AAIZ;AAAM;EACE,qBAAA;AAER;AACI;EACE,wBAAA;EACA,8FAAA;AACN","sourcesContent":[".page {\n  .account-options-wrapper {\n    width: 85%;\n    margin: 0 auto 0 auto;\n    .container {\n      display: flex;\n      border-bottom: black 1px solid;\n      border-left: #1a697f 4px solid;\n      padding: 0 0 0 10px;\n      background-color: #fbfaed !important;\n      transition: all 0.15s ease-out;\n      cursor: pointer;\n      .column {\n        flex: 1;\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        .next-button {\n          padding: 8px !important;\n          height: 40px;\n          width: 40px;\n          position: relative;\n          z-index: 20;\n        }\n        .multi-factor-status {\n          display: flex;\n          align-items: center;\n          mat-icon {\n            margin-right: 10px;\n            border-radius: 50%;\n            color: white;\n          }\n          .check-icon {\n            color: var(--master);\n          }\n          .close-icon {\n            color: var(--warn);\n          }\n        }\n      }\n      .column:last-child {\n        align-items: flex-end;\n      }\n    }\n    .container:hover {\n      filter: brightness(0.95);\n      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),\n        0px 1px 3px 1px rgba(0, 0, 0, 0.15) !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
