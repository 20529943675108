// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-list-item-content {
  padding-right: 0px !important;
}

#form {
  display: flex;
  flex-direction: column;
}

::ng-deep .checkbox-fixed-label .mdc-form-field .mdc-label {
  text-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/app/payment-references/dialog/sepa-confirmation/sepa-confirmation.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAII;EACE,eAAA;AADN","sourcesContent":[".mat-list-item-content {\n  padding-right: 0px !important;\n}\n\n#form {\n  display: flex;\n  flex-direction: column;\n}\n\n::ng-deep .checkbox-fixed-label {\n  .mdc-form-field {\n    .mdc-label {\n      text-wrap: wrap;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
