import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { GeneralStatistics, Widget } from 'src/app/interfaces';
import { Observable } from 'rxjs';
import { checkAndUpdateStats } from '../../bigquery-helper';
import { doc, getDoc } from 'firebase/firestore';
import { calculateWidgetHeight } from '../../widgets-helper';

@Component({
  selector: 'widget-total-worth-sold-vouchers',
  templateUrl: './widget-total-worth-sold-vouchers.component.html',
  styleUrls: ['./widget-total-worth-sold-vouchers.component.scss'],
})
export class WidgetTotalWorthSoldVouchersComponent implements OnInit {
  @Input() widget: Widget;
  @Input() statistics: Observable<GeneralStatistics>;
  townshipId = localStorage.getItem('township');
  compareDate: Date;
  totalCash: any = 0;
  vouchersSince = 0;
  activatedVouchers = 0;
  percentageIncrease = 0;
  widgetHeight: string;

  constructor(public db: AngularFirestore) {}

  async ngOnInit(): Promise<void> {
    this.compareDate = this.widget.settings.fromDate.toDate();
    this.totalCash = Number(
      this.widget.settings?.cashAmount.totalCash
        ? this.widget.settings.cashAmount.totalCash
        : 0
    ).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' });
    this.vouchersSince = this.widget.settings?.vouchersSince.vouchersSince
      ? this.widget.settings.vouchersSince.vouchersSince
      : 0;

    const params = {
      townshipId: this.townshipId,
      fromDate: this.compareDate,
      widgetId: this.widget.id,
    };

    checkAndUpdateStats(this.widget, 'bigqueryTotalWorthSoldVouchers', params);

    this.statistics.subscribe((statistics) => {
      this.activatedVouchers = statistics.activatedVouchers;

      let percentage =
        this.vouchersSince && this.activatedVouchers
          ? (this.vouchersSince / this.activatedVouchers) * 100
          : 100;
      percentage =
        percentage > 99 && percentage < 100
          ? Math.floor(percentage)
          : Math.round(percentage);
      this.percentageIncrease = 100 - percentage;
    });
    const widgetInfo = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${localStorage.getItem('township')}/widgets/${
            this.widget.id
          }`
        )
      )
    ).data();
    this.widgetHeight = calculateWidgetHeight(widgetInfo.height, 34);
  }
}
