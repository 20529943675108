// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-selection-list {
  overflow-y: auto;
  max-height: 500px;
}
mat-selection-list .township-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
mat-selection-list .township-option .icon-group {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
mat-selection-list .township-option .icon-group .mat-icon:first-child {
  margin: 0 5px 0 0;
}

::ng-deep .mat-mdc-dialog-title {
  margin: 0 0 20px 0;
}

::ng-deep .mdc-list-item__end {
  display: none !important;
}

.search {
  width: 100%;
  margin: 0 0 -15px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard-township/dialog/change-township-dialog/change-township-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AAAI;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;AAEN;AADM;EACE,iBAAA;AAGR;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,wBAAA;AAAF;;AAGA;EACE,WAAA;EACA,mBAAA;AAAF","sourcesContent":["mat-selection-list {\n  overflow-y: auto;\n  max-height: 500px;\n\n  .township-option {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    .icon-group {\n      display: flex;\n      align-items: center;\n      justify-content: space-around;\n      .mat-icon:first-child {\n        margin: 0 5px 0 0;\n      }\n    }\n  }\n}\n\n::ng-deep .mat-mdc-dialog-title {\n  margin: 0 0 20px 0;\n}\n\n::ng-deep .mdc-list-item__end {\n  display: none !important;\n}\n\n.search {\n  width: 100%;\n  margin: 0 0 -15px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
