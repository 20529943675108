// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-color {
  color: var(--primary);
}

.img-wrapper {
  width: 100%;
  text-align: center;
}
.img-wrapper img {
  width: 40%;
  height: 310px;
}

.description .description-txt {
  background-color: whitesmoke;
  padding: 10px 10px;
  padding-bottom: 12px;
}

.form {
  margin-top: 28px;
  margin-bottom: 28px;
}
.form img {
  width: 80%;
  height: 100px;
}
.form button {
  color: white;
}
.form button mat-icon {
  color: white;
}

h3 {
  font-weight: 425;
}

.button-wrapper {
  display: flex;
  justify-content: center;
}

::ng-deep .compile-promo-dialog-title {
  padding: 0;
}

::ng-deep .compile-promo-dialog-content {
  padding: 16px 0 0 0 !important;
}
::ng-deep .compile-promo-dialog-content mat-form-field {
  width: 100%;
}

::ng-deep .compile-promo-dialog-actions {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/promotion-materials/dialogs/compile-promo/compile-promo.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;AACF;AACE;EACE,UAAA;EACA,aAAA;AACJ;;AAIE;EACE,4BAAA;EACA,kBAAA;EACA,oBAAA;AADJ;;AAKA;EACE,gBAAA;EACA,mBAAA;AAFF;AAGE;EACE,UAAA;EACA,aAAA;AADJ;AAGE;EACE,YAAA;AADJ;AAEI;EACE,YAAA;AAAN;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,aAAA;EACA,uBAAA;AAFF;;AAKA;EACE,UAAA;AAFF;;AAKA;EACE,8BAAA;AAFF;AAGE;EACE,WAAA;AADJ;;AAKA;EACE,UAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AAFF","sourcesContent":[".primary-color {\n  color: var(--primary);\n}\n\n.img-wrapper {\n  width: 100%;\n  text-align: center;\n  // background-color: whitesmoke;\n  img {\n    width: 40%;\n    height: 310px;\n  }\n}\n\n.description {\n  .description-txt {\n    background-color: whitesmoke;\n    padding: 10px 10px;\n    padding-bottom: 12px;\n  }\n}\n\n.form {\n  margin-top: 28px;\n  margin-bottom: 28px;\n  img {\n    width: 80%;\n    height: 100px;\n  }\n  button {\n    color: white;\n    mat-icon {\n      color: white;\n    }\n  }\n}\n\nh3 {\n  font-weight: 425;\n}\n\n.button-wrapper {\n  display: flex;\n  justify-content: center;\n}\n\n::ng-deep .compile-promo-dialog-title {\n  padding: 0;\n}\n\n::ng-deep .compile-promo-dialog-content {\n  padding: 16px 0 0 0 !important;\n  mat-form-field {\n    width: 100%;\n  }\n}\n\n::ng-deep .compile-promo-dialog-actions {\n  padding: 0;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
