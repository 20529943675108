import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import {
  arrayUnion,
  getDoc,
  doc,
  updateDoc,
  arrayRemove,
  getDocs,
  collection,
} from 'firebase/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organisation, Voucher, VoucherGroup } from 'src/app/interfaces';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

export interface DialogData {
  voucher: Voucher;
  voucherRef: DocumentReference;
  voucherGroup?: VoucherGroup;
  org?: Organisation;
  orgHasClaimRights?: boolean;
  userRights?: string;
}

@Component({
  selector: 'app-manage-assignment',
  templateUrl: './manage-assignment.component.html',
  styleUrls: ['./manage-assignment.component.scss'],
})
export class ManageAssignmentComponent implements OnInit {
  voucher: Voucher;
  voucherGroup: VoucherGroup;
  voucherRef: DocumentReference;
  townshipId = localStorage.getItem('township') as string;
  voucherForm: FormGroup = new FormGroup({
    responsibles: new FormControl(''),
    contactedOn: new FormControl(),
    appointmentDate: new FormControl(),
    appointmentTime: new FormControl(''),
    material: new FormControl(''),
    notes: new FormControl(''),
    done: new FormControl(),
    declined: new FormControl(),
    receiptValue: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
  });
  saving: boolean;
  disabledForm: boolean;
  amountToPayOrg: number = 0;
  customerShouldPay: number = 0;
  receiptFile: File;
  receiptUrl: string;
  township = localStorage.getItem('township');
  voucherCollection: AngularFirestoreCollection<Voucher>;
  organisationCollection: AngularFirestoreCollection<Organisation>;
  voucherGroups: Map<string, VoucherGroup> = new Map();

  allFiltered: string[] = [];
  switchValuesRemove = [];
  switchValuesSelected = [];

  responsibles: string[] = [];
  responsiblesStatus: boolean;
  responsibleCtrl = new FormControl();
  allResponsibles: string[] = [];
  filteredResponsibles: Observable<string[]>;

  materials: string[] = [];
  materialsStatus: boolean;
  materialCtrl = new FormControl();
  allFilteredMaterials: string[] = [];
  filteredMaterials: Observable<string[]>;

  readonly separatorKeysCodes = [COMMA, ENTER] as const;
  @ViewChild('responsibleInput')
  responsibleInput: ElementRef<HTMLInputElement>;
  @ViewChild('materialInput')
  materialInput: ElementRef<HTMLInputElement>;
  @ViewChild('responsibleInput', { read: MatAutocompleteTrigger })
  responsibleAutoComplete: MatAutocompleteTrigger;
  @ViewChild('materialInput', { read: MatAutocompleteTrigger })
  materialAutoComplete: MatAutocompleteTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public db: AngularFirestore,
    public dialogRef: MatDialogRef<ManageAssignmentComponent>,
    private snackbar: MatSnackBar,
    private storage: AngularFireStorage
  ) {
    this.voucher = data.voucher;
    this.voucherGroup = data.voucherGroup;
    // console.log('data', data);
    if (data.userRights && data.userRights === 'employee') {
      this.voucherForm.controls.responsibles.disable();
    }
    if (!data.org) {
      // Only organisations are allowed to claim vouchers
      // this.voucherForm.controls.done.disable();
    } else if (!data.orgHasClaimRights) {
      // Only organisations with claim rights can manipulate these vouchers
      this.disabledForm = true;
      this.voucherForm.disable();
    }
    if (this.voucher.appointmentDate) {
      this.voucher.appointmentDate = this.createDateFromString(
        this.voucher.appointmentDate
      );
    }
    if (this.voucher.contactedOn) {
      this.voucher.contactedOn = this.createDateFromString(
        this.voucher.contactedOn
      );
    }
    const patchObj = this.voucher as any;
    if (this.voucher.claimDate) {
      this.voucherForm.controls.done.disable();
      this.voucherForm.controls.declined.disable();
      if (this.voucher.voucherGroupId !== 'expired') {
        patchObj.done = true;
      }
      if (this.voucher.voucherGroupId === 'expired') {
        patchObj.declined = true;
      }
    }
    if (!this.voucher.receiptValue && this.voucher.value) {
      patchObj.receiptValue = Number(this.voucher.value);
    }
    this.voucherRef = data.voucherRef;
    this.voucherForm.patchValue(this.voucher);
    if (this.voucherForm.controls.done.enabled && this.voucherForm.value.done) {
      this.voucherForm.controls.receiptValue.enable();
    }
  }

  async ngOnInit(): Promise<void> {
    const voucher = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${this.township}/vouchers/${this.data.voucher.number}`
        )
      )
    ).data() as Voucher;

    if (this.data.org) {
      const organisation = (
        await getDoc(
          doc(
            this.db.firestore,
            `township/${this.township}/organisations/${this.data.org.id}`
          )
        )
      ).data() as Organisation;
      const orgUserDocs = (
        await getDocs(
          collection(
            this.db.firestore,
            `township/${this.township}/organisations/${this.data.org.id}/users`
          )
        )
      ).docs;
      orgUserDocs.forEach((userDoc) => {
        const user = userDoc.data();
        if (user.name) {
          this.allResponsibles.push(user.name);
        }
      });

      console.log('allResponsibles', this.allResponsibles);
      this.filteredResponsibles = this.responsibleCtrl.valueChanges.pipe(
        startWith(null),
        map((responsible: string | null) =>
          responsible
            ? this._filter('responsible', responsible)
            : this.allResponsibles.slice()
        )
      );

      if (organisation.materials) {
        this.allFilteredMaterials = organisation.materials;
      }
      if (this.data.org.materials) {
        this.filteredMaterials = this.materialCtrl.valueChanges.pipe(
          startWith(null),
          map((material: string | null) =>
            material
              ? this._filter('material', material)
              : this.allFilteredMaterials.slice()
          )
        );
      }
    }

    console.log('data', this.data);
    this.voucherForm.controls.done.valueChanges.subscribe((val) => {
      if (val) {
        this.voucherForm.controls.receiptValue.enable();
        if (this.voucherForm.value.declined) {
          this.voucherForm.controls.declined.setValue(false);
        }
      } else {
        this.voucherForm.controls.receiptValue.disable();
      }
    });
    this.voucherForm.controls.declined.valueChanges.subscribe((val) => {
      if (val) {
        if (this.voucherForm.value.done) {
          this.voucherForm.controls.done.setValue(false);
        }
      }
    });
    this.voucherForm.controls.receiptValue.valueChanges.subscribe((val) => {
      if (val) {
        this.amountToPayOrg =
          val > this.voucher.value ? this.voucher.value : val;
        this.customerShouldPay = val - this.amountToPayOrg;
      } else {
        this.amountToPayOrg = 0;
        this.customerShouldPay = 0;
      }
      // console.log('amountToPayOrg', this.amountToPayOrg);
      // console.log('customerShouldPay', this.customerShouldPay);
    });

    if (voucher.responsibles) {
      for (const responsibles of voucher.responsibles) {
        this.responsibles.push(responsibles);
      }
    }

    if (voucher.materials) {
      for (const materials of voucher.materials) {
        this.materials.push(materials);
      }
    }
  }

  async add(type, event: MatChipInputEvent): Promise<void> {
    switch (type) {
      case 'responsibleAdd':
        this.switchValuesSelected['stringArray'] = this.responsibles;
        this.switchValuesSelected['FormControl'] = this.responsibleCtrl;
        break;
      case 'materialAdd':
        this.switchValuesSelected['stringArray'] = this.materials;
        this.switchValuesSelected['FormControl'] = this.materialCtrl;
        break;
    }
    const value = (event.value || '').trim();
    if (value) {
      this.switchValuesSelected['stringArray'].push(value);
      if (this.data.org) {
        const orgRef = doc(
          this.db.firestore,
          `township/${this.township}/organisations/${this.data.org.id}`
        );
        const organisation = (await getDoc(orgRef)).data() as Organisation;

        switch (type) {
          // case 'responsibleAdd':
          //   if (organisation.responsibles) {
          //     updateDoc(orgRef, { responsibles: arrayUnion(value) });
          //   } else {
          //     updateDoc(orgRef, { responsibles: Array(value) });
          //   }
          //   if (!this.allResponsibles.includes(value)) {
          //     this.allResponsibles =
          //       this.allResponsibles.concat(value);
          //   }
          //   break;
          case 'materialAdd':
            updateDoc(orgRef, { materials: arrayUnion(value) });
            if (!this.allFilteredMaterials.includes(value)) {
              this.allFilteredMaterials =
                this.allFilteredMaterials.concat(value);
            }
            break;
        }
      }
    }
    event.chipInput!.clear();

    this.switchValuesSelected['FormControl'].setValue(null);
  }

  remove(type, value: string): void {
    switch (type) {
      case 'responsibleRemove':
        this.switchValuesRemove = this.responsibles;
        break;
      case 'materialRemove':
        this.switchValuesRemove = this.materials;
        break;
    }
    const index = this.switchValuesRemove.indexOf(value);
    if (index >= 0) {
      this.switchValuesRemove.splice(index, 1);
    }
  }

  async removeFromDb(type, value: any): Promise<void> {
    const orgRef = doc(
      this.db.firestore,
      `township/${this.township}/organisations/${this.data.org.id}`
    );
    switch (type) {
      // case 'responsibleRemoveDB':
      //   this.switchValuesSelected['FormControl'] = this.responsibleCtrl;
      //   updateDoc(orgRef, { responsibles: arrayRemove(value.responsible) });
      //   this.allResponsibles = this.allResponsibles.filter(
      //     function (f) {
      //       return f !== value.responsible;
      //     }
      //   );
      //   break;
      case 'materialRemoveDB':
        this.switchValuesSelected['FormControl'] = this.materialCtrl;
        updateDoc(orgRef, { materials: arrayRemove(value.material) });
        this.allFilteredMaterials = this.allFilteredMaterials.filter(function (
          f
        ) {
          return f !== value.material;
        });
        break;
    }
    this.switchValuesSelected['FormControl'].setValue(null);
  }

  selected(type, event: any): void {
    switch (type) {
      case 'responsibleChipList':
        this.switchValuesSelected['stringArray'] = this.responsibles;
        this.switchValuesSelected['ElementRef'] = this.responsibleInput;
        this.switchValuesSelected['FormControl'] = this.responsibleCtrl;
        break;
      case 'materialChipList':
        this.switchValuesSelected['stringArray'] = this.materials;
        this.switchValuesSelected['ElementRef'] = this.materialInput;
        this.switchValuesSelected['FormControl'] = this.materialCtrl;
        break;
    }
    const index = this.switchValuesSelected['stringArray'].indexOf(
      event.option.viewValue
    );
    if (index === -1) {
      this.switchValuesSelected['stringArray'].push(event.option.viewValue);
    }
    this.switchValuesSelected['ElementRef'].nativeElement.value = '';
    this.switchValuesSelected['FormControl'].setValue(null);
  }

  private _filter(type, value: string): string[] {
    const filterValue = value.toLowerCase();
    switch (type) {
      case 'responsible':
        this.allFiltered = this.allResponsibles;
        break;
      case 'material':
        this.allFiltered = this.allFilteredMaterials;
        break;
    }
    return this.allFiltered.filter((value) =>
      value.toLowerCase().includes(filterValue)
    );
  }

  createDateFromString(dateString) {
    console.log('dateString', dateString);
    const splitDateString = dateString.split('-');
    const day = splitDateString[0];
    const month = splitDateString[1];
    const year = splitDateString[2];
    const yearMonthDayString = `${year}-${month}-${day}`;
    console.log('yearMonthDayString', yearMonthDayString);
    return new Date(yearMonthDayString);
  }

  removeAutocompleteFocus() {
    this.responsibleAutoComplete.closePanel();
    this.responsibleInput.nativeElement.blur();
    this.materialAutoComplete.closePanel();
    this.materialInput.nativeElement.blur();
  }

  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }

  uploadedFile(event, type) {
    const file = event.target.files[0] as File;
    if (type === 'receipt') {
      if (file && file.size > 2048000) {
        this.snackbar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
          duration: 5000,
        });
        return;
      } else {
        this.receiptFile = file;
      }
    }
  }

  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const form = this.voucherForm.value;
    // console.log('form', form);
    // console.log('orgId', this.data.orgId);
    // console.log('orgName', this.data.orgName);
    if (!this.voucherForm.valid) {
      this.voucherForm.markAllAsTouched();
      this.saving = false;
      return;
    }
    const contactedOn = form.contactedOn
      ? `${form.contactedOn.getDate()}-${
          form.contactedOn.getMonth() + 1
        }-${form.contactedOn.getFullYear()}`
      : null;
    const appointmentDate = form.appointmentDate
      ? `${form.appointmentDate.getDate()}-${
          form.appointmentDate.getMonth() + 1
        }-${form.appointmentDate.getFullYear()}`
      : null;

    const saveObj: any = {
      responsibles: this.responsibles,
      materials: this.materials,
      contactedOn,
      appointmentDate,
      appointmentTime: form.appointmentTime,
      notes: form.notes,
      lastAssignmentEditedDate: new Date(),
    };

    if (this.data.org) {
      saveObj.assignmentEditors = arrayUnion(this.data.org.name);
    }

    if (form.done) {
      saveObj.claimDate = new Date();
      saveObj.claimOrganisation = this.data.org.name;
      saveObj.claimOrganisationId = this.data.org.id;
      saveObj.assignmentStatus = 'done';
      saveObj.receiptValue = form.receiptValue;
      saveObj.amountToPayOrg = this.amountToPayOrg;
    } else if (form.declined) {
      saveObj.validUntilDate = new Date();
      saveObj.assignmentStatus = 'declined';
    } else if (!this.voucher.assignmentStatus) {
      saveObj.assignmentStatus = 'in_progress';
    }

    if (this.receiptFile) {
      const file = this.receiptFile;
      const filePath = `${this.townshipId}/receipts/${this.voucher.number}`;
      const uploadTask = await this.storage.upload(filePath, file);
      saveObj.imageUrl = await uploadTask.ref.getDownloadURL();
      this.receiptFile = null;
      // console.log('receiptImageUrl', receiptImageUrl);
    }

    console.log('saveObj', saveObj);

    await updateDoc(this.voucherRef, saveObj);
    this.dialogRef.close({
      from: this.voucher.assignmentStatus,
      to: saveObj.assignmentStatus
        ? saveObj.assignmentStatus
        : this.voucher.assignmentStatus,
    });
  }
}
