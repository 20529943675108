// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-content table {
  width: 100%;
  margin-bottom: 16px;
}
.mat-dialog-content table th {
  font-size: 14px;
  font-weight: bold;
}
.mat-dialog-content .mat-slide-toggle {
  margin-top: 8px;
}
.mat-dialog-content .cdk-column-Aantal,
.mat-dialog-content .mat-column-Bedrag {
  text-align: center !important;
}
.mat-dialog-content .check-sum {
  margin-top: 16px;
}

::ng-deep .mat-step-text-label {
  font-size: 13px;
}

.date {
  margin-top: 10px;
}

.font-weight {
  font-weight: bold;
}

.red {
  color: red;
}

#form {
  display: flex;
  flex-direction: column;
}

.org-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-selector-wrapper {
  padding-top: 20px;
}

.file-selector {
  display: flex;
  flex-direction: column;
}

::ng-deep .payment-stepper {
  --mat-stepper-container-color: none;
}`, "",{"version":3,"sources":["webpack://./src/app/payment-references/dialog/sepa/sepa.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,mBAAA;AAAJ;AAEI;EACE,eAAA;EACA,iBAAA;AAAN;AAGE;EACE,eAAA;AADJ;AAIE;;EAEE,6BAAA;AAFJ;AAKE;EACE,gBAAA;AAHJ;;AAOA;EACE,eAAA;AAJF;;AAOA;EACE,gBAAA;AAJF;;AAOA;EACE,iBAAA;AAJF;;AAOA;EACE,UAAA;AAJF;;AAOA;EACE,aAAA;EACA,sBAAA;AAJF;;AAOA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAJF;;AAOA;EACE,iBAAA;AAJF;;AAOA;EACE,aAAA;EACA,sBAAA;AAJF;;AAOA;EACE,mCAAA;AAJF","sourcesContent":[".mat-dialog-content {\n  table {\n    width: 100%;\n    margin-bottom: 16px;\n\n    th {\n      font-size: 14px;\n      font-weight: bold;\n    }\n  }\n  .mat-slide-toggle {\n    margin-top: 8px;\n  }\n\n  .cdk-column-Aantal,\n  .mat-column-Bedrag {\n    text-align: center !important;\n  }\n\n  .check-sum {\n    margin-top: 16px;\n  }\n}\n\n::ng-deep .mat-step-text-label {\n  font-size: 13px;\n}\n\n.date {\n  margin-top: 10px;\n}\n\n.font-weight {\n  font-weight: bold;\n}\n\n.red {\n  color: red;\n}\n\n#form {\n  display: flex;\n  flex-direction: column;\n}\n\n.org-wrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.file-selector-wrapper {\n  padding-top: 20px;\n}\n\n.file-selector {\n  display: flex;\n  flex-direction: column;\n}\n\n::ng-deep .payment-stepper {\n  --mat-stepper-container-color: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
