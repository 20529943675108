import { Validators } from '@angular/forms';

export function getWidgetsAsArray() {
  const widgetsArray = [];
  Object.keys(widgets).forEach((key) => {
    const widget = widgets[key];
    widget.id = key;
    widgetsArray.push(widget);
  });
  return widgetsArray;
}
export function getWidget(id: string) {
  return widgets[id];
}

export const widgets = {
  daily: {
    name: 'Dagelijks bijgehouden actieinformatie',
    height: {
      defaultHeight: 130,
      heightAdjustable: false,
      minHeight: 130,
      maxHeight: 130,
    },
  },
  actionRunningTime: {
    name: 'Actie doorlooptijd',
    height: {
      defaultHeight: 130,
      heightAdjustable: false,
      minHeight: 130,
      maxHeight: 130,
    },
    forms: [
      {
        id: 'startDate',
        label: 'Begin datum',
        type: 'date1',
      },
      {
        id: 'endDate',
        label: 'Eind datum',
        type: 'date2',
      },
    ],
  },
  homesPreserved: {
    name: 'Huizen verduurzaamd',
    height: {
      defaultHeight: 130,
      heightAdjustable: false,
      minHeight: 130,
      maxHeight: 130,
    },
    forms: [
      {
        id: 'fromDate',
        label: 'Vanaf',
        type: 'date1',
      },
    ],
  },
  vouchersAvailable: {
    name: 'Beschikbare bonnen',
    height: {
      defaultHeight: 200,
      heightAdjustable: false,
      minHeight: 200,
      maxHeight: 200,
    },
    forms: [
      {
        id: 'fromDate',
        label: 'Vanaf',
        type: 'date1',
      },
    ],
  },
  vouchersClaimed: {
    name: 'Ingeleverde bonnen',
    height: {
      defaultHeight: 200,
      heightAdjustable: false,
      minHeight: 200,
      maxHeight: 200,
    },
    forms: [
      {
        id: 'fromDate',
        label: 'Vanaf',
        type: 'date1',
      },
    ],
  },
  vouchersClaimedPerOrg: {
    name: 'Ingeleverde bonnen per ondernemer',
    height: {
      defaultHeight: 350,
      heightAdjustable: true,
      minHeight: 150,
      maxHeight: 700,
    },
    forms: [
      {
        id: 'amountToShow',
        label: 'Aantal ondernemers om te laten zien',
        type: 'number',
        validators: [Validators.min(3), Validators.max(10)],
      },
    ],
  },
  vouchersSoldPerOrg: {
    name: 'Verkochte bonnen per ondernemer',
    height: {
      defaultHeight: 350,
      heightAdjustable: true,
      minHeight: 150,
      maxHeight: 700,
    },
    forms: [
      {
        id: 'amountToShow',
        label: 'Aantal ondernemers om te laten zien',
        type: 'number',
        validators: [Validators.min(3), Validators.max(10)],
      },
    ],
  },
  transactionsPerOrg: {
    name: 'Transacties per ondernemer',
    height: {
      defaultHeight: 350,
      heightAdjustable: true,
      minHeight: 150,
      maxHeight: 700,
    },
    forms: [
      {
        id: 'amountToShow',
        label: 'Aantal ondernemers om te laten zien',
        type: 'number',
        validators: [Validators.min(3), Validators.max(10)],
      },
    ],
  },
  totalClaimedValue: {
    name: 'Totale waarde ingeleverde bonnen',
    height: {
      defaultHeight: 130,
      heightAdjustable: false,
      minHeight: 130,
      maxHeight: 130,
    },
    forms: [
      {
        id: 'fromDate',
        label: 'Vanaf',
        type: 'date1',
      },
    ],
  },
  totalWorthSoldVouchers: {
    name: 'Totale waarde verkochte bonnen',
    height: {
      defaultHeight: 130,
      heightAdjustable: false,
      minHeight: 130,
      maxHeight: 130,
    },
    forms: [
      {
        id: 'fromDate',
        label: 'Vanaf',
        type: 'date1',
      },
    ],
  },
  vouchersInVoucherGroup: {
    name: 'Bonnen in actie',
    height: {
      defaultHeight: 130,
      heightAdjustable: false,
      minHeight: 130,
      maxHeight: 130,
    },
    forms: [
      {
        id: 'voucherGroupId',
        label: 'Selecteer actie',
        type: 'select',
      },
    ],
  },
  vouchersInGivenPeriod: {
    name: 'Grafiekweergave ingeleverde en aangevraagde bonnen',
    height: {
      defaultHeight: 500,
      heightAdjustable: true,
      minHeight: 300,
      maxHeight: 700,
    },
  },
};

export function calculateBarOverflowWidth(height: number) {
  return `${Math.round((height / 3 + height) * 100) / 100}px`;
}

export function calculateBarOverflowHeight(height: number) {
  return `${Math.round(((height / 3 + height) / 2) * 100) / 100}px`;
}

export function calculateBarHeight(height: number) {
  return `${Math.round(height / 3 + height)}px`;
}

export function calculateBarWidth(height: number) {
  return `${Math.round(height / 3 + height)}px`;
}

export function calculateBarThickness(height: number) {
  return `${Math.round(height / 3 / 4)}px`;
}

export function calculateWidgetHeight(height: number, number: number) {
  return `${height - number}px`;
}

export interface WidgetForm {
  id: string;
  name: string;
  type: 'string' | 'date';
  validators: any[];
}
