import { Component, OnInit } from '@angular/core';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  orderBy,
  query,
} from 'firebase/firestore';
import { db } from '../app.component';
import { MatDialog } from '@angular/material/dialog';
import { DeleteAppointmentTypeDialogComponent } from './dialogs/delete-appointment-type-dialog/delete-appointment-type-dialog.component';
import { getAuth } from 'firebase/auth';
import { User } from '../interfaces';
import { Router } from '@angular/router';
import { AppointmentTypeKind } from '../enums';

@Component({
  selector: 'app-appointment-types',
  templateUrl: './appointment-types.component.html',
  styleUrls: ['./appointment-types.component.scss'],
})
export class AppointmentTypesComponent implements OnInit {
  loaded: boolean = false;
  townshipId = localStorage.getItem('township') as string;
  appointmentTypes: any[];
  systemUser: boolean = false;
  appointmentTypeKind = AppointmentTypeKind;

  constructor(private dialog: MatDialog, private router: Router) {}

  async ngOnInit() {
    const auth = getAuth();
    if (auth.currentUser) {
      const userDoc = doc(db, `users/${auth.currentUser.uid}`);
      const user = (await getDoc(userDoc)).data() as User;
      if (user.rights === 'admin') {
        this.systemUser = true;
      }
    }
    await this.getAppointmentTypes();
    this.loaded = true;
  }

  async getAppointmentTypes() {
    const appointmentTypesDocs = (
      await getDocs(
        query(
          collection(db, `township/${this.townshipId}/appointmentTypes`),
          orderBy('name')
        )
      )
    ).docs;
    this.appointmentTypes = appointmentTypesDocs.map((doc) => {
      const data = doc.data();
      return { id: doc.id, ...data };
    });
  }

  deleteAppointmentType(appointmentType) {
    const dialogRef = this.dialog.open(DeleteAppointmentTypeDialogComponent, {
      width: '350px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await deleteDoc(
          doc(
            db,
            `township/${this.townshipId}/appointmentTypes/${appointmentType.id}`
          )
        );
        await this.getAppointmentTypes();
      }
    });
  }
  getDuration(appointmentType) {
    let duration = '';
    if (appointmentType.durationHours > 0) {
      duration = `${appointmentType.durationHours} uur`;
    }
    if (appointmentType.durationMinutes > 0) {
      duration = `${duration} ${appointmentType.durationMinutes} minuten`;
    }
    return duration;
  }
  editAppointmentType(appointmentType) {
    this.router.navigate(['/appointment-types', appointmentType.id]);
  }
  createAppointmentType() {
    this.router.navigate(['/appointment-types', 'new']);
  }
}
