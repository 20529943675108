// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-stepper-horizontal {
  background-color: rgba(0, 0, 0, 0);
}
::ng-deep .mat-stepper-horizontal .mat-horizontal-stepper {
  max-height: 65vh;
}
::ng-deep .mat-stepper-horizontal .mat-horizontal-stepper .mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header {
  pointer-events: none !important;
}
::ng-deep .mat-stepper-horizontal .mat-horizontal-stepper .mat-horizontal-stepper-wrapper .mat-horizontal-content-container .mat-horizontal-stepper-content {
  max-height: 100%;
  overflow-y: scroll;
}
::ng-deep .mat-stepper-horizontal .mat-horizontal-stepper button {
  margin: 6px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
form .house-number-addition-forms {
  display: flex;
  flex-direction: row;
}
form .house-number-addition-forms .house-number {
  width: 60%;
  padding-right: 6px;
  box-sizing: border-box;
}
form .house-number-addition-forms .addition {
  width: 40%;
  padding-left: 6px;
  box-sizing: border-box;
}
form .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.outlined-button {
  margin-right: 10px;
}

::ng-deep .setup-payment-dialog-title {
  padding: 0;
}

::ng-deep .setup-payment-dialog-content {
  padding: 16px 0 0 0 !important;
}

::ng-deep .setup-payment-dialog-actions {
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

::ng-deep .payment-stepper {
  --mat-stepper-container-color: none;
}`, "",{"version":3,"sources":["webpack://./src/app/settings/dialog/setup-payment-dialog/setup-payment-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;AACF;AAAE;EACE,gBAAA;AAEJ;AACM;EACE,+BAAA;AACR;AAEQ;EACE,gBAAA;EACA,kBAAA;AAAV;AAKI;EACE,WAAA;AAHN;;AAQA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AALF;AAOE;EACE,aAAA;EACA,mBAAA;AALJ;AAMI;EACE,UAAA;EACA,kBAAA;EACA,sBAAA;AAJN;AAMI;EACE,UAAA;EACA,iBAAA;EACA,sBAAA;AAJN;AAQE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;AANJ;;AAUA;EACE,kBAAA;AAPF;;AAUA;EACE,UAAA;AAPF;;AAUA;EACE,8BAAA;AAPF;;AAUA;EACE,UAAA;EACA,aAAA;EACA,yBAAA;AAPF;;AAUA;EACE,mCAAA;AAPF","sourcesContent":["::ng-deep .mat-stepper-horizontal {\n  background-color: rgba(0, 0, 0, 0);\n  .mat-horizontal-stepper {\n    max-height: 65vh;\n\n    .mat-horizontal-stepper-wrapper {\n      .mat-horizontal-stepper-header {\n        pointer-events: none !important;\n      }\n      .mat-horizontal-content-container {\n        .mat-horizontal-stepper-content {\n          max-height: 100%;\n          overflow-y: scroll;\n        }\n      }\n    }\n\n    button {\n      margin: 6px;\n    }\n  }\n}\n\nform {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n\n  .house-number-addition-forms {\n    display: flex;\n    flex-direction: row;\n    .house-number {\n      width: 60%;\n      padding-right: 6px;\n      box-sizing: border-box;\n    }\n    .addition {\n      width: 40%;\n      padding-left: 6px;\n      box-sizing: border-box;\n    }\n  }\n\n  .buttons {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-end;\n  }\n}\n\n.outlined-button {\n  margin-right: 10px;\n}\n\n::ng-deep .setup-payment-dialog-title {\n  padding: 0;\n}\n\n::ng-deep .setup-payment-dialog-content {\n  padding: 16px 0 0 0 !important;\n}\n\n::ng-deep .setup-payment-dialog-actions {\n  padding: 0;\n  display: flex;\n  justify-content: flex-end;\n}\n\n::ng-deep .payment-stepper {\n  --mat-stepper-container-color: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
