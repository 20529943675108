import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Widget } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore';
import { WidgetManageComponent } from './widget-manage/widget-manage.component';
import { WidgetDeleteComponent } from './widget-delete/widget-delete.component';
import { arrayRemove, encodeRouteParam } from 'src/app/globals';
import { widgets } from '../widgets-helper';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-statistics-management',
  templateUrl: './statistics-management.component.html',
  styleUrls: ['./statistics-management.component.scss'],
})
export class StatisticsManagementComponent implements OnInit {
  townshipId = localStorage.getItem('township');
  organisationId = localStorage.getItem('organisationId');
  env = environment;
  allWidgets = widgets;
  widgets: Widget[] = [];
  widgetCollection: CollectionReference;

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  async ngOnInit() {
    this.widgetCollection = collection(
      this.db.firestore,
      `township/${this.townshipId}/widgets`
    );
    const widgetDocs = await getDocs(
      query(this.widgetCollection, orderBy('order'))
    );
    widgetDocs.forEach((widgetDoc) => {
      const widget = widgetDoc.data() as Widget;
      widget.id = widgetDoc.id;
      this.widgets.push(widget);
    });
    console.log('widgets', widgets);
  }

  async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.widgets, event.previousIndex, event.currentIndex);
    await this.updateOrdersInDb();
  }

  async updateOrdersInDb() {
    const promises: any[] = [];
    let currentIndex = 0;
    this.widgets.forEach((widget) => {
      const widgetRef = doc(
        this.db.firestore,
        `${this.widgetCollection.path}/${widget.id}`
      );
      promises.push(updateDoc(widgetRef, { order: currentIndex }));
      currentIndex++;
    });
    await Promise.all(promises);
  }

  copyWidgetUrl(widget?: Widget) {
    console.log('widget', widget);
    const encTownship = encodeRouteParam(this.townshipId);
    const encWidget = encodeRouteParam(widget.id);
    const shareUrl = `${window.location.origin}/statistics/widget/${encTownship}/${encWidget}`;
    console.log('shareUrl', shareUrl);
    navigator.clipboard.writeText(shareUrl);
    this.snackbar.open('Link gekopieerd.', 'X', {
      duration: 3000,
    });
  }

  manageWidget(widget?: Widget) {
    console.log('widget', widget);
    let widgetOrder;
    if (widget) {
      widgetOrder = this.widgets.findIndex(
        (element) => element.id === widget.id
      );
    }
    const order = widget ? widgetOrder : this.widgets.length;
    const dialogRef = this.dialog.open(WidgetManageComponent, {
      width: '500px',
      data: {
        widget,
        order,
      },
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((newWidget) => {
      if (newWidget) {
        this.widgets[order] = newWidget;
      }
    });
  }

  deleteWidget(widget: Widget) {
    const dialogRef = this.dialog.open(WidgetDeleteComponent, {
      width: '500px',
      data: {
        widget,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('result', result);
      if (result) {
        //delete widget now
        const widgetRef = doc(
          this.db.firestore,
          `${this.widgetCollection.path}/${widget.id}`
        );
        await deleteDoc(widgetRef);
        console.log(widget.order);
        console.log({ ...this.widgets });
        this.widgets = arrayRemove(this.widgets, widget);
        console.log({ ...this.widgets });
        await this.updateOrdersInDb();
      }
    });
  }
}
