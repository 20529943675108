// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.themes {
  max-width: 715px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  position: relative;
  background-color: #fefcf4;
}
.themes .title {
  color: #62c890;
  font-weight: 500;
  font-size: 26px;
}
.themes .page-title .page-icon mat-icon {
  color: #1a697f;
}
.themes .flex {
  padding: 15px 20px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.themes .flex .theme {
  width: 220px;
  height: 220px;
  margin: 5px;
  cursor: grab;
  text-align: center;
}
.themes .flex .theme .theme-text,
.themes .flex .theme .theme-image {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.themes .flex .theme .theme-text {
  background-color: lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
}
.themes .button-position {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/app/themes/themes.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAAA;AACF;AAAE;EACE,cAAA;EACA,gBAAA;EACA,eAAA;AAEJ;AAEM;EACE,cAAA;AAAR;AAIE;EACE,4BAAA;EACA,aAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,uBAAA;AAFJ;AAGI;EACE,YAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AADN;AAEM;;EAEE,WAAA;EACA,YAAA;EACA,mBAAA;AAAR;AAIM;EACE,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AAFR;AAOE;EACE,aAAA;EACA,8BAAA;AALJ","sourcesContent":[".themes {\n  max-width: 715px;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 30px;\n  position: relative;\n  background-color: #fefcf4;\n  .title {\n    color: #62c890;\n    font-weight: 500;\n    font-size: 26px;\n  }\n  .page-title {\n    .page-icon {\n      mat-icon {\n        color: #1a697f;\n      }\n    }\n  }\n  .flex {\n    padding: 15px 20px 20px 20px;\n    display: flex;\n    flex-wrap: wrap;\n    margin-left: auto;\n    margin-right: auto;\n    justify-content: center;\n    .theme {\n      width: 220px;\n      height: 220px;\n      margin: 5px;\n      cursor: grab;\n      text-align: center;\n      .theme-text,\n      .theme-image {\n        width: 100%;\n        height: 100%;\n        border-radius: 16px;\n      }\n      .theme-image {\n      }\n      .theme-text {\n        background-color: lightgrey;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        font-size: 20px;\n        font-weight: 600;\n      }\n    }\n  }\n\n  .button-position {\n    display: flex;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
