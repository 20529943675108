import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import {
  collection,
  getDoc,
  getDocs,
  query,
  where,
  doc,
} from 'firebase/firestore';
import { environment } from 'src/environments/environment';
import { Township } from '../interfaces';
import { ThemedVoucherGroupEditComponent } from '../voucher-management/themed-voucher-group-edit/themed-voucher-group-edit.component';

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.scss'],
})
export class ThemesComponent implements OnInit {
  themes: any[] = [];
  selectedTheme: any;
  env = environment;
  townshipId: string = localStorage.getItem('township');
  township: Township;
  constructor(private db: AngularFirestore, public dialog: MatDialog) {}

  async ngOnInit() {
    console.log('env themes', this.env);
    this.township = (
      await getDoc(doc(this.db.firestore, `township/${this.townshipId}`))
    ).data() as Township;
    const q = query(
      collection(this.db.firestore, `themes`),
      where('env', '==', this.env.name)
    );
    const themesCol = await getDocs(q);
    themesCol.forEach((theme) => {
      let themeData = theme.data();
      themeData = { ...themeData, id: theme.id };
      this.themes.push(themeData);
    });
  }

  themeSelected(theme) {
    console.log('selected', theme.name);
    console.log('selected', theme.id);
    this.selectedTheme = theme;
  }

  nextClicked() {
    let panelClass = [];
    panelClass.push('fullscreen-dialog');
    this.dialog.open(ThemedVoucherGroupEditComponent, {
      width: '500px',
      data: {
        voucherGroup: {},
        township: this.township,
        newGroup: true,
      },
      disableClose: true,
      panelClass,
    });
  }
}
