import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Email, Voucher } from 'src/app/interfaces';
import { query, collection, getDocs, where } from 'firebase/firestore';

@Component({
  selector: 'app-email-data-dialog',
  templateUrl: './email-data-dialog.component.html',
  styleUrls: ['./email-data-dialog.component.scss'],
})
export class EmailDataDialogComponent implements OnInit {
  displayedColumns: string[] = ['email', 'opened', 'delivered', 'date', 'spam'];
  vouchers: Voucher[] = [];

  emails: Email[] = [];
  townshipId: string = localStorage.getItem('township');

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private db: AngularFirestore,
    private ref: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    console.log('data', this.data);
    if (this.data.voucher.sendgridId) {
      const tempArr: Email[] = [];
      for (const id of this.data.voucher.sendgridId) {
        console.log('id', id);
        const q = query(
          collection(
            this.db.firestore,
            `township/${this.townshipId}/emailStatus`
          ),
          where('sendgridId', '==', id)
        );
        const emails = await getDocs(q);
        emails.forEach((email) => {
          let emailData = email.data() as Email;
          if (emailData.sendgridData) {
            emailData.sendgridData.date = emailData.sendgridData.date.toDate();
            tempArr.push(emailData);
          }
        });
      }
      this.emails = tempArr;
      console.log('emails', this.emails);
      this.ref.detectChanges();
    }
  }
}
