import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VoucherGroup } from 'src/app/interfaces';

@Component({
  selector: 'app-vouchergroups-dialog',
  templateUrl: './vouchergroups-dialog.component.html',
  styleUrls: ['./vouchergroups-dialog.component.scss'],
})
export class VouchergroupsDialogComponent implements OnInit {
  voucherGroupForm: UntypedFormGroup;
  allVoucherGroups: VoucherGroup[] = [];
  constructor(
    private fb: UntypedFormBuilder,
    private db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit(): Promise<any> {
    this.voucherGroupForm = this.fb.group({
      voucherGroup: [, Validators.required],
    });

    this.db
      .collection(
        `township/${localStorage.getItem('township')}/voucherGroups`,
        (ref) => ref.orderBy('name')
      )
      .valueChanges({ idField: 'id' })
      .subscribe((voucherGroups) => {
        voucherGroups.forEach((voucherGroup: any) => {
          const totalUsedVouchers = voucherGroup.activatedVouchers;
          if (
            totalUsedVouchers > voucherGroup.totalVouchers ||
            voucherGroup.type === 'expired'
          ) {
            return;
          }
          this.allVoucherGroups.push(voucherGroup);
        });
      });

    console.log('allVoucherGroups', this.allVoucherGroups);
    console.log('this.data', this.data);
  }
}
