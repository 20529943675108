import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { doc, setDoc } from 'firebase/firestore';
import { Theme } from '../../../interfaces';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileSaverService } from 'ngx-filesaver';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
export interface DialogData {
  id?: string;
  theme?: Theme;
}

@Component({
  selector: 'app-edit-themes-dialog',
  templateUrl: './edit-themes-dialog.component.html',
  styleUrls: ['./edit-themes-dialog.component.scss'],
})
export class EditThemesDialogComponent implements OnInit {
  newTheme: boolean;
  themeForm: UntypedFormGroup;
  saving = false;
  themeId: string;
  env = environment;
  tempAmount: number = 0;
  saveImageObj: any[] = [];
  saveImageObjTemp: any[] = [];
  selectedThemeImageUrl: string = null;

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public db: AngularFirestore,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditThemesDialogComponent>,
    private _FileSaverService: FileSaverService,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    if (this.data.theme.images) {
      this.saveImageObj = this.data.theme.images;
    }
    if (this.data.theme.selectedThemeImageUrl) {
      this.selectedThemeImageUrl = this.data.theme.selectedThemeImageUrl;
    }

    if (localStorage.getItem('tempAmount')) {
      const max = parseInt(localStorage.getItem('tempAmount')) + 1;
      for (let i = 1; i !== max; ++i) {
        this.storage.ref(localStorage.getItem(`tempFile${i}`)).delete();
        localStorage.removeItem(`tempFile${i}`);
      }
      localStorage.removeItem('tempAmount');
    }

    this.themeForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      env: ['', [Validators.required]],
      free: ['', [Validators.required]],
      basicInfoTitle: [this.data.theme.basicInfo.title, [Validators.required]],
      basicInfoVisible: [
        this.data.theme.basicInfo.visible,
        [Validators.required],
      ],
      basicInfoWidth: [this.data.theme.basicInfo.width, [Validators.required]],
      basicInfoNameLabel: [
        this.data.theme.basicInfo.inputs.name.label,
        [Validators.required],
      ],
      basicInfoNamePlaceholder: [
        this.data.theme.basicInfo.inputs.name.placeholder,
      ],
      basicInfoNameRequired: [this.data.theme.basicInfo.inputs.name.required],
      basicInfoNameDisabled: [this.data.theme.basicInfo.inputs.name.disabled],
      basicInfoNameVisible: [
        this.data.theme.basicInfo.inputs.name.visible,
        [Validators.required],
      ],
      basicInfoNameWidth: [
        this.data.theme.basicInfo.inputs.name.width,
        [Validators.required],
      ],
      basicInfoDescriptionLabel: [
        this.data.theme.basicInfo.inputs.description.label,
        [Validators.required],
      ],
      basicInfoDescriptionPlaceholder: [
        this.data.theme.basicInfo.inputs.description.placeholder,
      ],
      basicInfoDescriptionRequired: [
        this.data.theme.basicInfo.inputs.description.required,
      ],
      basicInfoDescriptionDisabled: [
        this.data.theme.basicInfo.inputs.description.disabled,
      ],
      basicInfoDescriptionVisible: [
        this.data.theme.basicInfo.inputs.description.visible,
        [Validators.required],
      ],
      basicInfoDescriptionWidth: [
        this.data.theme.basicInfo.inputs.description.width,
        [Validators.required],
      ],
      basicInfoSloganLabel: [
        this.data.theme.basicInfo.inputs.slogan.label,
        [Validators.required],
      ],
      basicInfoSloganPlaceholder: [
        this.data.theme.basicInfo.inputs.slogan.placeholder,
      ],
      basicInfoSloganRequired: [
        this.data.theme.basicInfo.inputs.slogan.required,
      ],
      basicInfoSloganDisabled: [
        this.data.theme.basicInfo.inputs.slogan.disabled,
      ],
      basicInfoSloganVisible: [
        this.data.theme.basicInfo.inputs.slogan.visible,
        [Validators.required],
      ],
      basicInfoSloganWidth: [
        this.data.theme.basicInfo.inputs.slogan.width,
        [Validators.required],
      ],
      basicInfoTypeLabel: [
        this.data.theme.basicInfo.inputs.type.label,
        [Validators.required],
      ],
      basicInfoTypePlaceholder: [
        this.data.theme.basicInfo.inputs.type.placeholder,
      ],
      basicInfoTypeRequired: [this.data.theme.basicInfo.inputs.type.required],
      basicInfoTypeDisabled: [this.data.theme.basicInfo.inputs.type.disabled],
      basicInfoTypeVisible: [
        this.data.theme.basicInfo.inputs.type.visible,
        [Validators.required],
      ],
      basicInfoTypeWidth: [
        this.data.theme.basicInfo.inputs.type.width,
        [Validators.required],
      ],
      basicInfoCouponValueLabel: [
        this.data.theme.basicInfo.inputs.couponValue.label,
        [Validators.required],
      ],
      basicInfoCouponValuePlaceholder: [
        this.data.theme.basicInfo.inputs.couponValue.placeholder,
      ],
      basicInfoCouponValueRequired: [
        this.data.theme.basicInfo.inputs.couponValue.required,
      ],
      basicInfoCouponValueDisabled: [
        this.data.theme.basicInfo.inputs.couponValue.disabled,
      ],
      basicInfoCouponValueVisible: [
        this.data.theme.basicInfo.inputs.couponValue.visible,
        [Validators.required],
      ],
      basicInfoCouponValueWidth: [
        this.data.theme.basicInfo.inputs.couponValue.width,
        [Validators.required],
      ],
      basicInfoSuffixLabel: [
        this.data.theme.basicInfo.inputs.suffix.label,
        [Validators.required],
      ],
      basicInfoSuffixPlaceholder: [
        this.data.theme.basicInfo.inputs.suffix.placeholder,
      ],
      basicInfoSuffixRequired: [
        this.data.theme.basicInfo.inputs.suffix.required,
      ],
      basicInfoSuffixDisabled: [
        this.data.theme.basicInfo.inputs.suffix.disabled,
      ],
      basicInfoSuffixVisible: [
        this.data.theme.basicInfo.inputs.suffix.visible,
        [Validators.required],
      ],
      basicInfoSuffixWidth: [
        this.data.theme.basicInfo.inputs.suffix.width,
        [Validators.required],
      ],
      basicInfoValidUntilDateLabel: [
        this.data.theme.basicInfo.inputs.validUntilDate.label,
        [Validators.required],
      ],
      basicInfoValidUntilDatePlaceholder: [
        this.data.theme.basicInfo.inputs.validUntilDate.placeholder,
      ],
      basicInfoValidUntilDateRequired: [
        this.data.theme.basicInfo.inputs.validUntilDate.required,
      ],
      basicInfoValidUntilDateDisabled: [
        this.data.theme.basicInfo.inputs.validUntilDate.disabled,
      ],
      basicInfoValidUntilDateVisible: [
        this.data.theme.basicInfo.inputs.validUntilDate.visible,
        [Validators.required],
      ],
      basicInfoValidUntilDateWidth: [
        this.data.theme.basicInfo.inputs.validUntilDate.width,
        [Validators.required],
      ],
      basicInfoValidUntilTimeLabel: [
        this.data.theme.basicInfo.inputs.validUntilTime.label,
        [Validators.required],
      ],
      basicInfoValidUntilTimePlaceholder: [
        this.data.theme.basicInfo.inputs.validUntilTime.placeholder,
      ],
      basicInfoValidUntilTimeRequired: [
        this.data.theme.basicInfo.inputs.validUntilTime.required,
      ],
      basicInfoValidUntilTimeDisabled: [
        this.data.theme.basicInfo.inputs.validUntilTime.disabled,
      ],
      basicInfoValidUntilTimeVisible: [
        this.data.theme.basicInfo.inputs.validUntilTime.visible,
        [Validators.required],
      ],
      basicInfoValidUntilTimeWidth: [
        this.data.theme.basicInfo.inputs.validUntilTime.width,
        [Validators.required],
      ],
      basicInfoValidUntilTimeValueLabel: [
        this.data.theme.basicInfo.inputs.validUntilTimeValue.label,
        [Validators.required],
      ],
      basicInfoValidUntilTimeValuePlaceholder: [
        this.data.theme.basicInfo.inputs.validUntilTimeValue.placeholder,
      ],
      basicInfoValidUntilTimeValueRequired: [
        this.data.theme.basicInfo.inputs.validUntilTimeValue.required,
      ],
      basicInfoValidUntilTimeValueDisabled: [
        this.data.theme.basicInfo.inputs.validUntilTimeValue.disabled,
      ],
      basicInfoValidUntilTimeValueVisible: [
        this.data.theme.basicInfo.inputs.validUntilTimeValue.visible,
        [Validators.required],
      ],
      basicInfoValidUntilTimeValueWidth: [
        this.data.theme.basicInfo.inputs.validUntilTimeValue.width,
        [Validators.required],
      ],
      basicInfoValidUntilTimeTypeLabel: [
        this.data.theme.basicInfo.inputs.validUntilTimeType.label,
        [Validators.required],
      ],
      basicInfoValidUntilTimeTypePlaceholder: [
        this.data.theme.basicInfo.inputs.validUntilTimeType.placeholder,
      ],
      basicInfoValidUntilTimeTypeRequired: [
        this.data.theme.basicInfo.inputs.validUntilTimeType.required,
      ],
      basicInfoValidUntilTimeTypeDisabled: [
        this.data.theme.basicInfo.inputs.validUntilTimeType.disabled,
      ],
      basicInfoValidUntilTimeTypeVisible: [
        this.data.theme.basicInfo.inputs.validUntilTimeType.visible,
        [Validators.required],
      ],
      basicInfoValidUntilTimeTypeWidth: [
        this.data.theme.basicInfo.inputs.validUntilTimeType.width,
        [Validators.required],
      ],
      basicInfoTermsLabel: [
        this.data.theme.basicInfo.inputs.terms.label,
        [Validators.required],
      ],
      basicInfoTermsPlaceholder: [
        this.data.theme.basicInfo.inputs.terms.placeholder,
      ],
      basicInfoTermsRequired: [this.data.theme.basicInfo.inputs.terms.required],
      basicInfoTermsDisabled: [this.data.theme.basicInfo.inputs.terms.disabled],
      basicInfoTermsVisible: [
        this.data.theme.basicInfo.inputs.terms.visible,
        [Validators.required],
      ],
      basicInfoTermsWidth: [
        this.data.theme.basicInfo.inputs.terms.width,
        [Validators.required],
      ],
      basicInfoTermsUrlLabel: [
        this.data.theme.basicInfo.inputs.termsUrl.label,
        [Validators.required],
      ],
      basicInfoTermsUrlPlaceholder: [
        this.data.theme.basicInfo.inputs.termsUrl.placeholder,
      ],
      basicInfoTermsUrlRequired: [
        this.data.theme.basicInfo.inputs.termsUrl.required,
      ],
      basicInfoTermsUrlDisabled: [
        this.data.theme.basicInfo.inputs.termsUrl.disabled,
      ],
      basicInfoTermsUrlVisible: [
        this.data.theme.basicInfo.inputs.termsUrl.visible,
        [Validators.required],
      ],
      basicInfoTermsUrlWidth: [
        this.data.theme.basicInfo.inputs.termsUrl.width,
        [Validators.required],
      ],
      extraInfoTitle: [this.data.theme.extraInfo.title, [Validators.required]],
      extraInfoVisible: [
        this.data.theme.extraInfo.visible,
        [Validators.required],
      ],
      extraInfoWidth: [this.data.theme.extraInfo.width, [Validators.required]],
      extraInfoLabelsLabel: [
        this.data.theme.extraInfo.inputs.labels.label,
        [Validators.required],
      ],
      extraInfoLabelsPlaceholder: [
        this.data.theme.extraInfo.inputs.labels.placeholder,
      ],
      extraInfoLabelsRequired: [
        this.data.theme.extraInfo.inputs.labels.required,
      ],
      extraInfoLabelsDisabled: [
        this.data.theme.extraInfo.inputs.labels.disabled,
      ],
      extraInfoLabelsVisible: [
        this.data.theme.extraInfo.inputs.labels.visible,
        [Validators.required],
      ],
      extraInfoLabelsWidth: [
        this.data.theme.extraInfo.inputs.labels.width,
        [Validators.required],
      ],
      extraInfoMaxVouchersLabel: [
        this.data.theme.extraInfo.inputs.maxVouchers.label,
        [Validators.required],
      ],
      extraInfoMaxVouchersPlaceholder: [
        this.data.theme.extraInfo.inputs.maxVouchers.placeholder,
      ],
      extraInfoMaxVouchersRequired: [
        this.data.theme.extraInfo.inputs.maxVouchers.required,
      ],
      extraInfoMaxVouchersDisabled: [
        this.data.theme.extraInfo.inputs.maxVouchers.disabled,
      ],
      extraInfoMaxVouchersVisible: [
        this.data.theme.extraInfo.inputs.maxVouchers.visible,
        [Validators.required],
      ],
      extraInfoMaxVouchersWidth: [
        this.data.theme.extraInfo.inputs.maxVouchers.width,
        [Validators.required],
      ],
      extraInfoGroupLinkLabel: [
        this.data.theme.extraInfo.inputs.groupLink.label,
        [Validators.required],
      ],
      extraInfoGroupLinkPlaceholder: [
        this.data.theme.extraInfo.inputs.groupLink.placeholder,
      ],
      extraInfoGroupLinkRequired: [
        this.data.theme.extraInfo.inputs.groupLink.required,
      ],
      extraInfoGroupLinkDisabled: [
        this.data.theme.extraInfo.inputs.groupLink.disabled,
      ],
      extraInfoGroupLinkVisible: [
        this.data.theme.extraInfo.inputs.groupLink.visible,
        [Validators.required],
      ],
      extraInfoGroupLinkWidth: [
        this.data.theme.extraInfo.inputs.groupLink.width,
        [Validators.required],
      ],
      extraInfoGroupLinkTxtLabel: [
        this.data.theme.extraInfo.inputs.groupLinkTxt.label,
        [Validators.required],
      ],
      extraInfoGroupLinkTxtPlaceholder: [
        this.data.theme.extraInfo.inputs.groupLinkTxt.placeholder,
      ],
      extraInfoGroupLinkTxtRequired: [
        this.data.theme.extraInfo.inputs.groupLinkTxt.required,
      ],
      extraInfoGroupLinkTxtDisabled: [
        this.data.theme.extraInfo.inputs.groupLinkTxt.disabled,
      ],
      extraInfoGroupLinkTxtVisible: [
        this.data.theme.extraInfo.inputs.groupLinkTxt.visible,
        [Validators.required],
      ],
      extraInfoGroupLinkTxtWidth: [
        this.data.theme.extraInfo.inputs.groupLinkTxt.visible,
        [Validators.required],
      ],
      extraInfoClaimedNoteLabel: [
        this.data.theme.extraInfo.inputs.claimedNote.label,
        [Validators.required],
      ],
      extraInfoClaimedNotePlaceholder: [
        this.data.theme.extraInfo.inputs.claimedNote.placeholder,
      ],
      extraInfoClaimedNoteRequired: [
        this.data.theme.extraInfo.inputs.claimedNote.required,
      ],
      extraInfoClaimedNoteDisabled: [
        this.data.theme.extraInfo.inputs.claimedNote.disabled,
      ],
      extraInfoClaimedNoteVisible: [
        this.data.theme.extraInfo.inputs.claimedNote.visible,
        [Validators.required],
      ],
      extraInfoClaimedNoteWidth: [
        this.data.theme.extraInfo.inputs.claimedNote.width,
        [Validators.required],
      ],
      extraInfoExpiredNoteLabel: [
        this.data.theme.extraInfo.inputs.expiredNote.label,
        [Validators.required],
      ],
      extraInfoExpiredNotePlaceholder: [
        this.data.theme.extraInfo.inputs.expiredNote.placeholder,
      ],
      extraInfoExpiredNoteRequired: [
        this.data.theme.extraInfo.inputs.expiredNote.required,
      ],
      extraInfoExpiredNoteDisabled: [
        this.data.theme.extraInfo.inputs.expiredNote.disabled,
      ],
      extraInfoExpiredNoteVisible: [
        this.data.theme.extraInfo.inputs.expiredNote.visible,
        [Validators.required],
      ],
      extraInfoExpiredNoteWidth: [
        this.data.theme.extraInfo.inputs.expiredNote.width,
        [Validators.required],
      ],
      extraInfoGroupContactEmailLabel: [
        this.data.theme.extraInfo.inputs.groupContactEmail.label,
        [Validators.required],
      ],
      extraInfoGroupContactEmailPlaceholder: [
        this.data.theme.extraInfo.inputs.groupContactEmail.placeholder,
      ],
      extraInfoGroupContactEmailRequired: [
        this.data.theme.extraInfo.inputs.groupContactEmail.required,
      ],
      extraInfoGroupContactEmailDisabled: [
        this.data.theme.extraInfo.inputs.groupContactEmail.disabled,
      ],
      extraInfoGroupContactEmailVisible: [
        this.data.theme.extraInfo.inputs.groupContactEmail.visible,
        [Validators.required],
      ],
      extraInfoGroupContactEmailWidth: [
        this.data.theme.extraInfo.inputs.groupContactEmail.width,
        [Validators.required],
      ],
      extraInfoAddressTypeLabel: [
        this.data.theme.extraInfo.inputs.addressType.label,
        [Validators.required],
      ],
      extraInfoAddressTypePlaceholder: [
        this.data.theme.extraInfo.inputs.addressType.placeholder,
      ],
      extraInfoAddressTypeRequired: [
        this.data.theme.extraInfo.inputs.addressType.required,
      ],
      extraInfoAddressTypeDisabled: [
        this.data.theme.extraInfo.inputs.addressType.disabled,
      ],
      extraInfoAddressTypeVisible: [
        this.data.theme.extraInfo.inputs.addressType.visible,
        [Validators.required],
      ],
      extraInfoAddressTypeWidth: [
        this.data.theme.extraInfo.inputs.addressType.width,
        [Validators.required],
      ],
      extraInfoActivateFromTypeLabel: [
        this.data.theme.extraInfo.inputs.activateFromType.label,
        [Validators.required],
      ],
      extraInfoActivateFromTypePlaceholder: [
        this.data.theme.extraInfo.inputs.activateFromType.placeholder,
      ],
      extraInfoActivateFromTypeRequired: [
        this.data.theme.extraInfo.inputs.activateFromType.required,
      ],
      extraInfoActivateFromTypeDisabled: [
        this.data.theme.extraInfo.inputs.activateFromType.disabled,
      ],
      extraInfoActivateFromTypeVisible: [
        this.data.theme.extraInfo.inputs.activateFromType.visible,
        [Validators.required],
      ],
      extraInfoActivateFromTypeWidth: [
        this.data.theme.extraInfo.inputs.activateFromType.width,
        [Validators.required],
      ],
      extraInfoVoucherReminderTimeLabel: [
        this.data.theme.extraInfo.inputs.voucherReminderTime.label,
        [Validators.required],
      ],
      extraInfoVoucherReminderTimePlaceholder: [
        this.data.theme.extraInfo.inputs.voucherReminderTime.placeholder,
      ],
      extraInfoVoucherReminderTimeRequired: [
        this.data.theme.extraInfo.inputs.voucherReminderTime.required,
      ],
      extraInfoVoucherReminderTimeDisabled: [
        this.data.theme.extraInfo.inputs.voucherReminderTime.disabled,
      ],
      extraInfoVoucherReminderTimeVisible: [
        this.data.theme.extraInfo.inputs.voucherReminderTime.visible,
        [Validators.required],
      ],
      extraInfoVoucherReminderTimeWidth: [
        this.data.theme.extraInfo.inputs.voucherReminderTime.width,
        [Validators.required],
      ],
      extraInfoVoucherReminderMailLabel: [
        this.data.theme.extraInfo.inputs.voucherReminderMail.label,
        [Validators.required],
      ],
      extraInfoVoucherReminderMailPlaceholder: [
        this.data.theme.extraInfo.inputs.voucherReminderMail.placeholder,
      ],
      extraInfoVoucherReminderMailRequired: [
        this.data.theme.extraInfo.inputs.voucherReminderMail.required,
      ],
      extraInfoVoucherReminderMailDisabled: [
        this.data.theme.extraInfo.inputs.voucherReminderMail.disabled,
      ],
      extraInfoVoucherReminderMailVisible: [
        this.data.theme.extraInfo.inputs.voucherReminderMail.visible,
        [Validators.required],
      ],
      extraInfoVoucherReminderMailWidth: [
        this.data.theme.extraInfo.inputs.voucherReminderMail.width,
        [Validators.required],
      ],
      extraInfoAmountVoucherReminderLabel: [
        this.data.theme.extraInfo.inputs.amountVoucherReminder.label,
        [Validators.required],
      ],
      extraInfoAmountVoucherReminderPlaceholder: [
        this.data.theme.extraInfo.inputs.amountVoucherReminder.placeholder,
      ],
      extraInfoAmountVoucherReminderRequired: [
        this.data.theme.extraInfo.inputs.amountVoucherReminder.required,
      ],
      extraInfoAmountVoucherReminderDisabled: [
        this.data.theme.extraInfo.inputs.amountVoucherReminder.disabled,
      ],
      extraInfoAmountVoucherReminderVisible: [
        this.data.theme.extraInfo.inputs.amountVoucherReminder.visible,
        [Validators.required],
      ],
      extraInfoAmountVoucherReminderWidth: [
        this.data.theme.extraInfo.inputs.voucherReminderMail.width,
        [Validators.required],
      ],
      extraInfoRequestCouponDaysLabel: [
        this.data.theme.extraInfo.inputs.requestCouponDays.label,
        [Validators.required],
      ],
      extraInfoRequestCouponDaysPlaceholder: [
        this.data.theme.extraInfo.inputs.requestCouponDays.placeholder,
      ],
      extraInfoRequestCouponDaysRequired: [
        this.data.theme.extraInfo.inputs.requestCouponDays.required,
      ],
      extraInfoRequestCouponDaysDisabled: [
        this.data.theme.extraInfo.inputs.requestCouponDays.disabled,
      ],
      extraInfoRequestCouponDaysVisible: [
        this.data.theme.extraInfo.inputs.requestCouponDays.visible,
        [Validators.required],
      ],
      extraInfoRequestCouponDaysWidth: [
        this.data.theme.extraInfo.inputs.requestCouponDays.width,
        [Validators.required],
      ],
      extraInfoStartDateLabel: [
        this.data.theme.extraInfo.inputs.startDate.label,
        [Validators.required],
      ],
      extraInfoStartDatePlaceholder: [
        this.data.theme.extraInfo.inputs.startDate.placeholder,
      ],
      extraInfoStartDateRequired: [
        this.data.theme.extraInfo.inputs.startDate.required,
      ],
      extraInfoStartDateDisabled: [
        this.data.theme.extraInfo.inputs.startDate.disabled,
      ],
      extraInfoStartDateVisible: [
        this.data.theme.extraInfo.inputs.startDate.visible,
        [Validators.required],
      ],
      extraInfoStartDateWidth: [
        this.data.theme.extraInfo.inputs.startDate.width,
        [Validators.required],
      ],
      extraInfoEndDateLabel: [
        this.data.theme.extraInfo.inputs.endDate.label,
        [Validators.required],
      ],
      extraInfoEndDatePlaceholder: [
        this.data.theme.extraInfo.inputs.endDate.placeholder,
      ],
      extraInfoEndDateRequired: [
        this.data.theme.extraInfo.inputs.endDate.required,
      ],
      extraInfoEndDateDisabled: [
        this.data.theme.extraInfo.inputs.endDate.disabled,
      ],
      extraInfoEndDateVisible: [
        this.data.theme.extraInfo.inputs.endDate.visible,
        [Validators.required],
      ],
      extraInfoEndDateWidth: [
        this.data.theme.extraInfo.inputs.endDate.width,
        [Validators.required],
      ],
      extraInfoStartTimeLabel: [
        this.data.theme.extraInfo.inputs.startTime?.label,
        [Validators.required],
      ],
      extraInfoStartTimePlaceholder: [
        this.data.theme.extraInfo.inputs.startTime?.placeholder,
      ],
      extraInfoStartTimeRequired: [
        this.data.theme.extraInfo.inputs.startTime?.required,
      ],
      extraInfoStartTimeDisabled: [
        this.data.theme.extraInfo.inputs.startTime?.disabled,
      ],
      extraInfoStartTimeVisible: [
        this.data.theme.extraInfo.inputs.startTime?.visible,
        [Validators.required],
      ],
      extraInfoStartTimeWidth: [
        this.data.theme.extraInfo.inputs.startTime?.width,
        [Validators.required],
      ],
      extraInfoEndTimeLabel: [
        this.data.theme.extraInfo.inputs.endTime?.label,
        [Validators.required],
      ],
      extraInfoEndTimePlaceholder: [
        this.data.theme.extraInfo.inputs.endTime?.placeholder,
      ],
      extraInfoEndTimeRequired: [
        this.data.theme.extraInfo.inputs.endTime?.required,
      ],
      extraInfoEndTimeDisabled: [
        this.data.theme.extraInfo.inputs.endTime?.disabled,
      ],
      extraInfoEndTimeVisible: [
        this.data.theme.extraInfo.inputs.endTime?.visible,
        [Validators.required],
      ],
      extraInfoEndTimeWidth: [
        this.data.theme.extraInfo.inputs.endTime?.width,
        [Validators.required],
      ],
      extraInfoPostalLabel: [
        this.data.theme.extraInfo.inputs.postal.label,
        [Validators.required],
      ],
      extraInfoPostalPlaceholder: [
        this.data.theme.extraInfo.inputs.postal.placeholder,
      ],
      extraInfoPostalRequired: [
        this.data.theme.extraInfo.inputs.postal.required,
      ],
      extraInfoPostalDisabled: [
        this.data.theme.extraInfo.inputs.postal.disabled,
      ],
      extraInfoPostalVisible: [
        this.data.theme.extraInfo.inputs.postal.visible,
        [Validators.required],
      ],
      extraInfoPostalWidth: [
        this.data.theme.extraInfo.inputs.postal.width,
        [Validators.required],
      ],
      extraInfoHouseNumberLabel: [
        this.data.theme.extraInfo.inputs.houseNumber.label,
        [Validators.required],
      ],
      extraInfoHouseNumberPlaceholder: [
        this.data.theme.extraInfo.inputs.houseNumber.placeholder,
      ],
      extraInfoHouseNumberRequired: [
        this.data.theme.extraInfo.inputs.houseNumber.required,
      ],
      extraInfoHouseNumberDisabled: [
        this.data.theme.extraInfo.inputs.houseNumber.disabled,
      ],
      extraInfoHouseNumberVisible: [
        this.data.theme.extraInfo.inputs.houseNumber.visible,
        [Validators.required],
      ],
      extraInfoHouseNumberWidth: [
        this.data.theme.extraInfo.inputs.houseNumber.width,
        [Validators.required],
      ],
      extraInfoHouseNumberAdditionLabel: [
        this.data.theme.extraInfo.inputs.houseNumberAddition.label,
        [Validators.required],
      ],
      extraInfoHouseNumberAdditionPlaceholder: [
        this.data.theme.extraInfo.inputs.houseNumberAddition.placeholder,
      ],
      extraInfoHouseNumberAdditionRequired: [
        this.data.theme.extraInfo.inputs.houseNumberAddition.required,
      ],
      extraInfoHouseNumberAdditionDisabled: [
        this.data.theme.extraInfo.inputs.houseNumberAddition.disabled,
      ],
      extraInfoHouseNumberAdditionVisible: [
        this.data.theme.extraInfo.inputs.houseNumberAddition.visible,
        [Validators.required],
      ],
      extraInfoHouseNumberAdditionWidth: [
        this.data.theme.extraInfo.inputs.houseNumberAddition.width,
        [Validators.required],
      ],
      extraDocumentTitle: [
        this.data.theme.extraDocument.title,
        [Validators.required],
      ],
      extraDocumentVisible: [
        this.data.theme.extraDocument.visible,
        [Validators.required],
      ],
      extraDocumentWidth: [
        this.data.theme.extraDocument.width,
        [Validators.required],
      ],
      extraDocumentGroupPhoneNumberLabel: [
        this.data.theme.extraDocument.inputs.groupPhoneNumber.label,
        [Validators.required],
      ],
      extraDocumentGroupPhoneNumberPlaceholder: [
        this.data.theme.extraDocument.inputs.groupPhoneNumber.placeholder,
      ],
      extraDocumentGroupPhoneNumberRequired: [
        this.data.theme.extraDocument.inputs.groupPhoneNumber.required,
      ],
      extraDocumentGroupPhoneNumberDisabled: [
        this.data.theme.extraDocument.inputs.groupPhoneNumber.disabled,
      ],
      extraDocumentGroupPhoneNumberVisible: [
        this.data.theme.extraDocument.inputs.groupPhoneNumber.visible,
        [Validators.required],
      ],
      extraDocumentGroupPhoneNumberWidth: [
        this.data.theme.extraDocument.inputs.groupPhoneNumber.width,
        [Validators.required],
      ],
      extraDocumentArrangementEmailLabel: [
        this.data.theme.extraDocument.inputs.arrangementEmail.label,
        [Validators.required],
      ],
      extraDocumentArrangementEmailPlaceholder: [
        this.data.theme.extraDocument.inputs.arrangementEmail.placeholder,
      ],
      extraDocumentArrangementEmailRequired: [
        this.data.theme.extraDocument.inputs.arrangementEmail.required,
      ],
      extraDocumentArrangementEmailDisabled: [
        this.data.theme.extraDocument.inputs.arrangementEmail.disabled,
      ],
      extraDocumentArrangementEmailVisible: [
        this.data.theme.extraDocument.inputs.arrangementEmail.visible,
        [Validators.required],
      ],
      extraDocumentArrangementEmailWidth: [
        this.data.theme.extraDocument.inputs.arrangementEmail.width,
        [Validators.required],
      ],
      guestOptionsTitle: [
        this.data.theme.guestOptions.title,
        [Validators.required],
      ],
      guestOptionsVisible: [
        this.data.theme.guestOptions.visible,
        [Validators.required],
      ],
      guestOptionsWidth: [
        this.data.theme.guestOptions.width,
        [Validators.required],
      ],
      guestsHaveToPayLabel: [
        this.data.theme.guestOptions.inputs.guestsHaveToPay.label,
        [Validators.required],
      ],
      guestsHaveToPayPlaceholder: [
        this.data.theme.guestOptions.inputs.guestsHaveToPay.placeholder,
      ],
      guestsHaveToPayRequired: [
        this.data.theme.guestOptions.inputs.guestsHaveToPay.required,
      ],
      guestsHaveToPayDisabled: [
        this.data.theme.guestOptions.inputs.guestsHaveToPay.disabled,
      ],
      guestsHaveToPayVisible: [
        this.data.theme.guestOptions.inputs.guestsHaveToPay.visible,
        [Validators.required],
      ],
      guestsHaveToPayWidth: [
        this.data.theme.guestOptions.inputs.guestsHaveToPay.width,
        [Validators.required],
      ],
      guestOptionsVoucherPriceLabel: [
        this.data.theme.guestOptions.inputs.voucherPrice
          ? this.data.theme.guestOptions.inputs.voucherPrice.label
          : '',
        [Validators.required],
      ],
      guestOptionsVoucherPricePlaceholder: [
        this.data.theme.guestOptions.inputs.voucherPrice
          ? this.data.theme.guestOptions.inputs.voucherPrice.placeholder
          : null,
      ],
      guestOptionsVoucherPriceRequired: [
        this.data.theme.guestOptions.inputs.voucherPrice
          ? this.data.theme.guestOptions.inputs.voucherPrice.required
          : '',
      ],
      guestOptionsVoucherPriceDisabled: [
        this.data.theme.guestOptions.inputs.voucherPrice
          ? this.data.theme.guestOptions.inputs.voucherPrice.disabled
          : '',
      ],
      guestOptionsVoucherPriceVisible: [
        this.data.theme.guestOptions.inputs.voucherPrice
          ? this.data.theme.guestOptions.inputs.voucherPrice.visible
          : '',
        [Validators.required],
      ],
      guestOptionsVoucherPriceWidth: [
        this.data.theme.guestOptions.inputs.voucherPrice
          ? this.data.theme.guestOptions.inputs.voucherPrice.width
          : '',
        [Validators.required],
      ],
      guestPaymentUrlLabel: [
        this.data.theme.guestOptions.inputs.guestPaymentUrl.label,
        [Validators.required],
      ],
      guestPaymentUrlPlaceholder: [
        this.data.theme.guestOptions.inputs.guestPaymentUrl.placeholder,
      ],
      guestPaymentUrlRequired: [
        this.data.theme.guestOptions.inputs.guestPaymentUrl.required,
      ],
      guestPaymentUrlDisabled: [
        this.data.theme.guestOptions.inputs.guestPaymentUrl.disabled,
      ],
      guestPaymentUrlVisible: [
        this.data.theme.guestOptions.inputs.guestPaymentUrl.visible,
        [Validators.required],
      ],
      guestPaymentUrlWidth: [
        this.data.theme.guestOptions.inputs.guestPaymentUrl.width,
        [Validators.required],
      ],
      guestOptionsMaxVouchersLabel: [
        this.data.theme.guestOptions.inputs.maxVouchers.label,
        [Validators.required],
      ],
      guestOptionsMaxVouchersPlaceholder: [
        this.data.theme.guestOptions.inputs.maxVouchers.placeholder,
      ],
      guestOptionsMaxVouchersRequired: [
        this.data.theme.guestOptions.inputs.maxVouchers.required,
      ],
      guestOptionsMaxVouchersDisabled: [
        this.data.theme.guestOptions.inputs.maxVouchers.disabled,
      ],
      guestOptionsMaxVouchersVisible: [
        this.data.theme.guestOptions.inputs.maxVouchers.visible,
        [Validators.required],
      ],
      guestOptionsMaxVouchersWidth: [
        this.data.theme.guestOptions.inputs.maxVouchers.width,
        [Validators.required],
      ],
      backgroundColorTitle: [
        this.data.theme.backgroundColor.title,
        [Validators.required],
      ],
      backgroundColorVisible: [
        this.data.theme.backgroundColor.visible,
        [Validators.required],
      ],
      backgroundColorWidth: [
        this.data.theme.backgroundColor.width,
        [Validators.required],
      ],
      backgroundColorInputLabel: [
        this.data.theme.backgroundColor.inputs.backgroundColor.label,
        [Validators.required],
      ],
      backgroundColorInputPlaceholder: [
        this.data.theme.backgroundColor.inputs.backgroundColor.placeholder,
      ],
      backgroundColorInputRequired: [
        this.data.theme.backgroundColor.inputs.backgroundColor.required,
      ],
      backgroundColorInputDisabled: [
        this.data.theme.backgroundColor.inputs.backgroundColor.disabled,
      ],
      backgroundColorInputVisible: [
        this.data.theme.backgroundColor.inputs.backgroundColor.visible,
        [Validators.required],
      ],
      backgroundColorInputWidth: [
        this.data.theme.backgroundColor.inputs.backgroundColor.width,
        [Validators.required],
      ],
      themeImageTitle: [
        this.data.theme.themeImage.title,
        [Validators.required],
      ],
      themeImageVisible: [
        this.data.theme.themeImage.visible,
        [Validators.required],
      ],
      themeImageWidth: [
        this.data.theme.themeImage.width,
        [Validators.required],
      ],
      themeImageInputLabel: [
        this.data.theme.themeImage.inputs.themeImage.label,
      ],
      themeImageInputPlaceholder: [
        this.data.theme.themeImage.inputs.themeImage.placeholder,
      ],
      themeImageInputDisabled: [
        this.data.theme.themeImage.inputs.themeImage.disabled,
      ],
      themeImageInputRequired: [
        this.data.theme.themeImage.inputs.themeImage.required,
      ],
      themeImageInputVisible: [
        this.data.theme.themeImage.inputs.themeImage.visible,
        [Validators.required],
      ],
      themeImageInputWidth: [
        this.data.theme.themeImage.inputs.themeImage.width,
        [Validators.required],
      ],
      logoTitle: [this.data.theme.logo.title, [Validators.required]],
      logoVisible: [this.data.theme.logo.visible, [Validators.required]],
      logoWidth: [this.data.theme.logo.width, [Validators.required]],
      fontsTitle: [this.data.theme.fonts.title, [Validators.required]],
      fontsVisible: [this.data.theme.fonts.visible, [Validators.required]],
      fontsWidth: [this.data.theme.fonts.width, [Validators.required]],
      defaultTextFontLabel: [
        this.data.theme.fonts.inputs.defaultTextFont.label,
        [Validators.required],
      ],
      defaultTextFontPlaceholder: [
        this.data.theme.fonts.inputs.defaultTextFont.placeholder,
      ],
      defaultTextFontRequired: [
        this.data.theme.fonts.inputs.defaultTextFont.required,
      ],
      defaultTextFontDisabled: [
        this.data.theme.fonts.inputs.defaultTextFont.disabled,
      ],
      defaultTextFontVisible: [
        this.data.theme.fonts.inputs.defaultTextFont.visible,
        [Validators.required],
      ],
      defaultTextFontWidth: [
        this.data.theme.fonts.inputs.defaultTextFont.width,
        [Validators.required],
      ],
      titleFontLabel: [
        this.data.theme.fonts.inputs.titleFont.label,
        [Validators.required],
      ],
      titleFontPlaceholder: [
        this.data.theme.fonts.inputs.titleFont.placeholder,
      ],
      titleFontRequired: [this.data.theme.fonts.inputs.titleFont.required],
      titleFontDisabled: [this.data.theme.fonts.inputs.titleFont.disabled],
      titleFontVisible: [
        this.data.theme.fonts.inputs.titleFont.visible,
        [Validators.required],
      ],
      titleFontWidth: [
        this.data.theme.fonts.inputs.titleFont.width,
        [Validators.required],
      ],
      titleColorTitle: [
        this.data.theme.titleColor.title,
        [Validators.required],
      ],
      titleColorVisible: [
        this.data.theme.titleColor.visible,
        [Validators.required],
      ],
      titleColorWidth: [
        this.data.theme.titleColor.width,
        [Validators.required],
      ],
      titleColorInputLabel: [
        this.data.theme.titleColor.inputs.titleColor.label,
        [Validators.required],
      ],
      titleColorInputPlaceholder: [
        this.data.theme.titleColor.inputs.titleColor.placeholder,
      ],
      titleColorInputRequired: [
        this.data.theme.titleColor.inputs.titleColor.required,
      ],
      titleColorInputDisabled: [
        this.data.theme.titleColor.inputs.titleColor.disabled,
      ],
      titleColorInputVisible: [
        this.data.theme.titleColor.inputs.titleColor.visible,
        [Validators.required],
      ],
      titleColorInputWidth: [
        this.data.theme.titleColor.inputs.titleColor.width,
        [Validators.required],
      ],
      textColorTitle: [this.data.theme.textColor.title, [Validators.required]],
      textColorVisible: [
        this.data.theme.textColor.visible,
        [Validators.required],
      ],
      textColorWidth: [this.data.theme.textColor.width, [Validators.required]],
      defaultTextColorLabel: [
        this.data.theme.textColor.inputs.defaultTextColor.label,
        [Validators.required],
      ],
      defaultTextColorPlaceholder: [
        this.data.theme.textColor.inputs.defaultTextColor.placeholder,
      ],
      defaultTextColorRequired: [
        this.data.theme.textColor.inputs.defaultTextColor.required,
      ],
      defaultTextColorDisabled: [
        this.data.theme.textColor.inputs.defaultTextColor.disabled,
      ],
      defaultTextColorVisible: [
        this.data.theme.textColor.inputs.defaultTextColor.visible,
        [Validators.required],
      ],
      defaultTextColorWidth: [
        this.data.theme.textColor.inputs.defaultTextColor.width,
        [Validators.required],
      ],
      requestOptionsTitle: [
        this.data.theme.requestOptions.title,
        [Validators.required],
      ],
      requestOptionsVisible: [
        this.data.theme.requestOptions.visible,
        [Validators.required],
      ],
      requestOptionsWidth: [
        this.data.theme.requestOptions.width,
        [Validators.required],
      ],
      verifyPhoneLabel: [
        this.data.theme.requestOptions.inputs.verifyPhone.label,
        [Validators.required],
      ],
      verifyPhonePlaceholder: [
        this.data.theme.requestOptions.inputs.verifyPhone.placeholder,
      ],
      verifyPhoneRequired: [
        this.data.theme.requestOptions.inputs.verifyPhone.required,
      ],
      verifyPhoneDisabled: [
        this.data.theme.requestOptions.inputs.verifyPhone.disabled,
      ],
      verifyPhoneVisible: [
        this.data.theme.requestOptions.inputs.verifyPhone.visible,
        [Validators.required],
      ],
      verifyPhoneWidth: [
        this.data.theme.requestOptions.inputs.verifyPhone.width,
        [Validators.required],
      ],
      hideVoucherRemainingInfoLabel: [
        this.data.theme.requestOptions.inputs.hideVoucherRemainingInfo.label,
        [Validators.required],
      ],
      hideVoucherRemainingInfoPlaceholder: [
        this.data.theme.requestOptions.inputs.hideVoucherRemainingInfo
          .placeholder,
      ],
      hideVoucherRemainingInfoRequired: [
        this.data.theme.requestOptions.inputs.hideVoucherRemainingInfo.required,
      ],
      hideVoucherRemainingInfoDisabled: [
        this.data.theme.requestOptions.inputs.hideVoucherRemainingInfo.disabled,
      ],
      hideVoucherRemainingInfoVisible: [
        this.data.theme.requestOptions.inputs.hideVoucherRemainingInfo.visible,
        [Validators.required],
      ],
      hideVoucherRemainingInfoWidth: [
        this.data.theme.requestOptions.inputs.hideVoucherRemainingInfo.width,
        [Validators.required],
      ],
      requestNameLabel: [
        this.data.theme.requestOptions.inputs.requestName.label,
        [Validators.required],
      ],
      requestNamePlaceholder: [
        this.data.theme.requestOptions.inputs.requestName.placeholder,
      ],
      requestNameRequired: [
        this.data.theme.requestOptions.inputs.requestName.required,
      ],
      requestNameDisabled: [
        this.data.theme.requestOptions.inputs.requestName.disabled,
      ],
      requestNameVisible: [
        this.data.theme.requestOptions.inputs.requestName.visible,
        [Validators.required],
      ],
      requestNameWidth: [
        this.data.theme.requestOptions.inputs.requestName.width,
        [Validators.required],
      ],
      requestPhoneLabel: [
        this.data.theme.requestOptions.inputs.requestPhone.label,
        [Validators.required],
      ],
      requestPhonePlaceholder: [
        this.data.theme.requestOptions.inputs.requestPhone.placeholder,
      ],
      requestPhoneRequired: [
        this.data.theme.requestOptions.inputs.requestPhone.required,
      ],
      requestPhoneDisabled: [
        this.data.theme.requestOptions.inputs.requestPhone.disabled,
      ],
      requestPhoneVisible: [
        this.data.theme.requestOptions.inputs.requestPhone.visible,
        [Validators.required],
      ],
      requestPhoneWidth: [
        this.data.theme.requestOptions.inputs.requestPhone.width,
        [Validators.required],
      ],
      requestIdentificationLabel: [
        this.data.theme.requestOptions.inputs.requestIdentification.label,
        [Validators.required],
      ],
      requestIdentificationPlaceholder: [
        this.data.theme.requestOptions.inputs.requestIdentification.placeholder,
      ],
      requestIdentificationRequired: [
        this.data.theme.requestOptions.inputs.requestIdentification.required,
      ],
      requestIdentificationDisabled: [
        this.data.theme.requestOptions.inputs.requestIdentification.disabled,
      ],
      requestIdentificationVisible: [
        this.data.theme.requestOptions.inputs.requestIdentification.visible,
        [Validators.required],
      ],
      requestIdentificationWidth: [
        this.data.theme.requestOptions.inputs.requestIdentification.width,
        [Validators.required],
      ],
      uniquePhoneNumbersOnlyLabel: [
        this.data.theme.requestOptions.inputs.uniquePhoneNumbersOnly.label,
        [Validators.required],
      ],
      uniquePhoneNumbersOnlyPlaceholder: [
        this.data.theme.requestOptions.inputs.uniquePhoneNumbersOnly
          .placeholder,
      ],
      uniquePhoneNumbersOnlyRequired: [
        this.data.theme.requestOptions.inputs.uniquePhoneNumbersOnly.required,
      ],
      uniquePhoneNumbersOnlyDisabled: [
        this.data.theme.requestOptions.inputs.uniquePhoneNumbersOnly.disabled,
      ],
      uniquePhoneNumbersOnlyVisible: [
        this.data.theme.requestOptions.inputs.uniquePhoneNumbersOnly.visible,
        [Validators.required],
      ],
      uniquePhoneNumbersOnlyWidth: [
        this.data.theme.requestOptions.inputs.uniquePhoneNumbersOnly.width,
        [Validators.required],
      ],
      uniqueMailsOnlyLabel: [
        this.data.theme.requestOptions.inputs.uniqueMailsOnly.label,
        [Validators.required],
      ],
      uniqueMailsOnlyPlaceholder: [
        this.data.theme.requestOptions.inputs.uniqueMailsOnly.placeholder,
      ],
      uniqueMailsOnlyRequired: [
        this.data.theme.requestOptions.inputs.uniqueMailsOnly.required,
      ],
      uniqueMailsOnlyDisabled: [
        this.data.theme.requestOptions.inputs.uniqueMailsOnly.disabled,
      ],
      uniqueMailsOnlyVisible: [
        this.data.theme.requestOptions.inputs.uniqueMailsOnly.visible,
        [Validators.required],
      ],
      uniqueMailsOnlyWidth: [
        this.data.theme.requestOptions.inputs.uniqueMailsOnly.width,
        [Validators.required],
      ],
      hideHeaderImageLabel: [
        this.data.theme.requestOptions.inputs.hideHeaderImage.label,
        [Validators.required],
      ],
      hideHeaderImagePlaceholder: [
        this.data.theme.requestOptions.inputs.hideHeaderImage.placeholder,
      ],
      hideHeaderImageRequired: [
        this.data.theme.requestOptions.inputs.hideHeaderImage.required,
      ],
      hideHeaderImageDisabled: [
        this.data.theme.requestOptions.inputs.hideHeaderImage.disabled,
      ],
      hideHeaderImageVisible: [
        this.data.theme.requestOptions.inputs.hideHeaderImage.visible,
        [Validators.required],
      ],
      hideHeaderImageWidth: [
        this.data.theme.requestOptions.inputs.hideHeaderImage.width,
        [Validators.required],
      ],
      noAdressChecksLabel: [
        this.data.theme.requestOptions.inputs.noAdressChecks.label,
        [Validators.required],
      ],
      noAdressChecksPlaceholder: [
        this.data.theme.requestOptions.inputs.noAdressChecks.placeholder,
      ],
      noAdressChecksRequired: [
        this.data.theme.requestOptions.inputs.noAdressChecks.required,
      ],
      noAdressChecksDisabled: [
        this.data.theme.requestOptions.inputs.noAdressChecks.disabled,
      ],
      noAdressChecksVisible: [
        this.data.theme.requestOptions.inputs.noAdressChecks.visible,
        [Validators.required],
      ],
      noAdressChecksWidth: [
        this.data.theme.requestOptions.inputs.noAdressChecks.width,
        [Validators.required],
      ],
      dontRequestAddressLabel: [
        this.data.theme.requestOptions.inputs.dontRequestAddress.label,
        [Validators.required],
      ],
      dontRequestAddressPlaceholder: [
        this.data.theme.requestOptions.inputs.dontRequestAddress.placeholder,
      ],
      dontRequestAddressRequired: [
        this.data.theme.requestOptions.inputs.dontRequestAddress.required,
      ],
      dontRequestAddressDisabled: [
        this.data.theme.requestOptions.inputs.dontRequestAddress.disabled,
      ],
      dontRequestAddressVisible: [
        this.data.theme.requestOptions.inputs.dontRequestAddress.visible,
        [Validators.required],
      ],
      dontRequestAddressWidth: [
        this.data.theme.requestOptions.inputs.dontRequestAddress.width,
        [Validators.required],
      ],
      multiplePeopleLabel: [
        this.data.theme.requestOptions.inputs.multiplePeople.label,
        [Validators.required],
      ],
      multiplePeoplePlaceholder: [
        this.data.theme.requestOptions.inputs.multiplePeople.placeholder,
      ],
      multiplePeopleRequired: [
        this.data.theme.requestOptions.inputs.multiplePeople.required,
      ],
      multiplePeopleDisabled: [
        this.data.theme.requestOptions.inputs.multiplePeople.disabled,
      ],
      multiplePeopleVisible: [
        this.data.theme.requestOptions.inputs.multiplePeople.visible,
        [Validators.required],
      ],
      multiplePeopleWidth: [
        this.data.theme.requestOptions.inputs.multiplePeople.width,
        [Validators.required],
      ],
      voucherOptionsTitle: [
        this.data.theme.voucherOptions.title,
        [Validators.required],
      ],
      voucherOptionsVisible: [
        this.data.theme.voucherOptions.visible,
        [Validators.required],
      ],
      voucherOptionsWidth: [
        this.data.theme.voucherOptions.width,
        [Validators.required],
      ],
      sendSmsCouponLabel: [
        this.data.theme.voucherOptions.inputs.sendSmsCoupon.label,
        [Validators.required],
      ],
      sendSmsCouponPlaceholder: [
        this.data.theme.voucherOptions.inputs.sendSmsCoupon.placeholder,
      ],
      sendSmsCouponRequired: [
        this.data.theme.voucherOptions.inputs.sendSmsCoupon.required,
      ],
      sendSmsCouponDisabled: [
        this.data.theme.voucherOptions.inputs.sendSmsCoupon.disabled,
      ],
      sendSmsCouponVisible: [
        this.data.theme.voucherOptions.inputs.sendSmsCoupon.visible,
        [Validators.required],
      ],
      sendSmsCouponWidth: [
        this.data.theme.voucherOptions.inputs.sendSmsCoupon.width,
        [Validators.required],
      ],
      claimInstantlyLabel: [
        this.data.theme.voucherOptions.inputs.claimInstantly.label,
        [Validators.required],
      ],
      claimInstantlyPlaceholder: [
        this.data.theme.voucherOptions.inputs.claimInstantly.placeholder,
      ],
      claimInstantlyRequired: [
        this.data.theme.voucherOptions.inputs.claimInstantly.required,
      ],
      claimInstantlyDisabled: [
        this.data.theme.voucherOptions.inputs.claimInstantly.disabled,
      ],
      claimInstantlyVisible: [
        this.data.theme.voucherOptions.inputs.claimInstantly.visible,
        [Validators.required],
      ],
      claimInstantlyWidth: [
        this.data.theme.voucherOptions.inputs.claimInstantly.width,
        [Validators.required],
      ],
      forceSpentFullAmountLabel: [
        this.data.theme.voucherOptions.inputs.forceSpentFullAmount.label,
        [Validators.required],
      ],
      forceSpentFullAmountPlaceholder: [
        this.data.theme.voucherOptions.inputs.forceSpentFullAmount.placeholder,
      ],
      forceSpentFullAmountRequired: [
        this.data.theme.voucherOptions.inputs.forceSpentFullAmount.required,
      ],
      forceSpentFullAmountDisabled: [
        this.data.theme.voucherOptions.inputs.forceSpentFullAmount.disabled,
      ],
      forceSpentFullAmountVisible: [
        this.data.theme.voucherOptions.inputs.forceSpentFullAmount.visible,
        [Validators.required],
      ],
      forceSpentFullAmountWidth: [
        this.data.theme.voucherOptions.inputs.forceSpentFullAmount.width,
        [Validators.required],
      ],
      dontSyncWithApiLabel: [
        this.data.theme.voucherOptions.inputs.dontSyncWithApi.label,
        [Validators.required],
      ],
      dontSyncWithApiPlaceholder: [
        this.data.theme.voucherOptions.inputs.dontSyncWithApi.placeholder,
      ],
      dontSyncWithApiRequired: [
        this.data.theme.voucherOptions.inputs.dontSyncWithApi.required,
      ],
      dontSyncWithApiDisabled: [
        this.data.theme.voucherOptions.inputs.dontSyncWithApi.disabled,
      ],
      dontSyncWithApiVisible: [
        this.data.theme.voucherOptions.inputs.dontSyncWithApi.visible,
        [Validators.required],
      ],
      dontSyncWithApiWidth: [
        this.data.theme.voucherOptions.inputs.dontSyncWithApi.width,
        [Validators.required],
      ],
      multipleUsesLabel: [
        this.data.theme.voucherOptions.inputs.multipleUses?.label,
        [Validators.required],
      ],
      multipleUsesPlaceholder: [
        this.data.theme.voucherOptions.inputs.multipleUses?.placeholder,
      ],
      multipleUsesRequired: [
        this.data.theme.voucherOptions.inputs.multipleUses?.required,
      ],
      multipleUsesDisabled: [
        this.data.theme.voucherOptions.inputs.multipleUses?.disabled,
      ],
      multipleUsesVisible: [
        this.data.theme.voucherOptions.inputs.multipleUses?.visible,
        [Validators.required],
      ],
      multipleUsesWidth: [
        this.data.theme.voucherOptions.inputs.multipleUses?.width,
        [Validators.required],
      ],
      scannableLabel: [
        this.data.theme.voucherOptions.inputs.scannable.label,
        [Validators.required],
      ],
      scannablePlaceholder: [
        this.data.theme.voucherOptions.inputs.scannable.placeholder,
      ],
      scannableRequired: [
        this.data.theme.voucherOptions.inputs.scannable.required,
      ],
      scannableDisabled: [
        this.data.theme.voucherOptions.inputs.scannable.disabled,
      ],
      scannableVisible: [
        this.data.theme.voucherOptions.inputs.scannable.visible,
        [Validators.required],
      ],
      scannableWidth: [
        this.data.theme.voucherOptions.inputs.scannable.width,
        [Validators.required],
      ],
      nameOnVoucherLabel: [
        this.data.theme.voucherOptions.inputs.nameOnVoucher.label,
        [Validators.required],
      ],
      nameOnVoucherPlaceholder: [
        this.data.theme.voucherOptions.inputs.nameOnVoucher.placeholder,
      ],
      nameOnVoucherRequired: [
        this.data.theme.voucherOptions.inputs.nameOnVoucher.required,
      ],
      nameOnVoucherDisabled: [
        this.data.theme.voucherOptions.inputs.nameOnVoucher.disabled,
      ],
      nameOnVoucherVisible: [
        this.data.theme.voucherOptions.inputs.nameOnVoucher.visible,
        [Validators.required],
      ],
      nameOnVoucherWidth: [
        this.data.theme.voucherOptions.inputs.nameOnVoucher.width,
        [Validators.required],
      ],
      priceOnTicketLabel: [
        this.data.theme.voucherOptions.inputs.priceOnTicket.label,
        [Validators.required],
      ],
      priceOnTicketPlaceholder: [
        this.data.theme.voucherOptions.inputs.priceOnTicket.placeholder,
      ],
      priceOnTicketRequired: [
        this.data.theme.voucherOptions.inputs.priceOnTicket.required,
      ],
      priceOnTicketDisabled: [
        this.data.theme.voucherOptions.inputs.priceOnTicket.disabled,
      ],
      priceOnTicketVisible: [
        this.data.theme.voucherOptions.inputs.priceOnTicket.visible,
        [Validators.required],
      ],
      priceOnTicketWidth: [
        this.data.theme.voucherOptions.inputs.priceOnTicket.width,
        [Validators.required],
      ],
      updateOnlyActivatedVouchersLabel: [
        this.data.theme.basicInfo.inputs.updateOnlyActivatedVouchers.label,
        [Validators.required],
      ],
      updateOnlyActivatedVouchersPlaceholder: [
        this.data.theme.basicInfo.inputs.updateOnlyActivatedVouchers
          .placeholder,
      ],
      updateOnlyActivatedVouchersRequired: [
        this.data.theme.basicInfo.inputs.updateOnlyActivatedVouchers.required,
      ],
      updateOnlyActivatedVouchersDisabled: [
        this.data.theme.basicInfo.inputs.updateOnlyActivatedVouchers.disabled,
      ],
      updateOnlyActivatedVouchersVisible: [
        this.data.theme.basicInfo.inputs.updateOnlyActivatedVouchers.visible,
        [Validators.required],
      ],
      updateOnlyActivatedVouchersWidth: [
        this.data.theme.basicInfo.inputs.updateOnlyActivatedVouchers.width,
        [Validators.required],
      ],
    });

    console.log('env themes', this.env);

    console.log('this.data', this.data);
    if (this.data.id && this.data.theme) {
      this.newTheme = false;
      this.themeId = this.data.id;
      this.themeForm.patchValue(this.data.theme);
      console.log('themeObj', this.data.theme);
    } else {
      this.newTheme = true;
      this.themeId = this.db.createId();
    }
    // if (this.data.theme) {
    //   this.themeForm.patchValue(this.data.theme);
    //   Object.keys(this.data.theme).forEach((groupKey) => {
    //     if (groupKey === 'basicInfo') {
    //       console.log('groupKey', groupKey);
    //       Object.keys(this.data.theme[groupKey].inputs).forEach((inputKey) => {
    //         console.log('inputKey', inputKey);
    //         const upperCasedInputName =
    //           inputKey[0].toUpperCase() + inputKey.substring(1);
    //         Object.keys(this.data.theme[groupKey].inputs[inputKey]).forEach(
    //           (key) => {
    //             console.log('key', key);
    //             const upperCasedInputKey =
    //               key[0].toUpperCase() + key.substring(1);
    //             const formFieldName = `${groupKey}${upperCasedInputName}${upperCasedInputKey}`;
    //             console.log('formFieldName', formFieldName);
    //             console.log(
    //               `logMapping[${formFieldName}]`,
    //               `this.data.theme[${groupKey}].inputs[${inputKey}][${key}]`
    //             );
    //             console.log(
    //               `valueToSet[${formFieldName}]`,
    //               this.data.theme[groupKey].inputs[inputKey][key]
    //             );
    //             if (this.themeForm.controls[formFieldName]) {
    //               console.log('true', this.themeForm.controls[formFieldName]);

    //               this.themeForm.controls[formFieldName].setValue(
    //                 this.data.theme[groupKey].inputs[inputKey][key]
    //               );
    //             }
    //           }
    //         );
    //       });
    //     }
    //   });
    // }
  }

  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }

  uploadedFile(event, type, temp?) {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      width: '700px',
      data: {
        file: event.target.files[0],
        ratio: '4 / 3',
        resizeWidth: 500,
        resizeHeight: 375,
        staticWidth: 500,
        staticHeight: 375,
        cropperMaxWidth: 0,
        cropperMaxHeight: 0,
        disableResizeSquares: true,
        containWithinAspectRatio: true,
        other: {
          type: type,
          temp: temp ? temp : null,
          upscale: true,
          upscaleWidth: 500,
        },
      },
      autoFocus: false,
    });
    if (event.target.files[0]) {
      dialogRef.afterClosed().subscribe(async (res) => {
        if (res && res.result === 'success') {
          let file = res.image;
          if (file && file.size < 2048000) {
            let fileName;
            let objArray;

            if (res.other.type === 'newImage') {
              fileName = `[${this.generateRandomString(10)}]-${file.name}`;
              objArray = this.saveImageObjTemp;
            } else {
              fileName = `${res.other.type}`;
              if (res.other.temp === true) {
                objArray = this.saveImageObjTemp;
              } else {
                objArray = this.saveImageObj;
              }
            }

            const filePath = `themes/${this.themeId}/${fileName}`;
            const uploadTask = await this.storage.upload(filePath, file);
            const imageUrl = await uploadTask.ref.getDownloadURL();

            if (res.other.type != 'newImage') {
              const index = objArray
                .map((object) => object.name)
                .indexOf(res.other.type);
              objArray.splice(index, 1);
            }
            objArray.push({
              name: `${fileName}`,
              originalName: `${file.name}`,
              url: `${imageUrl}`,
              filePath: `${filePath}`,
            });
            if ((res.other, type != 'newImage' && res.other.temp === false)) {
              setDoc(
                doc(this.db.firestore, `themes/${this.themeId}`),
                {
                  images: this.saveImageObj,
                },
                { merge: true }
              );
            } else {
              this.tempAmount = this.tempAmount + 1;
              localStorage.setItem('tempAmount', `${this.tempAmount}`);
              localStorage.setItem(`tempFile${this.tempAmount}`, `${filePath}`);
            }
          } else {
            this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
              duration: 5000,
            });
            return;
          }
        } else {
          event.target.value = null;
        }
      });
    }
  }

  selectFile(url) {
    this.selectedThemeImageUrl = url;
  }

  deleteFile(path, name, temp) {
    if (temp === 'true') {
      const index = this.saveImageObjTemp
        .map((object) => object.name)
        .indexOf(name);
      this.saveImageObjTemp.splice(index, 1);
    } else {
      const index = this.saveImageObj
        .map((object) => object.name)
        .indexOf(name);
      this.saveImageObj.splice(index, 1);
      setDoc(
        doc(this.db.firestore, `themes/${this.themeId}`),
        {
          images: this.saveImageObj,
        },
        { merge: true }
      );
    }

    const file = this.storage.ref(path).delete();
    file.subscribe((result) => {
      this.snackBar.open(`Afbeelding verwijderd`, 'X', {
        duration: 5000,
      });
    });
  }

  openFile(url) {
    window.open(url);
  }

  generateRandomString(length) {
    let generatedString = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      generatedString += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }
    return generatedString;
  }

  save() {
    try {
      this.saving = true;

      if (this.saveImageObjTemp.length > 0) {
        this.saveImageObjTemp.forEach((object) => {
          this.saveImageObj.push(object);
          localStorage.removeItem(`tempFile${this.tempAmount}`);
          this.tempAmount = this.tempAmount - 1;
        });
        localStorage.removeItem('tempAmount');
      }

      setDoc(
        doc(this.db.firestore, `themes/${this.themeId}`),
        {
          selectedThemeImageUrl: this.selectedThemeImageUrl,
          images: this.saveImageObj,
          name: this.themeForm.value.name,
          env: this.themeForm.value.env,
          free: this.themeForm.value.free,
          basicInfo: {
            title: this.themeForm.value.basicInfoTitle,
            visible: this.themeForm.value.basicInfoVisible,
            width: this.themeForm.value.basicInfoWidth,
            inputs: {
              name: {
                label: this.themeForm.value.basicInfoNameLabel,
                placeholder: this.themeForm.value.basicInfoNamePlaceholder,
                required: this.themeForm.value.basicInfoNameRequired,
                disabled: this.themeForm.value.basicInfoNameDisabled,
                visible: this.themeForm.value.basicInfoNameVisible,
                width: this.themeForm.value.basicInfoNameWidth,
              },
              description: {
                label: this.themeForm.value.basicInfoDescriptionLabel,
                placeholder:
                  this.themeForm.value.basicInfoDescriptionPlaceholder,
                required: this.themeForm.value.basicInfoDescriptionRequired,
                disabled: this.themeForm.value.basicInfoDescriptionDisabled,
                visible: this.themeForm.value.basicInfoDescriptionVisible,
                width: this.themeForm.value.basicInfoDescriptionWidth,
              },
              slogan: {
                label: this.themeForm.value.basicInfoSloganLabel,
                placeholder: this.themeForm.value.basicInfoSloganPlaceholder,
                required: this.themeForm.value.basicInfoSloganRequired,
                disabled: this.themeForm.value.basicInfoSloganDisabled,
                visible: this.themeForm.value.basicInfoSloganVisible,
                width: this.themeForm.value.basicInfoSloganWidth,
              },
              type: {
                label: this.themeForm.value.basicInfoTypeLabel,
                placeholder: this.themeForm.value.basicInfoTypePlaceholder,
                required: this.themeForm.value.basicInfoTypeRequired,
                disabled: this.themeForm.value.basicInfoTypeDisabled,
                visible: this.themeForm.value.basicInfoTypeVisible,
                width: this.themeForm.value.basicInfoTypeWidth,
              },
              couponValue: {
                label: this.themeForm.value.basicInfoCouponValueLabel,
                placeholder:
                  this.themeForm.value.basicInfoCouponValuePlaceholder,
                required: this.themeForm.value.basicInfoCouponValueRequired,
                disabled: this.themeForm.value.basicInfoCouponValueDisabled,
                visible: this.themeForm.value.basicInfoCouponValueVisible,
                width: this.themeForm.value.basicInfoCouponValueWidth,
              },
              suffix: {
                label: this.themeForm.value.basicInfoSuffixLabel,
                placeholder: this.themeForm.value.basicInfoSuffixPlaceholder,
                required: this.themeForm.value.basicInfoSuffixRequired,
                disabled: this.themeForm.value.basicInfoSuffixDisabled,
                visible: this.themeForm.value.basicInfoSuffixVisible,
                width: this.themeForm.value.basicInfoSuffixWidth,
              },
              validUntilDate: {
                label: this.themeForm.value.basicInfoValidUntilDateLabel,
                placeholder:
                  this.themeForm.value.basicInfoValidUntilDatePlaceholder,
                required: this.themeForm.value.basicInfoValidUntilDateRequired,
                disabled: this.themeForm.value.basicInfoValidUntilDateDisabled,
                visible: this.themeForm.value.basicInfoValidUntilDateVisible,
                width: this.themeForm.value.basicInfoValidUntilDateWidth,
              },
              validUntilTime: {
                label: this.themeForm.value.basicInfoValidUntilTimeLabel,
                placeholder:
                  this.themeForm.value.basicInfoValidUntilTimePlaceholder,
                required: this.themeForm.value.basicInfoValidUntilTimeRequired,
                disabled: this.themeForm.value.basicInfoValidUntilTimeDisabled,
                visible: this.themeForm.value.basicInfoValidUntilTimeVisible,
                width: this.themeForm.value.basicInfoValidUntilTimeWidth,
              },
              validUntilTimeValue: {
                label: this.themeForm.value.basicInfoValidUntilTimeValueLabel,
                placeholder:
                  this.themeForm.value.basicInfoValidUntilTimeValuePlaceholder,
                required:
                  this.themeForm.value.basicInfoValidUntilTimeValueRequired,
                disabled:
                  this.themeForm.value.basicInfoValidUntilTimeValueDisabled,
                visible:
                  this.themeForm.value.basicInfoValidUntilTimeValueVisible,
                width: this.themeForm.value.basicInfoValidUntilTimeValueWidth,
              },
              validUntilTimeType: {
                label: this.themeForm.value.basicInfoValidUntilTimeTypeLabel,
                placeholder:
                  this.themeForm.value.basicInfoValidUntilTimeTypePlaceholder,
                required:
                  this.themeForm.value.basicInfoValidUntilTimeTypeRequired,
                disabled:
                  this.themeForm.value.basicInfoValidUntilTimeTypeDisabled,
                visible:
                  this.themeForm.value.basicInfoValidUntilTimeTypeVisible,
                width: this.themeForm.value.basicInfoValidUntilTimeTypeWidth,
              },
              updateOnlyActivatedVouchers: {
                label: this.themeForm.value.updateOnlyActivatedVouchersLabel,
                placeholder:
                  this.themeForm.value.updateOnlyActivatedVouchersPlaceholder,
                required:
                  this.themeForm.value.updateOnlyActivatedVouchersRequired,
                disabled:
                  this.themeForm.value.updateOnlyActivatedVouchersDisabled,
                visible:
                  this.themeForm.value.updateOnlyActivatedVouchersVisible,
                width: this.themeForm.value.updateOnlyActivatedVouchersWidth,
              },
              terms: {
                label: this.themeForm.value.basicInfoTermsLabel,
                placeholder: this.themeForm.value.basicInfoTermsPlaceholder,
                required: this.themeForm.value.basicInfoTermsRequired,
                disabled: this.themeForm.value.basicInfoTermsDisabled,
                visible: this.themeForm.value.basicInfoTermsVisible,
                width: this.themeForm.value.basicInfoTermsWidth,
              },
              termsUrl: {
                label: this.themeForm.value.basicInfoTermsUrlLabel,
                placeholder: this.themeForm.value.basicInfoTermsUrlPlaceholder,
                required: this.themeForm.value.basicInfoTermsUrlRequired,
                disabled: this.themeForm.value.basicInfoTermsUrlDisabled,
                visible: this.themeForm.value.basicInfoTermsUrlVisible,
                width: this.themeForm.value.basicInfoTermsUrlWidth,
              },
            },
          },
          extraInfo: {
            title: this.themeForm.value.extraInfoTitle,
            visible: this.themeForm.value.extraInfoVisible,
            width: this.themeForm.value.extraInfoWidth,
            inputs: {
              labels: {
                label: this.themeForm.value.extraInfoLabelsLabel,
                placeholder: this.themeForm.value.extraInfoLabelsPlaceholder,
                required: this.themeForm.value.extraInfoLabelsRequired,
                disabled: this.themeForm.value.extraInfoLabelsDisabled,
                visible: this.themeForm.value.extraInfoLabelsVisible,
                width: this.themeForm.value.extraInfoLabelsWidth,
              },
              maxVouchers: {
                label: this.themeForm.value.extraInfoMaxVouchersLabel,
                placeholder:
                  this.themeForm.value.extraInfoMaxVouchersPlaceholder,
                required: this.themeForm.value.extraInfoMaxVouchersRequired,
                disabled: this.themeForm.value.extraInfoMaxVouchersDisabled,
                visible: this.themeForm.value.extraInfoMaxVouchersVisible,
                width: this.themeForm.value.extraInfoMaxVouchersWidth,
              },
              groupLink: {
                label: this.themeForm.value.extraInfoGroupLinkLabel,
                placeholder: this.themeForm.value.extraInfoGroupLinkPlaceholder,
                required: this.themeForm.value.extraInfoGroupLinkRequired,
                disabled: this.themeForm.value.extraInfoGroupLinkDisabled,
                visible: this.themeForm.value.extraInfoGroupLinkVisible,
                width: this.themeForm.value.extraInfoGroupLinkWidth,
              },
              groupLinkTxt: {
                label: this.themeForm.value.extraInfoGroupLinkTxtLabel,
                placeholder:
                  this.themeForm.value.extraInfoGroupLinkTxtPlaceholder,
                required: this.themeForm.value.extraInfoGroupLinkTxtRequired,
                disabled: this.themeForm.value.extraInfoGroupLinkTxtDisabled,
                visible: this.themeForm.value.extraInfoGroupLinkTxtVisible,
                width: this.themeForm.value.extraInfoGroupLinkTxtWidth,
              },
              expiredNote: {
                label: this.themeForm.value.extraInfoExpiredNoteLabel,
                placeholder:
                  this.themeForm.value.extraInfoExpiredNotePlaceholder,
                required: this.themeForm.value.extraInfoExpiredNoteRequired,
                disabled: this.themeForm.value.extraInfoExpiredNoteDisabled,
                visible: this.themeForm.value.extraInfoExpiredNoteVisible,
                width: this.themeForm.value.extraInfoExpiredNoteWidth,
              },
              claimedNote: {
                label: this.themeForm.value.extraInfoClaimedNoteLabel,
                placeholder:
                  this.themeForm.value.extraInfoClaimedNotePlaceholder,
                required: this.themeForm.value.extraInfoClaimedNoteRequired,
                disabled: this.themeForm.value.extraInfoClaimedNoteDisabled,
                visible: this.themeForm.value.extraInfoClaimedNoteVisible,
                width: this.themeForm.value.extraInfoClaimedNoteWidth,
              },
              groupContactEmail: {
                label: this.themeForm.value.extraInfoGroupContactEmailLabel,
                placeholder:
                  this.themeForm.value.extraInfoGroupContactEmailPlaceholder,
                required:
                  this.themeForm.value.extraInfoGroupContactEmailRequired,
                disabled:
                  this.themeForm.value.extraInfoGroupContactEmailDisabled,
                visible: this.themeForm.value.extraInfoGroupContactEmailVisible,
                width: this.themeForm.value.extraInfoGroupContactEmailWidth,
              },
              addressType: {
                label: this.themeForm.value.extraInfoAddressTypeLabel,
                placeholder:
                  this.themeForm.value.extraInfoAddressTypePlaceholder,
                required: this.themeForm.value.extraInfoAddressTypeRequired,
                disabled: this.themeForm.value.extraInfoAddressTypeDisabled,
                visible: this.themeForm.value.extraInfoAddressTypeVisible,
                width: this.themeForm.value.extraInfoAddressTypeWidth,
              },
              activateFromType: {
                label: this.themeForm.value.extraInfoActivateFromTypeLabel,
                placeholder:
                  this.themeForm.value.extraInfoActivateFromTypePlaceholder,
                required:
                  this.themeForm.value.extraInfoActivateFromTypeRequired,
                disabled:
                  this.themeForm.value.extraInfoActivateFromTypeDisabled,
                visible: this.themeForm.value.extraInfoActivateFromTypeVisible,
                width: this.themeForm.value.extraInfoActivateFromTypeWidth,
              },
              voucherReminderTime: {
                label: this.themeForm.value.extraInfoVoucherReminderTimeLabel,
                placeholder:
                  this.themeForm.value.extraInfoVoucherReminderTimePlaceholder,
                required:
                  this.themeForm.value.extraInfoVoucherReminderTimeRequired,
                disabled:
                  this.themeForm.value.extraInfoVoucherReminderTimeDisabled,
                visible:
                  this.themeForm.value.extraInfoVoucherReminderTimeVisible,
                width: this.themeForm.value.extraInfoVoucherReminderTimeWidth,
              },
              voucherReminderMail: {
                label: this.themeForm.value.extraInfoVoucherReminderMailLabel,
                placeholder:
                  this.themeForm.value.extraInfoVoucherReminderMailPlaceholder,
                required:
                  this.themeForm.value.extraInfoVoucherReminderMailRequired,
                disabled:
                  this.themeForm.value.extraInfoVoucherReminderMailDisabled,
                visible:
                  this.themeForm.value.extraInfoVoucherReminderMailVisible,
                width: this.themeForm.value.extraInfoVoucherReminderMailWidth,
              },
              amountVoucherReminder: {
                label: this.themeForm.value.extraInfoAmountVoucherReminderLabel,
                placeholder:
                  this.themeForm.value
                    .extraInfoAmountVoucherReminderPlaceholder,
                required:
                  this.themeForm.value.extraInfoAmountVoucherReminderRequired,
                disabled:
                  this.themeForm.value.extraInfoAmountVoucherReminderDisabled,
                visible:
                  this.themeForm.value.extraInfoAmountVoucherReminderVisible,
                width: this.themeForm.value.extraInfoAmountVoucherReminderWidth,
              },
              requestCouponDays: {
                label: this.themeForm.value.extraInfoRequestCouponDaysLabel,
                placeholder:
                  this.themeForm.value.extraInfoRequestCouponDaysPlaceholder,
                required:
                  this.themeForm.value.extraInfoRequestCouponDaysRequired,
                disabled:
                  this.themeForm.value.extraInfoRequestCouponDaysDisabled,
                visible: this.themeForm.value.extraInfoRequestCouponDaysVisible,
                width: this.themeForm.value.extraInfoRequestCouponDaysWidth,
              },
              startDate: {
                label: this.themeForm.value.extraInfoStartDateLabel,
                placeholder: this.themeForm.value.extraInfoStartDatePlaceholder,
                required: this.themeForm.value.extraInfoStartDateRequired,
                disabled: this.themeForm.value.extraInfoStartDateDisabled,
                visible: this.themeForm.value.extraInfoStartDateVisible,
                width: this.themeForm.value.extraInfoStartDateWidth,
              },
              endDate: {
                label: this.themeForm.value.extraInfoEndDateLabel,
                placeholder: this.themeForm.value.extraInfoEndDatePlaceholder,
                required: this.themeForm.value.extraInfoEndDateRequired,
                disabled: this.themeForm.value.extraInfoEndDateDisabled,
                visible: this.themeForm.value.extraInfoEndDateVisible,
                width: this.themeForm.value.extraInfoEndDateWidth,
              },
              startTime: {
                label: this.themeForm.value.extraInfoStartTimeLabel,
                placeholder: this.themeForm.value.extraInfoStartTimePlaceholder,
                required: this.themeForm.value.extraInfoStartTimeRequired,
                disabled: this.themeForm.value.extraInfoStartTimeDisabled,
                visible: this.themeForm.value.extraInfoStartTimeVisible,
                width: this.themeForm.value.extraInfoStartTimeWidth,
              },
              endTime: {
                label: this.themeForm.value.extraInfoEndTimeLabel,
                placeholder: this.themeForm.value.extraInfoEndTimePlaceholder,
                required: this.themeForm.value.extraInfoEndTimeRequired,
                disabled: this.themeForm.value.extraInfoEndTimeDisabled,
                visible: this.themeForm.value.extraInfoEndTimeVisible,
                width: this.themeForm.value.extraInfoEndTimeWidth,
              },
              postal: {
                label: this.themeForm.value.extraInfoPostalLabel,
                placeholder: this.themeForm.value.extraInfoPostalPlaceholder,
                required: this.themeForm.value.extraInfoPostalRequired,
                disabled: this.themeForm.value.extraInfoPostalDisabled,
                visible: this.themeForm.value.extraInfoPostalVisible,
                width: this.themeForm.value.extraInfoPostalWidth,
              },
              houseNumber: {
                label: this.themeForm.value.extraInfoHouseNumberLabel,
                placeholder:
                  this.themeForm.value.extraInfoHouseNumberPlaceholder,
                required: this.themeForm.value.extraInfoHouseNumberRequired,
                disabled: this.themeForm.value.extraInfoHouseNumberDisabled,
                visible: this.themeForm.value.extraInfoHouseNumberVisible,
                width: this.themeForm.value.extraInfoHouseNumberWidth,
              },
              houseNumberAddition: {
                label: this.themeForm.value.extraInfoHouseNumberAdditionLabel,
                placeholder:
                  this.themeForm.value.extraInfoHouseNumberAdditionPlaceholder,
                required:
                  this.themeForm.value.extraInfoHouseNumberAdditionRequired,
                disabled:
                  this.themeForm.value.extraInfoHouseNumberAdditionDisabled,
                visible:
                  this.themeForm.value.extraInfoHouseNumberAdditionVisible,
                width: this.themeForm.value.extraInfoHouseNumberAdditionWidth,
              },
            },
          },
          extraDocument: {
            title: this.themeForm.value.extraDocumentTitle,
            visible: this.themeForm.value.extraDocumentVisible,
            width: this.themeForm.value.extraDocumentWidth,
            inputs: {
              groupPhoneNumber: {
                label: this.themeForm.value.extraDocumentGroupPhoneNumberLabel,
                placeholder:
                  this.themeForm.value.extraDocumentGroupPhoneNumberPlaceholder,
                required:
                  this.themeForm.value.extraDocumentGroupPhoneNumberRequired,
                disabled:
                  this.themeForm.value.extraDocumentGroupPhoneNumberDisabled,
                visible:
                  this.themeForm.value.extraDocumentGroupPhoneNumberVisible,
                width: this.themeForm.value.extraDocumentGroupPhoneNumberWidth,
              },
              arrangementEmail: {
                label: this.themeForm.value.extraDocumentArrangementEmailLabel,
                placeholder:
                  this.themeForm.value.extraDocumentArrangementEmailPlaceholder,
                required:
                  this.themeForm.value.extraDocumentArrangementEmailRequired,
                disabled:
                  this.themeForm.value.extraDocumentArrangementEmailDisabled,
                visible:
                  this.themeForm.value.extraDocumentArrangementEmailVisible,
                width: this.themeForm.value.extraDocumentArrangementEmailWidth,
              },
            },
          },
          guestOptions: {
            title: this.themeForm.value.guestOptionsTitle,
            visible: this.themeForm.value.guestOptionsVisible,
            width: this.themeForm.value.guestOptionsWidth,
            inputs: {
              guestsHaveToPay: {
                label: this.themeForm.value.guestsHaveToPayLabel,
                placeholder: this.themeForm.value.guestsHaveToPayPlaceholder,
                required: this.themeForm.value.guestsHaveToPayRequired,
                disabled: this.themeForm.value.guestsHaveToPayDisabled,
                visible: this.themeForm.value.guestsHaveToPayVisible,
                width: this.themeForm.value.guestsHaveToPayWidth,
              },
              voucherPrice: {
                label: this.themeForm.value.guestOptionsVoucherPriceLabel,
                placeholder:
                  this.themeForm.value.guestOptionsVoucherPricePlaceholder,
                required: this.themeForm.value.guestOptionsVoucherPriceRequired,
                disabled: this.themeForm.value.guestOptionsVoucherPriceDisabled,
                visible: this.themeForm.value.guestOptionsVoucherPriceVisible,
                width: this.themeForm.value.guestOptionsVoucherPriceWidth,
              },
              guestPaymentUrl: {
                label: this.themeForm.value.guestPaymentUrlLabel,
                placeholder: this.themeForm.value.guestPaymentUrlPlaceholder,
                required: this.themeForm.value.guestPaymentUrlRequired,
                disabled: this.themeForm.value.guestPaymentUrlDisabled,
                visible: this.themeForm.value.guestPaymentUrlVisible,
                width: this.themeForm.value.guestPaymentUrlWidth,
              },
              maxVouchers: {
                label: this.themeForm.value.guestOptionsMaxVouchersLabel,
                placeholder:
                  this.themeForm.value.guestOptionsMaxVouchersPlaceholder,
                required: this.themeForm.value.guestOptionsMaxVouchersRequired,
                disabled: this.themeForm.value.guestOptionsMaxVouchersDisabled,
                visible: this.themeForm.value.guestOptionsMaxVouchersVisible,
                width: this.themeForm.value.guestOptionsMaxVouchersWidth,
              },
            },
          },
          backgroundColor: {
            title: this.themeForm.value.backgroundColorTitle,
            visible: this.themeForm.value.backgroundColorVisible,
            width: this.themeForm.value.backgroundColorWidth,
            inputs: {
              backgroundColor: {
                label: this.themeForm.value.backgroundColorInputLabel,
                placeholder:
                  this.themeForm.value.backgroundColorInputPlaceholder,
                required: this.themeForm.value.backgroundColorInputRequired,
                disabled: this.themeForm.value.backgroundColorInputDisabled,
                visible: this.themeForm.value.backgroundColorInputVisible,
                width: this.themeForm.value.backgroundColorInputWidth,
              },
            },
          },
          themeImage: {
            title: this.themeForm.value.themeImageTitle,
            visible: this.themeForm.value.themeImageVisible,
            width: this.themeForm.value.themeImageWidth,
            inputs: {
              themeImage: {
                label: this.themeForm.value.themeImageInputLabel,
                placeholder: this.themeForm.value.themeImageInputPlaceholder,
                required: this.themeForm.value.themeImageInputRequired,
                disabled: this.themeForm.value.themeImageInputDisabled,
                visible: this.themeForm.value.themeImageInputVisible,
                width: this.themeForm.value.themeImageInputWidth,
              },
            },
          },
          logo: {
            title: this.themeForm.value.logoTitle,
            visible: this.themeForm.value.logoVisible,
            width: this.themeForm.value.logoWidth,
            inputs: {},
          },
          fonts: {
            title: this.themeForm.value.fontsTitle,
            visible: this.themeForm.value.fontsVisible,
            width: this.themeForm.value.fontsWidth,
            inputs: {
              defaultTextFont: {
                label: this.themeForm.value.defaultTextFontLabel,
                placeholder: this.themeForm.value.defaultTextFontPlaceholder,
                required: this.themeForm.value.defaultTextFontRequired,
                disabled: this.themeForm.value.defaultTextFontDisabled,
                visible: this.themeForm.value.defaultTextFontVisible,
                width: this.themeForm.value.defaultTextFontWidth,
              },
              titleFont: {
                label: this.themeForm.value.titleFontLabel,
                placeholder: this.themeForm.value.titleFontPlaceholder,
                required: this.themeForm.value.titleFontRequired,
                disabled: this.themeForm.value.titleFontDisabled,
                visible: this.themeForm.value.titleFontVisible,
                width: this.themeForm.value.titleFontWidth,
              },
            },
          },
          titleColor: {
            title: this.themeForm.value.titleColorTitle,
            visible: this.themeForm.value.titleColorVisible,
            width: this.themeForm.value.titleColorWidth,
            inputs: {
              titleColor: {
                label: this.themeForm.value.titleColorInputLabel,
                placeholder: this.themeForm.value.titleColorInputPlaceholder,
                required: this.themeForm.value.titleColorInputRequired,
                disabled: this.themeForm.value.titleColorInputDisabled,
                visible: this.themeForm.value.titleColorInputVisible,
                width: this.themeForm.value.titleColorInputWidth,
              },
            },
          },
          textColor: {
            title: this.themeForm.value.textColorTitle,
            visible: this.themeForm.value.textColorVisible,
            width: this.themeForm.value.textColorWidth,
            inputs: {
              defaultTextColor: {
                label: this.themeForm.value.defaultTextColorLabel,
                placeholder: this.themeForm.value.defaultTextColorPlaceholder,
                required: this.themeForm.value.defaultTextColorRequired,
                disabled: this.themeForm.value.defaultTextColorDisabled,
                visible: this.themeForm.value.defaultTextColorVisible,
                width: this.themeForm.value.defaultTextColorWidth,
              },
            },
          },
          requestOptions: {
            title: this.themeForm.value.requestOptionsTitle,
            visible: this.themeForm.value.requestOptionsVisible,
            width: this.themeForm.value.requestOptionsWidth,
            inputs: {
              verifyPhone: {
                label: this.themeForm.value.verifyPhoneLabel,
                placeholder: this.themeForm.value.verifyPhonePlaceholder,
                required: this.themeForm.value.verifyPhoneRequired,
                disabled: this.themeForm.value.verifyPhoneDisabled,
                visible: this.themeForm.value.verifyPhoneVisible,
                width: this.themeForm.value.verifyPhoneWidth,
              },
              hideVoucherRemainingInfo: {
                label: this.themeForm.value.hideVoucherRemainingInfoLabel,
                placeholder:
                  this.themeForm.value.hideVoucherRemainingInfoPlaceholder,
                required: this.themeForm.value.hideVoucherRemainingInfoRequired,
                disabled: this.themeForm.value.hideVoucherRemainingInfoDisabled,
                visible: this.themeForm.value.hideVoucherRemainingInfoVisible,
                width: this.themeForm.value.hideVoucherRemainingInfoWidth,
              },
              requestName: {
                label: this.themeForm.value.requestNameLabel,
                placeholder: this.themeForm.value.requestNamePlaceholder,
                required: this.themeForm.value.requestNameRequired,
                disabled: this.themeForm.value.requestNameDisabled,
                visible: this.themeForm.value.requestNameVisible,
                width: this.themeForm.value.requestNameWidth,
              },
              requestPhone: {
                label: this.themeForm.value.requestPhoneLabel,
                placeholder: this.themeForm.value.requestPhonePlaceholder,
                required: this.themeForm.value.requestPhoneRequired,
                disabled: this.themeForm.value.requestPhoneDisabled,
                visible: this.themeForm.value.requestPhoneVisible,
                width: this.themeForm.value.requestPhoneWidth,
              },
              requestIdentification: {
                label: this.themeForm.value.requestIdentificationLabel,
                placeholder:
                  this.themeForm.value.requestIdentificationPlaceholder,
                required: this.themeForm.value.requestIdentificationRequired,
                disabled: this.themeForm.value.requestIdentificationDisabled,
                visible: this.themeForm.value.requestIdentificationVisible,
                width: this.themeForm.value.requestIdentificationWidth,
              },
              uniquePhoneNumbersOnly: {
                label: this.themeForm.value.uniquePhoneNumbersOnlyLabel,
                placeholder:
                  this.themeForm.value.uniquePhoneNumbersOnlyPlaceholder,
                required: this.themeForm.value.uniquePhoneNumbersOnlyRequired,
                disabled: this.themeForm.value.uniquePhoneNumbersOnlyDisabled,
                visible: this.themeForm.value.uniquePhoneNumbersOnlyVisible,
                width: this.themeForm.value.uniquePhoneNumbersOnlyWidth,
              },
              uniqueMailsOnly: {
                label: this.themeForm.value.uniqueMailsOnlyLabel,
                placeholder: this.themeForm.value.uniqueMailsOnlyPlaceholder,
                required: this.themeForm.value.uniqueMailsOnlyRequired,
                disabled: this.themeForm.value.uniqueMailsOnlyDisabled,
                visible: this.themeForm.value.uniqueMailsOnlyVisible,
                width: this.themeForm.value.uniqueMailsOnlyWidth,
              },
              hideHeaderImage: {
                label: this.themeForm.value.hideHeaderImageLabel,
                placeholder: this.themeForm.value.hideHeaderImagePlaceholder,
                required: this.themeForm.value.hideHeaderImageRequired,
                disabled: this.themeForm.value.hideHeaderImageDisabled,
                visible: this.themeForm.value.hideHeaderImageVisible,
                width: this.themeForm.value.hideHeaderImageWidth,
              },
              noAdressChecks: {
                label: this.themeForm.value.noAdressChecksLabel,
                placeholder: this.themeForm.value.noAdressChecksPlaceholder,
                required: this.themeForm.value.noAdressChecksRequired,
                disabled: this.themeForm.value.noAdressChecksDisabled,
                visible: this.themeForm.value.noAdressChecksVisible,
                width: this.themeForm.value.noAdressChecksWidth,
              },
              dontRequestAddress: {
                label: this.themeForm.value.dontRequestAddressLabel,
                placeholder: this.themeForm.value.dontRequestAddressPlaceholder,
                required: this.themeForm.value.dontRequestAddressRequired,
                disabled: this.themeForm.value.dontRequestAddressDisabled,
                visible: this.themeForm.value.dontRequestAddressVisible,
                width: this.themeForm.value.dontRequestAddressWidth,
              },
              multiplePeople: {
                label: this.themeForm.value.multiplePeopleLabel,
                placeholder: this.themeForm.value.multiplePeoplePlaceholder,
                required: this.themeForm.value.multiplePeopleRequired,
                disabled: this.themeForm.value.multiplePeopleDisabled,
                visible: this.themeForm.value.multiplePeopleVisible,
                width: this.themeForm.value.multiplePeopleWidth,
              },
            },
          },
          voucherOptions: {
            title: this.themeForm.value.voucherOptionsTitle,
            visible: this.themeForm.value.voucherOptionsVisible,
            width: this.themeForm.value.voucherOptionsWidth,
            inputs: {
              sendSmsCoupon: {
                label: this.themeForm.value.sendSmsCouponLabel,
                placeholder: this.themeForm.value.sendSmsCouponPlaceholder,
                required: this.themeForm.value.sendSmsCouponRequired,
                disabled: this.themeForm.value.sendSmsCouponDisabled,
                visible: this.themeForm.value.sendSmsCouponVisible,
                width: this.themeForm.value.sendSmsCouponWidth,
              },
              claimInstantly: {
                label: this.themeForm.value.claimInstantlyLabel,
                placeholder: this.themeForm.value.claimInstantlyPlaceholder,
                required: this.themeForm.value.claimInstantlyRequired,
                disabled: this.themeForm.value.claimInstantlyDisabled,
                visible: this.themeForm.value.claimInstantlyVisible,
                width: this.themeForm.value.claimInstantlyWidth,
              },
              forceSpentFullAmount: {
                label: this.themeForm.value.forceSpentFullAmountLabel,
                placeholder:
                  this.themeForm.value.forceSpentFullAmountPlaceholder,
                required: this.themeForm.value.forceSpentFullAmountRequired,
                disabled: this.themeForm.value.forceSpentFullAmountDisabled,
                visible: this.themeForm.value.forceSpentFullAmountVisible,
                width: this.themeForm.value.forceSpentFullAmountWidth,
              },
              dontSyncWithApi: {
                label: this.themeForm.value.dontSyncWithApiLabel,
                placeholder: this.themeForm.value.dontSyncWithApiPlaceholder,
                required: this.themeForm.value.dontSyncWithApiRequired,
                disabled: this.themeForm.value.dontSyncWithApiDisabled,
                visible: this.themeForm.value.dontSyncWithApiVisible,
                width: this.themeForm.value.dontSyncWithApiWidth,
              },
              multipleUses: {
                label: this.themeForm.value.multipleUsesLabel,
                placeholder: this.themeForm.value.multipleUsesPlaceholder,
                required: this.themeForm.value.multipleUsesRequired,
                disabled: this.themeForm.value.multipleUsesDisabled,
                visible: this.themeForm.value.multipleUsesVisible,
                width: this.themeForm.value.multipleUsesWidth,
              },
              scannable: {
                label: this.themeForm.value.scannableLabel,
                placeholder: this.themeForm.value.scannablePlaceholder,
                required: this.themeForm.value.scannableRequired,
                disabled: this.themeForm.value.scannableDisabled,
                visible: this.themeForm.value.scannableVisible,
                width: this.themeForm.value.scannableWidth,
              },
              nameOnVoucher: {
                label: this.themeForm.value.nameOnVoucherLabel,
                placeholder: this.themeForm.value.nameOnVoucherPlaceholder,
                required: this.themeForm.value.nameOnVoucherRequired,
                disabled: this.themeForm.value.nameOnVoucherDisabled,
                visible: this.themeForm.value.nameOnVoucherVisible,
                width: this.themeForm.value.nameOnVoucherWidth,
              },
              priceOnTicket: {
                label: this.themeForm.value.priceOnTicketLabel,
                placeholder: this.themeForm.value.priceOnTicketPlaceholder,
                required: this.themeForm.value.priceOnTicketRequired,
                disabled: this.themeForm.value.priceOnTicketDisabled,
                visible: this.themeForm.value.priceOnTicketVisible,
                width: this.themeForm.value.priceOnTicketWidth,
              },
            },
          },
        },
        { merge: true }
      );
      this.saving = false;
      this.dialogRef.close();
    } catch (error) {
      this.saving = false;
      console.log(error);
    }
  }
}
