import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  Address,
  Appointment,
  AppointmentStatisticUser,
  AppointmentType,
  DayPart,
  DistanceLabel,
  PlanningUser,
} from 'src/app/interfaces';
import { ManageAppointmentComponent } from '../../dialogs/manage-appointment/manage-appointment.component';
import moment from 'moment';
import { AppointmentDetailsComponent } from '../../dialogs/appointment-details/appointment-details.component';
import {
  calculateMaximumAppointmentsInDayPart,
  getPlanningUsersStatistics,
} from '../../helper';
import { doc, getDoc } from '@firebase/firestore';
import { db } from 'src/app/app.component';

export interface AppointmentInfoData {
  appointment: Appointment;
  showEditButton: boolean;
  planningUsers: PlanningUser[];
}

@Component({
  selector: 'app-appointment-info-card',
  templateUrl: './appointment-info-card.component.html',
  styleUrls: ['./appointment-info-card.component.scss'],
})
export class AppointmentInfoCardComponent implements OnInit {
  moment = moment;
  townshipId = localStorage.getItem('township') as string;
  appointmentUsers: AppointmentUser[] = [];
  dayPart: DayPart;
  appointmentType: AppointmentType;
  planningUsersStatistics: AppointmentStatisticUser[] = [];
  maximumAppointmentsInDayPart: number;
  @Input() data: AppointmentInfoData;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AppointmentDetailsComponent>
  ) {}

  async ngOnInit() {
    moment.locale('nl');

    this.planningUsersStatistics = await getPlanningUsersStatistics(
      moment(this.data.appointment.start).format('YYYY-ww')
    );

    await this.getDayPart();
    await this.getAppointmentType();
    this.maximumAppointmentsInDayPart = calculateMaximumAppointmentsInDayPart(
      this.dayPart,
      this.appointmentType
    );

    this.data.appointment.planningUserIds?.forEach((userId) => {
      const user = this.getPlanningUser(userId);
      const userDistance = this.getPlanningUserDistance(userId);
      const plannedInDayPart = this.getPlanningUserStatisticsCount(
        userId,
        this.dayPart.id
      );
      const percentage =
        (100 / this.maximumAppointmentsInDayPart) * plannedInDayPart;
      const degrees = (360 / 100) * percentage;
      const circularProgress = `conic-gradient(var(--primary) ${degrees}deg, transparent 0deg)`;
      const appointmentUser: AppointmentUser = {
        id: userId,
        user: user,
        circularProgress: circularProgress,
        maxNumberOfAppointments: this.maximumAppointmentsInDayPart,
        amountOfAppointments: plannedInDayPart,
        distanceLabel: userDistance?.distanceLabel,
        lastAddress: userDistance?.lastAddress,
      };
      if (user) this.appointmentUsers.push(appointmentUser);
    });
  }

  async getDayPart() {
    const dayPartsRef = doc(
      db,
      `township/${this.townshipId}/dayParts/${this.data.appointment.dayPartId}`
    );
    this.dayPart = (await getDoc(dayPartsRef)).data() as DayPart;
    this.dayPart.id = this.data.appointment.dayPartId;
  }

  async getAppointmentType() {
    const appointmentTypeRef = doc(
      db,
      `township/${this.townshipId}/appointmentTypes/${this.data.appointment.appointmentTypeId}`
    );
    this.appointmentType = (
      await getDoc(appointmentTypeRef)
    ).data() as AppointmentType;
    this.appointmentType.id = this.data.appointment.appointmentTypeId;
  }

  getPlanningUserStatisticsCount(userId: string, dayPartId: string) {
    const user = this.planningUsersStatistics.find(
      (user) => user.id === userId
    );
    if (!user) {
      return 0;
    }
    const filteredAppointments = user.appointments.filter((appointment) => {
      return appointment.dayPartId == dayPartId;
    });
    return filteredAppointments.length;
  }

  capitalizeFirstLetter(val: string) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  getPlanningUser(id: string) {
    const planningUser = this.data.planningUsers.find((user) => user.id === id);
    return planningUser;
  }

  getPlanningUserDistance(id: string) {
    const planningUserDistance =
      this.data.appointment.planningUserDistance?.find(
        (user) => user.userId === id
      );
    return planningUserDistance;
  }

  editAppointment() {
    const dialogRef = this.dialog.open(ManageAppointmentComponent, {
      width: '500px',
      data: {
        appointment: this.data.appointment,
        planningUsers: this.data.planningUsers,
      },
      disableClose: true,
      panelClass: ['fullscreen-dialog'],
    });
    dialogRef.afterClosed().subscribe(async (changesMade) => {
      console.log('changesMade', changesMade);
      if (changesMade) {
        this.dialogRef.close();
      }
    });
  }
}

interface AppointmentUser {
  id: string;
  user: PlanningUser;
  circularProgress: string;
  maxNumberOfAppointments: number;
  amountOfAppointments: number;
  distanceLabel?: DistanceLabel;
  lastAddress?: Address;
}
