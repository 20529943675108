import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { encodeRouteParam, iframeUrl } from 'src/app/globals';
import { Theme, VoucherGroup } from 'src/app/interfaces';
import { ThemedVoucherGroupEditComponent } from '../themed-voucher-group-edit/themed-voucher-group-edit.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';

export interface DialogData {
  townshipId: string;
  voucherGroup: VoucherGroup;
  theme: Theme;
  details?: boolean;
}

@Component({
  selector: 'app-share-themed-voucher-group',
  templateUrl: './share-themed-voucher-group.component.html',
  styleUrls: ['./share-themed-voucher-group.component.scss'],
})
export class ShareThemedVoucherGroupComponent implements OnInit {
  shareUrl: string = '';
  voucherGroup: VoucherGroup;
  details: boolean;
  constructor(
    public dialogRef: MatDialogRef<ShareThemedVoucherGroupComponent>,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
  async ngOnInit(): Promise<void> {
    this.details = this.data.details ? this.data.details : false;
    this.shareUrl = await this.getIframeUrl(this.data.voucherGroup);
    this.voucherGroup = this.data.voucherGroup;
  }

  close() {
    this.dialogRef.close();
  }

  editVoucher() {
    this.dialogRef.close();
    let panelClass = [];
    panelClass.push('fullscreen-dialog');
    this.dialog.open(ThemedVoucherGroupEditComponent, {
      width: '500px',
      data: {
        voucherGroup: this.data.voucherGroup,
        townshipId: this.data.townshipId,
        theme: this.data.theme,
        newGroup: false,
      },
      disableClose: true,
      panelClass,
    });
  }

  async share() {
    const shareData = {
      title: `${this.data.theme.name}`,
      url: this.shareUrl,
    };
    try {
      await navigator.share(shareData);
      console.log('share successfull');
    } catch (err) {
      console.error('error while sharing', err);
      this.snackbar.open(
        'Je browser ondersteunt delen niet, kopieer de link aub zelf.',
        'X',
        {
          duration: 10000,
        }
      );
    }
  }

  copyLink() {
    navigator.clipboard.writeText(this.shareUrl);
    this.snackbar.open('Link gekopieerd.', 'X', {
      duration: 3000,
    });
  }

  async getIframeUrl(voucherGroup: VoucherGroup, language?: string) {
    let url = await iframeUrl(
      this.data.townshipId,
      this.db.firestore,
      language
    );
    let type = 'activate-tb';
    if (voucherGroup.type === 'sell') {
      type = 'buy';
    }
    const encodeTownshipId = encodeRouteParam(localStorage.getItem('township'));
    const encodeVoucherGroupId = encodeRouteParam(voucherGroup.id);
    url = `${url}/${type}/${encodeTownshipId}/${encodeVoucherGroupId}`;
    return url;
  }
}
