// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .receipt-dialog-title {
  padding: 0;
}

::ng-deep .receipt-dialog-content {
  padding: 16px 0 0 0 !important;
  overflow: hidden;
}
::ng-deep .receipt-dialog-content .webcam {
  margin-top: 6px;
  text-align: center;
}
::ng-deep .receipt-dialog-content .uploadOrWebcam {
  text-align: center;
}
::ng-deep .receipt-dialog-content .uploadOrWebcam button {
  width: 250px;
  margin: auto;
}
::ng-deep .receipt-dialog-content .uploadOrWebcam h4 {
  padding-bottom: 10px;
  width: 100%;
  text-align: center;
  margin: auto;
}
::ng-deep .receipt-dialog-content .uploadOrWebcam h5 {
  margin-top: 4px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 6px;
}
::ng-deep .receipt-dialog-content .webcam img {
  width: 500px;
  height: 400px;
}

::ng-deep .receipt-dialog-actions {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
::ng-deep .receipt-dialog-actions button {
  margin-top: 16px;
}
::ng-deep .receipt-dialog-actions .photo-buttons button {
  margin-top: 0px;
}
::ng-deep .receipt-dialog-actions .photo-buttons .done {
  color: green;
}
::ng-deep .receipt-dialog-actions .photo-buttons .retry {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/voucher-management/dialogs/receipt/receipt.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,8BAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;EACA,kBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;AACI;EACE,YAAA;EACA,YAAA;AACN;AACI;EACE,oBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AACN;AACI;EACE,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AACN;AAII;EACE,YAAA;EACA,aAAA;AAFN;;AAOA;EACE,UAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AAJF;AAKE;EACE,gBAAA;AAHJ;AAMI;EACE,eAAA;AAJN;AAMI;EACE,YAAA;AAJN;AAMI;EACE,UAAA;AAJN","sourcesContent":["::ng-deep .receipt-dialog-title {\n  padding: 0;\n}\n\n::ng-deep .receipt-dialog-content {\n  padding: 16px 0 0 0 !important;\n  overflow: hidden;\n\n  .webcam {\n    margin-top: 6px;\n    text-align: center;\n  }\n\n  .uploadOrWebcam {\n    text-align: center;\n    button {\n      width: 250px;\n      margin: auto;\n    }\n    h4 {\n      padding-bottom: 10px;\n      width: 100%;\n      text-align: center;\n      margin: auto;\n    }\n    h5 {\n      margin-top: 4px;\n      text-align: center;\n      font-weight: normal;\n      margin-bottom: 6px;\n    }\n  }\n\n  .webcam {\n    img {\n      width: 500px;\n      height: 400px;\n    }\n  }\n}\n\n::ng-deep .receipt-dialog-actions {\n  padding: 0;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  button {\n    margin-top: 16px;\n  }\n  .photo-buttons {\n    button {\n      margin-top: 0px;\n    }\n    .done {\n      color: green;\n    }\n    .retry {\n      color: red;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
