import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  userForm: UntypedFormGroup;
  saving: boolean = false;
  townshipId = localStorage.getItem('township') as string;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private fns: AngularFireFunctions
  ) {}

  ngOnInit(): void {
    this.userForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      passwordVerification: ['', Validators.required],
    });

    this.userForm.controls.password.valueChanges.subscribe((value) => {
      this.userForm.controls.passwordVerification.reset();
    });

    this.userForm.controls.passwordVerification.valueChanges.subscribe(
      (value) => {
        if (value !== this.userForm.controls.password.value) {
          this.userForm.controls.passwordVerification.setErrors({
            customError: true,
          });
        }
      }
    );
  }

  async save() {
    this.saving = true;
    try {
      const callable = this.fns.httpsCallable('httpUpdateUserPassword');
      const result = await lastValueFrom(
        callable({
          email: this.userForm.controls.email.value,
          password: this.userForm.controls.passwordVerification.value,
        })
      );
      console.log('result', result);
      if (result == 'succes') {
        this.snackBar.open(
          'Het wachtwoord is veranderd, er wordt nu een mail verstuurd naar de gebruiker.',
          'X',
          {
            duration: 5000,
          }
        );
        this.saving = false;
        this.dialogRef.close();
      } else if (result.code == 'auth/user-not-found') {
        this.snackBar.open(
          'Er kan geen gebruiker worden gevonden met opgegeven e-mailadres',
          'X',
          {
            duration: 5000,
          }
        );
        this.saving = false;
      }
    } catch (e) {
      this.saving = false;
      console.error(e);
    }
  }
}
