// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
#form mat-form-field {
  width: 100%;
}
#form .address-errors mat-icon {
  color: var(--primary) !important;
  margin-left: 6px !important;
}

::ng-deep .voucher-groups-dialog-title {
  padding: 0;
  display: flex;
  justify-content: space-between;
}

::ng-deep .voucher-groups-dialog-content {
  padding: 16px 0 0 0 !important;
}

::ng-deep .voucher-groups-dialog-actions {
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.activation-info {
  width: 100%;
  border: 1px solid rgba(241, 142, 6, 0.81);
  background-color: rgba(220, 128, 1, 0.16);
  box-shadow: 0px 0px 2px #ffb103;
  color: rgba(0, 0, 0, 0.9);
  padding: 8px;
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/helpdesk/dialogs/vouchergroups-dialog/vouchergroups-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,2BAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;AAEJ;AACI;EACE,gCAAA;EACA,2BAAA;AACN;;AAIA;EACE,UAAA;EACA,aAAA;EACA,8BAAA;AADF;;AAIA;EACE,8BAAA;AADF;;AAIA;EACE,UAAA;EACA,aAAA;EACA,yBAAA;AADF;;AAIA;EACE,WAAA;EACA,yCAAA;EACA,yCAAA;EACA,+BAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;AADF","sourcesContent":["#form {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  align-items: center;\n  mat-form-field {\n    width: 100%;\n  }\n  .address-errors {\n    mat-icon {\n      color: var(--primary) !important;\n      margin-left: 6px !important;\n    }\n  }\n}\n\n::ng-deep .voucher-groups-dialog-title {\n  padding: 0;\n  display: flex;\n  justify-content: space-between;\n}\n\n::ng-deep .voucher-groups-dialog-content {\n  padding: 16px 0 0 0 !important;\n}\n\n::ng-deep .voucher-groups-dialog-actions {\n  padding: 0;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.activation-info {\n  width: 100%;\n  border: 1px solid rgba(241, 142, 6, 0.81);\n  background-color: rgba(220, 128, 1, 0.16);\n  box-shadow: 0px 0px 2px #ffb103;\n  color: rgba(0, 0, 0, 0.9);\n  padding: 8px;\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
