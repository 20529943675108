import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralStatistics, Widget } from 'src/app/interfaces';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  collection,
  deleteField,
  doc,
  getDocs,
  getDoc,
  setDoc,
  where,
  query,
} from 'firebase/firestore';
import {
  calculateWidgetHeight,
  calculateBarOverflowWidth,
  calculateBarOverflowHeight,
  calculateBarHeight,
  calculateBarWidth,
  calculateBarThickness,
} from '../../widgets-helper';

@Component({
  selector: 'widget-vouchers-claimed',
  templateUrl: './vouchers-claimed.component.html',
  styleUrls: ['./vouchers-claimed.component.scss'],
})
export class VouchersClaimedComponent implements OnInit {
  @Input() widget: Widget;
  @Input() statistics: Observable<GeneralStatistics>;
  totalVouchers = 0;
  claimedVouchers = 0;
  percentage: number;
  degrees: number;
  widgetHeight: any;
  barOverflowHeight: any;
  barOverflowWidth: any;
  barHeight: any;
  barWidth: any;
  barThickness: any;

  constructor(public db: AngularFirestore) {}

  async ngOnInit(): Promise<void> {
    this.statistics.subscribe((statistics) => {
      console.log('statistics', statistics);
      this.claimedVouchers = statistics.claimedVouchers;
      this.totalVouchers = statistics.totalVouchers;
      this.percentage = (this.claimedVouchers / this.totalVouchers) * 100;
      this.degrees = 45 + this.percentage * 1.8;
    });

    const widgetInfo = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${localStorage.getItem('township')}/widgets/${
            this.widget.id
          }`
        )
      )
    ).data();

    this.barOverflowHeight = calculateBarOverflowHeight(widgetInfo.height);
    this.barOverflowWidth = calculateBarOverflowWidth(widgetInfo.height);
    this.barHeight = calculateBarHeight(widgetInfo.height);
    this.barWidth = calculateBarWidth(widgetInfo.height);
    this.barThickness = calculateBarThickness(widgetInfo.height);
    this.widgetHeight = calculateWidgetHeight(widgetInfo.height, 34);
  }
}
