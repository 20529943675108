// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page .verification-wrapper {
  margin: 0 auto 0 auto;
  width: 85%;
}
.page .verification-wrapper .container {
  padding: 16px 16px 10px 16px;
}
.page .verification-wrapper .container .form-item {
  margin: 10px 0 10px 0;
  display: flex;
}
.page .verification-wrapper .container .verify-button {
  display: flex;
  justify-content: flex-end;
}
.page .verification-wrapper .container .full-width {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/account/multi-factor/multi-factor.component.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;EACA,UAAA;AAAJ;AACI;EACE,4BAAA;AACN;AAAM;EACE,qBAAA;EACA,aAAA;AAER;AAAM;EACE,aAAA;EACA,yBAAA;AAER;AAAM;EACE,WAAA;AAER","sourcesContent":[".page {\n  .verification-wrapper {\n    margin: 0 auto 0 auto;\n    width: 85%;\n    .container {\n      padding: 16px 16px 10px 16px;\n      .form-item {\n        margin: 10px 0 10px 0;\n        display: flex;\n      }\n      .verify-button {\n        display: flex;\n        justify-content: flex-end;\n      }\n      .full-width {\n        width: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
