// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .check-vouchers-dialog-title {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::ng-deep .check-vouchers-dialog-content {
  padding: 16px 0 0 0 !important;
}
::ng-deep .check-vouchers-dialog-content #form .form-form-fields {
  display: flex;
  justify-content: space-around;
}
::ng-deep .check-vouchers-dialog-content #form .form-form-fields mat-form-field {
  width: 33%;
  box-sizing: border-box;
}
::ng-deep .check-vouchers-dialog-content #form .vouchers-worth {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
}
::ng-deep .check-vouchers-dialog-content #form .voucher-item {
  cursor: pointer;
  padding: 5px 0 5px 0;
}
::ng-deep .check-vouchers-dialog-content #form .voucher-item .voucher-item-number-date-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::ng-deep .check-vouchers-dialog-content #form .voucher-item .voucher-item-number-date-amount .voucher-item-number-date {
  display: flex;
  flex-direction: column;
}
::ng-deep .check-vouchers-dialog-content #form .voucher-item .voucher-item-number-date-amount .voucher-item-number-date .voucher-item-number {
  font-weight: 800;
}
::ng-deep .check-vouchers-dialog-content #form .voucher-item .voucher-item-number-date-amount .voucher-item-number-date .voucher-item-date {
  font-size: 14px;
}
::ng-deep .check-vouchers-dialog-actions {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/organisation-management/check-vouchers/check-vouchers.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,8BAAA;AACF;AACI;EACE,aAAA;EACA,6BAAA;AACN;AAAM;EACE,UAAA;EACA,sBAAA;AAER;AACI;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACN;AACI;EACE,eAAA;EACA,oBAAA;AACN;AAAM;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAER;AADQ;EACE,aAAA;EACA,sBAAA;AAGV;AAFU;EACE,gBAAA;AAIZ;AAFU;EACE,eAAA;AAIZ;AAMA;EACE,UAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAJF","sourcesContent":["::ng-deep .check-vouchers-dialog-title {\n  padding: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n::ng-deep .check-vouchers-dialog-content {\n  padding: 16px 0 0 0 !important;\n  #form {\n    .form-form-fields {\n      display: flex;\n      justify-content: space-around;\n      mat-form-field {\n        width: 33%;\n        box-sizing: border-box;\n      }\n    }\n    .vouchers-worth {\n      font-weight: bold;\n      display: flex;\n      align-items: center;\n      margin: 0 0 10px 0;\n    }\n    .voucher-item {\n      cursor: pointer;\n      padding: 5px 0 5px 0;\n      .voucher-item-number-date-amount {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        .voucher-item-number-date {\n          display: flex;\n          flex-direction: column;\n          .voucher-item-number {\n            font-weight: 800;\n          }\n          .voucher-item-date {\n            font-size: 14px;\n          }\n        }\n        .voucher-item-amount {\n        }\n      }\n    }\n  }\n}\n\n::ng-deep .check-vouchers-dialog-actions {\n  padding: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
