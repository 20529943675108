import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { doc, getDoc } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { Widget } from 'src/app/interfaces';
import { checkAndUpdateStats } from '../../bigquery-helper';
import { calculateWidgetHeight } from '../../widgets-helper';

@Component({
  selector: 'widget-vouchers-sold-per-org',
  templateUrl: './widget-vouchers-sold-per-org.component.html',
  styleUrls: ['./widget-vouchers-sold-per-org.component.scss'],
})
export class WidgetVouchersSoldPerOrgComponent implements OnInit {
  @Input() widget: Widget;
  statistics: Observable<any>;
  townshipId = localStorage.getItem('township');
  organisations: any[] = [];
  orgsToShow: any[];
  colorScheme = { domain: ['#1a697f'] };
  widgetHeight: number;
  chartHeight: string;
  amountToShow: number = 4;

  constructor(public db: AngularFirestore) {}

  async ngOnInit(): Promise<void> {
    checkAndUpdateStats(this.widget, 'bigqueryVouchersSoldPerOrg', {
      townshipId: this.townshipId,
    });

    this.statistics = this.db
      .doc<any>(`township/${this.townshipId}/statistics/vouchersSoldPerOrg`)
      .valueChanges();
    this.statistics.subscribe((stats) => {
      this.organisations = [];
      this.orgsToShow = [];
      console.log('vouchersClaimedPerOrg', stats);
      stats.soldList.forEach((stat) => {
        this.organisations.push({
          name: stat.activateOrganisation,
          value: stat.vouchersClaimed,
        });
        this.sortAddresses();
      });
      console.log('this.organisations', this.organisations);
      if (this.organisations.length > this.amountToShow) {
        this.orgsToShow = this.organisations.slice(0, this.amountToShow);
      } else {
        this.orgsToShow = this.organisations;
      }
    });

    const widgetInfo = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${localStorage.getItem('township')}/widgets/${
            this.widget.id
          }`
        )
      )
    ).data();
    this.widgetHeight = widgetInfo.height;
    this.chartHeight = calculateWidgetHeight(widgetInfo.height, 64);
  }

  sortAddresses() {
    this.organisations.sort((org1, org2) => {
      const a = org2.value;
      const b = org1.value;
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }
}
