export enum CommissionType {
  Provided = 'VERSTREKT',
  Upgraded = 'OPGEWAARDEERD',
}

export enum BurdenOfProofStatus {
  Submitted = 'Ingediend',
  Rejected = 'Afgekeurd',
  Accepted = 'Goedgekeurd',
  Concept = 'Concept',
}

export enum BadlyInsulatedParts {
  flatRoof = 'Dak (hellend/plat): slecht geïsoleerd als dikte isolatie < 9 cm. ',
  roofAttic = 'Zolder-/vlieringvloer: slecht geïsoleerd als er geen isolatie aanwezig is (als alternatief voor dakisolatie, alleen bij een onverwarmde zolder en gesloten vlieringluik of gesloten toegangsdeur).',
  facade = 'Gevel: slecht geïsoleerd als geen spouwmuurisolatie, buitengevelisolatie of voorzetwand aanwezig is.',
  floor = 'Vloer/bodem: slecht geïsoleerd als isolatie < 5 cm.',
  glas = 'Glas: slecht geïsoleerd als sprake is van enkel glas, oud dubbel glas of HR-glas.',
}

export enum VoucherCheckStatus {
  todo = `todo`,
  accepted = `accepted`,
  declined = `declined`,
}

export enum Reports {
  sisaReport = 'SiSa rapport',
}

export enum SubsidyOptionNames {
  co2Ventilation = 'CO2-gestuurde ventilatie',
  interiorFacadeInsulation = 'Isolatie binnengevel',
  insulatingGlassAndFramePanelsHigh = 'Isolerend glas en kozijnpanelen (Ug en Up ≤ 1,2 W/m2K) (HR++ glas)',
  exteriorFacadeInsulation = 'Isolatie buitengevel',
  insulatingDoorsHigh = 'Isolerende deuren (Ud ≤ 1,5 W/m2K)',
  exteriorRoofInsulation = 'Isolatie buitendak',
  balanceVentilationWithHeatRecovery = 'Balansventilatie met WTW',
  insulatingGlassAndFramePanelsLow = 'Isolerend glas en kozijnpanelen (Ug en Up ≤ 0,7 W/m2K) (Triple glas)',
  cavityWallInsulation = 'Isolatie spouwmuur',
  insulationBottom = 'Isolatie bodem',
  insulatingDoorsLow = 'Isolerende deuren (Ud ≤ 1,0 W/m2K i.c.m. nieuwe isolerende kozijnen Uf ≤ 1,5 W/m2K)',
  innerRoofInsulation = 'Isolatie binnendak',
  insulationGroundFloor = 'Isolatie vloer (begane grond)',
  atticLoftFloorInsulation = 'Isolatie zolder-/vlieringvloer (mits onverwarmde zolder)',
}

export enum AmbitionLevels {
  bronze = 'Brons',
  silver = 'Zilver',
  gold = 'Goud',
  platinum = 'Platina',
}

export enum ManagementRights {
  admin = 'Administrator',
  owner = 'Eigenaar',
}

export enum TownshipUserRights {
  controller = 'Controlleur',
  helpdesk = 'Helpdesk',
  financial = 'Financieel',
  statistics = 'Raportages',
}

export enum OrgUserRights {
  employee = 'Werknemer',
}

export enum PlanningUserRights {
  planner = 'Planner',
}

export enum TeamUserRights {
  coach = 'Coach',
  handyman = 'Klusser',
}

export enum ActionType {
  created = `created`,
  edited = `edited`,
  canceled = `canceled`,
  noshow = `noshow`,
  done = `done`,
}

export enum AppointmentTypeKind {
  coach = 'Coach',
  job = 'Klus',
  internal = 'Intern',
}