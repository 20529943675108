import { Component } from '@angular/core';

@Component({
  selector: 'app-teamuser-exception-delete-dialog',
  templateUrl: './teamuser-exception-delete-dialog.component.html',
  styleUrls: ['./teamuser-exception-delete-dialog.component.scss']
})
export class TeamuserExceptionDeleteDialogComponent {

}
