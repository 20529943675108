import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import moment from 'moment';
import { Observable } from 'rxjs';
import { Widget } from 'src/app/interfaces';
import { curveBasis } from 'd3-shape';
import { checkAndUpdateStats } from '../../bigquery-helper';
import { doc, getDoc } from 'firebase/firestore';
import { calculateWidgetHeight } from '../../widgets-helper';
import { range } from 'lodash';

@Component({
  selector: 'vouchers-line-chart',
  templateUrl: './vouchers-line-chart.component.html',
  styleUrls: ['./vouchers-line-chart.component.scss'],
})
export class VouchersLineChartComponent implements OnInit {
  @Input() widget: Widget;
  yearlyStatistics: Observable<any>;
  monthlyStatistics: Observable<any>;
  weeklyStatistics: Observable<any>;
  yearSelected: boolean = true;
  monthSelected: boolean = false;
  weekSelected: boolean = false;
  selected: string;
  chartData: any[] = [];
  yearlyChartData: any[] = [];
  monthlyChartData: any[] = [];
  weeklyChartData: any[] = [];
  townshipId = localStorage.getItem('township');
  colorScheme = { domain: ['#fc9f5b', '#62c890'] };
  curve: any = curveBasis;
  widgetHeight: any;
  chartHeight: any;

  constructor(public db: AngularFirestore) {}

  async ngOnInit(): Promise<void> {
    moment.locale('nl');
    this.selected = moment(new Date()).year().toString();
    checkAndUpdateStats(
      this.widget,
      'bigqueryVouchersPerYear',
      {
        townshipId: this.townshipId,
      },
      true
    );
    checkAndUpdateStats(
      this.widget,
      'bigqueryVouchersPerMonth',
      {
        townshipId: this.townshipId,
      },
      true
    );
    checkAndUpdateStats(
      this.widget,
      'bigqueryVouchersPerWeek',
      {
        townshipId: this.townshipId,
      },
      true
    );

    this.yearlyStatistics = this.db
      .doc<any>(`township/${this.townshipId}/statistics/yearly`)
      .valueChanges();
    this.yearlyStatistics.subscribe((stats) => {
      const claimedVouchers = this.createYearlyChartData(
        'Ingeleverd',
        stats.claimedVouchers
      );
      const activatedVouchers = this.createYearlyChartData(
        'Aangevraagd',
        stats.activatedVouchers
      );
      this.yearlyChartData.push(claimedVouchers);
      this.yearlyChartData.push(activatedVouchers);
      if (this.yearSelected) {
        this.chartData.push(claimedVouchers);
        this.chartData.push(activatedVouchers);
      }
      console.log('chartData yearly', this.yearlyChartData);
    });

    this.monthlyStatistics = this.db
      .doc<any>(`township/${this.townshipId}/statistics/monthly`)
      .valueChanges();
    this.monthlyStatistics.subscribe((stats) => {
      const claimedVouchers = this.createMonthlyChartData(
        'Ingeleverd',
        stats.claimedVouchers
      );
      const activatedVouchers = this.createMonthlyChartData(
        'Aangevraagd',
        stats.activatedVouchers
      );
      this.monthlyChartData.push(claimedVouchers);
      this.monthlyChartData.push(activatedVouchers);
      if (this.monthSelected) {
        this.chartData.push(claimedVouchers);
        this.chartData.push(activatedVouchers);
      }
      console.log('chartData monthly', this.monthlyChartData);
    });

    this.weeklyStatistics = this.db
      .doc<any>(`township/${this.townshipId}/statistics/weekly`)
      .valueChanges();
    this.weeklyStatistics.subscribe((stats) => {
      const claimedVouchers = this.createWeeklyChartData(
        'Ingeleverd',
        stats.claimedVouchers
      );
      const activatedVouchers = this.createWeeklyChartData(
        'Aangevraagd',
        stats.activatedVouchers
      );
      this.weeklyChartData.push(claimedVouchers);
      this.weeklyChartData.push(activatedVouchers);
      if (this.weekSelected) {
        this.chartData.push(claimedVouchers);
        this.chartData.push(activatedVouchers);
      }
      console.log('chartData weekly', this.weeklyChartData);
    });

    const widgetInfo = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${localStorage.getItem('township')}/widgets/${
            this.widget.id
          }`
        )
      )
    ).data();
    this.widgetHeight = calculateWidgetHeight(widgetInfo.height, 64);
    this.chartHeight = calculateWidgetHeight(widgetInfo.height, 180);
  }

  createYearlyChartData(name: string, array: any[]) {
    let yearMonth = '';
    if (name === 'Ingeleverd') {
      yearMonth = 'claimDate_month';
    } else if (name === 'Aangevraagd') {
      yearMonth = 'activateDate_month';
    }

    const monthsArray = this.fillMonthArray();

    if (array.length > 0) {
      array.forEach((element) => {
        const monthName = this.getMonthName(element[yearMonth]);
        monthsArray.find((month) => {
          if (month.name === monthName) {
            month.value = element.vouchers;
          }
        });
      });
    }

    const chartObj = {
      name: name,
      series: monthsArray,
    };
    return chartObj;
  }

  createMonthlyChartData(name: string, array: any[]) {
    let yearMonth = '';
    if (name === 'Ingeleverd') {
      yearMonth = 'claimDate_week';
    } else if (name === 'Aangevraagd') {
      yearMonth = 'activateDate_week';
    }

    const weeksArray = this.fillWeekArray(new Date());

    if (array.length > 0) {
      array.forEach((element) => {
        const weekNumber = this.getWeekNumber(element[yearMonth]);
        weeksArray.find((week) => {
          if (week.name === `Week ${weekNumber}`) {
            week.value = element.vouchers;
          }
        });
      });
    }

    const chartObj = {
      name: name,
      series: weeksArray,
    };
    return chartObj;
  }

  createWeeklyChartData(name: string, array: any[]) {
    let yearDay = '';
    if (name === 'Ingeleverd') {
      yearDay = 'claimDate_day';
    } else if (name === 'Aangevraagd') {
      yearDay = 'activateDate_day';
    }

    const daysArray = this.fillDaysArray();

    if (array.length > 0) {
      array.forEach((element) => {
        const dayName = this.getDayName(element[yearDay]);
        daysArray.find((day) => {
          if (day.name === dayName) {
            day.value = element.vouchers;
          }
        });
      });
    }

    const chartObj = {
      name: name,
      series: daysArray,
    };
    return chartObj;
  }

  getMonthName(monthInYear: string) {
    const monthNumber = parseInt(monthInYear.split('_').pop());
    return moment.monthsShort(monthNumber - 1); // -1 because moment.monthsShort range is 0-11 instead of 1-12
  }

  getWeekNumber(weekInYear: string) {
    return weekInYear.split('_').pop();
  }

  getDayName(dayInYear: string) {
    const dayNumber = parseInt(dayInYear.split('_').pop());
    const day = moment().dayOfYear(dayNumber);
    return day.format('dddd');
  }

  fillMonthArray() {
    const monthArray = [];
    const monthsShort = moment.monthsShort();
    monthsShort.forEach((month) => {
      monthArray.push({
        value: 0,
        name: month,
      });
    });
    return monthArray;
  }

  fillWeekArray(date: Date) {
    const startDate = moment(date).startOf('month').toDate();
    const endDate = moment(date).endOf('month').toDate();
    const startWeek = parseInt(moment(startDate).format('WW'));
    const endWeek = parseInt(moment(endDate).format('WW'));
    const weeksArray = [];
    const weeks = range(startWeek, endWeek + 1);
    weeks.forEach((week) => {
      weeksArray.push({
        value: 0,
        name: `Week ${week}`,
      });
    });

    return weeksArray;
  }

  fillDaysArray() {
    const daysArray = [];
    const days = moment.weekdays();
    days.forEach((day) => {
      daysArray.push({
        value: 0,
        name: day,
      });
    });
    return daysArray;
  }

  getWeekView() {
    this.yearSelected = false;
    this.weekSelected = true;
    this.monthSelected = false;
    this.chartData = this.weeklyChartData;
    this.selected = `Week ${moment(new Date()).format('WW')} ${moment(
      new Date()
    )
      .year()
      .toString()}`;
  }

  getMonthView() {
    this.yearSelected = false;
    this.weekSelected = false;
    this.monthSelected = true;
    this.chartData = this.monthlyChartData;
    const months = moment.months();
    const index = Number(moment(new Date()).format('MM')) - 1;
    const monthString = months[index].split('');
    const upperCase = monthString[0].toUpperCase();
    const lowerCase = months[index].split(monthString[0]).pop();
    const month = `${upperCase}${lowerCase} ${moment(new Date())
      .year()
      .toString()}`;
    this.selected = month;
  }

  getYearView() {
    this.yearSelected = true;
    this.weekSelected = false;
    this.monthSelected = false;
    this.chartData = this.yearlyChartData;
    this.selected = moment(new Date()).year().toString();
  }
}
