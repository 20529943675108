import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  DocumentReference,
  doc,
  setDoc,
  Timestamp,
  getDocs,
  collection,
  query,
  where,
} from 'firebase/firestore';
import { VoucherGroup, Widget } from 'src/app/interfaces';
import { getWidget, getWidgetsAsArray, WidgetForm } from '../../widgets-helper';
import { getInitialStats } from '../../bigquery-helper';

export interface DialogData {
  widget: Widget;
  order: number;
}

@Component({
  selector: 'app-widget-manage',
  templateUrl: './widget-manage.component.html',
  styleUrls: ['./widget-manage.component.scss'],
})
export class WidgetManageComponent implements OnInit {
  townshipId = localStorage.getItem('township') as string;
  newWidget: boolean = true;
  prevFormVal: any = {};
  widgetForm: FormGroup = new FormGroup({
    disabled: new FormControl(false),
    name: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    width: new FormControl('33.33333', [Validators.required]),
    height: new FormControl([Validators.required]),
  });
  addedWidgetOptions: any[];
  addedWidgetOptionsFg: FormGroup = new FormGroup({});
  possibleWidgets = getWidgetsAsArray();
  widgetId: string;
  widgetRef: DocumentReference;
  voucherGroups = [];
  saving: boolean = false;
  heightMin: number;
  heightMax: number;
  heightAdjust: boolean = true;
  noInitialStatsNeeded: boolean = false;
  defaultHeight: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public db: AngularFirestore,
    public dialogRef: MatDialogRef<WidgetManageComponent>,
    public http: HttpClient
  ) {}

  async ngOnInit(): Promise<void> {
    this.widgetForm.valueChanges.subscribe((val) => {
      const prevFormVal = { ...this.prevFormVal };
      this.prevFormVal = val;
      if (prevFormVal.type != val.type) {
        this.addedWidgetOptions = [];
        this.addedWidgetOptionsFg = new FormGroup({});
        if (val.type) {
          const widgetInfo = getWidget(val.type);
          if (widgetInfo?.forms) {
            widgetInfo.forms.forEach((form: WidgetForm) => {
              let value = this.data.widget?.settings
                ? this.data.widget.settings[form.id]
                : null;
              if (value instanceof Timestamp) {
                value = value.toDate();
              }
              const validators = [Validators.required];
              if (form.validators) {
                form.validators.forEach((validator) => {
                  validators.push(validator);
                });
              }
              this.addedWidgetOptionsFg.addControl(
                form.id,
                new FormControl(value, validators)
              );
              this.addedWidgetOptions.push(form);
            });
          }
          if (widgetInfo?.height) {
            this.defaultHeight = widgetInfo.height.defaultHeight;
            if (!widgetInfo.height.heightAdjustable) {
              this.widgetForm.controls['height'].disable();
              this.heightAdjust = false;
            } else {
              this.widgetForm.controls['height'].enable();
              this.heightAdjust = true;
            }
            this.heightMin = widgetInfo.height.minHeight;
            this.heightMax = widgetInfo.height.maxHeight;
            this.widgetForm.controls['height'].setValue(this.defaultHeight);
          }
        }
      }
    });
    if (!this.data.widget) {
      this.widgetId = this.db.createId();
    }
    if (this.data.widget) {
      this.newWidget = false;
      this.widgetId = this.data.widget.id;
      const patchObj = this.data.widget as any;
      this.widgetForm.patchValue(patchObj);
      this.widgetForm.controls['height'].setValue(this.data.widget.height);
    }
    this.widgetRef = doc(
      this.db.firestore,
      `township/${this.townshipId}/widgets/${this.widgetId}`
    );
    const q = query(
      collection(
        this.db.firestore,
        `township/${this.townshipId}/voucherGroups`
      ),
      where('hidden', '!=', true)
    );
    const voucherGroupDocs = await getDocs(q);
    voucherGroupDocs.forEach((doc) => {
      const voucherGroup = doc.data() as VoucherGroup;
      const selectOption = { name: voucherGroup.name, id: doc.id };
      this.voucherGroups.push(selectOption);
    });
  }

  async save() {
    if (
      this.saving ||
      !this.widgetForm.valid ||
      !this.addedWidgetOptionsFg.valid
    ) {
      this.widgetForm.markAllAsTouched();
      this.addedWidgetOptionsFg.markAllAsTouched();
      return;
    }
    this.saving = true;
    let saveObj = { ...this.widgetForm.value } as Widget;
    if (!this.heightAdjust) {
      saveObj.height = this.defaultHeight;
    }
    saveObj.settings = { ...this.addedWidgetOptionsFg.value };
    console.log('saveObj', saveObj);
    saveObj.order = this.data.order;

    await setDoc(this.widgetRef, saveObj, { merge: true });
    saveObj.id = this.widgetId;
    if (this.widgetForm.value.type) {
      let callable;
      let params;
      switch (this.widgetForm.value.type) {
        case 'daily':
          callable = 'bigqueryStatisticsOfToday';
          params = {
            townshipId: this.townshipId,
          };
          break;
        case 'vouchersClaimedPerOrg':
          //localstorage is set, so when the user returns to the Statistics page, it refreshes. Fix for frontend.
          localStorage.setItem('rfWidget', '0');
          callable = 'bigqueryVouchersClaimedPerOrg';
          params = {
            townshipId: this.townshipId,
          };
          break;
        case 'homesPreserved':
          callable = 'bigqueryHomesPreserved';
          params = {
            townshipId: this.townshipId,
            fromDate: saveObj.settings.fromDate,
            widgetId: saveObj.id,
          };
          break;
        case 'transactionsPerOrg':
          callable = 'bigqueryTransactionsPerOrg';
          params = {
            townshipId: this.townshipId,
            widgetId: saveObj.id,
          };
          break;
        case 'vouchersSoldPerOrg':
          callable = 'bigqueryVouchersSoldPerOrg';
          params = {
            townshipId: this.townshipId,
          };
          break;
        case 'vouchersInGivenPeriod':
          callable = 'bigqueryVouchersPerYear';
          params = {
            townshipId: this.townshipId,
          };
          break;
        case 'vouchersSoldPerOrg':
          callable = 'bigqueryVouchersSoldPerOrg';
          params = {
            townshipId: this.townshipId,
          };
          break;
        case 'totalWorthSoldVouchers':
          callable = 'bigqueryTotalWorthSoldVouchers';
          params = {
            townshipId: this.townshipId,
            fromDate: saveObj.settings.fromDate,
            widgetId: saveObj.id,
          };
          break;
        default:
          this.noInitialStatsNeeded = true;
          break;
      }
      if (!this.noInitialStatsNeeded) {
        getInitialStats(saveObj.id, callable, params);
      }
    }
    this.dialogRef.close(saveObj);
  }
}
