import { Component, NgZone, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Order, User } from '../interfaces';
import { OrderComponent } from './dialogs/order/order.component';
// import { EditTagComponent } from './edit-tag/edit-tag.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  env = environment;
  userDoc: AngularFirestoreDocument<User>;
  userData: User;
  user: Observable<User>;
  orderCollection: AngularFirestoreCollection<Order>;
  orders: Observable<Order[]>;
  townShipRef: string;
  filterForm: UntypedFormGroup;
  filterFormChanged: Subject<object> = new Subject<object>();
  public filteredOrders: ReplaySubject<Order[]> = new ReplaySubject<Order[]>(1);
  totalFilteredOrders: number;
  amountToShow = 25;
  amountToShowChanged: Subject<number> = new Subject<number>();
  loaded = false;
  loading = true;

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      search: [''],
      status: ['all'],
    });
    this.filterForm.valueChanges.subscribe((val) => {
      this.amountToShow = 25;
      this.amountToShowChanged.next(this.amountToShow);
      this.filterFormChanged.next(val);
    });
    this.afAuth.user.forEach((user) => {
      this.userDoc = this.db.doc<User>('users/' + user.uid);
      this.user = this.userDoc.valueChanges();
      this.user.forEach((userDoc) => {
        this.userData = userDoc;
        this.townShipRef = '/township/' + userDoc.township + '/';
        this.orderCollection = this.db.collection<Order>(
          '/township/' + userDoc.township + '/orders',
          (ref) => ref.orderBy('paidDate', 'desc').limit(500)
        );
        this.orders = this.orderCollection.snapshotChanges().pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as any;
              data.id = a.payload.doc['id'];
              if (data.invoiceNumber && typeof data.invoiceNumber == 'number') {
                // Used to change old invoiceNumbers to be changed to strings
                data.invoiceNumber = data.invoiceNumber.toString();
              }
              if (!data.vouchers) {
                data.vouchers = [];
              }
              return { ...(data as Order) };
            })
          )
        );
        const combinedObservable = combineLatest([
          this.orders,
          this.filterFormChanged,
          this.amountToShowChanged.pipe(debounceTime(300)),
        ]);
        combinedObservable.pipe(distinctUntilChanged()).subscribe((values) => {
          const orders = values[0];
          const filters = values[1];
          const amountToShow = values[2];
          let filteredOrders = orders.filter((order) => {
            let passesSearchFilter = true;
            let passesStatusFilter = true;
            if (filters['search'].length > 0) {
              passesSearchFilter = false;
              const searchVal = filters['search'].toLowerCase();
              if (filters['search'])
                if (
                  order.invoiceNumber &&
                  order.invoiceNumber.toLowerCase().includes(searchVal)
                ) {
                  passesSearchFilter = true;
                }
              if (
                order.trxId &&
                order.trxId.toLowerCase().includes(searchVal)
              ) {
                passesSearchFilter = true;
              }
              if (
                order.mollieOrderId &&
                order.mollieOrderId.toLowerCase().includes(searchVal)
              ) {
                passesSearchFilter = true;
              }
              if (
                order.customerEmail &&
                order.customerEmail.toLowerCase().includes(searchVal)
              ) {
                passesSearchFilter = true;
              }
              if (
                order.customerName &&
                order.customerName.toLowerCase().includes(searchVal)
              ) {
                passesSearchFilter = true;
              }
              if (
                order.customerPostal &&
                order.customerPostal.toLowerCase().includes(searchVal)
              ) {
                passesSearchFilter = true;
              }
              if (
                order.customerCity &&
                order.customerCity.toLowerCase().includes(searchVal)
              ) {
                passesSearchFilter = true;
              }
              if (
                order.customerAddress &&
                order.customerAddress.toLowerCase().includes(searchVal)
              ) {
                passesSearchFilter = true;
              }
              if (
                order.voucherGroupName &&
                order.voucherGroupName.toLowerCase().includes(searchVal)
              ) {
                passesSearchFilter = true;
              }
            }
            if (filters['status'] != 'all') {
              if (filters['status'] == 'paid' && order.status != 'paid') {
                passesStatusFilter = false;
              } else if (
                filters['status'] == 'done' &&
                order.status != 'done'
              ) {
                passesStatusFilter = false;
              }
            }
            if (passesSearchFilter && passesStatusFilter) {
              return true;
            } else {
              return false;
            }
          });
          this.totalFilteredOrders = filteredOrders.length;

          this.filteredOrders.next(filteredOrders.slice(0, amountToShow));
          this.loaded = true;
          this.loading = false;
        });
        this.filterFormChanged.next(this.filterForm.value);
        this.amountToShowChanged.next(this.amountToShow);
      });
    });

    this.zone.runOutsideAngular(() => {
      window.addEventListener('scroll', async (e) => {
        if (
          window.innerHeight + window.scrollY >=
          document.body.scrollHeight - 5
        ) {
          // you're at the bottom of the page
          this.zone.run(() => {
            this.loadMore();
          });
        }
      });
    });
  }

  loadMore() {
    if (this.totalFilteredOrders <= this.amountToShow || this.loading) {
      return;
    }
    this.loading = true;
    this.amountToShow = this.amountToShow + 25;
    this.amountToShowChanged.next(this.amountToShow);
  }

  getStatusColor(order: Order) {
    let color = 'white';
    switch (order.status) {
      case 'reserved':
        color = 'red';
        break;
      case 'paid':
        color = '#fc9f5b';
        break;
      case 'done':
        color = '#62C890';
        break;
    }
    return color;
  }
  getCurrencyString(number) {
    if (number.toString().indexOf('.') == -1) {
      return `€${number.toString()},-`;
    }
    return `€${number.toFixed(2).replace('.', ',')}`;
  }
  openOrder(order: Order) {
    const orderRef = this.db.doc(
      `township/${this.userData.township}/orders/${order.id}`
    );
    this.dialog.open(OrderComponent, {
      width: '375px',
      data: { orderRef: orderRef },
    });
  }
}
