// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  height: calc(100vh - 2rem);
}
.page .page-title {
  width: auto;
}

.widgets-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100vh;
  align-items: center;
}
.widgets-wrapper .widget-wrapper {
  width: 100%;
}

.page-title {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/statistics/single-widget/single-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;AAAE;EACE,WAAA;AAEJ;;AACA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AAEF;AADE;EACE,WAAA;AAGJ;;AAAA;EACE,gBAAA;AAGF","sourcesContent":[".page {\n  height: calc(100vh - 2rem);\n  .page-title {\n    width: auto;\n  }\n}\n.widgets-wrapper {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  height: 100vh;\n  align-items: center;\n  .widget-wrapper {\n    width: 100%;\n  }\n}\n.page-title {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
