import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import moment from 'moment';
import { db } from 'src/app/app.component';
import { DayPart } from 'src/app/interfaces';
import { CustomValidators } from 'src/app/validators/custom-validators';

@Component({
  selector: 'app-day-part-edit',
  templateUrl: './day-part-edit.component.html',
  styleUrls: ['./day-part-edit.component.scss'],
})
export class DayPartEditComponent implements OnInit {
  townshipId: string = localStorage.getItem('township');
  saving: boolean = false;
  loaded: boolean = false;
  dayPart: DayPart;
  minHour: number = 0;
  maxHour: number = 23;
  dayPartForm: UntypedFormGroup = this.fb.group({
    name: new UntypedFormControl({ value: null, disabled: true }),
    part: new UntypedFormControl({ value: null, disabled: true }),
    enabled: new UntypedFormControl(true),
    startHour: new UntypedFormControl(null, [
      (control: AbstractControl) => Validators.min(this.minHour)(control),
      (control: AbstractControl) => Validators.max(this.maxHour)(control),
      CustomValidators.numberInput(false, false, 0),
    ]),
    startMinutes: new UntypedFormControl(null, [
      Validators.min(0),
      Validators.max(59),
      CustomValidators.numberInput(false, false, 0),
    ]),
    endHour: new UntypedFormControl(null, [
      (control: AbstractControl) => Validators.min(this.minHour)(control),
      (control: AbstractControl) => Validators.max(this.maxHour)(control),
      CustomValidators.numberInput(false, false, 0),
    ]),
    endMinutes: new UntypedFormControl(null, [
      Validators.min(0),
      Validators.max(59),
      CustomValidators.numberInput(false, false, 0),
    ]),
  });

  constructor(
    private fb: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      const ref = doc(db, `township/${this.townshipId}/dayParts/${id}`);
      this.dayPart = { id: id, ...(await getDoc(ref)).data() } as DayPart;
    } else {
      this.router.navigate(['day-parts']);
    }
    if (this.dayPart.name == 'Ochtend') {
      this.maxHour = 12;
    } else if (this.dayPart.name == 'Middag') {
      this.minHour = 13;
    }
    this.dayPartForm.patchValue(this.dayPart);
    this.toggleHandler();
    this.loaded = true;
  }

  toggleHandler() {
    const controls = this.dayPartForm.controls;
    const timeControls = ['startHour', 'startMinutes', 'endHour', 'endMinutes'];

    if (controls.enabled.value) {
      for (const control of timeControls) {
        controls[control].enable();
        controls[control].addValidators(Validators.required);
      }
    } else {
      for (const control of timeControls) {
        controls[control].disable();
        controls[control].removeValidators(Validators.required);
        controls[control].updateValueAndValidity();
      }
    }
  }

  async save() {
    this.dayPartForm.markAllAsTouched();
    if (!this.dayPartForm.valid) {
      return;
    }
    const saveObj = this.dayPartForm.value;
    if (saveObj.enabled && this.checkTimeOverlap(saveObj)) {
      this.snackbar.open(
        'De starttijd moet plaatsvinden voor de eindtijd',
        'X',
        {
          duration: 5000,
        }
      );
      return;
    }

    const docRef = doc(
      db,
      `/township/${this.townshipId}/dayParts/${this.dayPart.id}`
    );
    try {
      await setDoc(docRef, saveObj, { merge: true });
    } catch (error) {
      console.log(error);
      this.snackbar.open(
        'Er is iets fout gegaan met het opslaan van de gegevens',
        'X',
        {
          duration: 5000,
        }
      );
      return;
    }
    this.router.navigate(['day-parts']);
  }

  checkTimeOverlap(obj) {
    const saveStartTime = moment().set({
      hour: obj.startHour,
      minutes: obj.startMinutes,
    });
    const saveEndTime = moment().set({
      hour: obj.endHour,
      minutes: obj.endMinutes,
    });
    if (saveStartTime >= saveEndTime) return true;
    return false;
  }

  getError(control) {
    const field = this.dayPartForm.get(control);
    if (field.touched || !field.pristine) {
      let error: string;
      if (field.hasError('required')) {
        error = 'Dit veld is verplicht';
        return error;
      }
      if (field.hasError('min')) {
        if (control == 'startHour') {
          error = `De startijd mag niet eerder dan ${
            this.minHour != 0 ? this.minHour + 'u' : 'middernacht'
          } zijn`;
        } else if (control == 'endHour') {
          error = `De eindtijd mag niet eerder dan ${
            this.minHour != 0 ? this.minHour + 'u' : 'middernacht'
          } zijn`;
        } else {
          error = 'De ingestelde minuut moet tussen 0 en 59 zijn';
        }
        return error;
      }
      if (field.hasError('max')) {
        if (control == 'startHour') {
          error = `De startijd mag niet later dan ${
            this.maxHour != 23 ? this.maxHour + 'u' : 'middernacht'
          } zijn`;
        } else if (control == 'endHour') {
          error = `De eindtijd mag niet later dan ${
            this.maxHour != 23 ? this.maxHour + 'u' : 'middernacht'
          } zijn`;
        } else {
          error = 'De ingestelde minuut moet tussen 0 en 59 zijn';
        }
        return error;
      }
      if (field.hasError('numberInput')) {
        error = 'Het ingevulde getal mag niet decimaal zijn';
      }
      return error;
    }
    return '';
  }
}
