// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
  text-align: center;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 12px;
}

::ng-deep .action-list-dialog-title {
  padding: 0;
}

::ng-deep .action-list-dialog-content {
  padding: 16px 0 0 0 !important;
}

::ng-deep .action-list-dialog-actions {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.content-button-wrapper {
  display: flex;
  flex-direction: column;
}
.content-button-wrapper button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/voucher-management/dialogs/action-list/action-list.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAKA;EACE,UAAA;AAFF;;AAKA;EACE,8BAAA;AAFF;;AAKA;EACE,UAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;AAFF;AAGE;EACE,WAAA;AADJ","sourcesContent":[".text {\n  text-align: center;\n  font-weight: bold;\n  margin-top: 12px;\n  margin-bottom: 12px;\n}\n// .file-name {\n//   font\n// }\n\n::ng-deep .action-list-dialog-title {\n  padding: 0;\n}\n\n::ng-deep .action-list-dialog-content {\n  padding: 16px 0 0 0 !important;\n}\n\n::ng-deep .action-list-dialog-actions {\n  padding: 0;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.content-button-wrapper {\n  display: flex;\n  flex-direction: column;\n  button {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
