import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-sepa-confirmation',
  templateUrl: './sepa-confirmation.component.html',
  styleUrls: ['./sepa-confirmation.component.scss'],
})
export class SepaConfirmationComponent implements OnInit {
  items: string[] = [];
  confirmationFormGroup: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<SepaConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    const sepaChecked = this.data.sepaChecked;
    const excelChecked = this.data.excelChecked;
    const invoiceChecked = this.data.invoiceChecked;

    this.confirmationFormGroup = this.fb.group({
      excel: [excelChecked],
      sepa: [sepaChecked],
      invoice: [invoiceChecked],
    });

    if (excelChecked) {
      this.items.push('Er wordt een Excel bestand aangemaakt.');
    } else {
      this.items.push('Er wordt geen Excel bestand aangemaakt.');
    }

    if (sepaChecked) {
      this.items.push('Er wordt een SEPA bestand aangemaakt.');
    } else {
      this.items.push('Er wordt geen SEPA bestand aangemaakt.');
    }

    if (invoiceChecked) {
      this.items.push('Er wordt een factuur bestand aangemaakt.');
    } else {
      this.items.push('Er wordt geen factuur bestand aangemaakt.');
    }
  }

  save() {
    this.dialogRef.close(true);
  }
}

// set all vouchers paidDate to date of today use collectionGroup with paymentReference
