import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-appointment-type-dialog',
  templateUrl: './delete-appointment-type-dialog.component.html',
  styleUrls: ['./delete-appointment-type-dialog.component.scss'],
})
export class DeleteAppointmentTypeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteAppointmentTypeDialogComponent>
  ) {}
  close(confirm: boolean) {
    this.dialogRef.close(confirm);
  }
}
