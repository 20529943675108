import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { db } from 'src/app/app.component';

@Component({
  selector: 'app-manage-particularity',
  templateUrl: './manage-particularity.component.html',
  styleUrls: ['./manage-particularity.component.scss'],
})
export class ManageParticularityComponent implements OnInit {
  townshipId;
  particularity;
  particularityForm: UntypedFormGroup = this.fb.group({
    info: new UntypedFormControl('', Validators.maxLength(40)),
  });
  svgUploaded: any;
  svgAlreadyUploaded: string;
  infoLength = 0;
  loaded = false;
  saving = false;

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.townshipId = localStorage.getItem('township') as string;
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id != 'new') {
      const ref = doc(
        db,
        `township/${this.townshipId}/planningParticularities/${id}`
      );
      this.particularity = { id: id, ...(await getDoc(ref)).data() };
      this.particularityForm.patchValue(this.particularity);
      this.infoLength = this.particularity.info.length;
      if (this.particularity.iconPath) {
        this.svgAlreadyUploaded = this.particularity.iconPath;
      }
    }
    this.loaded = true;
  }

  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }

  async downloadFile() {
    const downloadUrl = this.particularity.iconPath;
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const blob = xhr.response;
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = `${this.particularity.id}.svg`;
      a.dispatchEvent(new MouseEvent('click'));
    };
    xhr.open('GET', downloadUrl);
    xhr.send();
  }

  uploadedFile(event) {
    const file = event.target.files[0];
    if (file.type != 'image/svg+xml') {
      this.snackBar.open('Het gekozen bestand is geen .svg', 'X', {
        duration: 5000,
      });
      return;
    }
    this.svgUploaded = file;
    const imgElement = document.getElementById('svgPreview');
    imgElement.setAttribute('src', URL.createObjectURL(file));
  }

  async save() {
    if (this.saving) {
      return;
    }
    this.particularityForm.markAllAsTouched();
    if (!this.particularityForm.valid) {
      return;
    }
    if (!this.svgUploaded && !this.svgAlreadyUploaded) {
      this.snackBar.open('Er moet een .svg bestand ge-upload worden', 'X', {
        duration: 5000,
      });
      return;
    }
    let docId = this.particularity?.id;
    if (!this.particularity) {
      docId = (
        await addDoc(
          collection(
            db,
            `/township/${this.townshipId}/planningParticularities`
          ),
          { default: false }
        )
      ).id;
    }
    const data = this.particularityForm.value;
    if (this.svgUploaded) {
      const storageRef = ref(
        getStorage(),
        `${this.townshipId}/particularities/${docId}`
      );
      const uploadTask = await uploadBytes(storageRef, this.svgUploaded);
      data.iconPath = await getDownloadURL(uploadTask.ref);
    }
    const docRef = doc(
      db,
      `/township/${this.townshipId}/planningParticularities/${docId}`
    );
    await setDoc(docRef, data, { merge: true });
    this.router.navigate(['planner-settings'], { state: { tabIndex: 1 } });
  }
}
