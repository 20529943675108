// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper {
  width: 85%;
  margin: 0 auto 0 auto;
}
.content-wrapper .item-form .half-fields {
  width: 100%;
  display: flex;
  gap: 15px;
}
.content-wrapper .item-form mat-form-field {
  width: 100%;
}
.content-wrapper .item-form mat-slide-toggle {
  margin: 0 0 1rem 0;
}
.content-wrapper .subtitle {
  font-weight: bold;
  margin: 0 0 0.5rem 0;
}
.content-wrapper .action-buttons {
  margin: 3rem 0 0 0;
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/app/day-parts/pages/exception-edit/exception-edit.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,qBAAA;AACF;AACI;EACE,WAAA;EACA,aAAA;EACA,SAAA;AACN;AACI;EACE,WAAA;AACN;AACI;EACE,kBAAA;AACN;AAEE;EACE,iBAAA;EACA,oBAAA;AAAJ;AAEE;EACE,kBAAA;EACA,aAAA;EACA,8BAAA;AAAJ","sourcesContent":[".content-wrapper {\n  width: 85%;\n  margin: 0 auto 0 auto;\n  .item-form {\n    .half-fields {\n      width: 100%;\n      display: flex;\n      gap: 15px;\n    }\n    mat-form-field {\n      width: 100%;\n    }\n    mat-slide-toggle {\n      margin: 0 0 1rem 0;\n    }\n  }\n  .subtitle {\n    font-weight: bold;\n    margin: 0 0 0.5rem 0;\n  }\n  .action-buttons {\n    margin: 3rem 0 0 0;\n    display: flex;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
