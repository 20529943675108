import { Component } from '@angular/core';

@Component({
  selector: 'app-appointment-delete',
  templateUrl: './appointment-delete.component.html',
  styleUrls: ['./appointment-delete.component.scss']
})
export class AppointmentDeleteComponent {

}
