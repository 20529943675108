import { Component } from '@angular/core';

export interface DialogData {
  type: string;
}

@Component({
  selector: 'app-daypart-delete-dialog',
  templateUrl: './day-part-delete-dialog.component.html',
  styleUrls: ['./day-part-delete-dialog.component.scss'],
})
export class DayPartDeleteDialogComponent {}
